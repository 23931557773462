import React, { useState, useEffect } from 'react';
import {
    Row, Col, Card, Modal, Tag, Button,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileDetails, postSkillMacthes, addToMatches, removeFromMatches } from '../../../redux/actions';
import '../ProfileD.css'
import schedule_icon from '../../../assets/icons/healthicons_i-schedule-school-date-time-outline.png'
import cil_chat_bubble from '../../../assets/icons/colorless_cil_chat-bubble.png';
import { retractBid } from '../../../redux/actions';
// import PlaceBidModal from "../../components/PlaceBidModal/PlaceBidModal";
import moment from "moment";
import PlaceBidModal from '../../../components/PlaceBidModal/PlaceBidModal';
import { LoadingOutlined } from '@ant-design/icons';


export const Inprogressbtns = () => {
    // <>
    //     <Row style={{ textAlign: 'center', width: '94%', margin: 'auto' }}>
    //         <Col className='edit-btn-div' xs={12}>
    //             <Button className='edit-bid-btn'>Edit Bid</Button>
    //         </Col>
    //         <Col className='retract-bid-btn-div' xs={12}>
    //             <Button className='retract-btn' onClick={showModal}>Retract Bid</Button>
    //         </Col>
    //     </Row>
    //     <Col lg={24} xs={24} ><button className='place-Bid-btn' onClick={placeABidFunction}>Place a Bid</button></Col>
    //     <Col lg={24} xs={24} ><button className='Bid-btn'>
    //         <img src={schedule_icon} />
    //         Schedule an Interview</button></Col>
    //     <Col lg={24} xs={24} ><button className='msg-btn'>job-listing.img
    //         <img src={cil_chat_bubble} className='social-icon' />
    //         Message Candidate</button></Col>
    // </>
}

export const HiredStatusbtns = ({ chaticon }) => {
    return (
        <>
            <Col lg={24} xs={24} >
                <button className='place-Bid-btn-disabled'>
                    Place a Bid
                </button>
            </Col>
            <Col lg={24} xs={24} ><button className='Bid-btn-disabled'>
                <img src={schedule_icon} />
                Schedule an Interview</button>
            </Col>
            <Col lg={24} xs={24} ><button className='msg-btn'>
                <img src={chaticon} className='social-icon' />
                Message Candidate</button>
            </Col>

            <p style=
                {{
                    textAlign: 'left',
                    paddingLeft: '3%',
                    fontSize: '12px',
                    color: '#7e7e7e',
                    marginTop: '2%'
                }}>
                Note : If the candidate is in Hired / On Credentialing / On Assignment you are not allowed to bid them</p>
        </>
    )
}

export const PlacedBidBtns = (props) => {
    const dispatch = useDispatch();

    const [visible, setVisible] = useState(false);
    const [visible2, setVisible2] = useState(false);

    const [placebidModal, setplacebidModal] = useState(false);

    const RetractLoading = useSelector(state => state.retractBidReducers.loading)
    const RetractSuccess = useSelector(state => state.retractBidReducers.success)
    const bidUpdateSuccess = useSelector(state => state.updateBidReducers.success)


    const Pdetails = useSelector(
        (state) => state.getProfileDetailsReducer.details
    );

    const userSKillListing = useSelector(
        (state) => state.oneUserskillListingReducer.skillListings
    );

    const showModal = () => {
        setVisible(true);
    };

    const showModal2 = () => {
        setVisible2(true);
    };

    const handleOk = () => {
        dispatch(retractBid({
            BidStatusName: 'Retracted',
            id: props.userSkillData.bid[0]._id, Message: "Bid was retracted",
            SkillListingId: props.userSkillData.bid[0].SkillListingId
        }));
    };

    useEffect(() => {
        if (RetractSuccess) {
            setVisible(false);
        }
    }, [RetractSuccess])

    const handleCancel = () => {
        setVisible(false);
    };


    var candidateList = [
        {
            id: Pdetails?.skill_listings?._id,
            //  img: profilePic && profilePic,
            img: Pdetails?.user_Schema?.profile.PictureUrl,
            name: Pdetails.user_Schema ? Pdetails.user_Schema.name : "",
            experience: Pdetails.experience ? Pdetails.experience : "0-0",
            title: Pdetails?.skill_listings?.Title,
            desired_rate: Pdetails?.skill_listings?.MinimumBid,
            bidType: Pdetails?.skill_listings?.MinimumBidType,

            //   profileType: profileTypeRes?.profile?.ProfessionCode
        },
    ];

    const placeBidModalVisisblityHandler = (value) => {
        setplacebidModal(value);
    };

    return (
        <>
            <Modal
                title="Retract Bid?"
                visible={visible}
                // okText={'Yes'}
                // cancelText={'No'}
                footer={[
                    <>
                        <Button onClick={handleCancel} className='modal-no-btn'>No</Button>
                        <Button onClick={handleOk} className='modal-ok-btn'>{RetractLoading ? <LoadingOutlined /> : 'Yes'}</Button>
                    </>
                ]}
            >
                <p>Are you sure you want to retract your placed
                    bid?</p>
            </Modal>

            <PlaceBidModal
                visible={visible2}
                placeBidModalVisisblityHandler={setVisible2}
                multiBid={false}
                EditBid={true}

                Description={bidUpdateSuccess?.Comments ?? userSKillListing?.bid[0]?.Comments}
                IdealConLength={bidUpdateSuccess?.IdealContractLength ?? userSKillListing?.bid[0]?.IdealContractLength}
                IdealStartDate={bidUpdateSuccess?.IdealStartDate ? moment(bidUpdateSuccess?.IdealStartDate).format('YYYY-MM-DD') : moment(userSKillListing?.bid[0]?.IdealStartDate).format('YYYY-MM-DD')}
                //desired_rate={userSKillListing?.bid[0]?.Rate}
                desired_rate={bidUpdateSuccess?.Rate ?? Pdetails?.skill_listings?.MinimumBid}
                BidId={userSKillListing?.bid[0]?._id}
                selectedCandidates={candidateList}
            />

            <Row style={{ textAlign: 'center', width: '94%', margin: 'auto' }}>
                <Col className='edit-btn-div' xs={12}>
                    <Button className='edit-bid-btn' onClick={showModal2}>Edit Bid</Button>
                </Col>
                <Col className='retract-bid-btn-div' xs={12}>
                    <Button className='retract-btn' onClick={showModal}>Retract Bid</Button>
                </Col>
            </Row>
        </>
    )
}

export const MatchesButton = (props) => {

    const [addedToMatch, setAddedToMatch] = useState(false);

    const dispatch = useDispatch();
    const userSKillListing = useSelector(
        (state) => state.oneUserskillListingReducer.skillListings
    );
    const addtoMatches = () => {
        dispatch(addToMatches({
            GigId: props.GigId,
            JobTitle: props.JobTitle,
            SkillListingId: props.SkillListingId,
            SkillListingTitle: props.SkillListingTitle
        }))
    }

    const removefromMatches = () => {
        dispatch(removeFromMatches({ SkillListingId: props.SkillListingId, GigId: props.GigId }))
    }

    useEffect(() => {
        if (userSKillListing?.isMatch) {
            setAddedToMatch(true)
        }
    }, [userSKillListing])


    console.log('Pdetails', userSKillListing)
    return (
        <div style={{ width: '100%' }}>
            <button className={addedToMatch ? 'remove-btn-match' : "add-match-btn"} onClick={addedToMatch ? removefromMatches : addtoMatches}>
                {addedToMatch ? 'Remove from Matches' : 'Add to Matches'}
            </button>
        </div>
    )
};