import React from "react";
//import "./CurrencyMenu.css";
import closeBtnIcon from "../../../assets/icons/clarity_window-closeLine.svg";
import checkBtnIcon from "../../../assets/icons/bi_check.svg";
import { useDispatch } from "react-redux";
import { updateProfile } from "../../../redux/actions";

const Currency_Menu_List_Item = ({
  currencyItem: { name, code, symbol },
  CurrencyCheck,
  SetCurrencyCheck,
  setshowCurrency,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <div
        className="Currency_Menu_List_Item"
        onClick={() => {
          SetCurrencyCheck(code);
          const data = {
            profile: {
              PreferredCurrency: code,
            },
          };
          dispatch(updateProfile(data));
          window.innerWidth < 1000 && setshowCurrency(false);
        }}
      >
        <div className="Check_Button_Icon">
          {CurrencyCheck === code && <img src={checkBtnIcon} alt="" />}
        </div>
        <div className="Currency_Menu_Content">
          <h3>{name}</h3>
          <div>
            <span>{code} -</span>
            <img src={symbol} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

const CurrencyMenu = ({
  currencyType,
  setCurrencyType,
  closeCurrencyModal,
  currencyList,
  setshowCurrency,
}) => {
  return (
    <>
      <div className="Currency_Menu_Container">
        <div className="Currency_Menu_Header">
          <h3>Choose Currency</h3>
          <img src={closeBtnIcon} alt="" onClick={closeCurrencyModal} />
        </div>
        {currencyList.map((currencyItem, i) => (
          <Currency_Menu_List_Item
            key={i}
            currencyItem={currencyItem}
            CurrencyCheck={currencyType}
            SetCurrencyCheck={setCurrencyType}
            setshowCurrency={setshowCurrency}
          />
        ))}
      </div>
    </>
  );
};

export default CurrencyMenu;
