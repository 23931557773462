import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";

import { Auth } from "../../../config/aws-exports";

// Redux
import { connect, useDispatch } from "react-redux";
import { updateUserInfo } from "../../../redux/actions";
import { fetchUserProfile } from "../../../redux/actions";
import { createBid } from "../../../redux/actions";
import { getCookie, setCookie } from "../../../shared/helpers";

import { useHistory } from "react-router-dom";

const AuthSuccess = (props) => {
  const history = useHistory();

  useEffect(() => {
    async function AuthCheck() {
      let session = null;

      try {
        if (getCookie("token") !== null && getCookie("token").length > 0) {
          // setCookie("token", token, true);
          props.profileDetails(getCookie("token"));
          // Temporarily commented to fix LinkedIn Login issue, because Auth can't find Linkedin Session. Need to add some checks on this
          // const cognitoUser = await Auth.currentAuthenticatedUser();
          // const currentSession = await Auth.currentSession();
          // cognitoUser.refreshSession(
          //   currentSession.refreshToken,
          //   (err, session) => {
          //     console.log("session", err, session);
          //     const token = session.idToken.jwtToken;
          //     // do whatever you want to do now :)
          //     console.log("authToken", token);
          //     setCookie("token", token, true);
          //     props.profileDetails(token);
          //     props.sendAuthToken(token);
          //   }
          // );
          // Temporarily commented to fix LinkedIn Login issue, because Auth can't find Linkedin Session. Need to add some checks on this
        } else if ((session = await Auth.currentSession())) {
          // const session = await Auth.currentSession();
          console.log("SESSION:", session);
          const token = session.getIdToken().getJwtToken();
          setCookie("token", token, true);
          console.log("TOKEN IN AUTH:", token);
          props.profileDetails(token);
          props.sendAuthToken(token);
          // history.push("/dashboard");
        } else {
          console.log("token not found");
          history.push("/login");
        }
      } catch (err) {
        console.log("ERROR IN VERIFYING SESSION:", err);
        history.push("/login");
      }
    }
    AuthCheck();
  }, []);

  useEffect(() => {
    //used for redirecting guest user flow

    const profile = props.user.profile;
    console.log("hELLo: ", props.user);
    // setState(data);

    console.log("profile?", profile.CompanyName, profile.ProfessionCode);
    if (props.user.email) {
      if (profile.AccountTypeCode == null) {
        history.push("/talentoremployer");
      } else if (
        profile.CompanyName == null &&
        profile.AccountTypeCode == "EMP"
      ) {
        window.location.replace("/empauth");
      } else if (
        profile.ProfessionCode == null &&
        profile.AccountTypeCode == "EMP"
      ) {
        window.location.replace("/empauth");
      } else if (
        profile.PreferredName == null &&
        profile.AccountTypeCode == "EMP"
      ) {
        window.location.replace("/empauth");
      } else if (
        profile.VisaStatusName == null &&
        profile.AccountTypeCode == "TAL"
      ) {
        window.location.replace("/role-experience");
      }
      //else if redirectTo exists in local storage redirectTo
      else if (localStorage.getItem("redirectTo")) {
        history.push(localStorage.getItem("redirectTo"));
      } else {
        if (profile.AccountTypeCode == "EMP") {
          // history.push("/employerdashboard");
          window.location.replace("/employerdashboard");
        } else {
          // history.push('/dashboard')
          window.location.replace("/talent");
        }
      }
    }
  }, [props.user.profile]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        fontSize: "2.5rem",
        margin: "40vh 0",
        color: "#118936",
      }}
    >
      <LoadingOutlined />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.profileReducer.user,
    error: state.authReducer.error,
    token: state.authReducer.user.authToken,
    // userProfile: state.profileReducer.user.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendAuthToken: (params) => {
      dispatch(updateUserInfo(params)); // action dispatched
    },
    profileDetails: (token) => {
      dispatch(fetchUserProfile(token)); // action dispatched
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthSuccess);
