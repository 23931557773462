import { call, put, takeEvery, select } from "redux-saga/effects";
import { types } from "../action-types";
import {
  fetchUserProfileFailure,
  fetchUserProfileSuccess,
  updateProfileSuccess,
  updateProfileFailure,
  uploadProfilePicSuccess,
  uploadProfilePicFailure,
  uploadResumeSuccess,
  uploadResumeFailure,
  fetchProfileExperience,
  fetchProfileExperienceSuccess,
  deleteResumeSuccess,
  deleteResumeFailure,
  fetchRoleDisciplineSuccess,
  getTalentInfo,
  getTalentInfoSuccess,
  getTalentInfoFailure,
  getAnalyticInfoSuccess,
  getAnalyticInfoFailure,
  fetchJobDetailsProfileSuccess,
  fetchJobDetailsProfileFailure,
  postFavoriteGigCardSuccess,
  postFavoriteGigCardFailure,
  removeFavoriteGigCard,
  removeFavoriteGigCardSuccess,
  removeFavoriteGigCardFailure,
  fetchCollegeMasterEducationHistorySuccess,
  fetchCollegeMasterEducationHistoryFailure,
  fetchDegreeMasterEducationHistorySuccess,
  fetchDegreeMasterEducationHistoryFailure,
  fetchCourseMasterEducationHistorySuccess,
  fetchCourseMasterEducationHistoryFailure,
  postEducationHistorySuccess,
  postEducationHistoryFailure,
  updateEducationHistorySuccess,
  updateEducationHistoryFailure,
  postWorkHistorySuccess,
  fetchCompanyWorkHistorySuccess,
  postWorkHistoryFailure,
  fetchCompanyWorkHistoryFailure,
  fetchJobTitleWorkHistoryFailure,
  fetchJobTitleWorkHistorySuccess,
  fetchIndustryWorkHistorySuccess,
  fetchIndustryWorkHistoryFailure,
  fetchLocationWorkHistorySuccess,
  fetchLocationWorkHistoryFailure,
  fetchSkillsWorkHistorySuccess,
  fetchSkillsWorkHistoryFailure,
  updateWorkHistory,
  updateWorkHistorySuccess,
  updateWorkHistoryFailure,
  fetchJobDetailsProfileCompletedSuccess,
  fetchJobDetailsProfileCompletedFailure,
  fetchJobDetailsProfileCanceledSuccess,
  fetchJobDetailsProfileCanceledFailure,
  fetchJobDetailsProfileTerminatedSuccess,
  fetchJobDetailsProfileTerminatedFailure,
  fetchJobDetailsProfileOnbilling,
  fetchJobDetailsProfileOnbillingSuccess,
  fetchJobDetailsProfileOnbillingFailure,
  getInterviewCardFailure,
  getInterviewCardSuccess,
  postCertificatesAndAwardsSuccess,
  postCertificatesAndAwardsFailure,
  updateCertificatesAndAwardsSuccess,
  updateCertificatesAndAwardsFailure,
} from "../actions";
import {
  fetchProfileURL,
  updateProfileApi,
  uploadProfilePicApi,
  uploadResumeApi,
  uploadFileApi,
  fetchProfileExperienceAPI,
  resumeDeleteAPI,
  fetchRoleDisciplineAPI,
  talentInfoAPI,
  AnalyticInfoAPI,
  fetchUserJobDetailsAPI,
  addGigCardFavoriteAPI,
  removefavoriteGigCardAPI,
  getCollegeEducationHistoryAPI,
  getCourseEducationHistoryAPI,
  getCourseTypeEducationHistoryAPI,
  postEducationHistoryAPI,
  updateEducationHistoryAPI,
  postWorkHistoryAPI,
  fetchCompanyWorkHistoryAPI,
  fetchJobTitleWorkHistoryAPI,
  fetchIndustryWorkHistoryAPI,
  fetchLocationWorkHistoryAPI,
  fetchSkillsApi,
  updateWorkHistoryAPI,
  fetchUserJobDetailsCompletedAPI,
  fetchUserJobDetailsTerminatedAPI,
  fetchUserJobDetailsActiveAPI,
  fetchUserJobDetailsCanceledAPI,
  InterviewCardAPI,
  postCertificatesAndAwardsAPI,
  updateCertificatesAndAwardsAPI,
} from "../../API/API";
import { getUserToken } from "../reducers/authReducer";

function* fetchProfileSaga(request) {
  console.log("PAYLOAD IN SAGA:", request);
  try {
    const response = yield call(() => fetchProfileURL(request.payload)); // API Backend
    console.log(response);
    if (response.data.status === true) {
      yield put(fetchUserProfileSuccess(response.data.data.body.user));
    } else {
      yield put(fetchUserProfileFailure(response.data.error));
    }
  } catch (error) {
    console.log(error);
    yield put(fetchUserProfileFailure(error));
  }
}

function* updateProfileSaga(params) {
  console.log('check', params);
  try {
    const response = yield call(() => updateProfileApi(params)); // API Backend
    console.log("UPDATE PROFILE RESPONSE:", response);
    // if (response.data.status === "success") {
    yield put(updateProfileSuccess(response.data));
    // } else {
    //   yield put(updateProfileFailure(response.data.error));
    // }
  } catch (error) {
    console.log(error);
    yield put(updateProfileFailure(error));
  }
}

function* uploadProfilePicSaga(params) {
  try {
    const response = yield call(() => uploadFileApi(params)); // API Backend
    console.log("UPLOAD PROFILE PIC RESPONSE:", response);
    if (response.data.status === "success") {
      yield put(uploadProfilePicSuccess(response.data.payload));
    } else {
      yield put(uploadProfilePicFailure(response.data.error));
    }
  } catch (error) {
    console.log(error);
    yield put(uploadProfilePicFailure(error));
  }
}

function* roleDisciplineSaga(params) {
  // console.log("Auth: ", params.payload.key);
  // console.log("Token: ", params.payload.authToken);

  try {
    const response = yield call(() => fetchRoleDisciplineAPI(params)); // API Backend
    // console.log("UPLOAD Role RESPONSE:", response.data);
    // if (response.request.status === "success") {
    yield put(fetchRoleDisciplineSuccess(response.data));
    // } else {
    //   yield put(uploadProfilePicFailure(response.data.error));
    // }
  } catch (error) {
    console.log(error);
    yield put(uploadProfilePicFailure(error));
  }
}

function* uploadResumeSaga(params) {
  try {
    // const { userToken } = yield select(getUserToken);
    // console.log(userToken);
    const response = yield call(() => uploadFileApi(params)); // API Backend
    console.log("UPLOAD RESUME RESPONSE:", response);
    if (response.data.status === "success") {
      yield put(uploadResumeSuccess(response.data.payload));
    } else {
      yield put(uploadResumeFailure(response.data.error));
    }
  } catch (error) {
    console.log(error);
    yield put(uploadResumeFailure(error));
  }
}

function* deleteResumeSaga() {
  try {
    const response = yield call(() => resumeDeleteAPI()); // API Backend
    console.log("DELETE RESUME RESPONSE:", response.status);
    if (response.status === 200) {
      yield put(deleteResumeSuccess(response.data.user));
    } else {
      yield put(deleteResumeFailure(response.data.error));
    }
  } catch (error) {
    console.log(error);
    yield put(deleteResumeFailure(error));
  }
}

function* fetchProfileExperienceSaga() {
  console.log("EXPERIENCE RESPONSE:");

  try {
    const response = yield call(() => fetchProfileExperienceAPI()); // API Backend
    console.log("EXPERIENCE RESPONSE:", response);
    if (response.status === 200) {
      yield put(fetchProfileExperienceSuccess(response.data));
    } else {
      yield put(uploadResumeFailure(response.data.error));
    }
  } catch (error) {
    console.log(error);
    yield put(uploadResumeFailure(error));
  }
}

function* fetchTalentInfoSaga(params) {
  console.log("EXPERIENCE RESPONSE:");

  try {
    const response = yield call(() => talentInfoAPI(params.payload)); // API Backend
    console.log("TALENT INFO RESPONSE:", response.data.data.body.user.profile);
    if (response.status === 200) {
      yield put(getTalentInfoSuccess(response.data.data.body.user.profile));
    } else {
      yield put(getTalentInfoFailure(response.data.error));
    }
  } catch (error) {
    console.log(error);
    yield put(getTalentInfoFailure(error));
  }
}

function* fetchAnalyticInfoSaga(params) {
  console.log("ANALYTIC RESPONSE:");

  try {
    const response = yield call(() => AnalyticInfoAPI(params.payload)); // API Backend
    console.log("ANALYTIC INFO RESPONSE:", response);
    if (response.status === 200) {
      yield put(getAnalyticInfoSuccess(response.data.data));
    } else {
      yield put(getAnalyticInfoFailure(response.data.error));
    }
  } catch (error) {
    console.log(error);
    yield put(getAnalyticInfoFailure(error));
  }
}

function* jobDetailsProfileSaga(params) {
  console.log("PROFILE_DETAILS RESPONSE:", params);

  try {
    const response = yield call(() => fetchUserJobDetailsAPI(params)); // API Backend
    console.log("PROFILE_DETAILS RESPONSE:", response.data.data);
    if (response.status === 200) {
      yield put(fetchJobDetailsProfileSuccess(response.data.data.data));
    } else {
      yield put(fetchJobDetailsProfileFailure(response.data.error));
    }
  } catch (error) {
    console.log(error);
    yield put(fetchJobDetailsProfileFailure(error));
  }
}

function* jobDetailsProfileCompletedSaga(params) {
  console.log("PROFILE_DETAILS RESPONSE:", params);

  try {
    const response = yield call(() => fetchUserJobDetailsCompletedAPI(params)); // API Backend
    console.log("PROFILE_DETAILS RESPONSE:", response.data.data);
    if (response.status === 200) {
      yield put(
        fetchJobDetailsProfileCompletedSuccess(response.data.data.data)
      );
    } else {
      yield put(fetchJobDetailsProfileCompletedFailure(response.data.error));
    }
  } catch (error) {
    console.log(error);
    yield put(fetchJobDetailsProfileCompletedFailure(error));
  }
}

function* jobDetailsProfileCanceledSaga(params) {
  console.log("PROFILE_DETAILS RESPONSE:", params);

  try {
    const response = yield call(() => fetchUserJobDetailsCanceledAPI(params)); // API Backend
    console.log("PROFILE_DETAILS RESPONSE:", response.data.data);
    if (response.status === 200) {
      yield put(fetchJobDetailsProfileCanceledSuccess(response.data.data.data));
    } else {
      yield put(fetchJobDetailsProfileCanceledFailure(response.data.error));
    }
  } catch (error) {
    console.log(error);
    yield put(fetchJobDetailsProfileCanceledFailure(error));
  }
}

function* jobDetailsProfileOnBillingSaga(params) {
  console.log("PROFILE_DETAILS RESPONSE:", params);

  try {
    const response = yield call(() => fetchUserJobDetailsActiveAPI(params)); // API Backend
    console.log("PROFILE_DETAILS RESPONSE:", response.data.data);
    if (response.status === 200) {
      yield put(
        fetchJobDetailsProfileOnbillingSuccess(response.data.data.data)
      );
    } else {
      yield put(fetchJobDetailsProfileOnbillingFailure(response.data.error));
    }
  } catch (error) {
    console.log(error);
    yield put(fetchJobDetailsProfileOnbillingFailure(error));
  }
}

function* jobDetailsProfileTerminatedSaga(params) {
  console.log("PROFILE_DETAILS RESPONSE:", params);

  try {
    const response = yield call(() => fetchUserJobDetailsTerminatedAPI(params)); // API Backend
    console.log("PROFILE_DETAILS RESPONSE:", response.data.data);
    if (response.status === 200) {
      yield put(
        fetchJobDetailsProfileTerminatedSuccess(response.data.data.data)
      );
    } else {
      yield put(fetchJobDetailsProfileTerminatedFailure(response.data.error));
    }
  } catch (error) {
    console.log(error);
    yield put(fetchJobDetailsProfileTerminatedFailure(error));
  }
}

function* fetchCollegeMasterSaga(request) {
  console.log(request, "CollegeMaster");
  try {
    const response = yield call(() =>
      getCollegeEducationHistoryAPI(request.payload)
    ); // API Backend
    // console.log("CollegeMaster RESPONSE:", response);
    if (response.status == 200) {
      yield put(fetchCollegeMasterEducationHistorySuccess(response.data));
    } else {
      yield put(fetchCollegeMasterEducationHistoryFailure(response));
    }
  } catch (error) {
    // console.log(error);
    yield put(fetchCollegeMasterEducationHistoryFailure(error));
  }
}

function* fetchDegreeMasterSaga(request) {
  // console.log(request, "Degree");
  try {
    const response = yield call(() =>
      getCourseEducationHistoryAPI(request.payload)
    ); // API Backend
    // console.log("Degree RESPONSE:", response);
    if (response.status == 200) {
      yield put(fetchDegreeMasterEducationHistorySuccess(response.data));
    } else {
      yield put(fetchDegreeMasterEducationHistoryFailure(response));
    }
  } catch (error) {
    // console.log(error);
    yield put(fetchDegreeMasterEducationHistoryFailure(error));
  }
}

function* fetchCourseMasterSaga(request) {
  // console.log(request, "Course");
  try {
    const response = yield call(() =>
      getCourseTypeEducationHistoryAPI(request.payload)
    ); // API Backend
    // console.log("Course RESPONSE:", response);
    if (response.status == 200) {
      yield put(fetchCourseMasterEducationHistorySuccess(response.data));
    } else {
      yield put(fetchCourseMasterEducationHistoryFailure(response));
    }
  } catch (error) {
    // console.log(error);
    yield put(fetchCourseMasterEducationHistoryFailure(error));
  }
}

function* fetchPostEducationHistorySaga(request) {
  console.log(request, "POST ED");
  try {
    const response = yield call(() => postEducationHistoryAPI(request.payload)); // API Backend
    console.log("POST ED RESPONSE:", response.data);
    if (response.status == 200) {
      yield put(postEducationHistorySuccess(response.data));
    } else {
      yield put(postEducationHistoryFailure(response));
    }
  } catch (error) {
    // console.log(error);
    yield put(postEducationHistoryFailure(error));
  }
}

function* fetchUpdateEducationHistorySaga(request) {
  console.log(request, "update ED");
  try {
    const response = yield call(() =>
      updateEducationHistoryAPI(request.payload)
    ); // API Backend
    console.log("update ED RESPONSE:", response);
    if (response.status == 200) {
      yield put(updateEducationHistorySuccess(response.data));
    } else {
      yield put(updateEducationHistoryFailure(response));
    }
  } catch (error) {
    // console.log(error);
    yield put(updateEducationHistoryFailure(error));
  }
}

// function* fetchCompanyWorkHistorySaga(request) {
//   console.log(request, "Company WH");
//   try {
//     const response = yield call(() =>
//       fetchCompanyWorkHistoryAPI(request.payload)
//     ); // API Backend
//     console.log("Company WH RESPONSE:", response);
//     if (response.status == 200) {
//       yield put(fetchCompanyWorkHistorySuccess(response.data));
//     } else {
//       yield put(fetchCompanyWorkHistoryFailure(response));
//     }
//   } catch (error) {
//     // console.log(error);
//     yield put(fetchCompanyWorkHistoryFailure(error));
//   }
// }

function* fetchJobTitleWorkHistorySaga(request) {
  console.log(request, "JobTitle Saga");
  try {
    const response = yield call(() =>
      fetchJobTitleWorkHistoryAPI(request.payload)
    ); // API Backend
    console.log("JobTitle WH RESPONSE:", response);
    if (response.status == 200) {
      yield put(fetchJobTitleWorkHistorySuccess(response.data));
    } else {
      yield put(fetchJobTitleWorkHistoryFailure(response));
    }
  } catch (error) {
    // console.log(error);
    yield put(fetchJobTitleWorkHistoryFailure(error));
  }
}

function* fetchIndustryWorkHistorySaga(request) {
  console.log(request, "Industry Saga");
  try {
    const response = yield call(() =>
      fetchIndustryWorkHistoryAPI(request.payload)
    ); // API Backend
    console.log("Industry WH RESPONSE:", response);
    if (response.status == 200) {
      yield put(fetchIndustryWorkHistorySuccess(response.data));
    } else {
      yield put(fetchIndustryWorkHistoryFailure(response));
    }
  } catch (error) {
    // console.log(error);
    yield put(fetchIndustryWorkHistoryFailure(error));
  }
}

function* fetchLocationWorkHistorySaga(request) {
  console.log(request, "Location Saga");
  try {
    const response = yield call(() =>
      fetchLocationWorkHistoryAPI(request.payload)
    ); // API Backend
    console.log("Location WH RESPONSE:", response);
    if (response.status == 200) {
      yield put(fetchLocationWorkHistorySuccess(response.data));
    } else {
      yield put(fetchLocationWorkHistoryFailure(response));
    }
  } catch (error) {
    // console.log(error);
    yield put(fetchLocationWorkHistoryFailure(error));
  }
}

function* fetchSkillsWorkHistorySaga(request) {
  console.log(request, "Skills Saga");
  try {
    const response = yield call(() => fetchSkillsApi(request)); // API Backend
    console.log("Skills WH RESPONSE:", response);
    if (response.status == 200) {
      yield put(fetchSkillsWorkHistorySuccess(response.data));
    } else {
      yield put(fetchSkillsWorkHistoryFailure(response));
    }
  } catch (error) {
    // console.log(error);
    yield put(fetchSkillsWorkHistoryFailure(error));
  }
}

function* fetchPostWorkHistorySaga(request) {
  console.log(request, "post WH");
  try {
    const response = yield call(() => postWorkHistoryAPI(request.payload)); // API Backend
    console.log("post WH RESPONSE:", response);
    if (response.status == 200) {
      yield put(postWorkHistorySuccess(response.data));
    } else {
      yield put(postWorkHistoryFailure(response));
    }
  } catch (error) {
    // console.log(error);
    yield put(postWorkHistoryFailure(error));
  }
}

function* fetchUpdateWorkHistorySaga(request) {
  console.log(request, "Update WH");
  try {
    const response = yield call(() => updateWorkHistoryAPI(request.payload)); // API Backend
    console.log("Update WH RESPONSE:", response);
    if (response.status == 200) {
      yield put(updateWorkHistorySuccess(response.data));
    } else {
      yield put(updateWorkHistoryFailure(response));
    }
  } catch (error) {
    // console.log(error);
    yield put(updateWorkHistoryFailure(error));
  }
}

function* postCertificatesAndAwardsSaga(params) {
  console.log("Certificates");
  try {
    const response = yield call(() =>
      postCertificatesAndAwardsAPI(params.payload)
    ); // API Backend
    console.log("Certificates  RESPONSE:", response);
    if (response.status === 200) {
      yield put(postCertificatesAndAwardsSuccess(response.data));
    } else {
      yield put(postCertificatesAndAwardsFailure(response));
    }
  } catch (error) {
    console.log(error);
    yield put(postCertificatesAndAwardsFailure(error));
  }
}

function* updateCertificatesAndAwardsSaga(params) {
  console.log("Certificates");
  try {
    const response = yield call(() =>
      updateCertificatesAndAwardsAPI(params.payload, params.id)
    ); // API Backend
    console.log("Certificates  RESPONSE:", response);
    if (response.status === 200) {
      yield put(
        updateCertificatesAndAwardsSuccess({
          response: response.data,
          id: params.id,
        })
      );
    } else {
      yield put(updateCertificatesAndAwardsFailure(response));
    }
  } catch (error) {
    console.log(error);
    yield put(updateCertificatesAndAwardsFailure(error));
  }
}

function* fetchInterviewCardSaga(params) {
  console.log("Interview response:");
  try {
    const response = yield call(() => InterviewCardAPI(params.payload)); // API Backend
    console.log("INTERVIEW CARD RESPONSE:", response);
    if (response.status === 200) {
      yield put(getInterviewCardSuccess(response.data.data));
    } else {
      yield put(getInterviewCardFailure(response.data.error));
    }
  } catch (error) {
    console.log(error);
    yield put(getInterviewCardFailure(error));
  }
}

export function* watchProfileSaga() {
  yield takeEvery(types.FETCH_PROFILE_INIT, fetchProfileSaga);
}

export function* watchUpdateProfileSaga() {
  yield takeEvery(types.UPDATE_PROFILE_INIT, updateProfileSaga);
}

export function* watchRoleDisciplineSaga() {
  yield takeEvery(types.FETCH_ROLE_DISCIPLINE_INIT, roleDisciplineSaga);
}

export function* watchUploadProfilePicSaga() {
  yield takeEvery(types.UPLOAD_PROFILE_PIC_INIT, uploadProfilePicSaga);
}

export function* watchUploadResumeSaga() {
  yield takeEvery(types.UPLOAD_RESUME_INIT, uploadResumeSaga);
}

export function* watchDeleteResumeSaga() {
  yield takeEvery(types.DELETE_RESUME_INIT, deleteResumeSaga);
}
export function* watchFetchProfileExperienceSaga() {
  yield takeEvery(
    types.FETCH_PROFILE_EXPERIENCE_INIT,
    fetchProfileExperienceSaga
  );
}

export function* watchTalentInfoSaga() {
  yield takeEvery(types.GET_TALENT_INFO_REQUEST, fetchTalentInfoSaga);
}

export function* watchAnalyticInfoSaga() {
  yield takeEvery(types.GET_ANALYTIC_INFO_REQUEST, fetchAnalyticInfoSaga);
}

export function* watchJobDetailsProfileSaga() {
  yield takeEvery(types.JOB_DETAILS_PROFILE_REQUESTED, jobDetailsProfileSaga);
}
export function* watchJobDetailsProfileCompletedSaga() {
  yield takeEvery(
    types.JOB_DETAILS_PROFILE_COMPLETED_REQUESTED,
    jobDetailsProfileCompletedSaga
  );
}

export function* watchJobDetailsProfileCanceledSaga() {
  yield takeEvery(
    types.JOB_DETAILS_PROFILE_CANCELED_REQUESTED,
    jobDetailsProfileCanceledSaga
  );
}

export function* watchJobDetailsProfileOnBillingSaga() {
  yield takeEvery(
    types.JOB_DETAILS_PROFILE_ON_BILLING_REQUESTED,
    jobDetailsProfileOnBillingSaga
  );
}

export function* watchJobDetailsProfileTerminatedSaga() {
  yield takeEvery(
    types.JOB_DETAILS_PROFILE_TERMINATED_REQUESTED,
    jobDetailsProfileTerminatedSaga
  );
}
/// Education History //////////////////////////////////////////////////////////////

export function* watchFetchCollegeMaster() {
  yield takeEvery(types.FETCH_COLLEGE_MASTER_INIT, fetchCollegeMasterSaga);
}

export function* watchFetchDegreeMaster() {
  yield takeEvery(types.FETCH_DEGREE_MASTER_INIT, fetchDegreeMasterSaga);
}

export function* watchFetchCourseMaster() {
  yield takeEvery(types.FETCH_COURSE_MASTER_INIT, fetchCourseMasterSaga);
}

export function* watchPostEducationHistory() {
  yield takeEvery(
    types.POST_EDUCATION_HISTORY_INIT,
    fetchPostEducationHistorySaga
  );
}

export function* watchUpdateEducationHistory() {
  yield takeEvery(
    types.UPDATE_EDUCATION_HISTORY_INIT,
    fetchUpdateEducationHistorySaga
  );
}

////////////////////////////////////////////////////////////////////////////////////

/// Work History //////////////////////////////////////////////////////////////////

// export function* watchCompanyWorkHistory() {
//   yield takeEvery(
//     types.GET_COMPANY_WORK_HISTORY_INIT,
//     fetchCompanyWorkHistorySaga
//   );
// }

export function* watchJobTitleWorkHistory() {
  yield takeEvery(
    types.GET_JOBTITLE_WORK_HISTORY_INIT,
    fetchJobTitleWorkHistorySaga
  );
}

export function* watchIndustryWorkHistory() {
  yield takeEvery(
    types.GET_INDUSTRY_WORK_HISTORY_INIT,
    fetchIndustryWorkHistorySaga
  );
}

export function* watchLocationWorkHistory() {
  yield takeEvery(
    types.GET_LOCATION_WORK_HISTORY_INIT,
    fetchLocationWorkHistorySaga
  );
}

export function* watchSkillsWorkHistory() {
  yield takeEvery(
    types.GET_SKILLS_WORK_HISTORY_INIT,
    fetchSkillsWorkHistorySaga
  );
}

export function* watchPostWorkHistory() {
  yield takeEvery(types.POST_WORK_HISTORY_INIT, fetchPostWorkHistorySaga);
}

export function* watchUpdateWorkHistory() {
  yield takeEvery(types.UPDATE_WORK_HISTORY_INIT, fetchUpdateWorkHistorySaga);
}

export function* watchetchInterviewCardSaga() {
  yield takeEvery(types.GET_INTERVIEW_CARD_REQUEST, fetchInterviewCardSaga);
}

///////////////////////////////////////////////////////////////////////////////////

export function* watchPostCertificatesAndAwardsSaga() {
  yield takeEvery(
    types.POST_CERTIFICATES_AND_AWARDS_INIT,
    postCertificatesAndAwardsSaga
  );
}

export function* watchUpdateCertificatesAndAwardsSaga() {
  yield takeEvery(
    types.UPDATE_CERTIFICATES_AND_AWARDS_INIT,
    updateCertificatesAndAwardsSaga
  );
}
