import React from "react";
//import "./ManageBidsMenu.css";

import { useHistory } from "react-router-dom";
import { Menu } from "antd";

const ManageBidsMenu = (props) => {
  const history = useHistory();
  return (
    <Menu>
      <section
        className="manageBidsMenuHeader"
        // onClick={() => props.setDropVisibleBids(false)}
      >
        <div
          className="receivedBidsHeader"
          onClick={() => {
            window.innerWidth > 1000 && props.setDropVisibleBids(false);
            history.push("/receivedBids");
          }}
        >
          <span>Received Bids</span>
          <span className="bidsCountHeader">3</span>
        </div>
        <div
          className="placedbidsHeader"
          onClick={() => {
            window.innerWidth > 1000 && props.setDropVisibleBids(false);
            history.push("/placebids");
          }}
        >
          Placed Bids
        </div>
        {/* <div onClick={() => history.push("/retractedBids")}>Retracted Bids</div> */}
      </section>
    </Menu>
  );
};

export default ManageBidsMenu;
