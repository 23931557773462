import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Avatar, Rate, Tag, Typography } from "antd";
import { SettingOutlined, StarFilled, EnvironmentFilled } from '@ant-design/icons';
import stars from "../../../assets/icons/Star23.png"
import './Review.css';
import moment from 'moment';

const Reviews = ({ Reviews }) => {
   const { Meta } = Card;
   const { Text } = Typography;
   const [countryFlag, setCountryFlag] = React.useState();

   var countrycodedict = {
      'United States': 'us',
      'Texas': 'TK',
      // ... fill in
   };

   const getCountryCode = (countryName) => {
      const flag = 'https://lipis.github.io/flag-icon-css/flags/4x3/' + countrycodedict['Texas'] + '.svg';
      console.log(flag, 'flag');
      setCountryFlag(flag);
   };

   useEffect(() => {
      getCountryCode(countrycodedict['United States']);
   }, [])
   return (
      <>
         <div className='review-card-parent'>
            {Reviews?.length ?
               Reviews?.map((items) => {
                  return (
                     <div className='Review-card'>
                        <Row className='profile-dp-div'>
                           <div className={'reviews'}>Reviews</div>
                           {/* <Rate value={2} className='profile-rating' />  */}
                           <div className="gig_star_cp">
                              <StarFilled style={{ padding: '4px' }} />
                           </div>
                           <p className='ratings-text'>{items.assignment?.Rate} <Text style={{ color: '#7e7e7e' }}>({Reviews.length})</Text></p>
                        </Row>
                        <Meta
                           avatar={<Avatar className='img-avatar' src="https://joeschmoe.io/api/v1/random" />}
                           title={<div>
                              {items.users?.name} <StarFilled style={{ padding: '4px', color: '#ffba08', fontSize: '12px' }} /><Text className='ratings-text'>{items.users.profile.Rating ?? 'N/A'}</Text>
                           </div>}
                           description={<div className='country-name'>
                              {/* <img src={countryFlag} /> */}
                              <img style={{ width: '18px', marginRight: '5px' }} src="https://flagcdn.com/48x36/pk.png"></img>
                              Pakistan
                           </div>}
                        />
                        <p className='Review-para'>
                           {items.Comment}
                        </p>
                        <div className='span-time'>{moment(items.Created).fromNow()}</div>
                        {Reviews?.length > 1 && <div class="bottom-line"></div>}
                     </div>
                  )
               })
               :
               <div className="Review-card" style={{ verticalAlign: "flex-start" }}>
                  <p style={{ textAlign: 'center' }}>No Reviews</p>
               </div>
            }
         </div>
      </>
   );
}
export default Reviews;