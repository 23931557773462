export const REACT_APP_API_URL="https://kt6m7lx9vc.execute-api.us-east-2.amazonaws.com/staging"
export const REACT_APP_S3_URL="https://skillgigs-v3-documents.s3.us-east-2.amazonaws.com"
export const REACT_APP_MY_URL="https://project3.skillgigs.com"
export const Bucket ="skillgigs-v3-documents"
// COGNITO variables
export const REACT_APP_COGNITO_DOMAIN="testsocial.auth.us-east-2.amazoncognito.com"
export const REACT_APP_COGNITO_identityPoolId ="us-east-2:eb2da4c8-48f4-422a-a173-4cda39644c02"
export const REACT_APP_COGNITO_region ="us-east-2"
export const COGNITO_identityPoolRegion ="XX-XXXX-X"
export const REACT_APP_COGNITO_userPoolId ="us-east-2_EjBopa2Vd"
export const REACT_APP_COGNITO_userPoolWebClientId ="3ro9tb4l7kfg7oc15fe559d4ka"
// LinkedIn variables
export const REACT_APP_LinkedInClientID ="78o4imey5bxrer"
// # REACT_APP_MY_URL="https://development.d27f4n34ewlmqp.amplifyapp.com"
// # BUILDER_PUBLIC_KEY =" b46254e5619c4885aeb9d29bd13189d8"