import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import Auth from "../pages/Auth/Auth";
import Home from "../pages/Home/Home";
import TalentEmployer from "../pages/WelcomeWizard/TalentEmployer";
import AuthSuccess from "../pages/Auth/components/AuthSuccess";
import ForgetPassword from "../pages/Auth/ForgetPassword";
import LinkedInCheck from "../pages/Auth/components/LinkedInCheck";
import TermsOfServices from "../pages/Auth/components/TermsOfServices";


import BidtoApply from "../pages/Bid/components/BidToApply";
import GuestGidDetailProvider from "../pages/SearchGigs/GuestUser/components/GuestGidDetailProvider";
import Header from "../components/Header/Header";
import SearchGigs from "../pages/SearchGigs/SearchGigs";
import {
  ProtectedRoute,
  PublicRoute,
  SpecialRoute,
  ValidateRoute,
} from "./routes";
import Footer from "../components/Footer";
import SearchSkillListings from "../pages/SearchSkillListing/SearchSkillListings";
import { useSelector } from "react-redux";
import EmloyerAppp from "../employerApp";
import CandidateAppp from "../candidateApp";
import ProfileDetail from "../pages/ProfileDetail/ProfileDetail";
import GuestHome from "../pages/Home/GuestHome";
import TalentLogout from "../pages/Auth/components/TalentLogout";

export const AppRouter = (props) => {
  const authToken = useSelector((state) => state.authReducer.user.authToken);
  return (
    <Router>
      {/* <Suspense fallback={<Loader />}> */}
      <div className="content-wrap">
        <div className="headerSpaceRouter">
          <Header changeStage={props.shareStage} stage={props.stage} />
          <Switch>
            <PublicRoute exact path="/">
              <CandidateAppp />
            </PublicRoute>
            <PublicRoute path="/login">
              <Auth />
            </PublicRoute>
            <PublicRoute exact path="/auth">
              <AuthSuccess />
            </PublicRoute>
            <PublicRoute exact path="/linkcheck">
              <LinkedInCheck />
            </PublicRoute>
            <PublicRoute exact path="/search">
              <SearchGigs />
            </PublicRoute>
            <PublicRoute exact path="/search/:searchValue">
              <SearchGigs />
            </PublicRoute>
            <PublicRoute exact path="/searchSkillListing/:searchValue">
              <SearchSkillListings />
            </PublicRoute>
            <PublicRoute exact path="/details/:jobid">
              <GuestGidDetailProvider />
            </PublicRoute>
            <PublicRoute exact path="/candidatedetail">
              <ProfileDetail />
            </PublicRoute>
            <PublicRoute path="/create-bid">
              <BidtoApply />
            </PublicRoute>
            <ProtectedRoute
              path="/talentoremployer"
              component={TalentEmployer}
            />
            <ProtectedRoute
              path="/tal-logout"
              component={TalentLogout}
            />
            <ProtectedRoute
              path={[
                "/interview/schedule",
                "/personaldetails",
                "/companyprofile",
                "/companyprofile/addemployee",
                "/uploademployeepic",
                "/myprofile",
                "/CompanyLogo",
                "/managecandidate",
                "/companyprofile/addemployee/empdetails",
                "/empheadshot",
                "/per-gig",
                "/mymatches",
                "/findmatches",
                "/companyprofile/companyculturephotos",
                "/allset",
                "/companyprofile/companydetails",
                "/updatecompanydetails",
                "/skillmatches",
                "/ratings",
                "/marqueealbum",
                "/followers",
                "/views",
                "/companyprofile/aboutcompany",
                "/myteams",
                "/myteams/addmember",
                "/myteams/activate",
                "/candidateskilllisting",
                "/view-interviews",
                "/myfavourites",
                "/timesheet/list",
                "/timesheet/details",
                "/notification",
                "/subscriptions-main",
                '/query',
                "/talentemployer",
                "/employerprofession",
                "/empauth",
                "/employerapp",
                "/companydetails",
                "/conversations",
                "/job-type",
                "/employerdashboard",
                "/joblisting",
                "/postjob",
                "/postajob/choosetemplate",
                "/contract-gig",
                "/jobdetail",
                "/postjob/budgetform",
                "/postjob/secondstep",
                "/profileDetail",
                "/candidatemarketing",
                "/resume-3d",
              ]}
            >
              {/* <employer-app /> */}
              <EmloyerAppp />
            </ProtectedRoute>
            <ProtectedRoute
              path={[
                "/candidateapp",
                "/all_rate_card",
                "/rating_form2",
                "/rating_form",
                "/manage_assignments",
                "homeSeeAll",
                "/HomeResult",
                "/RetractedBids",
                "/3d_resume",
                "/interview",
                "/resume-upload",
                "requestcorrection",
                "/profile",
                "/timesheet",
                "/DeclineBids",
                "/chat/history",
                "/messages/:id",
                "/messages",
                "/edit-bid/:id",
                "/bidsuccess",
                "/proposals",
                "/myfavorites",
                "/dummy",
                "/role-experience",
                "/create-skilllisting-signup",
                "/create-skilllisting",
                "/basic-profile-success",
                "/profession",
                "/talentemployer",
                "/placedbid-detail/:bidId",
                "/placebids",
                "/receivedbid-detail/:bidId",
                "/receivedBids",
                "/dashboard",
                // "/create-bid",
                "/search/:searchValue",
                "/credentialing",
                "/manageskilllisting",
                "/bidsuccess",
                "/chat/history",
              ]}
            >
              {/* <candidate-app /> */}
              <CandidateAppp />
            </ProtectedRoute>
            <SpecialRoute path="/forgot">
              <ForgetPassword />
            </SpecialRoute>
            <SpecialRoute path="/terms">
              <TermsOfServices />
            </SpecialRoute>
            <Redirect to="/" />
          </Switch>
        </div>
      </div>
      {authToken == null ? <Footer /> : null}
      {/* </Suspense> */}
    </Router>
  );
};
