import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, Modal } from "antd";
import { connect, useDispatch } from "react-redux";

import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LoadingOutlined,
} from "@ant-design/icons";

import { Auth } from "../../../config/aws-exports";
import LostPassword from "./LostPassword";

import { setCookie } from "../../../shared/helpers";
import { VscLock } from "react-icons/vsc";

//import "./SignUpLogin.css";
import GreenCheck from "../../../assets/icons/typcn_tick.svg";
import { GoAlert } from "react-icons/go";
import { isModalVisibleAuth } from "../../../redux/actions";

const Login = () => {
  const getRememberMe = JSON.parse(localStorage.getItem("RememberMe"));
  let InitialValue = !getRememberMe ? false : getRememberMe;
  console.log("InitialValue", InitialValue);
  const history = useHistory();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(false);
  const [isremember, setIsRemember] = useState(InitialValue);
  const [errormessage, setErrorMessage] = useState("");
  const [isModalVisibleAuth, setIsModalVisibleAuth] = useState(false);

  const [greenCheck, setGreenCheck] = useState(false);
  const [showCheckIconEmail, setShowCheckIconEmail] = useState(false);

  const [greenCheckPassword, setGreenCheckPassword] = useState(false);
  const [showCheckPassword, setShowCheckPassword] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [backClicked, setBackClicked] = useState(false);

  const GetEmail = localStorage.getItem("Email");
//   console.log("GetEmail", GetEmail);
  const GetPassword = localStorage.getItem("password");
//   console.log("GetEmail", GetPassword);

  const remember = (e) => {
    if (e.target.checked === true) {
      // setIsRemember(true);
      localStorage.setItem("RememberMe", true);
    } else if (e.target.checked === false) {
      // setIsRemember(false);
      localStorage.setItem("RememberMe", false);
    }
    setIsRemember(!isremember);
    console.log(isremember);
  };

  const showModal = () => {
    // dispatch(isModalVisibleAuth(true));
    setIsModalVisibleAuth(true);
  };

  const modalCancel = () => {
    // dispatch(isModalVisibleAuth(false));
    setIsModalVisibleAuth(false);
    form.resetFields();
    setBackClicked(true);
  };
  const lostPassToLogin = (data) => {
    // console.log("lostpass data", data);
    if (data === "Success") {
      setTimeout(() => {
        setIsModalVisibleAuth(false);
      }, 3000);
    }
  };
  function onLinkClick(e) {
    e.preventDefault();
    // further processing happens here
  }

  const forgetpassword = () => {
    if (window.innerWidth < 768) {
      history.push("/forgot");
    } else {
      showModal();
    }
  };

  // const lostPass = () => {
  //   history.push("/forgot");
  // };

  // const enterEmail = <div className="enterEmail">Enter your email</div>;

  const validateEmail = (e) => {
    const email = e.target.value;
    const valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (email.length > 0) {
      setShowCheckIconEmail(true);
    }
    if (valid.test(email)) {
      setGreenCheck(true);
    } else {
      setGreenCheck(false);
    }
  };

  const validatePassword = (e) => {
    const password = e.target.value;
    if (password.length === 0) setPasswordValidation(false);

    // const valid = /^\S{8,}$/;
    // console.log(password);

    // if (valid.test(password)) {
    //   setPasswordValidation(false);
    // } else {
    //   setPasswordValidation(true);
    // }
  };

  const onLogin = async ({ email, password }) => {
    localStorage.setItem("Email", email);
    localStorage.setItem("password", password);

    setCount(false);
    setLoading(true);
    // console.log("Received values of form: ", values);

    try {
      const response = await Auth.signIn({ username: email, password });
      console.log(response);
      // https://serverless-idm.awssecworkshops.com/01-user-auth/#validate-sign-up-and-sign-in
      const session = await Auth.currentSession();
      // console.log('Cognito User Access Token:', session.getAccessToken().getJwtToken());
      console.log(
        "Cognito User Identity Token:",
        session.getIdToken().getJwtToken()
      );
      if (isremember) {
        console.log("Clicked inside if");
        const result = await Auth.rememberDevice();
        console.log("Remember me: ", result);

        if (result == "SUCCESS") {
          setCookie("RememberMe", "true");
        }
      }
      // console.log('Cognito User Refresh Token', session.getRefreshToken().getToken());
      setLoading(false);
      history.push("/auth");
    } catch (error) {
      setLoading(false);
      setCount(true);
      // message.error(error.message);
      setPasswordValidation(true);
      setTimeout(() => {
        setCount(false);
      }, 4000);
      console.log("error signing in", error);
      if (isremember) {
        console.log("Error remembering device", error);
      }
    }
  };

  useEffect(() => {
    const valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (GetEmail?.length > 0 && InitialValue === true) {
      setShowCheckIconEmail(true);
    }
    if (valid.test(GetEmail) && InitialValue === true) {
      setGreenCheck(true);
    } else {
      setGreenCheck(false);
    }
  }, []);

  return (
    <div className="loginSkillgigs">
      <Form
        name="normal_login"
        className="formLogin"
        initialValues={{
          remember: InitialValue,
          email: InitialValue === true ? GetEmail : "",
          password: InitialValue === true ? GetPassword : "",
        }}
        onFinish={onLogin}
        onLinkClick
      >
        <Form.Item
          name="email"
          hasFeedback
          validateStatus={loading ? "validating" : null}
          rules={[
            {
              type: "email",
              message: "email is not Valid!",
            },
            {
              required: true,
              message: "Please add your email address",
            },
          ]}
        >
          <Input
            // type="email"
            onChange={(e) => validateEmail(e)}
            className={`emailInputSignUp ${
              greenCheck && !passwordValidation
                ? "validInputCSS"
                : `${showCheckIconEmail ? "notValidInput" : null}`
            }`}
            loading={true}
            placeholder="Enter your email"
            id="validating"
            suffix={
              greenCheck && !passwordValidation ? (
                <>
                  {!loading ? <img src={GreenCheck} alt="greenCheck" /> : null}
                </>
              ) : (
                <>
                  {showCheckIconEmail ? (
                    <>
                      {!loading ? (
                        <GoAlert
                          style={{
                            color: "#db2828",
                            marginRight: "0.4rem",
                          }}
                        />
                      ) : null}
                    </>
                  ) : null}
                </>
              )
            }
          />
        </Form.Item>
        <Form.Item
          name="password"
          help={
            passwordValidation ? (
              <div className="notValidColor">
                Incorrect username or password
              </div>
            ) : null
          }
          rules={[
            {
              required: true,
              message: "Please enter your password",
            },
          ]}
        >
          <Input.Password
            // name="password"
            rules={[
              // {
              //   pattern: /^\S{8,}$/,
              //   message: "please use a valid password",
              // },
              {
                required: true,
              },
            ]}
            onChange={validatePassword}
            className={`passwordSignUp ${
              passwordValidation ? "notValidInput" : null
            } `}
            placeholder="Password"
            iconRender={(visible) =>
              visible ? (
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "right",
                    color: "#4054b2",
                  }}
                >
                  Hide
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: 500,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "right",
                    color: "#4054b2",
                  }}
                >
                  Show
                </span>
              )
            }
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            disabled={loading}
            style={{ marginBottom: "0.7rem" }}
          >
            <span style={{ fontSize: "1rem" }}>Continue</span>
          </Button>
        </Form.Item>
        <Form.Item className="remeberForgotWRAP">
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox onChange={(e) => remember(e)}>
              <div className="remeberForgotCSS">
                <span>Remember me</span>
              </div>
            </Checkbox>
          </Form.Item>

          <div
            className="forgotPassCSS"
            // href=""
            onClick={forgetpassword}
            style={{ marginTop: "0.15rem" }}
          >
            Forgot Password?
          </div>
        </Form.Item>
      </Form>
      <Modal
        bodyStyle={{ padding: 0 }}
        width="24.37rem"
        visible={isModalVisibleAuth}
        closable={false}
        footer={null}
        // style={{ top: 45, padding: 0 }}
        onCancel={modalCancel}
        maskClosable={true}
        centered={true}
        destroyOnClose={true}
        destroyPopupOnHide={true}
      >
        <LostPassword
          lostPassToLogin={lostPassToLogin}
          backClick={backClicked}
        />
        <div className="authDivider"></div>
        <div
          className="bottom-text"
          onClick={() => {
            // history.push("/login");
            {
              modalCancel();
            }
          }}
        >
          Back to Sign In
        </div>
      </Modal>
    </div>
  );
};

export default Login;
