import { types } from "../action-types";

const initialState = {
  loading: false,
  success: [],
  error: {},
};

export const NotificationReducer = (state = initialState, action) => {
    switch (action.type) {
      case types.UPDATE_NOTIFICATION_INIT:
        return {
          ...state,
          loading: true,
        };

      case types.UPDATE_NOTIFICATIN_SUCCESS:
        return {
          ...state,
          loading: false,
          success: action.payload,
          error: {},
        };

      case types.UPDATE_NOTIFICATIN_FAILURE:
        return {
          ...state,
          loading: false,
          success: [],
          error: action.error,
        };

      default:
        return state;
    }
  };