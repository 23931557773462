import React, { useState, useEffect, useRef } from "react";
//import "./MobileFilters.css";
import { Button, Radio, Input, Menu, Drawer, Divider, Space } from "antd";
import { debounce, timeout } from "../../../shared/helpers";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import {
  fetchKeySearchLocation,
  fetchSearchSkill,
  saveSearchLocation,
  saveSearchSkill,
  UpdateSearchFilter,
} from "../../../redux/actions";
import { state } from "@antv/g2plot/lib/adaptor/common";

const MobileFilters = (props) => {
  const jobQuery = useSelector((state) => state.searchGigsReducer.jobQuery);
  const skillQuery = useSelector((state) => state.searchGigsReducer.skillQuery);
  const locationQuery = useSelector(
    (state) => state.searchGigsReducer.locationQuery
  );
  const minbudgetQuery = useSelector(
    (state) => state.searchGigsReducer.minbudgetQuery
  );
  const maxbudgetQuery = useSelector(
    (state) => state.searchGigsReducer.maxbudgetQuery
  );
  const budgetQuery = useSelector(
    (state) => state.searchGigsReducer.budgetQuery
  );
  const budgetTypeQuery = useSelector(
    (state) => state.searchGigsReducer.budgetTypeQuery
  );
  const ProfessionCode = props.ProfessionCode;
  const WorkplacePolicy = useSelector(
    (state) => state.searchGigsReducer.WorkplacePolicy
  );
  const pageNo = useSelector((state) => state.searchGigsReducer.pageNo);
  const [jobValue, setJobValue] = useState(jobQuery);
  const [budgetMin, setBudgetMin] = useState(minbudgetQuery);
  const [budgetMax, setBudgetMax] = useState(maxbudgetQuery);
  const [budgetType, setBudgetType] = useState(budgetTypeQuery);
  const [WorkplacePolicyItem, setWorkplacePolicyItem] =
    useState(WorkplacePolicy);
  const [skillsinput, setSkillsInput] = useState(skillQuery);
  const [locationinput, setLocationInput] = useState(locationQuery);
  const reducerSkill = useSelector(
    (state) => state.skillListingReducer.skillQuery
  );
  const reducerLocation = useSelector(
    (state) => state.skillListingReducer.LocationQuery
  );
  const [skillValue, setSkillValue] = useState(reducerSkill);
  const [locationValue, setLocationValue] = useState(reducerLocation);
  const [noDataView, setNoDataView] = useState("");
  const [visible, setVisible] = useState(false);
  const [filterDrawerSkill, setFilterDrawerSkill] = useState(false);
  const [filterDrawerLocation, setFilterDrawerLocation] = useState(false);
  const skills = useSelector((state) => state.skillListingReducer.skills);
  const locations = useSelector(
    (state) => state.credentialingReducer.searchedLocation
  );
  const [isBudgetType1, setIsBudgetType1] = useState(false);
  const [isBudgetType2, setIsBudgetType2] = useState(false);
  const [validation, setValidation] = useState(false);
  const isBudgetContract = useSelector(
    (state) => state.searchGigsReducer.isBudgetContract
  );
  const isBudgetPermanent = useSelector(
    (state) => state.searchGigsReducer.isBudgetPermanent
  );
  const dispatch = useDispatch();
  const notInitialRender = useRef(false);
  const minBudgetRef = useRef(null);
  const maxBudgetRef = useRef(null);

  // useEffect(() => {
  //   setSkillValue(skillQuery);
  //   setLocationValue(locationQuery);
  //   setBudgetMin(minbudgetQuery);
  //   setBudgetMax(maxbudgetQuery);
  //   setBudgetType(budgetTypeQuery);
  // }, [
  //   skillQuery,
  //   locationQuery,
  //   minbudgetQuery,
  //   maxbudgetQuery,
  //   budgetTypeQuery,
  // ]);

  useEffect(() => {
    if (notInitialRender.current) {
      setBudgetMin(null);
      setBudgetMax(null);
      setBudgetType(null);
      setSkillValue(null);
      setLocationValue(null);
      setJobValue(null);
      setValidation(false);
      setWorkplacePolicyItem(null);
      // minBudgetRef.current.focus();
      // minBudgetRef.current.background = "red";
      // maxBudgetRef.current.value = "A";
      // console.log("props.oncloearall", props.onClearAll);
    } else {
      notInitialRender.current = true;
    }
  }, [props.onClearAll]);

  const onChangeSkill = (e) => {
    console.log("radio checked", e.target.value);
    // dispatch(UpdateSearchFilter("skillQuery", e.target.value));
    setSkillValue(e.target.value);
    setFilterDrawerSkill(false);
    // dispatch(UpdateSearchFilter("filterDrawer", false));
  };
  const onChangeLocation = (e) => {
    console.log("radio checked", e.target.value);
    // dispatch(UpdateSearchFilter("locationQuery", e.target.value));
    setLocationValue(e.target.value);
    setFilterDrawerLocation(false);
    // dispatch(UpdateSearchFilter("filterDrawer", false));
  };

  const onApply = (value, filter) => {
    if (
      jobValue &&
      !locationValue &&
      !skillValue &&
      !budgetType &&
      !budgetMin &&
      !budgetMax
    ) {
      console.log("applied");
      // setjobQuery(value);
      dispatch(UpdateSearchFilter("jobQuery", jobValue));
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
      if (jobValue === "Contract") {
        dispatch(UpdateSearchFilter("isBudgetContract", true));
        dispatch(UpdateSearchFilter("isBudgetPermanent", false));
      }
      if (jobValue === "Permanent") {
        dispatch(UpdateSearchFilter("isBudgetContract", false));
        dispatch(UpdateSearchFilter("isBudgetPermanent", true));
      }
    }
    if (
      skillValue &&
      !jobValue &&
      !locationValue &&
      !budgetType &&
      !budgetMin &&
      !budgetMax
    ) {
      console.log("applied");
      // setskillQuery(value);
      // props.UpdateSearchFilter("skillQuery", value);
      dispatch(UpdateSearchFilter("skillQuery", skillValue));
      console.log("query value", skillValue);
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
    }
    if (
      locationValue &&
      !jobValue &&
      !skillValue &&
      !budgetType &&
      !budgetMin &&
      !budgetMax
    ) {
      console.log("applied");
      // setlocationQuery(value);
      dispatch(UpdateSearchFilter("locationQuery", locationValue));
      console.log("query value", locationValue);
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
    }
    if (
      (budgetType &&
        budgetMin > 0 &&
        !budgetMax &&
        !jobValue &&
        !locationValue &&
        !skillValue) ||
      (budgetType &&
        budgetMax > 0 &&
        !budgetMin &&
        !jobValue &&
        !locationValue &&
        !skillValue)
    ) {
      console.log("applied");
      dispatch(UpdateSearchFilter("minbudgetQuery", budgetMin));
      dispatch(UpdateSearchFilter("maxbudgetQuery", budgetMax));
      dispatch(UpdateSearchFilter("budgetTypeQuery", budgetType));
      dispatch(UpdateSearchFilter("budgetQuery", true));
      // setBudgetQuery(true);
      console.log("query value", budgetMin);
      console.log("query value", budgetMax);
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
    }
    if (
      budgetType &&
      budgetMin > 0 &&
      budgetMax > 0 &&
      budgetMin < budgetMax &&
      !jobValue &&
      !locationValue &&
      !skillValue
    ) {
      console.log("applied");
      dispatch(UpdateSearchFilter("minbudgetQuery", budgetMin));
      dispatch(UpdateSearchFilter("maxbudgetQuery", budgetMax));
      dispatch(UpdateSearchFilter("budgetTypeQuery", budgetType));
      dispatch(UpdateSearchFilter("budgetQuery", true));
      console.log("query value", budgetMin);
      console.log("query value", budgetMax);
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
    }
    // if (
    //   jobValue ||
    //   skillValue ||
    //   locationValue ||
    //   (budgetType && (budgetMin > 0 || budgetMax > 0)) ||
    //   (budgetType &&
    //     budgetMin > 0 &&
    //     budgetMax > 0 &&
    //     budgetMax > 0 > budgetMin > 0)
    // ) {
    //   console.log("applied");
    //   if (jobValue) {
    //     // setjobQuery(value);
    //     dispatch(UpdateSearchFilter("jobQuery", jobValue));
    //     // setPageNo("1");
    //     dispatch(UpdateSearchFilter("pageNo", "1"));
    //     dispatch(UpdateSearchFilter("filterDrawer", false));
    //     if (jobValue === "Contract") {
    //       dispatch(UpdateSearchFilter("isBudgetContract", true));
    //       dispatch(UpdateSearchFilter("isBudgetPermanent", false));
    //     }
    //     if (jobValue === "Permanent") {
    //       dispatch(UpdateSearchFilter("isBudgetContract", false));
    //       dispatch(UpdateSearchFilter("isBudgetPermanent", true));
    //     }
    //   }
    //   if (skillValue) {
    //     // setskillQuery(value);
    //     // props.UpdateSearchFilter("skillQuery", value);
    //     dispatch(UpdateSearchFilter("skillQuery", skillValue));
    //     console.log("query value", skillValue);
    //     // setPageNo("1");
    //     dispatch(UpdateSearchFilter("pageNo", "1"));
    //     dispatch(UpdateSearchFilter("filterDrawer", false));
    //   }
    //   if (locationValue) {
    //     // setlocationQuery(value);
    //     dispatch(UpdateSearchFilter("locationQuery", locationValue));
    //     console.log("query value", locationValue);
    //     // setPageNo("1");
    //     dispatch(UpdateSearchFilter("pageNo", "1"));
    //     dispatch(UpdateSearchFilter("filterDrawer", false));
    //   }
    //   dispatch(UpdateSearchFilter("minbudgetQuery", budgetMin));
    //   dispatch(UpdateSearchFilter("maxbudgetQuery", budgetMax));
    //   dispatch(UpdateSearchFilter("budgetTypeQuery", budgetType));
    //   dispatch(UpdateSearchFilter("budgetQuery", true));
    //   console.log("query value", budgetMin);
    //   console.log("query value", budgetMax);
    //   // setPageNo("1");
    //   dispatch(UpdateSearchFilter("pageNo", "1"));
    //   dispatch(UpdateSearchFilter("filterDrawer", false));
    // }
    if (
      (jobValue && (locationValue || skillValue)) ||
      (budgetType && (budgetMin > 0 || budgetMax > 0)) ||
      (budgetType && budgetMin > 0 && budgetMax > 0 && budgetMax > budgetMin) ||
      (skillValue && (locationValue || jobValue)) ||
      (budgetType && (budgetMin > 0 || budgetMax > 0)) ||
      (budgetType && budgetMin > 0 && budgetMax > 0 && budgetMax > budgetMin)
    ) {
      console.log("applied");
      if (jobValue) {
        // setjobQuery(value);
        dispatch(UpdateSearchFilter("jobQuery", jobValue));
        // setPageNo("1");
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
        if (jobValue === "Contract") {
          dispatch(UpdateSearchFilter("isBudgetContract", true));
          dispatch(UpdateSearchFilter("isBudgetPermanent", false));
        }
        if (jobValue === "Permanent") {
          dispatch(UpdateSearchFilter("isBudgetContract", false));
          dispatch(UpdateSearchFilter("isBudgetPermanent", true));
        }
      }
      if (skillValue) {
        // setskillQuery(value);
        // props.UpdateSearchFilter("skillQuery", value);
        dispatch(UpdateSearchFilter("skillQuery", skillValue));
        console.log("query value", skillValue);
        // setPageNo("1");
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
      }
      if (locationValue) {
        // setlocationQuery(value);
        dispatch(UpdateSearchFilter("locationQuery", locationValue));
        console.log("query value", locationValue);
        // setPageNo("1");
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
      }
      dispatch(UpdateSearchFilter("minbudgetQuery", budgetMin));
      dispatch(UpdateSearchFilter("maxbudgetQuery", budgetMax));
      dispatch(UpdateSearchFilter("budgetTypeQuery", budgetType));
      dispatch(UpdateSearchFilter("budgetQuery", true));
      console.log("query value", budgetMin);
      console.log("query value", budgetMax);
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
    }
    if (
      (jobValue && skillValue && locationValue) ||
      (jobValue &&
        skillValue &&
        budgetType &&
        (budgetMin > 0 || budgetMax > 0)) ||
      (budgetType && budgetMin > 0 && budgetMax > 0 && budgetMax > budgetMin) ||
      (jobValue &&
        locationValue &&
        budgetType &&
        (budgetMin > 0 || budgetMax > 0)) ||
      (budgetType && budgetMin > 0 && budgetMax > 0 && budgetMax > budgetMin)
    ) {
      console.log("applied");
      if (jobValue) {
        // setjobQuery(value);
        dispatch(UpdateSearchFilter("jobQuery", jobValue));
        // setPageNo("1");
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
        if (jobValue === "Contract") {
          dispatch(UpdateSearchFilter("isBudgetContract", true));
          dispatch(UpdateSearchFilter("isBudgetPermanent", false));
        }
        if (jobValue === "Permanent") {
          dispatch(UpdateSearchFilter("isBudgetContract", false));
          dispatch(UpdateSearchFilter("isBudgetPermanent", true));
        }
      }
      if (skillValue) {
        // setskillQuery(value);
        // props.UpdateSearchFilter("skillQuery", value);
        dispatch(UpdateSearchFilter("skillQuery", skillValue));
        console.log("query value", skillValue);
        // setPageNo("1");
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
      }
      if (locationValue) {
        // setlocationQuery(value);
        dispatch(UpdateSearchFilter("locationQuery", locationValue));
        console.log("query value", locationValue);
        // setPageNo("1");
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
      }
      dispatch(UpdateSearchFilter("minbudgetQuery", budgetMin));
      dispatch(UpdateSearchFilter("maxbudgetQuery", budgetMax));
      dispatch(UpdateSearchFilter("budgetTypeQuery", budgetType));
      dispatch(UpdateSearchFilter("budgetQuery", true));
      console.log("query value", budgetMin);
      console.log("query value", budgetMax);
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
    }
    if (
      (jobValue &&
        budgetType &&
        skillValue &&
        locationValue &&
        (budgetMin > 0 || budgetMax > 0)) ||
      (budgetType && budgetMin > 0 && budgetMax > 0 && budgetMax > budgetMin)
    ) {
      console.log("applied");
      if (jobValue) {
        // setjobQuery(value);
        dispatch(UpdateSearchFilter("jobQuery", jobValue));
        // setPageNo("1");
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
        if (jobValue === "Contract") {
          dispatch(UpdateSearchFilter("isBudgetContract", true));
          dispatch(UpdateSearchFilter("isBudgetPermanent", false));
        }
        if (jobValue === "Permanent") {
          dispatch(UpdateSearchFilter("isBudgetContract", false));
          dispatch(UpdateSearchFilter("isBudgetPermanent", true));
        }
      }
      if (skillValue) {
        // setskillQuery(value);
        // props.UpdateSearchFilter("skillQuery", value);
        dispatch(UpdateSearchFilter("skillQuery", skillValue));
        console.log("query value", skillValue);
        // setPageNo("1");
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
      }
      if (locationValue) {
        // setlocationQuery(value);
        dispatch(UpdateSearchFilter("locationQuery", locationValue));
        console.log("query value", locationValue);
        // setPageNo("1");
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
      }
      dispatch(UpdateSearchFilter("minbudgetQuery", budgetMin));
      dispatch(UpdateSearchFilter("maxbudgetQuery", budgetMax));
      dispatch(UpdateSearchFilter("budgetTypeQuery", budgetType));
      dispatch(UpdateSearchFilter("budgetQuery", true));
      console.log("query value", budgetMin);
      console.log("query value", budgetMax);
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
    }
    if (WorkplacePolicyItem) {
      dispatch(UpdateSearchFilter("WorkplacePolicy", WorkplacePolicyItem));
      // console.log("query value", skillValue);
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
    } else {
      setValidation(true);
    }
  };

  // GET SKILLS
  const onSkillSearch = (val) => {
    console.log("value", val);
    setNoDataView(val);
    // props.searchSkills(val);
    dispatch(fetchSearchSkill(val));
    setSkillsInput(val);
    // console.log("skill", skill);
  };
  const getSearchSkill = (value) => {
    if (value) {
      dispatch(saveSearchSkill(value));
    }
  };

  // GET LOCATIONS
  function onLocationSearch(val) {
    console.log("search:", val);
    // console.log("location", city);
    setNoDataView(val);
    dispatch(fetchKeySearchLocation(val));
  }

  const getSearchLocation = (value) => {
    console.log("value", value);
    if (value) {
      // setLocation(value);
      // console.log("location", location);
      dispatch(saveSearchLocation(value));
    }
  };

  // Filter Drawer
  const handleFilterDrawerSkill = (state) => {
    setFilterDrawerSkill(state);
    setVisible(false);
  };
  const handleFilterDrawerLocation = (state) => {
    setFilterDrawerLocation(state);
    setVisible(false);
  };
  const mobileFilterSkill = () => {
    // alert("Filter");
    if (window.innerWidth < 768) handleFilterDrawerSkill(!filterDrawerSkill);
    setVisible(true);
  };
  const mobileFilterLocation = () => {
    // alert("Filter");
    if (window.innerWidth < 768)
      handleFilterDrawerLocation(!filterDrawerLocation);
    setVisible(true);
  };
  const closeFilterDrawer = () => {
    setFilterDrawerSkill(false);
    setFilterDrawerLocation(false);
  };
  const skillMenu = (
    <Menu onClick={null} className="s-result-mobile">
      <Input
        placeholder="Select Skill"
        prefix={<SearchOutlined />}
        defaultValue={skillValue}
        // style={{ width: 200 }}
        className="skill-search-mobile"
        onChange={debounce((e) => onSkillSearch(e.target.value))}
      />

      <Menu>
        {skills &&
          skills.map((skill) => {
            return (
              <Menu.Item onClick={(val) => getSearchLocation(val)}>
                <div>
                  <span
                  // className={skill === receivedBidSort ? "greenTextRB" : null}
                  >
                    <Radio.Group onChange={onChangeSkill} value={skillValue}>
                      <Radio
                        value={skill?.SkillName}
                        className="Search-jobs"
                      >
                        <span style={{ color: "#3e3e3e" }}>
                          {skill?.SkillName}
                        </span>
                      </Radio>
                    </Radio.Group>
                  </span>
                </div>
              </Menu.Item>
            );
          })}
      </Menu>
    </Menu>
  );
  const locationMenu = (
    <Menu onClick={null} className="s-result-mobile">
      <Input
        placeholder="Select Skill"
        prefix={<SearchOutlined />}
        defaultValue={locationValue}
        // style={{ width: 200 }}
        className="skill-search-mobile"
        onChange={debounce((e) => onLocationSearch(e.target.value))}
      />

      <Menu>
        {locations &&
          locations.map((city) => {
            return (
              <Menu.Item onClick={(val) => getSearchSkill(val)}>
                <div>
                  <span
                  // className={skill === receivedBidSort ? "greenTextRB" : null}
                  >
                    <Radio.Group
                      onChange={onChangeLocation}
                      value={locationValue}
                    >
                      <Radio
                        value={city?.Location}
                        className="Search-jobs"
                      >
                        <span style={{ color: "#3e3e3e" }}>
                          {city?.Location}
                        </span>
                      </Radio>
                    </Radio.Group>
                  </span>
                </div>
              </Menu.Item>
            );
          })}
      </Menu>
    </Menu>
  );
  const budgetTypeClick1 = () => {
    // setIsBudgetType1(true);
    // dispatch(UpdateSearchFilter("isBudgetContract", true));
    // dispatch(UpdateSearchFilter("isBudgetPermanent", false));
    // setIsBudgetType2(false);
    setJobValue("Contract");
    setValidation(false);
  };
  const budgetTypeClick2 = () => {
    // setIsBudgetType2(true);
    // dispatch(UpdateSearchFilter("isBudgetPermanent", true));
    dispatch(UpdateSearchFilter("isBudgetContract", false));
    // setIsBudgetType1(false);
    setJobValue("Permanent");
    setValidation(false);
  };
  const onChangeBudget = (e) => {
    setBudgetType(e.target.value);
    console.log("Budget type", e.target.value);
  };
  const onChangeWorkplace = (e) => {
    setWorkplacePolicyItem(e.target.value);
    console.log("setWorkplacePolicyItem", e.target.value);
  };
  const ITFilter = ["Fully Remote", "Hybrid", "On-Prem"];
  return (
    <div className="Mobile-filter-container">
      <div className="job-type">Job Type</div>
      <div className="mobile-filter">
        <div
          className={
            validation
              ? "left-invalid"
              : jobValue === "Contract"
              ? "left-active"
              : "left"
          }
          onClick={budgetTypeClick1}
        >
          <div className="text" onClick={budgetTypeClick1}>
            Contract
          </div>
        </div>
        <div
          className={
            validation
              ? "left-invalid"
              : jobValue === "Permanent"
              ? // ||isBudgetPermanent ||
                //   jobQuery === "Permanent"
                "left-active"
              : "left"
          }
          onClick={budgetTypeClick2}
        >
          <div className="text">Permanent</div>
        </div>
      </div>
      {validation ? (
        <div className="search-results-filter-mobile-validation-text">
          Please select any of the job type
        </div>
      ) : null}
      {ProfessionCode !== "IT" ? (
        <>
          <div className="job-type">Budget</div>
          {jobValue || isBudgetContract || isBudgetPermanent || jobQuery ? (
            <>
              <Radio.Group
                onChange={onChangeBudget}
                value={budgetType}
                defaultValue={budgetTypeQuery}
              >
                <Radio value={"Hourly"} className="Search-jobs">
                  Hourly
                </Radio>
                <Radio value={"Weekly"} className="Search-jobs">
                  Weekly
                </Radio>
              </Radio.Group>
            </>
          ) : null}
          <div className="input-container">
            <Input
              prefix="$"
              className={
                validation ? "input-left-invalid" : "input-left"
              }
              placeholder="50"
              onChange={(e) => setBudgetMin(e.target.value)}
              // value={minbudgetQuery}
              ref={minBudgetRef}
              value={budgetMin}
            />
            <div className="dash">-</div>
            <Input
              prefix="$"
              className={
                validation ? "input-right-invalid" : "input-right"
              }
              placeholder="60"
              onChange={(e) => setBudgetMax(e.target.value)}
              ref={maxBudgetRef}
              value={budgetMax}
            />
          </div>
          {validation ? (
            <div className="search-results-filter-mobile-validation-text">
              Please enter budget
            </div>
          ) : null}
        </>
      ) : null}
      {/* LOCATION AND SEARCH INPUTS */}

      <div className="job-type">Skill</div>
      <Input
        placeholder="Search Skill"
        value={skillQuery ? skillQuery : skillValue}
        // style={{ width: 200 }}
        className={
          validation
            ? "skill-search-mobile-invalid"
            : "skill-search-mobile"
        }
        // onChange={debounce((e) => onSkillSearch(e.target.value))}
        onClick={mobileFilterSkill}
      />
      {validation ? (
        <div className="search-results-filter-mobile-validation-text">
          Please select skill
        </div>
      ) : null}
      <div className="job-type">Location</div>
      <Input
        placeholder="Search Location"
        value={locationQuery ? locationQuery : locationValue}
        // style={{ width: 200 }}
        className={
          validation
            ? "skill-search-mobile-invalid"
            : "skill-search-mobile"
        }
        // onChange={debounce((e) => onSkillSearch(e.target.value))}
        onClick={mobileFilterLocation}
      />
      {validation ? (
        <div className="search-results-filter-mobile-validation-text">
          Please select location
        </div>
      ) : null}
      {ProfessionCode === "IT" ? (
        <>
          <div className="job-type">Work Policy</div>
          <Radio.Group
            className="radioSortReceivedBidsGroupFilter"
            onChange={(e) => onChangeWorkplace(e)}
            value={WorkplacePolicyItem}
            defaultValue={WorkplacePolicy}
          >
            <Space direction="vertical">
              {ITFilter.map((dataIT) => {
                return (
                  <>
                    <Radio
                      className={
                        "radioSortReceivedBidsFilter " +
                        (validation
                          ? "MobileFilter-Search-input-left-invalid"
                          : null)
                      }
                      value={dataIT}
                    >
                      <span className="SearchRadioButtonColor">
                        {dataIT}
                      </span>
                    </Radio>
                  </>
                );
              })}
            </Space>
          </Radio.Group>
        </>
      ) : null}
      <div className="mobile-filter">
        {/* <Space direction="vertical">
                <Space wrap> */}
        <Drawer
          title="Select Skill"
          placement="top"
          onClose={closeFilterDrawer}
          visible={filterDrawerSkill}
          className="SearchfilterDrawer-mobile"
          height="40rem"
          width="100%"
        >
          {skillMenu}
        </Drawer>
      </div>
      <div className="mobile-filter">
        {/* <Space direction="vertical">
                <Space wrap> */}
        <Drawer
          title="Select Location"
          placement="top"
          onClose={closeFilterDrawer}
          visible={filterDrawerLocation}
          className="SearchfilterDrawer-mobile"
          height="40rem"
          width="100%"
          // footer="Hello"
        >
          {locationMenu}
        </Drawer>
      </div>
      {/* <div>
        <Divider />
      </div> */}
      <div className="apply_button_main">
        <div className="apply-button-mobile-filter">
          <div className="apply-button-text" onClick={onApply}>
            Apply Filter
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileFilters;
