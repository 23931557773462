import { types } from "../action-types";

const initialState = {
  loading: false,
  user: {
    authToken: "",
    userDocs: [],
    profile: {},
    name: null,
    email: null,
  },
  error: {},
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        user: { ...state.user, authToken: action.payload },
        error: {},
      };
    case types.LINKEDIN_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: { ...state.user, authToken: action.payload },
        error: {},
      };
    case types.SEND_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        user: { ...state.user, userDocs: action.payload },
        error: {},
      };
    case types.SEND_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        user: {},
        error: action.error,
      };
    case types.UPLOAD_DOCUMENT_SUCCESS:
      console.log("PAYLOAD IN REDUCER:", action.payload);
      const docItem = state.user.userDocs.find(
        (item) => item._id == action.payload.CredentialDocumentId
      );
      docItem["userDoc"] = action.payload;
      return {
        ...state,
        loading: false,
        user: { ...state.user },
        error: {},
      };
    case types.UPLOAD_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        user: {},
        error: action.error,
      };
    case types.FETCH_PROFILE_INIT:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_PROFILE_REQUEST_SUCCESS:
      const { profile, email, name, _id } = action.payload;
      return {
        ...state,
        loading: false,
        user: { ...state.user, profile, email, name, id: _id },
        error: {},
      };
    case types.FETCH_PROFILE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.UPDATE_PROFILE_INIT:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: { ...state.user, profile: action.payload.profile },
        error: {},
      };
    case types.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default appReducer;
