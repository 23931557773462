import React, { useState, useEffect } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  ApolloLink,
  gql,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { RestLink } from "apollo-link-rest";
import { HttpLink } from "apollo-link-http";
import GuestGigDetails from "./GuestGigDetails";
import { useSelector } from "react-redux";
import { REACT_APP_API_URL } from "../../../../config";

const GuestGidDetailProvider = (props) => {
  const authTokenValue = useSelector(
    (state) => state.authReducer.user.authToken
  );
  const BackendApiBaseUrl = REACT_APP_API_URL;
  console.log("Auth token", authTokenValue);
  const httpLink = new HttpLink({
    uri: `${BackendApiBaseUrl}/query`,
  });
  const authLink = setContext((_, { headers }) => {
    // get the authentication token if it exists
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: authTokenValue ? `${authTokenValue}` : "",
      },
    };
  });
  const client = new ApolloClient({
    // uri: `${BackendApiBaseUrl}/query`,
    cache: new InMemoryCache(),
    // link: ApolloLink.from([httpLink]),
    link: authLink.concat(httpLink),
  });

  // useEffect(() => {
  //   props.GetGigs();
  //   const GigsResponse = props.user.Gigs;
  //   console.log(GigsResponse);
  //   // setState(data);
  // }, []);

  // useEffect(() => {
  //   const GigsResponse = props.user.Gigs;
  //   // console.log(GigsResponse);
  //   setGigSet(GigsResponse);
  //   // setState(data);
  // }, [props.user.Gigs]);
  // console.log(GetGigs);
  return (
    <ApolloProvider client={client}>
      <GuestGigDetails />
    </ApolloProvider>
  );
};

export default GuestGidDetailProvider;

// Link: link allows you to customize the flow of data from your graphQL queries
// and mutations to your backend and in-app state management.
// This can include: adding custom headers and routing to REST endpoints and graphQL endpoints.
