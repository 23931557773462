import { call, put, takeEvery } from "redux-saga/effects";
import { types } from "../action-types";
import { approveLinkedInUserAPI, fetchToken } from "../../API/API";
import { getCookie, setCookie } from "../../shared/helpers";
import { linkedInTokenFail } from "../actions";

function* authSaga(action) {
  // console.log("SAGA TOKEN HIT");
  yield put({ type: types.UPDATE_USER_INFO_SUCCESS, payload: action.payload });
}

// catches the action before going to the reducer and run the saga attached to it
export function* watchAuthSaga() {
  yield takeEvery(types.UPDATE_USER_INFO_INIT, authSaga);
}

// Linked In Saga

function* linkedInSaga(action) {
  console.log("Saga Token: ", action.payload);
  try {
    const response = yield call(() => approveLinkedInUserAPI(action.payload)); // API Backend
    console.log("API RESPONSE LinkedIn: ", response);
    setCookie("token", response.data.token, true);
    if (response.data.token) {
      window.location.replace('/auth')
    }
    yield put({
      type: types.UPDATE_USER_INFO_SUCCESS,
      payload: response.data.token,
    });
  } catch (error) {
    console.log("LINK ERROR RESPONSE", error.response);
    yield put(linkedInTokenFail(error.response.data));
  }
  // yield put({ type: types.LINKEDIN_TOKEN_FETCH, payload: action.payload });
}
export function* watchLinkedInSaga() {
  yield takeEvery(types.LINKEDIN_TOKEN_INIT, linkedInSaga);
}
