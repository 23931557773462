import Amplify, { Auth, Hub } from "aws-amplify";
import { REACT_APP_COGNITO_DOMAIN, REACT_APP_COGNITO_identityPoolId, REACT_APP_COGNITO_region, REACT_APP_COGNITO_userPoolId, REACT_APP_COGNITO_userPoolWebClientId, REACT_APP_MY_URL } from "../config";

Amplify.configure({
  // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
  Auth: {
    identityPoolId: REACT_APP_COGNITO_identityPoolId,

    // REQUIRED - Amazon Cognito Region
    region: REACT_APP_COGNITO_region,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: REACT_APP_COGNITO_userPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: REACT_APP_COGNITO_userPoolWebClientId,
  },

  //   // OPTIONAL - Hosted UI configuration
  oauth: {
    domain: REACT_APP_COGNITO_DOMAIN,
    scope: [
      "phone",
      "email",
      "profile",
      "openid",
      "aws.cognito.signin.user.admin",
    ],

    redirectSignIn: `${REACT_APP_MY_URL}/auth`,
    redirectSignOut: `${REACT_APP_MY_URL}/`,

    responseType: "token", // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
});

export { Auth, Hub };
