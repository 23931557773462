import React, { useState } from "react";
import {
  DownOutlined,
  InstagramOutlined,
  TwitterOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Col, Divider, Row } from "antd";
import { BsFacebook, BsLinkedin, BsSpotify, BsYoutube } from "react-icons/bs";
import FooterLogo from "../assets/img/FooterLogo.png";
import { useHistory } from "react-router-dom";

// import "./Footer.css";

const Footer = () => {
  const history = useHistory();
  const [dimensions, setDimensions] = useState(window.innerWidth);
  const [ListOfAbout, SetListOfAbout] = useState(false);
  const [ListOfSupport, SetListOfSupport] = useState(false);
  const [ListOfCommunity, SetListOfCommunity] = useState(false);
  const [ListOfMoreFromUs, SetListOfMoreFromUs] = useState(false);
  console.log(dimensions, "dimensionsfooter");

  const About = (
    <>
      <span className="footer-first-span">About SG</span>
      <span
        onClick={() => {
          history.push("/dummy");
        }}
      >
        How it Works
      </span>
      <span
        onClick={() => {
          history.push("/dummy");
        }}
      >
        Employers
      </span>
      <span
        onClick={() => {
          history.push("/dummy");
        }}
      >
        Investor Relations
      </span>
      <span
        onClick={() => {
          history.push("/dummy");
        }}
        className="footer-bottom-span"
      >
        Sitemap
      </span>
    </>
  );

  const Support = (
    <>
      <span
        onClick={() => {
          history.push("/dummy");
        }}
        className="footer-first-span"
      >
        Terms of Service
      </span>
      <span
        onClick={() => {
          history.push("/dummy");
        }}
      >
        Privacy Policy
      </span>
      <span
        onClick={() => {
          history.push("/dummy");
        }}
      >
        Security
      </span>
      <span
        onClick={() => {
          history.push("/dummy");
        }}
      >
        Help and Support
      </span>
      <span
        onClick={() => {
          history.push("/dummy");
        }}
        className="footer-bottom-span"
      >
        Contact Us
      </span>
    </>
  );

  const Community = (
    <>
      <span
        onClick={() => {
          history.push("/dummy");
        }}
        className="footer-first-span"
      >
        Our Expectations
      </span>
      <span
        onClick={() => {
          history.push("/dummy");
        }}
      >
        Career Advice
      </span>
      <span
        onClick={() => {
          history.push("/dummy");
        }}
      >
        Business Insights
      </span>
      <span
        onClick={() => {
          history.push("/dummy");
        }}
      >
        Blog
      </span>
      <span
        onClick={() => {
          history.push("/dummy");
        }}
        className="footer-bottom-span"
      >
        Invite a Friend
      </span>
    </>
  );

  const MoreFromUs = (
    <>
      <span
        onClick={() => {
          history.push("/dummy");
        }}
        className="footer-first-span"
      >
        Swipefox
      </span>
      <span
        onClick={() => {
          history.push("/dummy");
        }}
      >
        Our Products
      </span>
      <span
        onClick={() => {
          history.push("/dummy");
        }}
        className="footer-bottom-span"
      >
        Employer Demo
      </span>
    </>
  );

  return (
    <>
      <Divider className="footer-divider-top" />
      <div className="footer-container">
        {dimensions < 768 ? (
          <>
            <div className="footer-section">
              <div className="footer-About-section">
                <div
                  onClick={() => SetListOfAbout(!ListOfAbout)}
                  className="footer-heading"
                >
                  <span>About</span>
                  <span>
                    {!ListOfAbout ? <DownOutlined /> : <UpOutlined />}
                  </span>
                </div>
                {ListOfAbout === true ? <>{About}</> : null}
              </div>
              <div className="footer-Support-section">
                <div
                  onClick={() => SetListOfSupport(!ListOfSupport)}
                  className="footer-heading"
                >
                  <span>Support</span>
                  <span>
                    {!ListOfSupport ? <DownOutlined /> : <UpOutlined />}
                  </span>
                </div>
                {ListOfSupport === true ? <>{Support}</> : null}
              </div>
              <div className="footer-Community-section">
                <div
                  onClick={() => SetListOfCommunity(!ListOfCommunity)}
                  className="footer-heading"
                >
                  <span>Community</span>
                  <span>
                    {!ListOfCommunity ? <DownOutlined /> : <UpOutlined />}
                  </span>
                </div>
                {ListOfCommunity === true ? <>{Community}</> : null}
              </div>
              <div className="footer-Community-section">
                <div
                  onClick={() => SetListOfMoreFromUs(!ListOfMoreFromUs)}
                  className="footer-heading"
                >
                  <span>More from Us</span>
                  <span>
                    {!ListOfMoreFromUs ? <DownOutlined /> : <UpOutlined />}
                  </span>
                </div>
                {ListOfMoreFromUs === true ? <>{MoreFromUs}</> : null}
              </div>
            </div>
          </>
        ) : (
          <div className="footer-section">
            <div className="footer-About-section">
              <div className="footer-heading">About</div>
              {About}
            </div>
            <div className="footer-Support-section">
              <div className="footer-heading">Support</div>
              {Support}
            </div>
            <div className="footer-Community-section">
              <div className="footer-heading">Community</div>
              {Community}
            </div>
            <div className="footer-Community-section">
              <div className="footer-heading">More from US</div>
              {MoreFromUs}
            </div>
          </div>
        )}
        <Divider />
        <div className="footer-logo-section">
          <div className="Footer-logo-Skillgig">
            <img src={FooterLogo} />
            <span>© SkillGigs 2022</span>
          </div>
          <div className="footer-social-link">
            <div>
              <a
                href="https://www.facebook.com/skillgigsonline/"
                target="_blank"
              >
                <BsFacebook className="footer-icon-modifiction" />
              </a>
            </div>
            <a href="https://www.instagram.com/skillgigs" target="_blank">
              <span>
                <InstagramOutlined />
              </span>
            </a>
            <a
              href="https://www.linkedin.com/company/skillgigs"
              target="_blank"
            >
              <span>
                <BsLinkedin className="footer-icon-modifiction" />
              </span>
            </a>
            <a
              href="https://twitter.com/skillgigsonline?lang=en"
              target="_blank"
            >
              <span>
                <TwitterOutlined />
              </span>
            </a>
            <a
              href="https://open.spotify.com/show/0Hv4UfacP60hX8JM7a69k0"
              target="_blank"
            >
              <span>
                <BsSpotify className="footer-icon-modifiction" />
              </span>
            </a>
            <a
              href="https://www.youtube.com/channel/UC3e1p8j1onjj7sDH2Z_1JAA"
              target="_blank"
            >
              <span>
                <BsYoutube className="footer-icon-modifiction" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
