import { call, put, takeEvery } from "redux-saga/effects";
import { types } from "../action-types";
import { retractBidUpdateStatus, updateBidApi } from "../../API/API";

function* updateBidSaga(params) {
    console.log("param:",JSON.stringify(params));
    try {
        const response = yield call(updateBidApi, params); 
        yield put({type: types.UPDATE_BID_SUCCESS, payload: response.data});
      }
      catch (error) {
        yield put({type: types.UPDATE_BID_FAILURE, error});
    }
}

function* RetractedupdateBidSaga(params) {
    console.log("param:", params);
    try {
        const response = yield call(retractBidUpdateStatus, params);
        yield put({ type: types.RETRACTED_BID_UPDATE_STAGES_SUCCESS, payload: response.data });
    }
    catch (error) {
        yield put({ type: types.RETRACTED_BID_UPDATE_STAGES_FAILURE, error });
    }
}

export function* watchUpdateBidSaga() {
    yield takeEvery(types.UPDATE_BID_REQUESTED, updateBidSaga);
}

export function* watchRetractUpdateBidSaga() {
    yield takeEvery(types.RETRACTED_BID_UPDATE_STAGES_INIT, RetractedupdateBidSaga);
}
