import { types } from "../action-types";

const initialState = {
    loading: false,
    details: [],
    error: false,
    dropdown: [],
    success: false,
};

const getCompanyDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_COMPANY_DETAILS_DROPDOWN_REQUESTED:
            return {
                ...state,
                loading: true,
            };

        case types.GET_COMPANY_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                dropdown: action.payload,
                error: false,
            };
        case types.GET_COMPANY_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                dropdown: [],
                error: true,
            };
        default:
            return state;
    }
};

export default getCompanyDetailsReducer;
