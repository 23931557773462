import { types } from "./action-types";
export const isModalVisibleAuth = (params) => ({
  type: types.IS_MODAL_VISIBLE,
  payload: params,
});
export const deleteFavorites = (SourceId) => {
  return {
    type: types.DELETE_FAVORITES_REQUESTED,
    SourceId,
  };
};
export const fetchProfileDetails = (id) => {
  return {
    type: types.FETCH_PROFILE_DETAILS_REQUESTED,
    payload: {
      id,
    },
  };
};
export const getJobListing = (params) => {
  return {
    type: types.GET_JOB_LISTING,
    payload: params,
  };
};
export const createMultiBid = (params) => {
  return {
    type: types.CREATE_MULTI_BID_REQUESTED,
    payload: params,
  };
};
export const createInterview = (params) => {
  console.log("Response params are", params);

  return {
    type: types.CREATE_INTERVIEW_REQUESTED,
    payload: params,
  };
}
export const updateInterview = (params) => {

  return {
    type: types.UPDATE_INTERVIEW_REQUESTED,
    payload: params,
  };
};
export const removeFromMatches = (request) => {
  return {
    type: types.REMOVE_FROM_MATCHES_REQUESTED,
    payload: request,
  };
};
export const addToMatches = (request) => {
  return {
    type: types.ADD_TO_MATCHES_REQUESTED,
    payload: request,
  };
};
export const postFavorites = (SourceId) => {
  return {
    type: types.POST_FAVORITES_REQUESTED,
    SourceId,
  };
};
export const getOneUserSkillListing = (params) => {
  return {
    type: types.GET_ONE_USER_SKILLLISTING_REQUESTED,
    payload: params,
  };
};
export const signUpLoginUpdate = (params) => ({
  type: types.SIGNUP_LOGIN_UPDATE,
  payload: params,
});
export const Modalclosefunction = (params) => ({
  type: types.MODAL_CLOSE_FUNCTION,
  payload: params,
});
export const checkEmailFlow = (params) => ({
  type: types.EMAIL_FLOW_UPDATE,
  payload: params,
});
export const fetchData = (params) => ({
  type: types.SEND_REQUEST_INIT,
  payload: params,
});
export const fetchDataSuccess = (response) => ({
  type: types.SEND_REQUEST_SUCCESS,
  payload: response,
});
export const fetchDataFailure = (error) => ({
  type: types.SEND_REQUEST_FAILURE,
  payload: {},
  error: error,
});
export const updateUserInfo = (params) => ({
  type: types.UPDATE_USER_INFO_INIT,
  payload: params,
});
export const uploadDocument = (params) => ({
  type: types.UPLOAD_DOCUMENT_INIT,
  params,
});
export const uploadDocSuccess = (response) => ({
  type: types.UPLOAD_DOCUMENT_SUCCESS,
  payload: response,
});
export const uploadDocFailure = (error) => ({
  type: types.UPLOAD_DOCUMENT_FAILURE,
  payload: {},
  error: error,
});
export const removeDocument = (params) => ({
  type: types.REMOVE_DOCUMENT_INIT,
  params,
});
export const removeDocumentSuccess = (params) => ({
  type: types.REMOVE_DOCUMENT_SUCCESS,
  params,
});
export const removeDocumentFailure = (params) => ({
  type: types.REMOVE_DOCUMENT_FAIL,
  params,
});
export const fetchUserProfile = (payload) => ({
  type: types.FETCH_PROFILE_INIT,
  payload,
});
export const fetchUserProfileSuccess = (response) => ({
  type: types.FETCH_PROFILE_REQUEST_SUCCESS,
  payload: response,
});
export const fetchUserProfileFailure = (error) => ({
  type: types.FETCH_PROFILE_REQUEST_FAILURE,
  payload: {},
  error: error,
});
export const updateProfile = (params) => ({
  type: types.UPDATE_PROFILE_INIT,
  params,
});
export const updateProfileSuccess = (response) => ({
  type: types.UPDATE_PROFILE_SUCCESS,
  payload: response,
});
export const updateProfileFailure = (error) => ({
  type: types.UPDATE_PROFILE_FAILURE,
  payload: {},
  error: error,
});
export const uploadProfilePic = (params) => ({
  type: types.UPLOAD_PROFILE_PIC_INIT,
  params,
});
export const uploadProfilePicSuccess = (response) => ({
  type: types.UPLOAD_PROFILE_PIC_SUCCESS,
  payload: response,
});
export const uploadProfilePicFailure = (error) => ({
  type: types.UPLOAD_PROFILE_PIC_FAILURE,
  payload: {},
  error: error,
});
export const uploadResume = (params) => ({
  type: types.UPLOAD_RESUME_INIT,
  params,
});
export const uploadResumeSuccess = (response) => ({
  type: types.UPLOAD_RESUME_SUCCESS,
  payload: response,
});
export const uploadResumeFailure = (error) => ({
  type: types.UPLOAD_RESUME_FAILURE,
  payload: {},
  error: error,
});
// linkedIn login
export const fetchLinkedInToken = (params) => ({
  type: types.LINKEDIN_TOKEN_FETCH,
  payload: params,
});
export const linkedInTokenInit = (params) => ({
  type: types.LINKEDIN_TOKEN_INIT,
  payload: params,
});
export const linkedInTokenFail = (params) => ({
  type: types.LINKEDIN_TOKEN_FAIL,
  payload: params,
});
// Search Gigs
export const SearchResponseRequested = (params) => ({
  type: types.SEARCH_GIGS_INIT,
  payload: params,
});
export const SearchResponseRequestedSkillListing = (params) => {
  console.log('skilll data');
  return {
    type: types.SEARCH_LISTING_INIT,
    payload: params,
  }
};
export const SearchResponse = (params) => ({
  type: types.SEARCH_GIGS_SUCCESS,
  payload: params,
});
export const SearchResponseSkillListing = (params) => ({
  type: types.SEARCH_LISTING_SUCCESS,
  payload: params,
});
export const SearchValue = (params) => ({
  type: types.SEARCH_VALUE,
  payload: params,
});
export const SearchValueSkillListing = (params) => ({
  type: types.SEARCH_SKILL_VALUE,
  payload: params,
});
export const GetFilters = () => {
  return {
    type: types.GET_FILTERS_REQUESTED,
    payload: {},
  };
};
export const SearchSuggestions = (params) => {
  return {
    type: types.SEARCH_SUGGESTIONS_REQUESTED,
    payload: params,
  };
};
// export const SearchResponseSuccess = (response) => ({
//   type: types.SEARCH_GIGS_SUCCESS,
//   payload: response,
// });
// export const SearchResponseFailure = (error) => ({
//   type: types.SEARCH_GIGS_FAILURE,
//   payload: {},
//   error: error,
// });
// Fetch existing user
export const fetchExistingtUser = (params) => ({
  type: types.CHECK_EXISTING_USER_INIT,
  params,
});
export const fetchExistingUserSuccess = (response) => ({
  type: types.CHECK_EXISTING_USER_SUCCESS,
  payload: response,
});
export const fetchExistingUserFailure = (error) => ({
  type: types.CHECK_EXISTING_USER_FAILURE,
  payload: { valid: false, msg: "User doesn't exist" },
  error: error,
});
// export const fetchLinkedInTokenFail = (error) => ({
//   type: types.LINKEDIN_TOKEN_FAIL,
//   payload: {},
//   error: error,
// });
export const fetchSearchSkill = (params) => ({
  type: types.FETCH_SKILLS_INIT,
  params,
});
export const fetchSearchSkillSuccess = (response) => ({
  type: types.FETCH_SKILLS_SUCCESS,
  payload: response,
});
export const createSkillListing = (params) => ({
  type: types.CREATE_SKILL_LISTING_INIT,
  params,
});
export const updateSkillListing = (params) => ({
  type: types.UPDATE_SKILL_LISTING_INIT,
  params,
});
export const deleteSkillListing = (params) => ({
  type: types.DELETE_SKILL_LISTING,
  params,
});
export const updateSkillListingSuccess = (response) => ({
  type: types.UPDATE_SKILL_LISTING_SUCCESS,
  payload: response,
});
export const saveSearchSkill = (params) => ({
  type: types.UPDATE_SKILL_LISTING_SAVE,
  stage: "step4",
  payload: params,
});
// CredentialingForm
export const formSubmitStepOne = (e) => {
  if (e.target.name === "resume") {
    return {
      type: types.STEP_ONE,
      stage: "step1",
      payload: e.target.files[0],
      key: e.target.name,
    };
  } else {
    return {
      type: types.STEP_ONE,
      stage: "step1",
      payload: e.target.value,
      key: e.target.name,
    };
  }
};
export const formSubmitSkillListingOne = (e) => ({
  type: types.SKILLISTING,
  stage: "step3",
  payload: e.target.value,
  key: e.target.name,
});
export const formSubmitSkillListingTwo = (e) => ({
  type: types.SKILLISTING,
  stage: "step4",
  payload: e.target.value,
  key: e.target.name,
});
// get location - save location
export const fetchKeySearchLocation = (params) => ({
  type: types.FETCH_SEARCH_LOCATION_INIT,
  payload: params,
});
export const saveSearchLocationStepOne = (params) => ({
  type: types.FETCH_LOCATION_SAVE,
  stage: "step1",
  payload: params,
});
export const saveExperienceStepOne = (params) => ({
  type: types.FETCH_EXPERIENCE_SAVE,
  stage: "step1",
  payload: params,
});
export const fetchLocationFailure = (error) => ({
  type: types.FETCH_SEARCH_LOCATION_FAILURE,
  payload: {},
  error: error,
});
export const saveSearchLocation = (params) => ({
  type: types.FETCH_SEARCH_LOCATION_SAVE,
  stage: "step4",
  payload: params,
  key: "desiredLocation",
});
export const fetchRoleDiscipline = (params) => ({
  type: types.FETCH_ROLE_DISCIPLINE_INIT,
  payload: params,
});
export const fetchRoleDisciplineSuccess = (params) => ({
  type: types.FETCH_ROLE_DISCIPLINE_SUCCESS,
  payload: params,
});
// get Discipline - save Discipline
export const fetchKeySearchDiscipline = (params) => ({
  type: types.FETCH_SEARCH_DISCIPLINE_INIT,
  payload: params,
});
export const saveSearchDiscipline = (params) => ({
  type: types.SEARCH_DISCIPLINE_SAVE,
  stage: "step3",
  payload: params,
});
// Fetching User SkillListing
export const setPubfilterSkillListing = (params) => ({
  type: types.SET_PUBFILTER_SKILLISTING,
  payload: params,
});
export const setPageSkillListing = (params) => ({
  type: types.SET_PAGE_SKILLISTING,
  payload: params,
});
export const fetchUserSkillListing = (...params) => ({
  type: types.FETCH_USER_SKILLISTING_INIT,
  payload: params,
});
export const fetchUserSkillListingSuccess = (params) => ({
  type: types.FETCH_USER_SKILLISTING_SUCCESS,
  payload: params,
  error: {},
});
export const gotSkillListingFailure = (error) => ({
  type: types.SKILLLISTING_FAILURE,
  payload: {},
  error: error,
});
// fetch profile experience
export const fetchProfileExperience = () => ({
  type: types.FETCH_PROFILE_EXPERIENCE_INIT,
});
export const fetchProfileExperienceSuccess = (payload) => ({
  type: types.FETCH_PROFILE_EXPERIENCE_SUCCESS,
  payload: payload,
});
//BIDS
//PROPOSALS
export const fetchProposals = (id, limit, skip, status) => {
  return {
    type: types.FETCH_PROPOSALS_REQUESTED,
    payload: { id, limit, skip, status },
  };
};
export const createBid = (
  GigId,
  ProposalId,
  JobOrderId,
  ProfileId,
  Message,
  Created,
  Modified,
  InterviewId,
  BudgetType,
  ProposalStatus,
  Rate,
  IdealTerms,
  Shortlisted,
  AcceptedDate,
  SGFees,
  DeclineReasonId,
  DeclineReason,
  ViewedAt,
  ActualRate,
  Currency
) => {
  return {
    type: types.CREATE_BID_REQUESTED,
    payload: {
      GigId,
      ProposalId,
      JobOrderId,
      ProfileId,
      Message,
      Created,
      Modified,
      InterviewId,
      BudgetType,
      ProposalStatus,
      Rate,
      IdealTerms,
      Shortlisted,
      AcceptedDate,
      SGFees,
      DeclineReasonId,
      DeclineReason,
      ViewedAt,
      ActualRate,
      Currency,
    },
  };
};
export const createBidReset = () => {
  return {
    type: types.CREATE_BID_RESET,
  };
};
export const updateBid = (
  id,
  IdealTerms,
  BudgetType,
  Message,
  Rate,
  ProposalStatus,
  Modified,
  SGFees,
  ActualRate,
  SenderLabels,
  RecipientLabels,
  Currency
) => {
  return {
    type: types.UPDATE_BID_REQUESTED,
    payload: {
      id,
      IdealTerms,
      BudgetType,
      Message,
      Rate,
      ProposalStatus,
      Modified,
      SGFees,
      ActualRate,
      SenderLabels,
      RecipientLabels,
      Currency,
    },
  };
};
export const updateBidReset = () => {
  return {
    type: types.UPDATE_BID_RESET,
  };
};
export const retractBid = (
  ProposalStatus,
  id,
  SenderLabels,
  RecipientLabels,
  Message
) => {
  return {
    type: types.RETRACT_BID_REQUESTED,
    payload: {
      ProposalStatus,
      id,
      SenderLabels,
      RecipientLabels,
      Message,
    },
  };
};
export const retractBidReset = () => {
  return {
    type: types.RETRACT_BID_RESET,
  };
};
//fetchBidData
export const fetchBidData = (_id) => {
  return {
    type: types.FETCH_BIDDATA_REQUESTED,
    payload: {
      _id,
    },
  };
};
//fetchBidData
export const ResetBidData = (_id) => {
  return {
    type: types.FETCH_BIDDATA_RESET,
  };
};
// MESSAGES
// possible types of messages: 'inbox' / 'sentItems'
export const fetchMessages = (messageType, results, skip) => {
  return {
    type: types.FETCH_MESSAGES_REQUESTED,
    payload: {
      type: messageType,
      results,
      skip,
    },
  };
};
export const sendMessage = (
  conversationId,
  name,
  recipientId,
  message,
  ProfilePic,
  fileUploadResponse
) => {
  return {
    type: types.SEND_MESSAGE_REQUESTED,
    payload: {
      conversationId,
      name,
      recipientId,
      message,
      ProfilePic,
      fileUploadResponse,
    },
  };
};
export const sendMessage_Attachment = () => {
  return {
    type: types.SEND_MESSAGE_ATTACHMENT_REQUESTED,
  };
};
//CONVERSATION
export const fetchChatHistory = (id) => {
  return {
    type: types.FETCH_CHAT_REQUESTED,
    payload: id,
  };
};
export function receivedMessage(messageObject) {
  console.log("msg obj:", messageObject);
  return {
    type: types.MESSAGE_RECEIVED,
    payload: {
      message: messageObject,
    },
  };
}
export const updateReceivedBids = (
  id,
  BidStatusName,
  Message,
  SenderLabels,
  RecipientLabels
) => {
  return {
    type: types.FETCH_UPDATEBIDS_REQUESTED,
    payload: {
      id,
      BidStatusName,
      Message,
      SenderLabels,
      RecipientLabels,
    },
  };
};
export const updateReceivedBidsTalent = (
  id,
  BidStatusName,
  Message,
  SenderLabels,
  RecipientLabels
) => {
  return {
    type: types.FETCH_UPDATEBIDS_REQUESTED,
    payload: {
      id,
      BidStatusName,
      Message,
      SenderLabels,
      RecipientLabels,
    },
  };
};
// Delete Resume
export const deleteResume = () => ({
  type: types.DELETE_RESUME_INIT,
});
export const deleteResumeSuccess = (params) => ({
  type: types.DELETE_RESUME_SUCCESS,
  payload: params,
});
export const deleteResumeFailure = () => ({
  type: types.DELETE_RESUME_FAILURE,
});
//Get data for the graph
export const getGraph = (type, StartTime, enddate) => {
  return {
    type: types.GET_GRAPH_DATA_REQUESTED,
    payload: {
      type,
      StartTime,
      enddate,
    },
  };
};
export const createTimeSheetCorrection = (
  TimeSheetId,
  Date,
  StartTime,
  EndTime,
  Reason,
  Status
) => {
  console.log("request correction action");
  return {
    type: types.CREATE_TIMESHEET_CORRECTION_REQUESTED,
    payload: { TimeSheetId, Date, StartTime, EndTime, Reason, Status },
  };
};
export const createTimesheet = (Status, Date, SpentTime, OverTime) => {
  console.log("action dispatching");
  return {
    type: types.CREATE_TIMESHEET_DATA_REQUESTED,
    payload: {
      Status,
      Date,
      SpentTime,
      OverTime,
    },
  };
};
export const updateTimesheetItem = (Status) => {
  console.log("action dispatching with", Status);
  return {
    type: types.UPDATE_TIMESHEET_DATA_REQUESTED,
    payload: {
      Status,
    },
  };
};
export const fetchTimeSheetList = (limit, skip, type, start, end) => {
  // console.log("limit: ",JSON.stringify(limit));
  // console.log("skip: ",JSON.stringify(skip));
  return {
    type: types.FETCH_TIMESHEETLIST_REQUESTED,
    payload: { limit, skip, type, start, end },
  };
};
export const userAssignment = () => {
  return {
    type: types.USERASSIGNMENT_REQUESTED,
    payload: {},
  };
};
//Fetching timesheet start timer
export const setTimerTimeSheet = (params) => ({
  type: types.SET_TIMER_TIMESHEET,
  payload: params,
});
export const getExistingListItem = () => {
  return {
    type: types.GET_EXISTING_LIST_ITEM_REQUESTED,
    payload: {},
  };
};
// fetch search suggestions
export const fetchSearchSuggestions = (params) => ({
  type: types.FETCH_SEARCH_SUGGESTIONS_INIT,
  payload: params,
});
export const fetchSearchSuggestionsFailure = (error) => ({
  type: types.FETCH_SEARCH_SUGGESTIONS_FAILURE,
  payload: {},
  error: error,
});
// SEARCH FILTERS]
export const UpdateSearchFilter = (key, payload) => ({
  type: types.SEARCH_FILTER_UPDATE,
  payload,
  key,
});
// DashBoardInbox
export const fetchInboxData = (limit, skip, type, bidType, bidStatus) => {
  console.log("fetchInbox");
  return {
    type: types.FETCH_INBOX_DATA_REQUEST,
    payload: { limit, skip, type, bidType, bidStatus },
  };
};
export const fetchInboxDataSuccess = (payload) => ({
  type: types.FETCH_INBOX_DATA_SUCCESS,
  payload,
});
// Talent Info in Dashboard
export const getTalentInfo = (params) => ({
  type: types.GET_TALENT_INFO_REQUEST,
  payload: params,
});
// Job details on dasboard
export const fetchJobDetailsProfile = (payload) => ({
  type: types.JOB_DETAILS_PROFILE_REQUESTED,
  payload,
});
export const fetchJobDetailsProfileSuccess = (payload) => ({
  type: types.JOB_DETAILS_PROFILE_SUCCESS,
  payload,
});
export const fetchJobDetailsProfileFailure = () => ({
  type: types.JOB_DETAILS_PROFILE_FAILURE,
});
export const jobDetailsSelecter = (payload) => ({
  type: types.JOB_DETAILS_PROFILE_SELECTER,
  payload,
});
// Job details on dasboard
export const fetchJobDetailsProfileCompleted = (payload) => ({
  type: types.JOB_DETAILS_PROFILE_COMPLETED_REQUESTED,
  payload,
});
export const fetchJobDetailsProfileCompletedSuccess = (payload) => ({
  type: types.JOB_DETAILS_PROFILE_COMPLETED_SUCCESS,
  payload,
});
export const fetchJobDetailsProfileCompletedFailure = () => ({
  type: types.JOB_DETAILS_PROFILE_COMPLETED_FAILURE,
});
// Job details on dasboard Onbilling
export const fetchJobDetailsProfileOnbilling = (payload) => ({
  type: types.JOB_DETAILS_PROFILE_ON_BILLING_REQUESTED,
  payload,
});
export const fetchJobDetailsProfileOnbillingSuccess = (payload) => ({
  type: types.JOB_DETAILS_PROFILE_ON_BILLING_SUCCESS,
  payload,
});
export const fetchJobDetailsProfileOnbillingFailure = () => ({
  type: types.JOB_DETAILS_PROFILE_ON_BILLING_FAILURE,
});
// Job details on dasboard canceled
export const fetchJobDetailsProfileCanceled = (payload) => ({
  type: types.JOB_DETAILS_PROFILE_CANCELED_REQUESTED,
  payload,
});
export const fetchJobDetailsProfileCanceledSuccess = (payload) => ({
  type: types.JOB_DETAILS_PROFILE_CANCELED_SUCCESS,
  payload,
});
export const fetchJobDetailsProfileCanceledFailure = () => ({
  type: types.JOB_DETAILS_PROFILE_CANCELED_FAILURE,
});
// Job details on terminated
export const fetchJobDetailsProfileTerminated = (payload) => ({
  type: types.JOB_DETAILS_PROFILE_TERMINATED_REQUESTED,
  payload,
});
export const fetchJobDetailsProfileTerminatedSuccess = (payload) => ({
  type: types.JOB_DETAILS_PROFILE_TERMINATED_SUCCESS,
  payload,
});
export const fetchJobDetailsProfileTerminatedFailure = () => ({
  type: types.JOB_DETAILS_PROFILE_TERMINATED_FAILURE,
});
// Job details on dasboard
export const getTalentInfoSuccess = (response) => ({
  type: types.GET_TALENT_INFO_SUCCESS,
  payload: response,
});
export const getTalentInfoFailure = (error) => ({
  type: types.GET_TALENT_INFO_FAILURE,
  payload: {},
  error: error,
});
export const getAnalyticInfo = (params) => ({
  type: types.GET_ANALYTIC_INFO_REQUEST,
  payload: params,
});
export const getAnalyticInfoSuccess = (response) => ({
  type: types.GET_ANALYTIC_INFO_SUCCESS,
  payload: response,
});
export const getAnalyticInfoFailure = (error) => ({
  type: types.GET_ANALYTIC_INFO_FAILURE,
  payload: {},
  error: error,
});
export const getFavoritesInfo = (page, perPage) => {
  return {
    type: types.GET_FAVORITES_INFO_REQUEST,
    payload: { page, perPage },
  };
};
export const getFavoritesInfoSuccess = (response) => ({
  type: types.GET_FAVORITES_INFO_SUCCESS,
  payload: response,
});
export const getFavoritesInfoFailure = (error) => ({
  type: types.GET_FAVORITES_INFO_FAILURE,
  payload: {},
  error: error,
});
// export const favoritefunctiontrigger = (payload) => ({
//   type: types.FAVORITE_FUNCTION_DETAIL,
//   payload,
// });
// Remove gigcard from favorite section
export const removeFavoriteGigCard = (...payload) => {
  console.log(payload);
  return {
    type: types.REMOVE_FAVORITE_GIG_CARD,
    payload,
  };
};
export const removeFavoriteGigCardSuccess = (payload) => ({
  type: types.REMOVE_FAVORITE_GIG_CARD_SUCCESS,
  payload,
});
export const removeFavoriteGigCardFailure = () => ({
  type: types.REMOVE_FAVORITE_GIG_CARD_FAILURE,
});
export const removeFavoriteGigCardReducer = (payload) => ({
  type: types.REMOVE_FAVORITE_GIG_CARD_REDUCER,
  payload,
});
// Adding gigcard into favorite section
export const postFavoriteGigCard = (...payload) => ({
  type: types.FAVORITE_GIG_CARD,
  payload,
});
export const postFavoriteGigCardSuccess = (payload) => ({
  type: types.FAVORITE_GIG_CARD_SUCCESS,
  payload,
});
export const postFavoriteGigCardFailure = () => ({
  type: types.FAVORITE_GIG_CARD_FAILURE,
});
export const favoriteBidUpdate = (payload) => ({
  type: types.FAVORITE_BID_UPDATE,
  payload,
});
// profile Education History ////////////////////////////////////////////////////
export const fetchCollegeMasterEducationHistory = (payload) => ({
  type: types.FETCH_COLLEGE_MASTER_INIT,
  payload,
});
export const fetchCollegeMasterEducationHistorySuccess = (payload) => ({
  type: types.FETCH_COLLEGE_MASTER_SUCCESS,
  payload,
});
export const fetchCollegeMasterEducationHistoryFailure = (error) => ({
  type: types.FETCH_COLLEGE_MASTER_FAILURE,
  error,
});
export const fetchDegreeMasterEducationHistory = (payload) => ({
  type: types.FETCH_DEGREE_MASTER_INIT,
  payload,
});
export const fetchDegreeMasterEducationHistorySuccess = (payload) => ({
  type: types.FETCH_DEGREE_MASTER_SUCCESS,
  payload,
});
export const fetchDegreeMasterEducationHistoryFailure = (error) => ({
  type: types.FETCH_DEGREE_MASTER_FAILURE,
  error,
});
export const fetchCourseMasterEducationHistory = (payload) => ({
  type: types.FETCH_COURSE_MASTER_INIT,
  payload,
});
export const fetchCourseMasterEducationHistorySuccess = (payload) => ({
  type: types.FETCH_COURSE_MASTER_SUCCESS,
  payload,
});
export const fetchCourseMasterEducationHistoryFailure = (error) => ({
  type: types.FETCH_COURSE_MASTER_FAILURE,
  error,
});
export const postEducationHistory = (payload) => ({
  type: types.POST_EDUCATION_HISTORY_INIT,
  payload,
});
export const postEducationHistorySuccess = (payload) => ({
  type: types.POST_EDUCATION_HISTORY_SUCCESS,
  payload,
});
export const postEducationHistoryFailure = (error) => ({
  type: types.POST_EDUCATION_HISTORY_FAILURE,
  error,
});
export const updateEducationHistory = (...payload) => ({
  type: types.UPDATE_EDUCATION_HISTORY_INIT,
  payload,
});
export const updateEducationHistorySuccess = (payload) => ({
  type: types.UPDATE_EDUCATION_HISTORY_SUCCESS,
  payload,
});
export const updateEducationHistoryFailure = (error) => ({
  type: types.UPDATE_EDUCATION_HISTORY_FAILURE,
  error,
});
///////////////////////////////////////////////////////////////////////////////////
// profile Work History /////////////////////////////////////////////////////////
// export const fetchCompanyWorkHistory = (payload) => ({
//   type: types.GET_COMPANY_WORK_HISTORY_INIT,
//   payload,
// });
// export const fetchCompanyWorkHistorySuccess = (payload) => ({
//   type: types.GET_COMPANY_WORK_HISTORY_SUCCESS,
//   payload,
// });
// export const fetchCompanyWorkHistoryFailure = (payload) => ({
//   type: types.GET_COMPANY_WORK_HISTORY_FAILURE,
//   payload,
// });
export const fetchJobTitleWorkHistory = (payload) => ({
  type: types.GET_JOBTITLE_WORK_HISTORY_INIT,
  payload,
});
export const fetchJobTitleWorkHistorySuccess = (payload) => ({
  type: types.GET_JOBTITLE_WORK_HISTORY_SUCCESS,
  payload,
});
export const fetchJobTitleWorkHistoryFailure = (payload) => ({
  type: types.GET_JOBTITLE_WORK_HISTORY_FAILURE,
  payload,
});
export const fetchIndustryWorkHistory = (payload) => ({
  type: types.GET_INDUSTRY_WORK_HISTORY_INIT,
  payload,
});
export const fetchIndustryWorkHistorySuccess = (payload) => ({
  type: types.GET_INDUSTRY_WORK_HISTORY_SUCCESS,
  payload,
});
export const fetchIndustryWorkHistoryFailure = (payload) => ({
  type: types.GET_INDUSTRY_WORK_HISTORY_FAILURE,
  payload,
});
export const fetchLocationWorkHistory = (payload) => ({
  type: types.GET_LOCATION_WORK_HISTORY_INIT,
  payload,
});
export const fetchLocationWorkHistorySuccess = (payload) => ({
  type: types.GET_LOCATION_WORK_HISTORY_SUCCESS,
  payload,
});
export const fetchLocationWorkHistoryFailure = (payload) => ({
  type: types.GET_LOCATION_WORK_HISTORY_FAILURE,
  payload,
});
export const fetchSkillsWorkHistory = (params) => ({
  type: types.GET_SKILLS_WORK_HISTORY_INIT,
  params,
});
export const fetchSkillsWorkHistorySuccess = (payload) => ({
  type: types.GET_SKILLS_WORK_HISTORY_SUCCESS,
  payload,
});
export const fetchSkillsWorkHistoryFailure = (payload) => ({
  type: types.GET_SKILLS_WORK_HISTORY_FAILURE,
  payload,
});
export const postWorkHistory = (payload) => ({
  type: types.POST_WORK_HISTORY_INIT,
  payload,
});
export const postWorkHistorySuccess = (payload) => ({
  type: types.POST_WORK_HISTORY_SUCCESS,
  payload,
});
export const postWorkHistoryFailure = (error) => ({
  type: types.POST_WORK_HISTORY_FAILURE,
  error,
});
export const updateWorkHistory = (...payload) => ({
  type: types.UPDATE_WORK_HISTORY_INIT,
  payload,
});
export const updateWorkHistorySuccess = (payload) => ({
  type: types.UPDATE_WORK_HISTORY_SUCCESS,
  payload,
});
export const updateWorkHistoryFailure = (error) => ({
  type: types.UPDATE_WORK_HISTORY_FAILURE,
  error,
});
/////////////////////////////////////////////////////////////////////////
//// Certificates and Awards Profile ////////////////////////////////////
export const postCertificatesAndAwards = (payload) => ({
  type: types.POST_CERTIFICATES_AND_AWARDS_INIT,
  payload,
});
export const postCertificatesAndAwardsSuccess = (payload) => ({
  type: types.POST_CERTIFICATES_AND_AWARDS_SUCCESS,
  payload,
});
export const postCertificatesAndAwardsFailure = (error) => ({
  type: types.POST_CERTIFICATES_AND_AWARDS_FAILURE,
  error,
});
export const updateCertificatesAndAwards = (payload, id) => ({
  type: types.UPDATE_CERTIFICATES_AND_AWARDS_INIT,
  payload,
  id,
});
export const updateCertificatesAndAwardsSuccess = (payload) => ({
  type: types.UPDATE_CERTIFICATES_AND_AWARDS_SUCCESS,
  payload,
});
export const updateCertificatesAndAwardsFailure = (error) => ({
  type: types.UPDATE_CERTIFICATES_AND_AWARDS_FAILURE,
  error,
});
/////////////////////////////////////////////////////////////////////////
// export const fetchRetractedBidsStages = (params) => {
//   return {
//     type: types.FETCH_RETRACTEDBIDS_STATUS_REQUESTED,
//     payload: params,
//   };
// };
export const fetchRetractedBids = (
  perPage,
  page,
  sort,
  longitude,
  latitude,
  maxDistance
) => {
  return {
    type: types.FETCH_RETRACTEDBIDS_REQUESTED,
    payload: {
      perPage,
      page,
      sort,
      longitude,
      latitude,
      maxDistance,
    },
  };
};
export const resetStore = () => ({
  type: types.USER_LOGOUT,
});
// retracted Bid Action Start
export const retractedBidFetch = (sort, lat, long, page, perPage) => ({
  type: types.FETCH_RETRACTED_BID_REQUESTED,
  payload: {
    sort,
    lat,
    long,
    page,
    perPage,
  },
});
export const retractedBidFetchSuccess = (payload) => ({
  type: types.FETCH_RETRACTED_BID_SUCCESS,
  payload,
});
export const retractedBidFetchFailure = () => ({
  type: types.FETCH_RETRACTED_BID_FAILURE,
});
export const retractedBidUpdate = (payload) => ({
  type: types.RETRACTED_BID_UPDATE,
  payload,
});
// Place Bid Action Start
export const placeBidFetch = (
  sort,
  lat,
  long,
  page,
  perPage,
  BidStatusName
) => ({
  type: types.FETCH_PLACE_BID_REQUESTED,
  payload: {
    sort,
    lat,
    long,
    page,
    perPage,
    BidStatusName,
  },
});
export const placeBidFetchSuccess = (payload) => ({
  type: types.FETCH_PLACE_BID_SUCCESS,
  payload,
});
export const placeBidFetchFailure = () => ({
  type: types.FETCH_PLACE_BID_FAILURE,
});
export const fetchDetailPlaceBid = (payload) => ({
  type: types.FETCH_DETAIL_PLACE_BID_INIT,
  payload,
});
export const fetchDetailPlaceBidSuccess = (payload) => ({
  type: types.FETCH_DETAIL_PLACE_BID_SUCCESS,
  payload,
});
export const fetchDetailPlaceBidFailure = (error) => ({
  type: types.FETCH_DETAIL_PLACE_BID_FAILURE,
  error,
});
// Place Bid Action End ////////////////////////////////////////////////////////
// Received Bid Action start ////////////////////////////////////////////////////////
export const fetchReceivedBidsStages = (params) => {
  return {
    type: types.FETCH_RECEIVEDBIDS_STATUS_REQUESTED,
    payload: params,
  };
};
export const fetchReceivedBids = (
  BidStatusName,
  SkillListingId,
  sortBy,
  lat,
  long,
  page,
  perPage
) => {
  return {
    type: types.FETCH_RECEIVEDBIDS_REQUESTED,
    payload: {
      BidStatusName,
      SkillListingId,
      sortBy,
      lat,
      long,
      page,
      perPage,
    },
  };
};
export const fetchDetailReceivedBid = (payload) => ({
  type: types.FETCH_DETAIL_RECEIVED_BID_INIT,
  payload,
});
export const fetchDetailReceivedSuccess = (payload) => ({
  type: types.FETCH_DETAIL_RECEIVED_BID_SUCCESS,
  payload,
});
export const fetchDetailReceivedFailure = (error) => ({
  type: types.FETCH_DETAIL_RECEIVED_BID_FAILURE,
  error,
});
export const fetchDeclinedReasonsBids = (payload) => ({
  type: types.FETCH_DECLINED_REASONS_BIDS_INIT,
  payload,
});
export const fetchDeclinedReasonsBidsSuccess = (payload) => ({
  type: types.FETCH_DECLINED_REASONS_BIDS_SUCCESS,
  payload,
});
export const fetchDeclinedReasonsBidsFailure = (error) => ({
  type: types.FETCH_DECLINED_REASONS_BIDS_FAILURE,
  error,
});
export const updateDeclinedReasonsReceivedBids = (payload) => ({
  type: types.UPDATE_DECLINED_REASONS_BIDS_INIT,
  payload,
});
export const updateDeclinedReasonsReceivedBidsSuccess = (payload) => ({
  type: types.UPDATE_DECLINED_REASONS_BIDS_SUCCESS,
  payload,
});
export const updateDeclinedReasonsReceivedBidsFailure = (error) => ({
  type: types.UPDATE_DECLINED_REASONS_BIDS_FAILURE,
  error,
});
// Received Bid Action end ////////////////////////////////////////////////////////
export const getInterviewCard = (params) => ({
  type: types.GET_INTERVIEW_CARD_REQUEST,
  payload: params,
});
export const getInterviewCardSuccess = (response) => ({
  type: types.GET_INTERVIEW_CARD_SUCCESS,
  payload: response,
});
export const getInterviewCardFailure = (error) => ({
  type: types.GET_INTERVIEW_CARD_FAILURE,
  payload: {},
  error: error,
});
/********** Interview Action Start **********/
export const fetchInterview = () => ({
  type: types.FETCH_INTERVIEW_INIT,
});
export const fetchInterviewSuccess = (payload) => ({
  type: types.FETCH_INTERVIEW_SUCCESS,
  payload,
});
export const fetchInterviewFailure = (error) => ({
  type: types.FETCH_INTERVIEW_FAILURE,
  error,
});
/********** Interview Action End **********/
/********** Resume Action start **********/
// GET FOLLOWERS //
export const fetchResumeGetfollowersInit = (page, perPage) => {
  console.log("pages", page, perPage);
  return {
    type: types.FETCH_RESUME_GET_FOLLOWERS_INIT,
    payload: { page, perPage },
  };
};
export const fetchResumeGetfollowersSuccess = (payload) => ({
  type: types.FETCH_RESUME_GET_FOLLOWERS_SUCCESS,
  payload,
});
export const fetchResumeGetfollowersFailure = (error) => ({
  type: types.FETCH_RESUME_GET_FOLLOWERS_FAILURE,
  error,
});
// GET VIEWS //
export const fetchResumeGetviewInit = (page, perPage) => {
  console.log("view Page", page, perPage);
  return {
    type: types.FETCH_RESUME_GET_VIEWS_INIT,
    payload: { page, perPage },
  };
};
export const fetchResumeGetviewSuccess = (payload) => ({
  type: types.FETCH_RESUME_GET_VIEWS_SUCCESS,
  payload,
});
export const fetchResumeGetviewFailure = (error) => ({
  type: types.FETCH_RESUME_GET_VIEWS_FAILURE,
  error,
});
// RESUME DETAIL //
export const fetchResumeDetailInit = () => ({
  type: types.FETCH_RESUME_DETAIL_INIT,
});
export const fetchResumeDetaiSuccess = (payload) => ({
  type: types.FETCH_RESUME_DETAIL_SUCCESS,
  payload,
});
export const fetchResumeDetaiFailure = (error) => ({
  type: types.FETCH_RESUME_DETAIL_FAILURE,
  error,
});
/********** Resume Action end **********/
/********** showPublicView Action End **********/
export const setShowPublicView = () => ({
  type: types.SHOW_PUBLIC_VIEW,
});
/********** showPublicView Action End **********/
// Home page action
export const fetchHomeDataInit = (
  continueBrowsing_perPage,
  Favorite_perPage,
  Suggested_perPage,
  Editor_perPage,
  Most_PopularMob,
  EditorPicks,
  Suggested,
  ContinueBrowsing,
  Favorites,
  Most_Popular,
) => {
  return {
    type: types.FETCH_HOME_PAGE_DATA_INIT,
    payload: {
      continueBrowsing_perPage,
      Favorite_perPage,
      Suggested_perPage,
      Editor_perPage,
      Most_PopularMob,
      EditorPicks,
      Suggested,
      ContinueBrowsing,
      Favorites,
      Most_Popular
    },
  };
};
export const fetchHomeDataSuccess = (payload) => ({
  type: types.FETCH_HOME_PAGE_DATA_SUCCESS,
  payload,
});
export const fetchHomeDataFailure = () => ({
  type: types.FETCH_HOME_PAGE_DATA_FAILURE,
});
export const HomepageFavoriteRemove = (payload) => ({
  type: types.HOME_PAGE_FAVORITE_REMOVE,
  payload,
});
export const HomepageFavorite = (payload) => ({
  type: types.HOME_PAGE_FAVORITE,
  payload,
});
export const HomepageFavoriteUpdate = (payload) => ({
  type: types.HOME_PAGE_FAVORITE_UPDATE,
  payload,
});
// Notification Action //
export const UpdateNotificationInit = (payload) => ({
  type: types.UPDATE_NOTIFICATION_INIT,
  payload,
});
export const UpdateNotificationSuccess = (payload) => ({
  type: types.UPDATE_NOTIFICATIN_SUCCESS,
  payload,
});
export const UpdateNotificationFailure = (error) => ({
  type: types.UPDATE_NOTIFICATIN_FAILURE,
  error,
});
// Interview Accepted//
export const InterviewScheduleAcceptedInit = (payload) => ({
  type: types.INTERVIEW_ACCEPTED_INIT,
  payload,
});
export const InterviewScheduleAcceptedSuccess = (payload) => ({
  type: types.INTERVIEW_ACCEPTED_SUCCESS,
  payload,
});
export const InterviewScheduleAcceptedfail = (error) => ({
  type: types.INTERVIEW_ACCEPTED_FAILURE,
});

export const RetractBidUpdateStageInit = (payload) => {
  console.log(payload, "RetractBidUpdateStageInit");
  return {
    type: types.RETRACTED_BID_UPDATE_STAGES_INIT,
    payload,
  };
};
export const RetractBidUpdateStageSuccess = (payload) => ({
  type: types.RETRACTED_BID_UPDATE_STAGES_SUCCESS,
  payload,
});
export const RetractBidUpdateStagefail = (error) => ({
  type: types.RETRACTED_BID_UPDATE_STAGES_FAILURE,
});

export const onClearFilterInit = (payload) => {
  console.log("=====", payload);
  return {
    type: types.CLEAR_FILTER_INIT,
    payload,
  };
};

export const searchSuggestionInput = (payload) => {
  console.log("seahsuggestion", payload);
  return {
    type: types.SEARCH_SUGGESTION_INPUT,
    payload,
  };
};
export const fecthIsLocation = (payload) => {
  return {
    type: types.IS_LOCATION_DISABLE,
    payload,
  };
};


//********************* Employer Actions
export const getCompanyDetailsSuggestions = () => {
  return {
    type: types.GET_COMPANY_DETAILS_DROPDOWN_REQUESTED,
    payload: {},
  };
};

export const updateCompanyProfileDetails = (params) => {
  return {
    type: types.POST_COMPANY_DETAILS_REQUESTED,
    payload: params,
  };
};

export const resetCompanyProfileDetails = () => {
  return {
    type: types.RESET_COMPANY_DETAILS,
    payload: {}
  }
}

