import { put, call, takeEvery, takeLatest } from "@redux-saga/core/effects";
import { fetchInterviewAPI, updateInterviewAPI } from "../../API/API";
import { types } from "../action-types";
import {
  fetchInterviewFailure,
  fetchInterviewSuccess,
  InterviewScheduleAcceptedfail,
  InterviewScheduleAcceptedSuccess,
} from "../actions";
function* fetchInterviewSaga() {
  console.log("in interview saga!");
  try {
    console.log("interview saga running");
    const response = yield call(() => fetchInterviewAPI()); // API Backend
    console.log("Interview RESPONSE: ", response);
    if (response.status == 200) {
      yield put(fetchInterviewSuccess(response.data.data.body.data));
    } else {
      yield put(fetchInterviewFailure(response));
    }
  } catch (error) {
    yield put(fetchInterviewFailure(error));
  }
}

function* InterviewScheduleSaga(params) {
  console.log(params.payload,"InterviewScheduleSaga")
  try {
    const response = yield call(() =>
      updateInterviewAPI(params.payload)
    ); // API Backend
    console.log("InterviewScheduleSaga:", response);
    if (response.status == 200) {
      // yield put(
      //   InterviewScheduleAcceptedSuccess({
      //     BidStatusName: "New",
      //     BidStatusId: 1,
      //     DeclineReason: "response.data.DeclineReason",
      //   })
      // );
    } else {
      yield put(InterviewScheduleAcceptedSuccess(response));
    }
  } catch (error) {
    yield put(InterviewScheduleAcceptedfail(error));
  }
}

export function* watchInterviewSaga() {
  yield takeEvery(types.FETCH_INTERVIEW_INIT, fetchInterviewSaga);
}

export function* watchInterviewScheduleSaga() {
  yield takeLatest(types.INTERVIEW_ACCEPTED_INIT, InterviewScheduleSaga);
}
