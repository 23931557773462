import { call, put, takeEvery } from "redux-saga/effects";
import { types } from "../action-types";
import { getProfileDetailAPi } from "../../API/API";

function* getProfileDetailsSaga(action) {
    console.log("in profile details saga");
    try {
        const { id } = action.payload;
        const response = yield call(() => getProfileDetailAPi(id));
        yield put({ type: types.FETCH_PROFILE_DETAILS_SUCCESS, payload: response.data });
        console.log('Response is:',response);
    } catch (error) {
        yield put({ type: types.FETCH_PROFILE_DETAILS_FAILURE, error });
        console.log(error);
    }
}

export function* watchGetProfileDetails() {
    yield takeEvery(types.FETCH_PROFILE_DETAILS_REQUESTED, getProfileDetailsSaga);
}
