import { all } from "redux-saga/effects";

import { watchFetchDataSaga } from "./fetchDataSaga";
import { watchAuthSaga, watchLinkedInSaga } from "./authSaga";
import {
  watchDocSaga,
  watchUploadDocSaga,
  watchRemoveDocSaga,
} from "./docSaga";
import {
  watchAnalyticInfoSaga,
  watchCompanyWorkHistory,
  watchDeleteResumeSaga,
  watchFetchCollegeMaster,
  watchFetchCourseMaster,
  watchFetchDegreeMaster,
  watchFetchProfileExperienceSaga,
  watchIndustryWorkHistory,
  watchJobDetailsProfileSaga,
  watchJobTitleWorkHistory,
  watchLocationWorkHistory,
  watchJobDetailsProfileCanceledSaga,
  watchJobDetailsProfileCompletedSaga,
  watchJobDetailsProfileOnBillingSaga,
  // watchJobDetailsProfileSaga,
  watchJobDetailsProfileTerminatedSaga,
  watchPostEducationHistory,
  watchPostWorkHistory,
  watchProfileSaga,
  watchRoleDisciplineSaga,
  watchSkillsWorkHistory,
  watchTalentInfoSaga,
  watchUpdateEducationHistory,
  watchUpdateProfileSaga,
  watchUpdateWorkHistory,
  watchUploadProfilePicSaga,
  watchUploadResumeSaga,
  watchetchInterviewCardSaga,
  watchPostCertificatesAndAwardsSaga,
  watchUpdateCertificatesAndAwardsSaga,
} from "./profileSaga";
import { watchcheckUserSaga } from "./chekckUserSaga";
import {
  watchFetchSkillSaga,
  watchCreateSkillListingSaga,
  watchUpdateSkillListingSaga,
  watchKeySearchDiscipline,
  watchFetchUserSkillListing,
  watchDeleteSkillListingSaga,
} from "./skillListingSaga";

import { watchSearchLocationSaga } from "./searchLocationSaga";
import { watchSearchGigsSaga } from "./searchGigsSaga";

import { watchFetchProposalsSaga } from "./ProposalsSaga";
import { watchCreateBidSaga } from "./createBidSaga";
import { watchRetractUpdateBidSaga, watchUpdateBidSaga } from "./updateBidSaga";
import {
  watchfetchInboxDataSaga,
  watchFetchMessagesSaga,
  watchSendMessagesSaga,
} from "./messagesSaga";
import { watchFetchBidDataSaga } from "./fetchBidDataSaga";
import { watchRetractBidSaga } from "./retractBidSaga";
import { watchGetChatSaga } from "./ChatHistorySaga";
import {
  watchFetchDeclinedReasonsBids,
  watchFetchDetailPlaceBidsSaga,
  watchFetchDetailReceivedBidsSaga,
  watchFetchPlaceBidsSaga,
  watchFetchReceivedBidsSaga,
  watchFetchretractedBidsSaga,
  watchStatusReceivedBidsSaga,
  watchUpdateReceivedBidsSaga,
  watchUpdateDeclinedReasonsBids,
} from "./BidsSaga";
import { watchGraphDataSaga } from "./TimeSheetSaga";
import {
  watchFetchTimesheetListSaga,
  watchFetchTimesheetSaga,
  watchUpdateTimesheetSaga,
  watchExisitingListItemSaga,
} from "./timeSheetListSaga";
import { watchCreateTimeSheetCorrectionSaga } from "./createTimeSheetCorrectionSaga";
import { watchUserAssignmentSaga } from "./userAssignmentSaga";
import { watchSearchSuggestionsSaga } from "./SearchSuggestionsSaga";
import {
  watchMyFavoriteSaga,
  watchFavoriteGigCard,
  watchRemoveFavoriteGigCard,
} from "./FavoritesSaga";
import {
  watchInterviewSaga,
  watchInterviewScheduleSaga,
} from "./interviewSaga";
import {
  watchResumeGetfollowersSaga,
  watchResumeGetviewsSaga,
  watchResumeDetailSaga,
} from "./resumeSaga";
import { watchHomepageInfoSaga } from "./HomePageSaga";
import { watchNotificationSaga } from "./NotificationSaga";
import { watchCompanyDetailsDropdown } from "./CompanyDetailsSaga";
import { watchGetFiltersSaga,watchGetSearchSuggestionSaga } from './searchSkillListingSaga'
import { watchGetProfileDetails } from "./ProfileDetailSaga";
import { watchCreateInterviewSaga } from "./createInterviewSaga";
// import { watchFetchRetractedBidsSaga } from "./RetractedBidSaga";
export default function* rootSaga() {
  yield all([
    watchFetchDataSaga(),
    watchAuthSaga(),
    watchDocSaga(),
    watchProfileSaga(),
    watchUpdateProfileSaga(),
    watchUploadDocSaga(),
    watchUploadProfilePicSaga(),
    watchUploadResumeSaga(),
    watchLinkedInSaga(),
    watchRemoveDocSaga(),
    watchcheckUserSaga(),
    watchFetchSkillSaga(),
    watchCreateSkillListingSaga(),
    watchUpdateSkillListingSaga(),
    watchSearchLocationSaga(),
    watchKeySearchDiscipline(),
    watchFetchUserSkillListing(),
    watchFetchProfileExperienceSaga(),

    watchFetchProposalsSaga(),
    watchCreateBidSaga(),
    watchUpdateBidSaga(),
    watchFetchMessagesSaga(),
    watchFetchBidDataSaga(),
    watchRetractBidSaga(),
    watchSendMessagesSaga(),
    watchGetChatSaga(),
    watchStatusReceivedBidsSaga(),
    watchFetchReceivedBidsSaga(),
    watchUpdateReceivedBidsSaga(),

    watchDeleteResumeSaga(),
    watchGraphDataSaga(),
    // watchSearchGigsSaga(),
    watchUpdateTimesheetSaga(),
    watchFetchTimesheetSaga(),
    watchFetchTimesheetListSaga(),
    watchCreateTimeSheetCorrectionSaga(),
    watchUserAssignmentSaga(),
    watchExisitingListItemSaga(),
    watchDeleteSkillListingSaga(),
    watchRoleDisciplineSaga(),
    watchSearchSuggestionsSaga(),
    watchfetchInboxDataSaga(),

    watchTalentInfoSaga(),
    watchAnalyticInfoSaga(),
    watchMyFavoriteSaga(),
    watchJobDetailsProfileSaga(),

    watchFavoriteGigCard(),
    watchRemoveFavoriteGigCard(),

    watchFetchCollegeMaster(),
    watchFetchDegreeMaster(),
    watchFetchCourseMaster(),
    watchPostEducationHistory(),
    watchUpdateEducationHistory(),

    // watchCompanyWorkHistory(),
    watchJobTitleWorkHistory(),
    watchIndustryWorkHistory(),
    watchLocationWorkHistory(),
    watchSkillsWorkHistory(),
    watchPostWorkHistory(),
    watchUpdateWorkHistory(),

    watchPostCertificatesAndAwardsSaga(),
    watchUpdateCertificatesAndAwardsSaga(),

    watchRetractBidSaga(),

    watchJobDetailsProfileCompletedSaga(),
    watchJobDetailsProfileCanceledSaga(),
    watchJobDetailsProfileTerminatedSaga(),
    watchJobDetailsProfileOnBillingSaga(),

    watchFetchretractedBidsSaga(),

    watchFetchPlaceBidsSaga(),
    watchFetchDetailPlaceBidsSaga(),
    watchFetchDetailReceivedBidsSaga(),

    watchetchInterviewCardSaga(),
    watchInterviewSaga(),
    watchInterviewScheduleSaga(),
    watchFetchDeclinedReasonsBids(),
    watchUpdateDeclinedReasonsBids(),
    // 3d Resume sagas start//
    watchResumeGetfollowersSaga(),
    watchResumeGetviewsSaga(),
    watchResumeDetailSaga(),
    // 3d Resume sagas end//
    watchHomepageInfoSaga(),
    watchNotificationSaga(),
    watchInterviewScheduleSaga(),
    watchRetractUpdateBidSaga(),
    watchCompanyDetailsDropdown(),
    watchGetFiltersSaga(),
    watchGetSearchSuggestionSaga(),
    watchGetProfileDetails(),
    watchCreateInterviewSaga(),
  ]);
}
