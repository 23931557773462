import { call, put, takeEvery } from "redux-saga/effects";
import { types } from "../action-types";
import { getCompanyDetailsDropdownApi } from "../../API/API";

function* fetchCompanyDetailsDropdown(action) {
    try {
        // const { _id } = action.payload;
        const response = yield call(() => getCompanyDetailsDropdownApi());
        yield put({
            type: types.GET_COMPANY_DETAILS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        yield put({ type: types.GET_COMPANY_DETAILS_FAILURE, error });
    }
}

export function* watchCompanyDetailsDropdown() {
    yield takeEvery(
        types.GET_COMPANY_DETAILS_DROPDOWN_REQUESTED,
        fetchCompanyDetailsDropdown
    );
}
