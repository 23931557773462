import { call, put, takeEvery } from "redux-saga/effects";
import { types } from "../action-types";
import { fetchDataFailure, fetchDataSuccess } from "../actions";
import { fetchDataApi } from "../../API/API";

function* fetchDataSaga(params) {
  try {
    const response = yield call(() => fetchDataApi(params)); // API Backend
    console.log(response.data);
    yield put({ type: types.SEND_REQUEST_SUCCESS, payload: response.data });
    // yield put(fetchDataSuccess(response.data));
  } catch (error) {
    yield put(fetchDataFailure(error));
  }
}

export function* watchFetchDataSaga() {
  yield takeEvery(types.SEND_REQUEST, fetchDataSaga);
}
