import { types } from "../action-types";

// import initialState from './appReducer';
const initialState = {
  loading: false,
  // skills: [],
  skillListings: [],
  // currentSkillListing: null,
  // searchedDiscipline: [],
  error: {},
};

const oneUserskillListingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ONE_USER_SKILLLISTING_REQUESTED:
      return{
        ...state,
        loading: true,
      }
    case types.GET_ONE_USER_SKILLLISTING_SUCCESS:
      return{
        ...state,
        loading: false,
        skillListings: action.payload,
        error: false,
      }
    case types.GET_ONE_USER_SKILLLISTING_FAILURE:
      return{
        ...state,
        loading: false,
        skillListings: [],
        error: true,
      }
    default:
      return state;
  }
};

export default oneUserskillListingReducer;
