import { types } from "../action-types";
const initialState = {
    loading: false,
    HomePageData: [],
    Error: {},
    HomeFav: [],
}

const homepageReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_HOME_PAGE_DATA_INIT:
            return {
                ...state,
                loading: true,
            }
        case types.FETCH_HOME_PAGE_DATA_SUCCESS:
            console.log(action.payload.body.Favorites)
            return {
                loading: false,
                ...state,
                HomePageData: action.payload,
                HomeFav: action.payload.body.Favorites
            }
        case types.FETCH_HOME_PAGE_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                Error: action.error,
            }
        case types.HOME_PAGE_FAVORITE_UPDATE:
            // console.log(action.payload,"HOME_PAGE_FAVORITE_UPDATE",state.HomeFav)
            const dataupdate = action?.payload?.payload[2]
            console.log(dataupdate?.Types, "HOME_PAGE_FAVORITE_UPDATE", state.HomeFav)
            return {
                ...state,
                HomeFav: [...state.HomeFav, { "gig": { ...dataupdate?.data, IsFavorite: true, Types: dataupdate?.Types }}]
            }
        case types.HOME_PAGE_FAVORITE:
            const data = action?.payload;
            let datatype = data[2].Types
            console.log(state.HomeFav, "homefav", data)
            let x;
            if (datatype === "ContinueBrowsing") {
                const index = state?.HomePageData?.body?.ContinueBrowsing?.map((obj, i) => { if (obj._id === data[0]) return x = i })
                let conditionstate = state?.HomePageData?.body?.ContinueBrowsing[x]?.IsFavorite
                const tempHome = [...state?.HomePageData?.body?.ContinueBrowsing]
                tempHome[x] = { ...state?.HomePageData?.body?.ContinueBrowsing[x], "IsFavorite": !conditionstate }
                return {
                    ...state,
                    HomePageData: { ...state?.HomePageData, body: { ...state?.HomePageData?.body, ContinueBrowsing: [...tempHome] } },
                }
            }
            else if (datatype === "Suggested") {
                const index = state?.HomePageData?.body?.Suggested?.map((obj, i) => { if (obj._id === data[0]) return x = i })
                let conditionstate = state?.HomePageData?.body?.Suggested[x]?.IsFavorite
                const tempHome = [...state?.HomePageData?.body?.Suggested]
                tempHome[x] = { ...state?.HomePageData?.body?.Suggested[x], "IsFavorite": !conditionstate }
                return {
                    ...state,
                    HomePageData: { ...state?.HomePageData, body: { ...state?.HomePageData?.body, Suggested: [...tempHome] } },
                }
            }
            else if (datatype === "EditorPicks") {
                const index = state?.HomePageData?.body?.EditorPicks?.map((obj, i) => { if (obj._id === data[0]) return x = i })
                let conditionstate = state?.HomePageData?.body?.EditorPicks[x]?.IsFavorite
                const tempHome = [...state?.HomePageData?.body?.EditorPicks]
                tempHome[x] = { ...state?.HomePageData?.body?.EditorPicks[x], "IsFavorite": !conditionstate }
                console.log(state?.HomeFav,"EditorPicksTest")
                return {
                    ...state,
                    HomePageData: { ...state?.HomePageData, body: { ...state?.HomePageData?.body, EditorPicks: [...tempHome] } },
                }
            }
            else if (datatype === "MostPopular") {
                const index = state?.HomePageData?.body?.MostPopular?.map((obj, i) => { if (obj._id === data[0]) return x = i })
                let conditionstate = state?.HomePageData?.body?.MostPopular[x]?.IsFavorite
                const tempHome = [...state?.HomePageData?.body?.MostPopular]
                tempHome[x] = { ...state?.HomePageData?.body?.MostPopular[x], "IsFavorite": !conditionstate }
                console.log(state?.HomeFav, "MostPopularTest")
                return {
                    ...state,
                    HomePageData: { ...state?.HomePageData, body: { ...state?.HomePageData?.body, MostPopular: [...tempHome] } },
                }
            }
        case types.HOME_PAGE_FAVORITE_REMOVE:
            const datarem = action?.payload.payload;
            console.log(datarem, "datarem", action)
            const dataType = datarem[1].Types;
            let z;
            if (dataType === "ContinueBrowsing") {
                const ind = state?.HomePageData?.body?.ContinueBrowsing?.map((obj, i) => { if (obj._id === datarem[0]) return z = i })
                console.log(state?.HomePageData,"state?.HomePageData")
                let conditionstaterem = state?.HomePageData?.body?.ContinueBrowsing[z]?.IsFavorite
                const tempHomerem = [...state?.HomePageData?.body?.ContinueBrowsing]
                tempHomerem[z] = { ...state?.HomePageData?.body?.ContinueBrowsing[z], "IsFavorite": !conditionstaterem }
                return {
                    ...state,
                    HomePageData: { ...state?.HomePageData, body: { ...state?.HomePageData?.body, ContinueBrowsing: [...tempHomerem] } },
                    HomeFav: state?.HomeFav?.filter((item) => datarem[0] !== item?.gig?._id),
                }
            }
            else if (dataType === "Suggested") {
                const ind = state?.HomePageData?.body?.Suggested?.map((obj, i) => { if (obj._id === datarem[0]) return z = i })
                let conditionstaterem = state?.HomePageData?.body?.Suggested[z]?.IsFavorite
                const tempHomerem = [...state?.HomePageData?.body?.Suggested]
                tempHomerem[z] = { ...state?.HomePageData?.body?.Suggested[z], "IsFavorite": !conditionstaterem }
                return {
                    ...state,
                    HomePageData: { ...state?.HomePageData, body: { ...state?.HomePageData?.body, Suggested: [...tempHomerem] } },
                    HomeFav: state?.HomeFav?.filter((item) => datarem[0] !== item?.gig?._id)
                }
            }
            else if (dataType === "EditorPicks") {
                const ind = state?.HomePageData?.body?.EditorPicks?.map((obj, i) => { if (obj._id === datarem[0]) return z = i })
                let conditionstaterem = state?.HomePageData?.body?.EditorPicks[z]?.IsFavorite
                const tempHomerem = [...state?.HomePageData?.body?.EditorPicks]
                tempHomerem[z] = { ...state?.HomePageData?.body?.EditorPicks[z], "IsFavorite": !conditionstaterem }
                return {
                    ...state,
                    HomePageData: { ...state?.HomePageData, body: { ...state?.HomePageData?.body, EditorPicks: [...tempHomerem] } },
                    HomeFav: state?.HomeFav?.filter((item) => datarem[0] !== item?.gig?._id)
                }
            }
            else if (dataType === "MostPopular") {
                const ind = state?.HomePageData?.body?.MostPopular?.map((obj, i) => { if (obj._id === datarem[0]) return z = i })
                let conditionstaterem = state?.HomePageData?.body?.MostPopular[z]?.IsFavorite
                const tempHomerem = [...state?.HomePageData?.body?.MostPopular]
                tempHomerem[z] = { ...state?.HomePageData?.body?.MostPopular[z], "IsFavorite": !conditionstaterem }
                return {
                    ...state,
                    HomePageData: { ...state?.HomePageData, body: { ...state?.HomePageData?.body, MostPopular: [...tempHomerem] } },
                    HomeFav: state?.HomeFav?.filter((item) => datarem[0] !== item?.gig?._id)
                }
            }
            else if (dataType === "Favorites"){
                console.log(state.HomeFav,"dataTypeFavoritesrdeucer",datarem[0])
                let dataType = datarem[1]?.data?.Types
                console.log(datarem[1]?.data?.Types, "ind")
                if (dataType === "ContinueBrowsing") {
                    const ind = state?.HomePageData?.body?.ContinueBrowsing?.map((obj, i) => { if (obj._id === datarem[0]) return z = i })
                    const tempHomerem = [...state?.HomePageData?.body?.ContinueBrowsing]
                    tempHomerem[z] = { ...state?.HomePageData?.body?.ContinueBrowsing[z], "IsFavorite": false }
                    return {
                        ...state,
                        HomeFav: state?.HomeFav?.filter((item) => datarem[0] !== item?.gig?._id),
                        HomePageData: { ...state?.HomePageData, body: { ...state?.HomePageData?.body, ContinueBrowsing: [...tempHomerem] } },
                    }
                }
                if (dataType === "Suggested"){
                    const ind = state?.HomePageData?.body?.Suggested?.map((obj, i) => { if (obj._id === datarem[0]) return z = i })
                    const tempHomerem = [...state?.HomePageData?.body?.Suggested]
                    tempHomerem[z] = { ...state?.HomePageData?.body?.Suggested[z], "IsFavorite": false }
                    return {
                        ...state,
                        HomeFav: state?.HomeFav?.filter((item) => datarem[0] !== item?.gig?._id),
                        HomePageData: { ...state?.HomePageData, body: { ...state?.HomePageData?.body, Suggested: [...tempHomerem] } }
                    }
                }
                if (dataType === "EditorPicks"){
                    const ind = state?.HomePageData?.body?.EditorPicks?.map((obj, i) => { if (obj._id === datarem[0]) return z = i })
                    const tempHomerem = [...state?.HomePageData?.body?.EditorPicks]
                    tempHomerem[z] = { ...state?.HomePageData?.body?.EditorPicks[z], "IsFavorite": false }
                    return {
                        ...state,
                        HomeFav: state?.HomeFav?.filter((item) => datarem[0] !== item?.gig?._id),
                        HomePageData: { ...state?.HomePageData, body: { ...state?.HomePageData?.body, EditorPicks: [...tempHomerem] } }
                    }
                }
                if (dataType === "MostPopular") {
                    const ind = state?.HomePageData?.body?.MostPopular?.map((obj, i) => { if (obj._id === datarem[0]) return z = i })
                    const tempHomerem = [...state?.HomePageData?.body?.MostPopular]
                    tempHomerem[z] = { ...state?.HomePageData?.body?.MostPopular[z], "IsFavorite": false }
                    return {
                        ...state,
                        HomeFav: state?.HomeFav?.filter((item) => datarem[0] !== item?.gig?._id),
                        HomePageData: { ...state?.HomePageData, body: { ...state?.HomePageData?.body, MostPopular: [...tempHomerem] } }
                    }
                }
                return {
                    ...state,
                    HomeFav: state?.HomeFav?.filter((item) => datarem[0] !== item?.gig?._id),
                }
            }

        default:
            return state;
    }
}

export default homepageReducer;