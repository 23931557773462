import React, { useState, useEffect } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  ApolloLink,
  gql,
} from "@apollo/client";
import { RestLink } from "apollo-link-rest";
import { HttpLink } from "apollo-link-http";
// import { ApolloProvider } from "react-apollo";
import { SearchGigsApi } from "../../API/API";
import { connect } from "react-redux";
import { fetchGigs } from "../../redux/actions";
//import "./SearchBar.css";
import SearchResults from "./SearchResults";
import { REACT_APP_API_URL } from "../../config";

const SearchSkillListings = (props) => {
  const BackendApiBaseUrl = REACT_APP_API_URL;
  // const GetGigs = props.user.Gigs;
  const [search, setSearch] = useState("");
  const [gigset, setGigSet] = useState();
  const restLink = new RestLink({
    endpoints: {
      openExchangeRate: "https://open.exchangerate-api.com/v6/",
    },
  });

  const httpLink = new HttpLink({
    // uri: `${BackendApiBaseUrl}/query`,
    uri: `${BackendApiBaseUrl}/guest/skilllisting/search`,
  });

  const client = new ApolloClient({
    // uri: `${BackendApiBaseUrl}/query`,
    cache: new InMemoryCache(),
    link: ApolloLink.from([httpLink]),
  });

  // useEffect(() => {
  //   props.GetGigs();
  //   const GigsResponse = props.user.Gigs;
  //   console.log(GigsResponse);
  //   // setState(data);
  // }, []);

  // useEffect(() => {
  //   const GigsResponse = props.user.Gigs;
  //   // console.log(GigsResponse);
  //   setGigSet(GigsResponse);
  //   // setState(data);
  // }, [props.user.Gigs]);

  const query = gql`
    query {
      skilllistings(
        _sort: PAY_HI_TO_LOW
        _page: 1
        _perPage: 20
        search: "hello"
      ) {
        aggregate {
          count
          totalCount
        }
        data {
          _id
          ProfileId
          Title
          CreatedBy
          ModifiedBy
          IsActive
          Description
          Skills
          GigType
        }
      }
    }
  `;

  // const { loading, error, data } = useQuery(query);
  const Query = () => {
    console.log("hitted");

    //props.GetGigs??????
    props.GetGigs(query);

    // const GetGigs = props.user.Gigs;
    // console.log(GetGigs);
    // if (loading) return "Loading...";
    // if (error) return `Error! ${error.message}`;
  };
  // console.log(GetGigs);
  return (
    <ApolloProvider client={client}>
      <SearchResults />
    </ApolloProvider>
  );
};

export default SearchSkillListings;

// Link: link allows you to customize the flow of data from your graphQL queries
// and mutations to your backend and in-app state management.
// This can include: adding custom headers and routing to REST endpoints and graphQL endpoints.
