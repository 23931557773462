import React, { useEffect } from 'react';
import { Auth } from "../../../config/aws-exports";


const TalentLogout = () => {
    useEffect(() => {
        Auth.signOut()
    },[])
    return (
        <div>
            
        </div>
    );
};

export default TalentLogout;