import { getFullStateName } from "../../../../shared/helpers";

export const getImageByStateCode = (
  stateCode,
  cityName = "state",
  format = "webp"
) => {
  //const url = `https://res.cloudinary.com/skillgigs/image/upload/q_auto,f_auto,fl_progressive/pwa/Locations/`;

  if (cityName === "state") {
    const url = `https://res.cloudinary.com/skillgigs/image/upload/f_auto,w_500,h_800,c_limit/pwa/Locations/`;
    const fileName = getFullStateName(stateCode);
    return url + fileName?.replace(" ", "_") + ".jpg";
  } else {
    const url = `https://res.cloudinary.com/skillgigs/image/upload/f_auto,w_500,h_800,c_limit/pwa/images/${stateCode.toLowerCase()}/${cityName      .replace(/ /g, "")
      .toLowerCase()}/1.jpg`;

    return url;
  }
};
