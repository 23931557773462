import { put, call, takeEvery } from "@redux-saga/core/effects";
import {fetchResumeGetviewsAPI,fetchResumeDetailAPI, fetchResumeGetfollowersAPI } from "../../API/API";
import { types } from "../action-types";
import {fetchResumeGetfollowersSuccess,fetchResumeGetfollowersFailure,fetchResumeGetviewSuccess,fetchResumeGetviewFailure,fetchResumeDetaiSuccess,fetchResumeDetaiFailure} from "../actions"


// GET FOLLOWERS RESUME SAGA START //
function* fetchResumeGetfollowersSaga (action){
    const {page,perPage} = action.payload
    console.log("Resume get followers Saga",page,perPage);
    try{
    console.log("Resume Saga Is Working");
    const response = yield call (()=>fetchResumeGetfollowersAPI(page,perPage))  
    console.log("Responce get followers" , response);
    if (response.status == 200) {
        yield put (fetchResumeGetfollowersSuccess(response.data.data.body.data))
    }else {
        yield put(fetchResumeGetfollowersFailure(response))
    }
    
    }catch (error){
        yield put (fetchResumeGetfollowersFailure(error))
    }
}



export function* watchResumeGetfollowersSaga(){
    yield takeEvery(types.FETCH_RESUME_GET_FOLLOWERS_INIT , fetchResumeGetfollowersSaga)
}

// GET FOLLOWERS RESUME SAGA END //
// GET VIEW RESUME SAGA START //

function* fetchResumeGetviewSaga (action){
    const {page,perPage} = action.payload
    console.log("Resume get View Saga" ,page,perPage);
    try{
    console.log("Resume View Saga");
    const response = yield call (()=>fetchResumeGetviewsAPI(page,perPage)) 
    console.log("Responce get view" , response);
   if(response.status === 200){
        yield put (fetchResumeGetviewSuccess(response.data.data.body.data)) 
   }else{
        yield put(fetchResumeGetviewFailure(response))
    }

    }catch (error){
        yield put (fetchResumeGetviewFailure(error))
    }
}



export function* watchResumeGetviewsSaga(){
    yield takeEvery(types.FETCH_RESUME_GET_VIEWS_INIT , fetchResumeGetviewSaga)
}


// GET FOLLOWERS SAGA END //

// GET DETAIL RESUME SAGA START //

function* fetchResumeDetailSaga (){
    console.log("Resume Detail Saga");
    try{
    console.log("Resume Detail Saga Is Working");
    const response = yield call (()=>fetchResumeDetailAPI())  
    console.log("Responce  Deatil" , response);
    if (response.status == 200) {
        yield put (fetchResumeDetaiSuccess(response.data.data.body))
    }else {
        yield put(fetchResumeDetaiFailure(response))
    }

    }catch (error){
        yield put (fetchResumeDetaiFailure(error))
    }
}

export function* watchResumeDetailSaga(){
    yield takeEvery(types.FETCH_RESUME_DETAIL_INIT , fetchResumeDetailSaga)
}
// GET DETAIL RESUME SAGA END //