import {
  AlertTwoTone,
  RightOutlined,
  HeartFilled,
  SearchOutlined,
  TwitterCircleFilled,
} from "@ant-design/icons";
import { Rate, Divider, Input, Modal } from "antd";
import { BsStarFill } from "react-icons/bs";
import { React, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  ApolloLink,
  gql,
  useLazyQuery,
} from "@apollo/client";
import { RestLink } from "apollo-link-rest";
import {
  fetchGigs,
  isModalVisibleAuth,
  postFavoriteGigCard,
  removeFavoriteGigCard,
  removeFavoriteGigCardReducer,
  SearchResponse,
  SearchResponseRequested,
  SearchValue,
} from "../../../../redux/actions";
import { HttpLink } from "apollo-link-http";
import { getImageByStateCode } from "../../GuestUser/components/cloudinary";
//import "./GuestGigDetails.css";
import { useParams, useHistory } from "react-router-dom";

import { toInteger } from "lodash";
import { FaFacebook, FaRegHospital, FaTwitter } from "react-icons/fa";
import { HiShare } from "react-icons/hi";
import { connect } from "react-redux";
import { debounce } from "../../../../shared/helpers";
import HeartIcon from "../../../../assets/icons/heart-filled.svg";
import Share from "../../../../assets/icons/cishare.svg";
import HospitalIcon from "../../../../assets/icons/hospital-outline.svg";
import SearchBarMobile from "../../components/SearchBarMobile";
import { useLocation } from "react-router-dom";
import { alertNotification } from "../../../../components/notifications/alertNotification";
import { AiFillLinkedin } from "react-icons/ai";
import copyLink from "../../../../assets/icons/copyLink.svg";
import twitter from "../../../../assets/icons/twitter.svg";
import facebook from "../../../../assets/icons/facebook.svg";
import copyLinks2 from "../../../../assets/icons/copyLinks2.svg";
import linkedinn from "../../../../assets/icons/linkedinn.svg";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import MetaTags from "react-meta-tags";
import { REACT_APP_API_URL } from "../../../../config";

function GuestGigDetails(props) {
  const history = useHistory();
  const { state } = useLocation();
  const { isModalVisibleAuth, setIsModalVisibleAuth } = props;
  const [placeholder, showPlaceholder] = useState(false);
  const [dimensions, setDimensions] = useState(window.innerWidth);
  const [Isfav, setIsfav] = useState();
  const [gig, setGig] = useState();
  const [IsFavoriteCheck, setIsFavoriteCheck] = useState(false);
  const [budget, setBudget] = useState();
  const locationURL = useLocation().pathname;
  const [isModalVisibleAuth2, setIsModalVisibleAuth2] = useState(false);
  const [copied, setCopied] = useState(false);
  const showModal = () => {
    setIsModalVisibleAuth2(true);
  };

  const modalCancel = () => {
    setIsModalVisibleAuth2(false);
    setCopied(false);
  };

  const copy = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  };

  const budgetTypeQuery = useSelector(
    (state) => state.searchGigsReducer.budgetTypeQuery
  );
  const [budgetType, setBudgetType] = useState("WK");
  console.log("budget: ", budget, budgetTypeQuery);

  const dispatch = useDispatch();
  const authTokenValue = useSelector(
    (state) => state.authReducer.user.authToken
  );
  console.log("budget: ", budget);
  let { jobid } = useParams();
  console.log("jobid ", jobid);
  const Skillgigs = () => {
    history.push("/search");
  };
  const Healthcare = () => {
    alert("Heathcare Page");
  };

  const retractedBidClick = () => {
    history.push("/RetractedBids");
  };

  const detailQuery = gql`
    query {
      gig(id: "${jobid}",
      ShowRateIn : ${budgetType}
      ) {
        _id
        JobOrderId
        ProfileId
        Title
        score
        BudgetType
        BudgetMin
        Location {
          CityName
          Location
          CountryName
          RegionCode
          Latitude
          Longitude
          LocationLong
        }
        ExpiryDate
        JobOrderStatusName
        JobOrderTypeName
        CreatedBy
        Created
        Modified
        ModifiedBy
        IsActive
        Rating
        Review
        IdealContractLengthId
        IdealContractLengthName
        Description
        SuggestionsFrequency
        SuggestionsSent
        ExperienceLevelName
        InternalGigTypeCode
        ImportSource
        ExternalGigId
        ProfessionCode
        ImportProcessStatus
        FacilityName
        AutoLoaded
        SourceName
        SourceId
        Shift
        Skills{
          SkillName
          SkillPercent
          }
        IsFavorite
      }
    }
  `;

  console.log("abhi", props.isRetractedBids);
  const BackendApiBaseUrl = REACT_APP_API_URL;
  const [hitQuery, { loading, error, data }] = useLazyQuery(detailQuery);
  useEffect(() => {
    hitQuery();
  }, [jobid, budgetType]);
  useEffect(() => {
    console.log("data", data?.gig);
    setGig(data?.gig);
  }, [data, []]);
  let budgetraw = gig?.BudgetMin;
  useEffect(() => {
    if (gig?.BudgetType == "Hour") {
      console.log("budgett");
      let bgt = budgetraw * 40;
      setBudget(bgt);
    } else {
      setBudget(budgetraw);
    }
  }, [data]);
  useEffect(() => {
    setIsFavoriteCheck(data?.gig?.IsFavorite);
  }, [data]);

  useEffect(() => {
    // BUDGET CHECK
    if (budgetTypeQuery === "Hourly") {
      console.log("Budget Query", budgetTypeQuery);
      setBudgetType("HR");
    }
    if (budgetTypeQuery === "Weekly") {
      setBudgetType("WK");
    }
  }, [budgetTypeQuery]);
  // CHECK FOR MOBILE
  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
  }, []);
  // const Budget = () => {
  //   if (gig?.BudgetType == "Hour") {
  //     console.log("budgett");
  //     let bgt = budgetraw * 40;
  //     setBudget(bgt);
  //   } else {
  //     setBudget(budgetraw);
  //   }
  // };
  // Gig created on
  let msec = Number(gig?.Created);
  let time = new Date(msec);
  let year = time.getFullYear();
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let month = months[time.getMonth()];
  let date = time.getDate();
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let day = days[time.getDay()];
  const dates = { day, date, month, year }.toString();

  // Gig Expires on
  let expmsec = Number(gig?.ExpiryDate);
  let exptime = new Date(expmsec);
  let expyear = exptime.getFullYear();
  let expmonth = months[exptime.getMonth()];
  let expdate = exptime.getDate();
  let expday = days[exptime.getDay()];
  const expdates = { expday, expdate, expmonth, expyear }.toString();
  const cardImage = placeholder
    ? getImageByStateCode(
        gig?.Location?.RegionCode
        // gig?.Location?.CityName
      )
    : getImageByStateCode(gig?.Location?.RegionCode, gig?.Location?.CityName);
  console.log("img", getImageByStateCode(cardImage));

  const onSearch = (e) => {
    props.searchValue(e.target.value);
    // if (e.target.value.length > 0) {
    // }
    if (e.target.value.length == 0) {
      history.push("/search");
    }
  };
  const handleApply = (gig) => {
    console.log({ gig });
    localStorage.setItem(
      "image",
      getImageByStateCode(gig?.Location?.RegionCode)
    );
    history.push({
      pathname: `/create-bid`,
      state: {
        title: gig.Title,
        FacilityName: gig.FacilityName,
        RegionName: gig.Location.Location,
        budget: gig.BudgetMin,
        id: gig._id,
      },
    });
  };
  const onRateClick = () => {
    if (dimensions < 769) {
      history.push("/login");
    } else {
      setIsModalVisibleAuth(true);
    }
  };

  const titleDrawerlogi = <div></div>;

  const onHeartClick = (e) => {
    if (dimensions < 769) {
      e.stopPropagation();
      history.push("/login");
    } else {
      e.stopPropagation();
      setIsModalVisibleAuth(true);
    }
  };
  const IsFavLocalvalueUpdate = (e) => {
    setIsFavoriteCheck(!IsFavoriteCheck);
    AfterLoginonHeartClick(e);
  };

  const AfterLoginonHeartClick = (e) => {
    if (IsFavoriteCheck === false) {
      e.stopPropagation();
      alertNotification("success", "Job added to favorites");
      dispatch(postFavoriteGigCard(gig?._id, "Gig"));
    } else if (IsFavoriteCheck === true) {
      e.stopPropagation();
      dispatch(removeFavoriteGigCardReducer(gig?._id));
      dispatch(removeFavoriteGigCard(gig?._id));
      alertNotification("success", "Job removed from favorites");
    }
  };

  const retractedBid12 = useSelector(
    (state) => state.BidsReducer?.isRetractedBids
  );

  const currentUrl = window.location.href;

  useEffect(() => {
    console.log("isRetractedBid12", retractedBid12);
  }, [retractedBid12]);

  return (
    <>
      <>
        <MetaTags>
          <title>{gig?.Title}</title>
          <meta name="description" content="Some description." />
          <meta
            property="og:title"
            content="Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Labore incidunt adipisci maiores maxime sequi ex voluptatum odio
            illo optio placeat nostrum accusantium quibusdam, inventore nesciunt
            a voluptate deserunt qui quod?"
          />
        </MetaTags>
      </>
      {/* MOBILE */}
      {dimensions < 769 ? (
        <>
          <div className="gig-detail-container">
            <div className="search">
              {/* <Input
                placeholder="Find Jobs"
                prefix={<SearchOutlined className="site-form-item-icon" />}
                onPressEnter={(e) => onSearch(e)}
                // style={{ width: 200 }}
              /> */}
              {/* <SearchBarMobile /> */}
            </div>
            {/* <div className="divider-header-mobile">
              <Divider />
            </div> */}
            <div className="header-container-mobile">
              <div className="g-details-header">
                {retractedBid12 ? (
                  <div onClick={Healthcare} className="header-text3">
                    Healthcare
                  </div>
                ) : (
                  <div
                    type="text"
                    onClick={() => history.goBack()}
                    className="header-text1"
                  >
                    {" "}
                    {gig?.Title ? gig?.Title?.substring(0, 15) : " "}...
                  </div>
                )}
                <div className="g-details-arrow-mobile">
                  <RightOutlined className="arrow-mobile" />
                </div>
                {retractedBid12 ? (
                  <div
                    onClick={retractedBidClick}
                    className="header-text2"
                  >
                    Retracted Bids
                  </div>
                ) : (
                  <div className="header-text22">Detail</div>
                )}
              </div>
            </div>
            <div className="content-container-mobile">
              <div
                className="ractangle-left"
                // style={{ background: `url(${cardImage})` }}
              >
                <div className="g-details-facility-right">
                  {/* <div className="div2">
                     <HiShare onClick={onRateClick} />
                    <img
                      src={Share}
                      alt=""
                      className="share-mobile"
                      onClick={onRateClick}
                    />
                  </div> */}
                  <div
                    className="div1-mobile"
                    onClick={
                      authTokenValue === null
                        ? (e) => onHeartClick(e)
                        : (e) => IsFavLocalvalueUpdate(e)
                    }
                  >
                    <Rate
                      character={<HeartFilled />}
                      className="heart"
                      count={1}
                      allowClear
                      disabled={authTokenValue === null ? true : false}
                      defaultValue={IsFavoriteCheck ? 1 : 0}
                      style={{
                        // margin: "0px 2px 0px",
                        position: "absolute",
                        fontSize: "0.875rem",
                        color: "red",
                        marginLeft: "0.156rem",
                        top: "0.375rem",
                      }}
                    />
                  </div>
                </div>
                <div className="bid-details-mobile-container">
                  <div className="bid-details">
                    {/* <div>
                      ${gig?.BudgetMin ? Number(gig?.BudgetMin) * 40 : 0}/wk
                    </div> */}
                    {budgetTypeQuery === "Hourly" ? (
                      <>
                        <div>${gig?.BudgetMin ? gig?.BudgetMin : 0}/HR</div>
                      </>
                    ) : (
                      <>
                        <div>${gig?.BudgetMin ? gig?.BudgetMin : 0}/wk</div>
                      </>
                    )}
                    <div>Pay Package</div>
                  </div>
                </div>
                <div className="location-details">
                  <div className="location-text">
                    {gig?.Location?.LocationLong}
                  </div>
                </div>
                {placeholder ? (
                  <img
                    src={getImageByStateCode(
                      gig?.Location?.RegionCode
                      // gig?.Location?.CityName
                    )}
                    className="city-image"
                  />
                ) : (
                  <img
                    src={getImageByStateCode(
                      gig?.Location?.RegionCode,
                      gig?.Location?.CityName
                    )}
                    className="city-image"
                    onError={(e) => showPlaceholder(true)}
                  />
                )}
              </div>
              {/* Job Details */}
              <div className="g-details-facility-container">
                <div className="job-details">{gig?.Title}</div>
                <div className="g-details-facility-name">
                  <div className="g-hospitadl-icon">
                    <FaRegHospital className="g-hospital-icon" />{" "}
                  </div>
                  <div>{gig?.FacilityName}</div>
                </div>
                <div className="rating-review">
                  <div className="g-star-rating">
                    <Rate
                      allowHalf
                      value={gig?.Rating ? Number(gig?.Rating) : 0}
                      disabled={true}
                    />
                  </div>
                  <div style={{ color: "#fadb14", margin: "0.3px 0 0 5px" }}>
                    {gig?.Rating ? Number(gig?.Rating) : 0}
                  </div>
                  <div>
                    <div style={{ color: "#7e7e7e", margin: "0 0 0 5px" }}>
                      ({gig?.Review ? gig?.Review : 0})
                    </div>
                  </div>
                </div>
                {/* <div className="g-details-facility-right">
                  <div className="div1">
                    <Rate
                      character={<HeartFilled />}
                      count={1}
                      allowClear
                      style={{ margin: "-9px 0px 0px -3px" }}
                    />
                  </div>
                  <div className="div2">
                    <HiShare />
                  </div>
                </div> */}
              </div>
              {/* <Divider /> */}
              {/* <div style={{}}></div> */}
              <div style={{ padding: "0 0.938rem" }}>
                <Divider className="divider-mobile" />
              </div>
              <div className="ractangle-right">
                <div className="about-job">About Job</div>
                <div
                  className="about-job-text"
                  dangerouslySetInnerHTML={{
                    __html: gig?.Description,
                  }}
                >
                  {/* Kinderd Hospital Indianapolis North, IN is seeking a Travel CNA job
            in Indiana, INDIANAPOLIS */}
                  {/* {gig?.Description ? gig?.Description : " "} */}
                </div>
                <div className="details">
                  <div>Bid Rate</div>
                  {/* <div>
                    ${gig?.BudgetMin ? Number(gig?.BudgetMin) * 40 : 0}/wk
                  </div> */}
                  {budgetTypeQuery === "Hourly" ? (
                    <>
                      <div>${gig?.BudgetMin ? gig?.BudgetMin : 0}/HR</div>
                    </>
                  ) : (
                    <>
                      <div>${gig?.BudgetMin ? gig?.BudgetMin : 0}/wk</div>
                    </>
                  )}
                </div>
                <div className="details">
                  <div>Experience</div>
                  <div>
                    {gig?.ExperienceLevelName ? gig?.ExperienceLevelName : "NA"}
                  </div>
                </div>
                <div className="details">
                  <div>Job Type</div>
                  <div>
                    {gig?.JobOrderTypeName ? gig?.JobOrderTypeName : "NA"}
                  </div>
                </div>
                <div className="details">
                  <div>Duration</div>
                  <div>
                    {gig?.IdealContractLengthName
                      ? gig?.IdealContractLengthName
                      : "NA"}
                  </div>
                </div>
                <div className="details">
                  <div>Shift</div>
                  <div>{gig?.Shift ? gig?.Shift : "NA"}</div>
                </div>
                <div className="details">
                  <div>Expires On</div>
                  <div>
                    {expday}, {expmonth} {expdate}, {expyear}
                  </div>
                </div>
                <div className="details">
                  <div>Posted On</div>
                  <div>
                    {day}, {month} {date}, {year}
                  </div>
                </div>
                {/* <Divider />
                <div className="bottom-details">
                  <div className="job-details-bid">
                    <div>${gig?.BudgetMin}/WK</div>
                    <div>Pay Package</div>
                  </div>
                  <div
                    className="bid-details-apply"
                    onClick={() => handleApply(gig)}
                  >
                    <div>Bid to Apply</div>
                  </div>
                </div> */}
                {/* <div className="bid-details-apply">Bid to Apply</div> */}
              </div>
              <Divider className="divider" />
              <div className="bottom-details">
                <div className="job-details-bid">
                  <div className="package-rate">
                    {budgetTypeQuery === "Hourly" ? (
                      <>
                        <div>${gig?.BudgetMin ? gig?.BudgetMin : 0}/HR</div>
                      </>
                    ) : (
                      <>
                        <div>${gig?.BudgetMin ? gig?.BudgetMin : 0}/wk</div>
                      </>
                    )}
                  </div>
                  <div className="pay package-text">Pay Package</div>
                </div>
                <div
                  className="bid-details-apply"
                  onClick={() => handleApply(gig)}
                >
                  <div>Bid to Apply</div>
                </div>
              </div>
              <Divider className="divider" />
            </div>
          </div>
        </>
      ) : null}
      {/* DESKTOP */}
      {dimensions > 769 ? (
        <>
          <div className="gig-detail-container">
            <div className="header-container">
              <div className="g-details-header">
                {retractedBid12 ? (
                  <div onClick={Healthcare} className="header-text">
                    Healthcare
                  </div>
                ) : (
                  <div
                    onClick={() => history.goBack()}
                    className="header-text23"
                  >
                    {" "}
                    {gig?.Title}
                  </div>
                )}
                <div className="g-details-arrow">
                  <RightOutlined className="arrow" />
                </div>
                {retractedBid12 ? (
                  <div
                    onClick={retractedBidClick}
                    className="header-text"
                  >
                    Retracted Bids
                  </div>
                ) : (
                  <div className="header-text"> Detail</div>
                )}
                <div className="g-detail-jb-title"></div>
              </div>
              <div className="job-title">{gig?.Title}</div>
              <div className="g-details-facility-container">
                <div className="g-details-facility-left">
                  <div className="g-details-facility-name">
                    <div className="g-hospitadl-icon">
                      <FaRegHospital className="g-hospital-icon" />{" "}
                    </div>
                    <div>{gig?.FacilityName}</div>
                  </div>
                  <div className="right">
                    <div className="g-star-rating">
                      <Rate
                        allowHalf
                        value={gig?.Rating ? Number(gig?.Rating) : 0}
                        disabled={true}
                      />
                    </div>
                    <div style={{ color: "#fadb14" }}>
                      {gig?.Rating ? Number(gig?.Rating) : 0}
                    </div>
                    <div>
                      {/* <div>({gig?.Review})</div> */}
                      <div style={{ color: "#7e7e7e" }}>
                        ({gig?.Review ? gig?.Review : 0})
                      </div>
                      {/* <div>{moment({ time }).fromNow()}</div> */}
                    </div>
                  </div>
                </div>
                <div className="g-details-facility-right">
                  <div
                    className="div1"
                    onClick={
                      authTokenValue === null
                        ? (e) => onHeartClick(e)
                        : (e) => IsFavLocalvalueUpdate(e)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <Rate
                      character={<HeartFilled />}
                      className="heart"
                      count={1}
                      allowClear
                      disabled={authTokenValue === null ? true : false}
                      value={IsFavoriteCheck ? 1 : 0}
                      style={{
                        margin: "-6px 0px 0px 0px",
                        fontSize: "16px",
                        color: "red",
                      }}
                    />
                    {
                      // console.log(fav)
                      /* <img
                      src={HeartIcon}
                      alt=""
                      className="heart"
                      onClick={onRateClick}
                    /> */
                    }
                  </div>
                  <div className="div2">
                    <img
                      src={Share}
                      alt=""
                      className="share"
                      onClick={showModal}
                      // onClick={onRateClick}
                    />
                    {/* <Share onClick={onRateClick} /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="content-container">
              <div
                className="ractangle-left"
                // style={{ background: `url(${cardImage})` }}
              >
                <div className="bid-details-container">
                  <div className="bid-details">
                    {/* <div>
                      ${gig?.BudgetMin ? Number(gig?.BudgetMin) * 40 : 0}/wk
                    </div> */}
                    {budgetTypeQuery === "Hourly" ? (
                      <>
                        <div>${gig?.BudgetMin ? gig?.BudgetMin : 0}/HR</div>
                      </>
                    ) : (
                      <>
                        <div>${gig?.BudgetMin ? gig?.BudgetMin : 0}/wk</div>
                      </>
                    )}
                    <div>Pay Package</div>
                  </div>
                  <div className="location-details">
                    <div>{gig?.Location?.LocationLong}</div>
                  </div>
                </div>
                {/* <div className="bid-details">
                  <div>
                    ${gig?.BudgetMin ? Number(gig?.BudgetMin) * 40 : 0}/wk
                  </div>
                  <div>Pay Package</div>
                </div>
                <div className="location-details">
                  <div>{gig?.Location?.LocationLong}</div>
                </div> */}
                {placeholder ? (
                  <img
                    src={getImageByStateCode(
                      gig?.Location?.RegionCode
                      // gig?.Location?.CityName
                    )}
                    className="city-image"
                  />
                ) : (
                  <img
                    src={getImageByStateCode(
                      gig?.Location?.RegionCode,
                      gig?.Location?.CityName
                    )}
                    className="city-image"
                    onError={(e) => showPlaceholder(true)}
                  />
                )}
              </div>
              <div className="ractangle-right">
                <div className="about-job">About Job</div>
                <div
                  className="about-job-text"
                  dangerouslySetInnerHTML={{
                    __html: gig?.Description ? gig?.Description : "<br>",
                  }}
                >
                  {/* Kinderd Hospital Indianapolis North, IN is seeking a Travel CNA job
            in Indiana, INDIANAPOLIS */}
                  {/* {gig?.Description ? gig?.Description : " "} */}
                </div>
                <div className="details">
                  <div>Bid Rate</div>
                  {/* <div>
                    ${gig?.BudgetMin ? Number(gig?.BudgetMin) * 40 : 0}/wk
                  </div> */}
                  {budgetTypeQuery === "Hourly" ? (
                    <>
                      <div style={{ color: "#118936", fontWeight: "500" }}>
                        ${gig?.BudgetMin ? gig?.BudgetMin : 0}/HR
                      </div>
                    </>
                  ) : (
                    <>
                      <div style={{ color: "#118936", fontWeight: "500" }}>
                        ${gig?.BudgetMin ? gig?.BudgetMin : 0}/WK
                      </div>
                    </>
                  )}
                </div>
                <div className="details">
                  <div>Experience</div>
                  <div>
                    {gig?.ExperienceLevelName ? gig?.ExperienceLevelName : "NA"}
                  </div>
                </div>
                <div className="details">
                  <div>Job Type</div>
                  <div>
                    {gig?.JobOrderTypeName ? gig?.JobOrderTypeName : "NA"}
                  </div>
                </div>
                <div className="details">
                  <div>Duration</div>
                  <div>
                    {gig?.IdealContractLengthName
                      ? gig?.IdealContractLengthName
                      : "NA"}
                  </div>
                </div>
                <div className="details">
                  <div>Shift</div>
                  <div>{gig?.Shift ? gig?.Shift : "NA"}</div>
                </div>
                <div className="details">
                  <div>Expires On</div>
                  <div>
                    {expday}, {expmonth} {expdate}, {expyear}
                  </div>
                </div>
                <div className="details">
                  <div>Posted On</div>
                  <div>
                    {day}, {month} {date}, {year}
                  </div>
                </div>
                <Divider />
                <div className="bottom-details">
                  <div className="job-details-bid">
                    <div className="salary">
                      {budgetTypeQuery === "Hourly" ? (
                        <>
                          <div>${gig?.BudgetMin ? gig?.BudgetMin : 0}/HR</div>
                        </>
                      ) : (
                        <>
                          <div>${gig?.BudgetMin ? gig?.BudgetMin : 0}/wk</div>
                        </>
                      )}
                    </div>
                    <div className="salary-text">Desired Salary</div>
                  </div>
                  <div
                    className="bid-details-apply"
                    onClick={() => handleApply(gig)}
                  >
                    <div>Bid to Apply</div>
                  </div>
                </div>
                {/* <div className="bid-details-apply">Bid to Apply</div> */}
              </div>
            </div>
            {/* <div
              className="bid-details-apply"
              onClick={() => history.push("/create-bid")}
            >
              Bid to Apply
            </div> */}
          </div>

          <Modal
            className="share-bid-model23"
            width={1000}
            visible={isModalVisibleAuth2}
            footer={null}
            // style={{ top: 45, padding: 0 }}
            onCancel={modalCancel}
            centered={true}
          >
            <div className="PopOver-on-shareBid">
              <div className="PopOver-on-shareBid-job">
                Share this job
              </div>
              <div className="PopOver-on-shareBid-content">
                Lorem epsum is simply dummy text of the printing
              </div>
              <div className="PopOver-on-shareBid-main">
                <div className="PopOver-on-shareBid-inside">
                  <FacebookShareButton
                    // url={window.location.href}
                    url={window.location.href.replace(
                      "http://localhost:3000/",
                      "https://development.d3u57m335l53bk.amplifyapp.com/"
                    )}
                    quote="Join me on Skillgigs, get 10% off and I will get a special discount too!"
                    hashtag="#skillgigs"
                  >
                    <div>
                      <img
                        src={facebook}
                        alt="facebook"
                        className="PopOver-on-shareBid-inside-image"
                      />
                    </div>
                    <div className="PopOver-on-shareBid-inside-content">
                      Facebook
                    </div>
                  </FacebookShareButton>
                </div>
                <div className="PopOver-on-shareBid-box-internal">
                  <LinkedinShareButton
                    // url={window.location.href}
                    url={window.location.href.replace(
                      "http://localhost:3000/",
                      "https://development.d3u57m335l53bk.amplifyapp.com/"
                    )}
                    title={gig?.Title ? gig.Title : "NA"}
                    summary={gig?.Description ? gig.Description : "NA"}
                    source={
                      "https://development.d3u57m335l53bk.amplifyapp.com/"
                    }
                  >
                    <div>
                      <img
                        src={linkedinn}
                        alt="linkedin"
                        className="PopOver-on-shareBid-inside-image"
                        // className="play_button"
                      />
                    </div>
                    <div className="PopOver-on-shareBid-inside-content">
                      LinkedIn
                    </div>
                  </LinkedinShareButton>
                </div>
                <div className="PopOver-on-shareBid-box-internal">
                  <TwitterShareButton
                    // url={window.location.href}
                    url={window.location.href.replace(
                      "http://localhost:3000/",
                      "https://development.d3u57m335l53bk.amplifyapp.com/"
                    )}
                    title={gig?.Title ? gig.Title : "NA"}
                    hashtag="#skillgigs"
                  >
                    <div>
                      <img
                        src={twitter}
                        alt="twitter"
                        className="PopOver-on-shareBid-inside-image"
                      />
                    </div>
                    <div className="PopOver-on-shareBid-inside-content">
                      Twitter
                    </div>
                  </TwitterShareButton>
                </div>
                <div
                  className="PopOver-on-shareBid-box-internal"
                  onClick={copy}
                >
                  {!copied ? (
                    <>
                      <div>
                        <img
                          src={copyLink}
                          alt="copyLink"
                          className="PopOver-on-shareBid-inside-image"
                        />
                      </div>
                      <div className="PopOver-on-shareBid-inside-content">
                        Copy Link
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <img
                          src={copyLinks2}
                          alt="play_button2"
                          // className="play_button"
                          className="PopOver-on-shareBid-inside-image"
                        />
                      </div>
                      <div className="PopOver-on-shareBid-inside-content2">
                        Link Copied
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Modal>
        </>
      ) : null}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    // GetGigs: (params) => {
    //   dispatch(fetchGigs(params)); // action dispatched
    // },
    // SearchValue
    searchValue: (params) => {
      dispatch(SearchValue(params)); // action dispatched
    },
    setIsModalVisibleAuth: (params) => {
      dispatch(isModalVisibleAuth(params));
    },
  };
};

export default connect(null, mapDispatchToProps)(GuestGigDetails);

// export default GuestGigDetails;
