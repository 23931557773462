import React, { useState } from "react";
import ReactNextPaging from "react-next-paging";
//import "./MobilePagination.css";
import { Row, Col } from "antd";
import {
  DoubleLeftOutlined,
  LeftOutlined,
  RightOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";

const MobilePagination = ({ current, defaultPageSize, total, onChange }) => {
  const totalPages = Math.ceil(total / defaultPageSize);

  const pageSetter = (page) => {
    // var pagerr = pager;

    if (page < 1 || page > totalPages) {
      return;
    }
    onChange(page);
  };

  return (
    <Row className="paginationContainer">
      <Col
        span={16}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <DoubleLeftOutlined
          onClick={() => {
            pageSetter(1);
          }}
          style={{ display: current === 1 ? "none" : "flex" }}
        />

        <LeftOutlined
          onClick={() => {
            pageSetter(current - 1);
          }}
          style={{ display: current === 1 ? "none" : "flex" }}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3 style={{ color: "#1e1e1e" }}>Page {current} </h3>
        </div>

        <RightOutlined
          onClick={() => {
            pageSetter(current + 1);
          }}
          style={{ display: current === totalPages ? "none" : "flex" }}
        />
        <DoubleRightOutlined
          onClick={() => {
            pageSetter(totalPages);
          }}
          style={{ display: current === totalPages ? "none" : "flex" }}
        />
      </Col>
    </Row>
  );
};

export default MobilePagination;
