import { call, put, takeEvery, debounce } from "redux-saga/effects";
import { types } from "../action-types";
import { getGraphDataApi } from "../../API/API";

function* fetchGraphDataSaga(action) {
    console.log('in graph data saga!');
    try {
        console.log('saga running');
        const {type,StartTime,enddate} = action.payload;
        const response = yield call(() => getGraphDataApi(type,StartTime,enddate));
        // yield put({type: types.GET_GRAPH_DATA_SUCCESS, payload: response.data});
        yield put({ type: types.GET_GRAPH_DATA_SUCCESS, payload: response.data });
        console.log(JSON.stringify(response.data));
    } catch (error) {
        yield put({type: types.GET_GRAPH_DATA_FAILURE, error});
    }
}

export function* watchGraphDataSaga() {
    yield takeEvery(types.GET_GRAPH_DATA_REQUESTED, fetchGraphDataSaga);
}
