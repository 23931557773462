import { call, put, takeEvery } from "redux-saga/effects";
import { types } from "../action-types";
import { createBidApi } from "../../API/API";

function* createBidSaga(params) {
    console.log("param:",JSON.stringify(params));
    try {
        const response = yield call(createBidApi, params); 
        
        // API Backend
        yield put({type: types.CREATE_BID_SUCCESS, payload: response.data});
      }
      catch (error) {
        yield put({type: types.CREATE_BID_FAILURE, error});
    }
}

export function* watchCreateBidSaga() {
    yield takeEvery(types.CREATE_BID_REQUESTED, createBidSaga);
}
