import React from "react";
import { Radio } from "antd";

export const HidePreviouslyViewedFilter = (props) => {
  const hidePreviousFunc = (e) => {
    if (props.hideCheck) {
      console.log("changed to false");
      props.setHideCheck(false);
      props.setSearchFilters({ ...props.searchFilters, hidePrevious: null });
    } else {
      console.log("changed to true");
      props.setHideCheck(true);
      props.setSearchFilters({ ...props.searchFilters, hidePrevious: true });
    }
  };

  return (
    <div>
      {/* <Radio className="hide-previously-filter">Hide previously viewed</Radio> */}
      <div style={{ width: "100%" }} onClick={(e) => hidePreviousFunc(e)}>
        <input
          style={{ marginLeft: "2%", cursor: "pointer" }}
          value=""
          checked={props.hideCheck ? "true" : ""}
          type="radio"
          name="hidePreviouslyViewed"
        />{" "}
        &nbsp;Hide previously viewed
      </div>
    </div>
  );
};
