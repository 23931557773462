import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AutoComplete, Input, Col, Row, Radio, Typography, Form } from "antd";
//import "./BudgetFilter.css";
import { ConsoleLogger } from "@aws-amplify/core";

const BudgetFilter = React.memo((props) => {
  const [warning, setWarning] = useState(false);
  const [maxForm] = Form.useForm();
  const [minForm] = Form.useForm();

  const { Text } = Typography;

  useEffect(() => {
    if (
      parseInt(props.budget?.budgetMin) >= parseInt(props.budget?.budgetMax) &&
      props.budget?.budgetMin != null &&
      props.budget?.budgetMax != null &&
      props.budget?.budgetMin != "" &&
      props.budget?.budgetMax != ""
    ) {
      setWarning(true);
      console.log("Warning");
    } else {
      setWarning(false);
    }
  }, [props.budget]);

  useEffect(() => {
    if (props.clear === true) {
      maxForm.resetFields();
      minForm.resetFields();
    }
  }, [props.clear]);

  const minBudgetValue = (e) => {
    console.log("min budget is:", e.target.value);
    props.setBudget({ ...props.budget, budgetMin: e.target.value });
    props.setClear(false);
  };

  const maxBudgetValue = (e) => {
    console.log("max budget is:", e.target.value);
    props.setBudget({ ...props.budget, budgetMax: e.target.value });
    props.setClear(false);
  };

  const bidRateSelect = (e) => {
    console.log("Bid rate is:", e.target.value);
    props.setBudget({ ...props.budget, budgetType: e.target.value });
  };

  return (
    <div className="budget-filter-div">
      <Radio.Group onChange={bidRateSelect} value={props.budget?.budgetType}>
        <Radio className="budget-radio-btn" value={"Hour"}>
          Hourly
        </Radio>
        <Radio className="budget-radio-btn" value={"Week"}>
          Weekly
        </Radio>
      </Radio.Group>{" "}
      <br />
      <Row className="input-row">
        <Col sm={11} xs={11}>
          <Form form={minForm} name="min_budget">
            <Form.Item name="min-budget">
              <Input
                prefix={"$"}
                type={"input"}
                className={
                  !warning
                    ? "min-budget-input"
                    : "min-budget-input-red"
                }
                onChange={minBudgetValue}
                placeholder={"0"}
                value={props.budget?.budgetMin}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col style={{ textAlign: "center", paddingTop: "12px" }} sm={2} xs={2}>
          <Text>_</Text>
        </Col>
        <Col sm={11} xs={11}>
          <Form form={maxForm} name="max_budget">
            <Form.Item name="max-budget">
              <Input
                prefix={"$"}
                type={"input"}
                className={
                  !warning
                    ? "min-budget-input"
                    : "min-budget-input-red"
                }
                onChange={maxBudgetValue}
                placeholder={"0"}
                value={props.budget?.budgetMax}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      {warning && (
        <div style={{ color: "red", fontSize: "12px", marginTop: "-2%" }}>
          Minimum Budget cannot be greater than or equal to Maximum Budget.
        </div>
      )}
    </div>
  );
});

export default BudgetFilter;
