import { call, put, takeEvery } from "redux-saga/effects";
import { types } from "../action-types";
import {createTimeSheetCorrectionApi} from "../../API/API"

function* createTimeSheetCorrectionSaga(params) {
    console.log("param:",JSON.stringify(params));
    try {
        const response = yield call(createTimeSheetCorrectionApi, params); 
        
        // API Backend
        yield put({type: types.CREATE_TIMESHEET_CORRECTION_SUCCESS, payload: response.data});
      }
      catch (error) {
        yield put({type: types.CREATE_TIMESHEET_CORRECTION_FAILURE, error});
    }
}

export function* watchCreateTimeSheetCorrectionSaga() {
    yield takeEvery(types.CREATE_TIMESHEET_CORRECTION_REQUESTED, createTimeSheetCorrectionSaga);
}