import React, { useState, StrictMode, useEffect } from "react";
// //import "./assets/css/common.css";
// import { getWelcomeString } from "common/src/main";
import { connect, useDispatch } from "react-redux";
import { AppRouter } from "./routes/router";
import Footer from "./components/Footer";
import { fetchData } from "./redux/actions";
import "./firebaseConfig";
import {
  fetchTimeSheetList,
  getGraph,
  UpdateNotificationInit,
} from "./redux/actions";
import { getMessaging, getToken } from "firebase/messaging";
import { useClearCache } from "react-clear-cache";
import { Auth } from "./config/aws-exports";
import { getCookie, setCookie } from "./shared/helpers";
import { useHistory } from "react-router-dom";

function App(props) {
  const [stage, seStage] = useState("");
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register(`${process.env.PUBLIC_URL}/sw.js`)
      .then(() => {
        console.log("SW Registered");
      });
  }

  const receiveStage = (stage) => {
    seStage(stage);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   if (!props.loading && props.user) {
  //     console.log("USER:", props.user);
  //   }
  // }, [props.user]);

  const pageSize = 20;

  const dispatch = useDispatch();

  const getTimeList = (skip) => {
    dispatch(fetchTimeSheetList(pageSize, skip, "tilldate", null, null));
    dispatch(getGraph("tilldate", null, null));
    // console.log("Work",skip)
  };
  const history = useHistory();
  useEffect(() => {
    async function AuthCheck() {
      let session = null;

      try {
        if (getCookie("token") !== null && getCookie("token").length > 0) {
          const cognitoUser = await Auth.currentAuthenticatedUser();
          const currentSession = await Auth.currentSession();
          cognitoUser.refreshSession(
            currentSession.refreshToken,
            (err, session) => {
              console.log("session", err, session);
              const token = session.idToken.jwtToken;
              // do whatever you want to do now :)
              console.log("authToken", token);
              setCookie("token", token, true);
              props.profileDetails(token);
              props.sendAuthToken(token);
            }
          );
        } else {
          console.log("token not found");
          history.push("/login");
        }
      } catch (err) {
        console.log("ERROR IN VERIFYING SESSION:", err);
        history.push("/login");
      }
    }
    AuthCheck();
  }, []);
  useEffect(() => {
    // getToken();
    const messaging = getMessaging();
    getToken(messaging, {
      vapidKey:
        "BIXrSZswxEmL7B1dL_MbVaPcveqHpmRocLule2o8kUF4F-NElqxVCqxp_bVa69OEM_vZ5xxSxEYjQ0gaQ30Zq7Y",
    })
      .then((currentToken) => {
        if (currentToken) {
          dispatch(UpdateNotificationInit(currentToken));
          console.log("Notification Token is " + currentToken);
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
    getTimeList(0);
  }, []);

  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  console.log(isLatestVersion, "isLatestVersion");
  if (!isLatestVersion) {
    console.log("Updating to latest");
    emptyCacheStorage();
  }
  return (
    <React.Fragment>
      <StrictMode>
        <div className="page-container">
          <AppRouter
            authToken={props.authToken}
            shareStage={receiveStage}
            stage={stage}
          />
        </div>
      </StrictMode>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    authToken: state.authReducer.user.authToken,
    loading: state.profileReducer.loading,
    user: state.profileReducer.user,
    error: state.profileReducer.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (params) => {
      dispatch(fetchData(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
