import { call, put, takeEvery, select } from "redux-saga/effects";
import { types } from "../action-types";
import { fetchSearchLocationAPI } from "../../API/API";
import { fetchLocationFailure } from "../actions";

function* searchLocationSaga(params) {
  console.log("LOCATION in SAGA:", params);
  try {
    const response = yield call(fetchSearchLocationAPI, params); // API Backend
    console.log(response);
    yield put({
      type: types.FETCH_SEARCH_LOCATION_SUCCESS,
      payload: response.data,
    });
    // if (response.data.status === "success") {
    //   const data = {
    //     key: response.data.payload,
    //     CredentialDocumentId: params.params.documentId,
    //   };
    //   yield put(uploadDocSuccess(data));
    // } else {
    //   yield put(uploadDocFailure(response.errorMessage));
    // }
  } catch (error) {
    console.log(error);
    yield put(fetchLocationFailure(error));
  }
}

export function* watchSearchLocationSaga() {
  yield takeEvery(types.FETCH_SEARCH_LOCATION_INIT, searchLocationSaga);
}
