import axios from "axios";
import store from "../redux/store";
import WS from "../webSocket/defaultSocket";
import { getCookie } from "../shared/helpers";
import { Auth } from "../config/aws-exports";
import { responsesAreSame } from "workbox-broadcast-update";
import { useDispatch, useSelector } from "react-redux";
import { receivedMessage } from "../redux/actions";
import { REACT_APP_API_URL } from "../config";


const BackendApiBaseUrl = REACT_APP_API_URL;

const fetchDataUrl = `${BackendApiBaseUrl}/`; //sample
const sendDataUrl = `${BackendApiBaseUrl}/`; //sample
const updateProfileUrl = `${BackendApiBaseUrl}/profile`; // need to append /profileId later
const fetchRoleDiscipline = `${BackendApiBaseUrl}/getDiscipline`;
const uploadFileUrl = `${BackendApiBaseUrl}/upload`; // need to append /profileId later
const uploadDPUrl = `${BackendApiBaseUrl}/upload`; // need to append /profileId later
const uploadResumeUrl = `${BackendApiBaseUrl}/uploadResume`; // need to append /profileId later
const fetchDocUrl = `${BackendApiBaseUrl}/credentialing/userdoc`;
const uploadDocUrl = `${BackendApiBaseUrl}/document/upload`;
const fetchProfileDetails = `${BackendApiBaseUrl}/profile/me`;
const fetchExistUserUrl = `${BackendApiBaseUrl}/auth/checkUser`;
const getSkillsUrl = `${BackendApiBaseUrl}/skill/getSkills`;
const skillListingUrl = `${BackendApiBaseUrl}/skill/listing`;
const fetchProfileExperience = `${BackendApiBaseUrl}/profile/experienceLevels`;

const createInterviewUrl = "https://kt6m7lx9vc.execute-api.us-east-2.amazonaws.com/staging/interview/create";

const fetchProposalsUrl = `${BackendApiBaseUrl}/proposal/placedbids`;
const createBidUrl = `${BackendApiBaseUrl}/proposal`;
const updateBidUrl = `${BackendApiBaseUrl}/proposal/placedbids/update`;
const fetchMessagesUrl = `${BackendApiBaseUrl}/message`;
const fetchBidDataUrl = `${BackendApiBaseUrl}/proposal`;

const getChatHistoryUrl = `${BackendApiBaseUrl}/message/get`;
const sendMessageUrl = `${BackendApiBaseUrl}/message/send`;
// const sendAttachments = `${BackendApiBaseUrl}/message/attachment`;
const receivedBidStatusUrl = `${BackendApiBaseUrl}/proposal/bidstatus`;
const fetchReceivedBidsUrl = `${BackendApiBaseUrl}/bids/receivedbids/`;
const fetchReceivedBidsDetailUrl = `${BackendApiBaseUrl}/bids`;
const updateReceivedBidsUrl = `${BackendApiBaseUrl}/proposal/receivedbids/update`;

const getGraphDataUrl = `${BackendApiBaseUrl}/timesheet/getgraph`;
const fetchTimeListUrl = `${BackendApiBaseUrl}/timesheet/get`;
const createTimesheetItemsUrl = `${BackendApiBaseUrl}/timesheet`;
const upateTimeSheetUrl = `${BackendApiBaseUrl}/timesheet`;
const createTimeSheetCorrectionUrl = `${BackendApiBaseUrl}/timesheet/corrections`;
const userAssignmentUrl = `${BackendApiBaseUrl}/timesheet/userassignment`;
const getExistingListItem = `${BackendApiBaseUrl}/timesheet/get/exists`;

const fetchDashboardStats = `${BackendApiBaseUrl}/profile/dashboardStats`;

const getUserJobDetailsURL = `${BackendApiBaseUrl}/profile/userJobAssigned`;

const addGigCardFavorite = `${BackendApiBaseUrl}/favorite`;
const removeGigCardFavorites = `${BackendApiBaseUrl}/favorite`;

// const getGraphDataUrl=`http://192.168.18.49:3000/development/timesheet/getgraph`;
// const fetchTimeListUrl = `http://192.168.18.40:3000/development/timesheet/get`;
// const createTimesheetItemsUrl=`http://192.168.18.49:3000/development/timesheet`;
// const upateTimeSheetUrl =`http://192.168.18.49:3000/development/timesheet`;
// const createTimeSheetCorrectionUrl='http://192.168.18.49:3000/development/timesheet/corrections';
// const userAssignmentUrl=`http://192.168.18.49:3000/development/timesheet/userassignment`;
// const getExistingListItem=`http://192.168.18.49:3000/development/timesheet/get/exists`;

const deleteResumeURL = `${BackendApiBaseUrl}/profile/removeResume`;
const gigSearchURL = `${BackendApiBaseUrl}/query`;
const getMyFavoritesURL = `${BackendApiBaseUrl}/favorites`;
// const retractedBidStatusUrl = `${BackendApiBaseUrl}/types/sort`;
const fetchRetractedBidsUrl = `${BackendApiBaseUrl}/types/sort/`;

//CAndidate Profile
const profileDetailsUrl = `${BackendApiBaseUrl}/guest/get`;

//Interview
//Create interview API
export const createInterviewApi = async (request) => {
  const data = request.payload;
  const headers = fetchToken();
  return await axios.post(`${createInterviewUrl}/${request.payload.bid_id}`, data, { headers });
};

const fetchRetractedBidData = `${BackendApiBaseUrl}/proposal/all/retractedbids`;

const fetchPlaceBidData = `${BackendApiBaseUrl}/proposal/all/placedbids`;
const fetchInterviewCardUrl = `${BackendApiBaseUrl}/interview/get-interviews`;

const fetchInterviewURL = `${BackendApiBaseUrl}/interview/get-interviews`;
const updateInterviewURL = `${BackendApiBaseUrl}/interview/update`;
const updateReceivedBidURL = `${BackendApiBaseUrl}/bids/receivedbids/update`;

//*****  3D Resume APIs  start  *****//
const fetchResumeGetfollowersURL = `${BackendApiBaseUrl}/profile/getfollowers`;
const fetchResumeGetviewsURL = `${BackendApiBaseUrl}/profile/getviews`;
const fetchResumeDetailURL = `${BackendApiBaseUrl}/profile/detail`;
//*****  3D Resume APIs  end  *****//

//****Message Decline Reasons API ******/
const fetchDeclineReasonsURL = `${BackendApiBaseUrl}/bids/declinedstatus`;

const fetchHomePageDataURL = `${BackendApiBaseUrl}/home/jobs`;

// Notification API //
const fetchNotificationURL = `${BackendApiBaseUrl}/profile`;
const retractBidUpdate = `${BackendApiBaseUrl}/proposal/retractedbid/update`;

//***********************Employer APIs
const getCompanyDetailsDropdownUrl = `https://mrcnet4vhh.execute-api.us-east-2.amazonaws.com/development/empprofile/companydetails`;
const getFiltersUrl = `${BackendApiBaseUrl}/guest/getfilters`;
const getSearchSuggestionUrl = `${BackendApiBaseUrl}/search?search=`;


let token = null;
let headers = null;
export const fetchToken = async () => {
  token = await store.getState().authReducer.user.authToken;
  headers = { "Content-Type": "application/json", Authorization: token };
  return headers;
};

// fetch Profile Experience
export const fetchProfileExperienceAPI = async () => {
  console.log("Experience");

  const response = await axios.get(fetchProfileExperience);
  return response;
};

// Fetch Location API
export const fetchSearchLocationAPI = async (value) => {
  console.log("PARAMS:", value.payload);

  const fetchLocationAPI = `${BackendApiBaseUrl}/skill/locations?location=${value.payload}`;
  const response = await axios.get(fetchLocationAPI);
  return response;
};

// Fetch User Skill Listings
export const fetchUserSkillListingAPI = async (params) => {
  const [type, page, perPage] = params;
  // console.log("SkillListing", type, page, perPage);
  const headers = await fetchToken();

  const response = await axios.get(
    `${skillListingUrl}?type=${type}&page=${page}&perPage=${perPage}`,
    { headers }
  );
  return response;
};

// Fetch Discipline API
export const fetchSearchDisciplineAPI = async (value) => {
  console.log("PARAMS:", value.payload);

  const fetchDisciplineAPI = `${BackendApiBaseUrl}/skill/discipline?discipline=${value.payload}`;
  const response = await axios.get(fetchDisciplineAPI);
  return response;
};

// LinkedIN User
const fetchLinkedInToken = `${BackendApiBaseUrl}/auth/linkedInSignin`;

export const approveLinkedInUserAPI = async (token) => {
  console.log("PARAMS:", token);
  // try {
  const response = await axios.post(
    fetchLinkedInToken,
    {
      code: token,
      redirectURL: `${process.env.REACT_APP_MY_URL}/linkcheck`,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response;
  // } catch (err) {
  //   return err.response;
  // }
};

export const fetchDocsList = async () => {
  // console.log("Fetching Doc List:", headers);
  const headers = await fetchToken();
  const response = await axios.get(fetchDocUrl, { headers });
  return response;
};

export const getProfileDetailAPi = async (id) => {
  // const headers=fetchToken();
  return await axios.get(`${profileDetailsUrl}/${id}`);
};

export const uploadFileApi = async (request, userToken) => {
  console.log("PARAMS:", request);
  // console.log(await fetchToken());
  const headers = await fetchToken();
  // console.log(headers);
  // const headers = {
  //   "Content-Type": "application/json",
  //   Authorization: userToken,
  // };
  const { file } = request.params;
  const contentType = file.type;
  // const payload = {contentType, type  }
  request.params["contentType"] = contentType;
  const response = await axios.post(uploadFileUrl, request.params, {
    headers,
  });
  console.log("UPLOAD FILE FIRST RESPONSE", response);
  const { uploadURL, Key } = response.data;
  const uploadResponse = await axios.put(uploadURL, file);
  console.log("Upload File Response", uploadResponse);
  if (uploadResponse.status == 200) {
    console.log("FILE UPLOAD SUCCESS");
    return { data: { status: "success", payload: Key } };
  } else {
    console.log("FILE UPLOAD FAIL");
    return {
      data: {
        status: "error",
        error: { message: "File upload failed", code: uploadResponse.status },
      },
    };
  }
};

// deleting Resume from Profile
export const resumeDeleteAPI = async () => {
  console.log("DELETE API");
  const headers = await fetchToken();

  const response = await axios.delete(deleteResumeURL, { headers });
  return response;
};

export const uploadDocApi = async (request) => {
  console.log("PARAMS:", request);

  const response = await axios.post(uploadDocUrl, request.params, {
    headers: { "Content-Type": "multipart/form-data", Authorization: token },
  });
  console.log("Upload Doc Response", response);
  return response;
};

export const removeDocApi = async (request) => {
  console.log("REQUEST in REMOVE DOC:", request);
  await fetchToken();
  const response = await axios.delete(`${fetchDocUrl}/${request.params}`, {
    headers: { Authorization: token },
  });
  console.log("Remove Doc Response", response);
  return response;
};

export const fetchProfileURL = async (token) => {
  console.log("TOKEN IN API:", token);
  const response = await axios.get(fetchProfileDetails, {
    headers: { Authorization: token },
  });

  return response;
};

export const fetchRoleDisciplineAPI = async (params) => {
  // console.log("HALO", params.payload);

  const key = params.payload.key;
  const token = params.payload.authToken;
  const profession = params.payload.pro;

  console.log("HALO", profession);

  // const headers = await fetchToken();
  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
  };

  const response = await axios.get(
    `${fetchRoleDiscipline}?search=${key ? key : ""}&profession=${profession}`,
    {
      headers,
    }
  );

  return response;
};

export const updateProfileApi = async (request) => {
  console.log("UPDATE PROFILE PAYLOAD:", request.params);
  // console.log("Headers", headers);
  // if (headers == null) {
  const headers = await fetchToken();
  // }
  console.log("Headers", headers);
  const response = await axios.put(`${updateProfileUrl}`, request.params, {
    headers,
  });
  console.log("Update Profile Response", response);
  return response;
};

export const uploadProfilePicApi = async ({ params }) => {
  console.log("PARAMS:", params);
  console.log("TYPE OF PARAMS:", typeof params);
  await fetchToken();
  const response = await axios.put(`${uploadDPUrl}`, params, {
    headers: { "Content-Type": "multipart/form-data", Authorization: token },
  });
  console.log("Upload Doc Response", response);
  return response;
};

export const uploadResumeApi = async ({ params }) => {
  console.log("PARAMS:", params);
  console.log("TYPE OF PARAMS:", typeof params);
  await fetchToken();
  const response = await axios.put(`${uploadResumeUrl}`, params, {
    headers: { "Content-Type": "multipart/form-data", Authorization: token },
  });
  console.log("Upload Doc Response", response);
  return response;
};

export const fetchSkillsApi = async (request) => {
  console.log("CREATE SKILL LISTING PAYLOAD:", request.params);
  const categoryId = 3;
  const response = await axios.get(
    `${getSkillsUrl}?skillName=${request.params}&SkillCategoryId=${categoryId}`
  );
  console.log("Fetch Skill Listing Response", response);
  return response;
};

export const createSkillListingApi = async (request) => {
  console.log("CREATE SKILL LISTING PAYLOAD:", request.params);

  const headers = await fetchToken();

  const response = await axios.post(`${skillListingUrl}`, request.params, {
    headers,
  });
  console.log("Create Skill Listing Response", response);
  return response;
};

export const updateSkillListingApi = async (request) => {
  console.log("UPDATE SKILL LISTING PAYLOAD:", request.params);

  const headers = await fetchToken();

  const response = await axios.put(
    `${skillListingUrl}/${request.params._id}`,
    request.params,
    {
      headers,
    }
  );
  console.log("Update Skill Listing Response", response);
  return response;
};

export const deleteSkillListingApi = async (request) => {
  const id = request.params;
  console.log("DELETE SKILL LISTING PAYLOAD:", id);

  const headers = await fetchToken();

  const response = await axios.delete(`${skillListingUrl}/${id}`, {
    headers,
  });
  console.log("Delete Skill Listing Response", response);
  return response;
};

export const fetchDataApi = async (params) => {
  return await axios.get(fetchDataUrl);
};

export const sendDataApi = async (params) => {
  const data = JSON.stringify(params);
  return await axios.post(sendDataUrl, data, { headers });
};
export const checkUserApi = async (request) => {
  // const data = JSON.stringify(params);
  console.log(request);
  // console.log(data);
  const response = await axios.post(
    fetchExistUserUrl,
    { email: request.params },
    { headers }
  );
  console.log("User Existance: ", response);
  return response;
};

// Bids
// Proposal
export const fetchProposalsApi = async (id, limit, skip, status) => {
  const headers = await fetchToken();
  return axios.get(`${fetchProposalsUrl}/${limit}/${skip}/${status}`, {
    headers,
  });
};

export const createBidApi = async (request) => {
  const data = JSON.stringify(request.payload);
  console.log(JSON.stringify(request));
  console.log("proposalData", request.payload, "abc", request);
  const headers = await fetchToken();
  return await axios.post(createBidUrl, data, { headers });
};

export const updateBidApi = async (request) => {
  const data = JSON.stringify(request.payload);
  console.log(JSON.stringify(request));
  const headers = await fetchToken();
  return await axios.put(`${updateBidUrl}/${request.payload.id}`, data, {
    headers,
  });
};

export const retractBidApi = async (request) => {
  const data = JSON.stringify(request.payload);
  console.log(JSON.stringify(request));
  const headers = await fetchToken();
  return await axios.put(`${updateBidUrl}/${request.payload.id}`, data, {
    headers,
  });
};

export const fetchMessagesAPI = async (messageType, limit, skip) => {
  const headers = await fetchToken();
  return await axios.get(
    `${fetchMessagesUrl}/${messageType}/${limit}/${skip}`,
    { headers }
  );
};

export const fetchInboxDataAPI = async (
  limit,
  skip,
  type = null,
  bidType = null,
  bidStatus,
) => {
  console.log("fetched_Inbox_API", limit, skip);
  const headers = await fetchToken(); // authentication
  // console.log("fetched_Inbox_API token", headers)

  return await axios.get(
    `${fetchMessagesUrl}/all/${limit}/${skip}?type=${type}&bidType=${bidType}&bidStatus=${bidStatus}`, //data fetch
    { headers }
  );
};

export const fetchBidDataApi = async (id) => {
  const headers = await fetchToken();
  return axios.get(`${fetchBidDataUrl}/${id}`, { headers });
};

export const SendMessageAPI = async (
  conversationId,
  name,
  recipientId,
  message,
  profPic,
  fileUploadResponse
) => {
  const headers = await fetchToken();
  headers["Content-Type"] = "multipart/form-data";
  const data = new FormData();

  data.append("ConversationId", conversationId);
  data.append("MessageText", message);
  for (let i = 0; i < fileUploadResponse.length; i++)
    data.append("Attachments[]", fileUploadResponse[i]);

  const req = await axios.post(`${sendMessageUrl}`, data, { headers });
};

export const fetchChatHistoryApi = async (id) => {
  const headers = await fetchToken();
  return axios.get(`${getChatHistoryUrl}/${id}`, { headers });
};

export const fetchReceivedBidsStatusAPI = async () => {
  console.log("Status hit");

  const headers = await fetchToken();

  const response = await axios.get(receivedBidStatusUrl, { headers });
  console.log("Status API hit", response);

  return response;
};

export const fetchReceivedBidsApi = async (
  BidStatusName,
  skillListingId,
  sortBy,
  lat,
  long,
  page,
  perPage
) => {
  const location = `latitude=${lat}&longitude=${long}&maxDistance=50&`;
  console.log("API ID:", skillListingId, lat, long, page, perPage);
  const headers = await fetchToken();
  return axios.get(
    `${fetchReceivedBidsUrl}${skillListingId ? skillListingId : null
    }/${sortBy}?${lat && long ? location : ""
    }page=${page}&perPage=${perPage}&status=${BidStatusName}
    `,
    {
      headers,
    }
  );
};
// export const downloadChatFilesApi=(FilePath)=>{
//   const headers=await fetchToken();
//   return axios.get(`${downloadFilesfromChat}/${FilePath}`,{headers});
// }

export const updateReceivedBidsApi = async (request) => {
  const data = JSON.stringify(request.payload);
  console.log(JSON.stringify("data: ", data));
  const headers = await fetchToken();
  return await axios.put(
    `${updateReceivedBidsUrl}/${request.payload.id}`,
    data,
    { headers }
  );
};

export const SearchGigsApi = async (request) => {
  // const data = JSON.stringify(params);
  console.log(request);
  // console.log(data);
  const response = await axios.post(gigSearchURL, request.params, { headers });
  console.log("Search Gigs Result : ", response);
  return response;
};
export const getGraphDataApi = async (type, startdate, enddate) => {
  const headers = await fetchToken();
  return axios.get(`${getGraphDataUrl}/${type}/${startdate}/${enddate}`, {
    headers,
  });
};

// export const fetchTimeSheetListApi=(limit, skip)=>{
//   const headers=await fetchToken();
//   console.log("limit api",JSON.stringify(limit))
//   return axios.get(`${fetchTimeListUrl}/${limit}/${skip}`,{headers});
// }

export const fetchTimeSheetListApi = async (limit, skip, type, start, end) => {
  const headers = await fetchToken();
  console.log("limit api", JSON.stringify(limit));
  return axios.get(
    `${fetchTimeListUrl}/${type}/${limit}/${skip}/${start}/${end}`,
    { headers }
  );
};

//TimeSheet Correction
//create
export const createTimeSheetCorrectionApi = async (request) => {
  const data = JSON.stringify(request.payload);
  console.log(JSON.stringify(request));
  const headers = await fetchToken();
  return await axios.post(createTimeSheetCorrectionUrl, data, { headers });
};
export const createTimeSheetItemsApi = async (request) => {
  const data = JSON.stringify(request.payload);
  console.log(JSON.stringify(request.payload));
  const headers = await fetchToken();
  return await axios.post(createTimesheetItemsUrl, data, { headers });
};
export const updateTimeSheetApi = async (request) => {
  console.log(request.payload);
  const headers = await fetchToken();
  return await axios.put(
    `${upateTimeSheetUrl}/${window.timesheetId}`,
    request.payload,
    { headers }
  );
};

// userAssignmentUrl
export const userAssignmentApi = async () => {
  const headers = await fetchToken();
  return await axios.get(`${userAssignmentUrl}`, { headers });
};

export const getExistingListemItemAPi = async () => {
  console.log("api called");
  const headers = await fetchToken();
  return await axios.get(`${getExistingListItem}`, { headers });
};

// SEARCHSUGGESTION
export const fetchSearchSuggestionsAPI = async (value) => {
  const headers = await fetchToken();
  console.log("Search suggestion PARAMS:", value.payload);
  const response = await axios.get(`${BackendApiBaseUrl}/search?search=${value.payload}`, { headers });
  //const fetchSuggestionAPI = `${BackendApiBaseUrl}/search?search=${value.payload}` ;
  //const response = await axios.get(fetchSuggestionAPI );
  return response;
};

// talent info API
export const talentInfoAPI = async (token) => {
  const headers = { "Content-Type": "application/json", Authorization: token };
  console.log("headers", headers);
  return await axios.get(`${updateProfileUrl}/detail`, { headers });
};

// Analytic info API
export const AnalyticInfoAPI = async (token) => {
  //const headers = {"Content-Type": "application/json", Authorization: token};
  const headers = await fetchToken();
  console.log("headers", headers);
  const response = await axios.get(`${fetchDashboardStats}`, { headers });
  return response;
};

export const fetchUserJobDetailsAPI = async (value) => {
  var payloadvalue = value.payload.slice(0, -9);
  var updatepayloadvalue;
  if (payloadvalue === "On Billing") {
    updatepayloadvalue = "Active";
  } else {
    updatepayloadvalue = payloadvalue;
  }
  const headers = await fetchToken(payloadvalue, "payloadvalue");
  const response = await axios.get(
    `${getUserJobDetailsURL}?page=1&perPage=2&status=${updatepayloadvalue}`,
    { headers }
  );
  return response;
};

export const fetchUserJobDetailsCompletedAPI = async (value) => {
  console.log("job details api hit", value.payload);
  const headers = await fetchToken();
  const response = await axios.get(
    `${getUserJobDetailsURL}?page=1&perPage=2&status=Completed`,
    { headers }
  );
  return response;
};

export const fetchUserJobDetailsCanceledAPI = async (value) => {
  console.log("job details api hit", value.payload);
  const headers = await fetchToken();
  const response = await axios.get(
    `${getUserJobDetailsURL}?page=1&perPage=2&status=Cancelled`,
    { headers }
  );
  return response;
};

export const fetchUserJobDetailsTerminatedAPI = async (value) => {
  console.log("job details api hit", value.payload);
  const headers = await fetchToken();
  const response = await axios.get(
    `${getUserJobDetailsURL}?page=1&perPage=2&status=Terminated`,
    { headers }
  );
  return response;
};

export const fetchUserJobDetailsActiveAPI = async (value) => {
  console.log("job details api hit", value.payload);
  const headers = await fetchToken();
  const response = await axios.get(
    `${getUserJobDetailsURL}?page=1&perPage=2&status=Active`,
    { headers }
  );
  return response;
};

// myFavorites info API
export const myFavoritesInfoAPI = async (page, perPage) => {
  //const headers = {"Content-Type": "application/json", Authorization: token};
  const headers = await fetchToken();
  console.log("headers", headers);
  const response = await axios.get(
    `${getMyFavoritesURL}?&page=${page}&perPage=${perPage}`,
    { headers }
  );
  return response;
};

export const addGigCardFavoriteAPI = async (value) => {
  const data = value.payload;
  const headers = await fetchToken();
  console.log("favoriteAPI", value.payload);
  return await axios.post(
    `${addGigCardFavorite}/${data[0]}`,
    { SourceName: data[1] },
    {
      headers,
    }
  );
};

export const removefavoriteGigCardAPI = async (value) => {
  const data = value.payload[0];
  const headers = await fetchToken();
  console.log("removefavoriteAPI", value.payload[0]);
  return await axios.delete(`${removeGigCardFavorites}/${data}`, { headers });
};

// profile Education History
export const getCollegeEducationHistoryAPI = async (key) => {
  //const headers = {"Content-Type": "application/json", Authorization: token};
  const headers = await fetchToken();
  const response = await axios.get(
    `${updateProfileUrl}/getCollegeMaster?search=${key}`,
    {
      headers,
    }
  );
  return response;
};

export const getCourseEducationHistoryAPI = async (key) => {
  //const headers = {"Content-Type": "application/json", Authorization: token};
  const headers = await fetchToken();
  const response = await axios.get(
    `${updateProfileUrl}/getCourseMaster?search=${key}`,
    {
      headers,
    }
  );
  return response;
};

export const getCourseTypeEducationHistoryAPI = async (key) => {
  //const headers = {"Content-Type": "application/json", Authorization: token};
  const headers = await fetchToken();
  const response = await axios.get(
    `${updateProfileUrl}/getCourseType?search=${key}`,
    {
      headers,
    }
  );
  return response;
};

export const postEducationHistoryAPI = async (data) => {
  console.log("Education:", data);
  //const headers = {"Content-Type": "application/json", Authorization: token};
  const headers = await fetchToken();
  // debugger;
  console.log("Education:", headers);

  const response = await axios.post(
    `${updateProfileUrl}/educationHistory`,
    data,
    {
      headers,
    }
  );
  return response;
};

export const updateEducationHistoryAPI = async (params) => {
  const headers = await fetchToken();

  const data = JSON.stringify(params[0]);

  const response = await axios.put(
    `${updateProfileUrl}/educationHistory/${params[1]}`,
    data,
    {
      headers,
    }
  );
  return response;
};

// export const fetchCompanyWorkHistoryAPI = async (key) => {
//   console.log("Work:", key);
//   //const headers = {"Content-Type": "application/json", Authorization: token};
//   const headers = await fetchToken();
//   // debugger;
//   console.log("Work:", headers);

//   const response = await axios.post(
//     `${updateProfileUrl}/companyMaster?search=${key}`,
//     {
//       headers,
//     }
//   );
//   return response;
// };

export const fetchJobTitleWorkHistoryAPI = async (key) => {
  console.log("jobTitleMaster:", key);
  const headers = await fetchToken();

  const response = await axios.get(
    `${updateProfileUrl}/jobTitleMaster?search=${key}`,
    {
      headers,
    }
  );
  return response;
};

export const fetchIndustryWorkHistoryAPI = async (key) => {
  console.log("Industry:", key);
  const headers = await fetchToken();

  const response = await axios.get(
    `${updateProfileUrl}/industriesMaster?search=${key}`,
    {
      headers,
    }
  );
  return response;
};

export const fetchLocationWorkHistoryAPI = async (key) => {
  console.log("Location:", key);
  // const headers = await fetchToken();

  const fetchLocationAPI = `${BackendApiBaseUrl}/skill/locations?location=${key}`;
  const response = await axios.get(fetchLocationAPI);
  return response;
};

export const postWorkHistoryAPI = async (data) => {
  console.log("Work:", data);
  //const headers = {"Content-Type": "application/json", Authorization: token};
  const headers = await fetchToken();
  // debugger;
  console.log("Work:", headers);

  const response = await axios.post(`${updateProfileUrl}/workHistory`, data, {
    headers,
  });
  return response;
};

export const postCertificatesAndAwardsAPI = async (data) => {
  console.log("Work:", data);
  const headers = await fetchToken();

  const response = await axios.post(`${updateProfileUrl}/certificate`, data, {
    headers,
  });
  return response;
};

export const updateCertificatesAndAwardsAPI = async (data, id) => {
  console.log("Work:", data);
  const headers = await fetchToken();

  const response = await axios.put(
    `${updateProfileUrl}/certificate/${id}`,
    data,
    {
      headers,
    }
  );
  return response;
};

export const updateWorkHistoryAPI = async (params) => {
  const headers = await fetchToken();

  const data = JSON.stringify(params[0]);

  const response = await axios.put(
    `${updateProfileUrl}/workHistory/${params[1]}`,
    data,
    {
      headers,
    }
  );
  return response;
};

// export const fetchRetractedBidsStatusAPI = async () => {
//   console.log("Status hit");

//   const headers = await fetchToken();

//   const response = await axios.get(retractedBidStatusUrl, { headers });
//   console.log("Status API hit", response);

//   return response;
// };

export const fetchRetractedBidsApi = async (
  perPage,
  page,
  sort,
  longitude,
  latitude,
  maxDistance
) => {
  const location = `latitude=${latitude}&longitude=${longitude}&maxDistance=50`;
  console.log("API ID:", maxDistance, latitude, longitude, page, perPage);
  // console.log(
  //   "RB URL:",
  //   `${fetchReceivedBidsUrl}${limit}/${skip}/${BidStatusName}/${
  //     skillListingId ? skillListingId : null
  //   }/${sortBy}/${lat && long && location}`
  // );
  const headers = await fetchToken();
  return axios.get(
    `${fetchRetractedBidsUrl}
      
    /${sort}?latitude=${latitude}&longitude=${longitude}&maxDistance=${maxDistance}
      latitude && longitude ? 50 : null
    }&page=${page}&perPage=${perPage}`,
    {
      headers,
    }
  );
};

export const fetchRetracteBidDataAPI = async (
  sort,
  lat,
  long,
  page,
  perPage
) => {
  console.log("Retractebid api hit");
  const headers = await fetchToken();
  const response = await axios.get(
    `${fetchRetractedBidData}/${sort}?perPage=${perPage}&page=${page}&longitude=${long}&latitude=${lat}&maxDistance=${lat && long ? 50 : null
    }`,
    { headers }
  );
  return response;
};

export const fetchPlaceBidDataAPI = async (
  sort,
  lat,
  long,
  page,
  perPage,
  BidStatusName
) => {
  console.log(
    "placebid api hit",
    sort,
    lat,
    long,
    page,
    perPage,
    BidStatusName
  );
  const headers = await fetchToken();
  const response = await axios.get(
    `${fetchPlaceBidData}/${sort}?latitude=${lat}&longitude=${long}&maxDistance=${lat && long ? 50 : null
    }&perPage=${perPage}&page=${page}&status=${BidStatusName}`,
    { headers }
  );
  return response;
};

export const fetchDetailPlaceBidDataAPI = async (id) => {
  console.log("placebid detail api hit", id);
  const headers = await fetchToken();
  const response = await axios.get(`${fetchBidDataUrl}/${id}`, { headers });
  return response;
};

export const fetchDetailReceivedBidDataAPI = async (id) => {
  console.log("received bid detail api hit", id);
  const headers = await fetchToken();
  const response = await axios.get(`${fetchReceivedBidsDetailUrl}/${id}`, {
    headers,
  });
  return response;
};

// InterviewCard info API
export const InterviewCardAPI = async (token) => {
  //const headers = {"Content-Type": "application/json", Authorization: token};
  const headers = await fetchToken();
  console.log("headers", headers);
  const response = await axios.get(`${fetchInterviewCardUrl}`, { headers });
  return response;
};

/********** Interview API Start **********/

export const fetchInterviewAPI = async () => {
  console.log("interview api hit");
  const headers = await fetchToken();
  const response = await axios.get(`${fetchInterviewURL}`, {
    headers,
  });
  return response;
};

/********** Interview API End **********/

export const fetchDeclinedReasonsBidsAPI = async (payload) => {
  // console.log("declined reasons api hit", payload);
  const headers = await fetchToken();
  const response = await axios.get(
    `${fetchReceivedBidsDetailUrl}/declinedstatus/${payload}`,
    {
      headers,
    }
  );
  return response;
};

export const updateDeclinedReasonsReceivedBidsAPI = async (payload) => {
  console.log("received bids api hit", payload);
  const data = JSON.stringify(payload.body);

  const headers = await fetchToken();
  const response = await axios.put(
    `${fetchReceivedBidsDetailUrl}/receivedbids/update/${payload.id}`,
    data,
    {
      headers,
    }
  );
  return response;
};

/********** Resume API End **********/

// Get Followers //
export const fetchResumeGetfollowersAPI = async (page, perPage) => {
  console.log("ResumeGetFollowers API hit");
  const headers = await fetchToken();
  const response = await axios.get(
    `${fetchResumeGetfollowersURL}?page=${page}&perPage=${perPage}`,
    { headers }
  );
  return response;
};

// Get Views //
export const fetchResumeGetviewsAPI = async (page, perPage) => {
  console.log("getView API hit");
  const headers = await fetchToken();
  const response = await axios.get(
    `${fetchResumeGetviewsURL}?page=${page}&perPage=${perPage}`,
    { headers }
  );
  return response;
};

//Get Detail //
export const fetchResumeDetailAPI = async () => {
  console.log("Resume Detail API hit");
  const headers = await fetchToken();
  const response = await axios.get(`${fetchResumeDetailURL}`, { headers });
  return response;
};

/********** Resume API End **********/

export const fetchHomePageInfoAPI = async (params) => {
  const {
    continueBrowsing_perPage,
    Favorite_perPage,
    Suggested_perPage,
    Editor_perPage,
    Most_PopularMob,
    EditorPicks,
    Suggested,
    ContinueBrowsing,
    Favorites,
    Most_Popular
  } = params.payload;
  console.log(params.EditorPicks, "home payload");
  //const headers = {"Content-Type": "application/json", Authorization: token};
  const headers = await fetchToken();
  console.log("headers", headers);
  const response = await axios.get(
    `${fetchHomePageDataURL}?editorType=${EditorPicks}&editorPage=1&editorPerPage=${Editor_perPage}&suggestType=${Suggested}&suggestPerPage=${Suggested_perPage}&suggestPage=1
    &continueBType=${ContinueBrowsing}&continueBPage=1&continueBPerPage=${continueBrowsing_perPage}&favoriteType=${Favorites}&favoritePage=1&favoritePerPage=${Favorite_perPage}
    &mostPopularType=${Most_Popular}&mostPopularPerPage=${Most_PopularMob}&mostPopularPage=1`,
    { headers }
  );
  return response;
};

// Notification API //
export const UpdateNotificationAPI = async (currentToken) => {
  const data = {
    profile: {
      notificationToken: currentToken,
    },
  };
  console.log("data", data);
  console.log("Notification API hit", currentToken);
  // const data = JSON.stringify(payload.body);

  const headers = await fetchToken();
  const response = await axios.put(`${fetchNotificationURL}`, data, {
    headers,
  });
  return response;
};

/********* Interview Update Start *********/
export const updateInterviewAPI = async (payload) => {
  console.log("interview api hit", payload);
  const data = JSON.stringify(payload.body);
  const headers = await fetchToken();
  const response = await axios.put(
    `${updateInterviewURL}/${payload.id}`,
    data,
    {
      headers,
    }
  );
  return response;
};

/********* Talent Bid Update Start *********/
export const updateReceivedBidTalentAPI = async (payload) => {
  console.log("updateRCBidsTalent api hit");
  const headers = await fetchToken();
  const response = await axios.put(`${updateReceivedBidURL}/${payload.id}`, {
    headers,
  });
  return response;
};

export const retractBidUpdateStatus = async (request) => {
  const data = JSON.stringify(request.payload);
  console.log(request.payload);
  const headers = await fetchToken();
  return await axios.put(`${retractBidUpdate}/${request.payload}`, data, {
    headers,
  });
};

//******Employer Company Details dropdowns*******/
export const getCompanyDetailsDropdownApi = async () => {
  const headers = fetchToken();
  return await axios.get(`${getCompanyDetailsDropdownUrl}`, { headers });
};


//*********Employer Search Filters API**********/
export const getFiltersAPi = async () => {
  return await axios.get(getFiltersUrl);
};
//******Employer Search Suggestions API************/
export const getSearchSuggestionsApi = async (searchKey) => {
  return await axios.get(`${getSearchSuggestionUrl}${searchKey}`);
};