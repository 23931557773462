import React, { useEffect, useState } from "react";
// //import "./BidForm.css";
import {
  Form,
  Input,
  Option,
  Alert,
  Col,
  Select,
  Spin,
  Row,
  Card,
  Image,
  Checkbox,
  Radio,
  Divider,
  Button,
} from "antd";
import {
  CloseCircleFilled,
  CheckCircleTwoTone,
  CheckCircleFilled,
  CloseCircleTwoTone,
  ConsoleSqlOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Panel from "./Panel";
import {
  createBid,
  updateBid,
  fetchBidData,
  isModalVisibleAuth,
} from "../../../redux/actions";
import { useHistory, useLocation } from "react-router-dom";
import { getCookie, setCookie } from "../../../shared/helpers";
import { useUpdateLogger } from "../../../shared/useUpdateLogger";
// import { getImageByStateCode } from "../../SearchGigs/components/cloudinary";

const BidForm = (props) => {
  var history = useHistory();
  const dispatch = useDispatch();
  const { Option } = Select;
  const { TextArea } = Input;
  const [rate, setRate] = useState(null);
  const [bill, setBill] = useState("");
  const [rateBy, setRateBy] = useState("Hourly");
  const [sgFee, setSGFEE] = useState(0);
  const [error, setError] = useState("");
  const [gigTitle, setgigTitle] = useState("");
  const [defaultValue, setDefaultValue] = useState("");

  //useState for bidInputError
  const [bidInputError, setBidInputError] = useState("");
  //useState for taxTermError
  const [taxTermError, setTaxTermError] = useState("");
  const [dimensions, setDimensions] = useState(window.innerWidth);
  //useState for messageError
  const [messageError, setMessageError] = useState("");
  const cookie = getCookie("token");

  const success = useSelector((state) => state.createBidReducers.success);
  const loading = useSelector((state) => state.createBidReducers.loading);
  const Error = useSelector((state) => state.createBidReducers.error);

  const upSuccess = useSelector((state) => state.updateBidReducers.success);
  const upLoading = useSelector((state) => state.updateBidReducers.loading);
  const upError = useSelector((state) => state.updateBidReducers.error);

  const rLoading = useSelector((state) => state.retractBidReducers.loading);
  const rError = useSelector((state) => state.retractBidReducers.error);

  const dataSuccess = useSelector((state) => state.getBidDataReducer.gigs);
  const dataLoading = useSelector((state) => state.getBidDataReducer.loading);
  const dataError = useSelector((state) => state.getBidDataReducer.error);

  //state.authReducer.isModalVisibleAuth
  const isModalVisibleAuthSelector = useSelector(
    (state) => state.authReducer.isModalVisibleAuth
  );

  // const getUser = useSelector(())

  const [message, setMessage] = useState(
    dataSuccess.length ? dataSuccess[0].Message : ""
  );
  const [currency, setcurrency] = useState("$USD");
  const [CurrencySign, setCurrencySign] = useState("")
  const [taxTerm, setTax] = useState("");
  const tax = ["1099/Corp-to-Corp", "W-2"];

  const [span, setSpan] = useState(
    "Your net pay will be different depending on factors such as how you want to be paid (w2 or 1099) and on taxes owed."
  );
  var date = Date().toLocaleString();
  var onlyNumb = /^[0-9\b]+$/;
  // const { isModalVisibleAuth, setIsModalVisibleAuth } = props;
  // const [ isModalVisibleAuth, setIsModalVisibleAuth ] = useState(false);

  const { state } = useLocation();

  //const { id, title, FacilityName, RegionName, budget } = state;
  const id = state?.id;

  useUpdateLogger("rateBy", rateBy);

  const handleChange = (value) => {
    console.log(value.substring(1));
    setcurrency(value);
    // dispatch(
    //   updateBid(
    setRate(null)
    setBill(null);
  };
  console.log("currency", currency);
  // setgigTitle(title);
  // console.log(id, title, FacilityName, budget, RegionName);
  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
  }, []);
  const saveBidToLocalStorage = () => {
    console.log("102", { state });
    //save state to local storage
    localStorage.setItem("bidData", JSON.stringify(state));
    const gigId = state?.id;
    const bidFormData = [
      gigId,
      null,
      null,
      null,
      message,
      date,
      date,
      null,
      rateBy,
      "In Progress",
      rate,
      taxTerm,
      0,
      date,
      sgFee,
      null,
      null,
      date,
      bill,
      currency.substring(1),
    ];
    localStorage.setItem("bid", JSON.stringify(bidFormData));
    localStorage.setItem("redirectTo", "/create-bid");
  };
  // let bidFromLocalStorage;
  const handleSubmit = (e) => {
    const cookie = getCookie("token");

    //multiple if  cases intaxTerm, rateBy, rate, bill are empty, then show error
    if (
      taxTerm === "" ||
      rateBy === "" ||
      rate === "" ||
      message === "" ||
      rate < 5
    ) {
      if (taxTerm === "") {
        setTaxTermError("Please choose tax term");
      }
      if (rateBy === "") {
        setBidInputError("Please add bid rate");
      }
      if (rate >= 0) {
        // setBidInputError("Minimum Bid amount is 5$");
        setBidInputError("Please add bid rate");
      }
      if (rate === "") {
        setBidInputError("Please add bid rate");
      }
      if (message === "") {
        setMessageError("Please add cover message");
      }
      return;
    }

    // if (
    //   taxTerm === "" ||
    //   rateBy === "" ||
    //   rate === "" ||
    //   bill === "" ||
    //   message === ""
    // ) {
    //   setBidInputError("Please choose a bid");
    //   setTaxTermError("Please choose a tax term");
    //   setMessageError("Please add cover message");
    //   return;
    // }

    console.log({ cookie });
    // console.log({isModalVisibleAuth});
    if (cookie) {
      // e.preventDefault();
      if (props.method == "post") {
        console.log("data sending", id);
        dispatch(
          createBid(
            id,
            null,
            null,
            null,
            // `${message}<br/>Bid Amount: ${bill}`,
            `${message}`,
            date,
            date,
            null,
            rateBy,
            // "In Progress",
            "New",
            rate,
            taxTerm,
            0,
            date,
            sgFee,
            null,
            null,
            date,
            bill,
            currency.substring(1)
          )
        );
      } else if (props.method == "put") {
        dispatch(
          updateBid(
            props.id,
            taxTerm,
            "Weekly",
            // `${message}<br/>Bid Amount : ${bill}`,
            `${message}`,
            rate,
            //"In Progress",
            "New",
            date,
            sgFee,
            bill,
            "Bid Updated",
            "View Updated Bid",
            currency.substring(1),
          )
        );
      }
    } else {
      saveBidToLocalStorage();
      // check if screensize is less than 768px push to /login
      if (window.innerWidth < 768) {
        history.push("/login");
      } else {
        dispatch(isModalVisibleAuth(true));
      }
    }
  };

  useEffect(() => {
    if (props.id && props.method == "put") {
      dispatch(fetchBidData(props.id));
    }
    setgigTitle(state?.title);
  }, []);
  useEffect(() => {
    if (props.method == "put") {
      if (dataSuccess?.Currency === "USD") {
        setCurrencySign("$USD")
      }
    }
  }, [dataSuccess?.ActualRate])
  console.log(CurrencySign, "CurrencySign")
  // useEffect(() => {
  //   debugger
  //   const { id ,title, FacilityName, RegionName, budget } =  state ;
  //   setgigTitle(state?.title);
  // }, []);

  useEffect(() => {
    if (props?.method === "put") {
      console.log("line 114", dataSuccess);
      const msgs = dataSuccess?.MessageText?.split("<br/>");
      setMessage(msgs);
      // setMessage(dataSuccess[0].Message);
      setRate(dataSuccess?.ActualRate);
      setTax(dataSuccess?.IdealTerms);
    }
  }, [dataSuccess?.Message]);
  useEffect(() => {
    console.log("line 293", { cookie });
    //if redirectTo is set then createBid
    if (localStorage.getItem("redirectTo") === "/create-bid" && cookie) {
      console.log("create trace");
      const bidFromLocalStorage = JSON.parse(localStorage.getItem("bid"));
      const id = bidFromLocalStorage[0];
      const message = bidFromLocalStorage[4];

      const rateBy = bidFromLocalStorage[8];
      const rate = bidFromLocalStorage[10];
      const taxTerm = bidFromLocalStorage[11];
      const sgFee = bidFromLocalStorage[14];
      const bill = bidFromLocalStorage[18];
      const date = bidFromLocalStorage[13];

      setBill(bill);
      setMessage(message);
      setRateBy(rateBy);
      setRate(rate);
      setTax(taxTerm);
      setSGFEE(sgFee);

      dispatch(
        createBid(
          id,
          null,
          null,
          null,
          // `${message}<br/>Bid Amount: ${bill}`,
          `${message}`,
          date,
          date,
          null,
          rateBy,
          "In Progress",
          rate,
          taxTerm,
          0,
          date,
          sgFee,
          null,
          null,
          date,
          bill,
          currency.substring(1)
        )
      );
    }
  });
  useEffect(() => {
    console.log("122 msg->", message);
    // setMessage(message);
  }, [dataSuccess]);

  useEffect(() => {
    if (success) {
      const stateLocal = JSON.parse(localStorage.getItem("bidData"));
      localStorage.removeItem("bidData");
      localStorage.removeItem("bid");
      localStorage.removeItem("redirectTo");

      if (stateLocal) {
        const { id, title, FacilityName, RegionName, budget } = stateLocal;
        // console.log({ stateLocal });
        history.push({
          pathname: "/bidsuccess",
          state: {
            id,
            taxTerm,
            message,
            rate,
            rateBy,
            title,
            RegionName,
            budget,
            currency,
          },
        });
      } else if (state) {
        const { id, title, FacilityName, RegionName, budget } = state;
        // console.log({ state });
        history.push({
          pathname: "/bidsuccess",
          state: {
            id,
            taxTerm,
            message,
            rate,
            rateBy,
            title,
            RegionName,
            budget,
            currency,
          },
        });
      }
    }

    if (upSuccess) {
      const title = dataSuccess?.gigs?.Title;
      const RegionName =
        dataSuccess?.gigs?.Location?.CityName +
        " " +
        dataSuccess?.gigs?.Location?.RegionCode;
      const budget = dataSuccess?.gigs?.BudgetMin;
      history.push({
        pathname: "/bidsuccess",
        state: {
          taxTerm,
          message,
          rate,
          title,
          RegionName,
          budget,
        },
      });

      //   // title={dataSuccess.length ? dataSuccess[0].gigs[0].Title : title}
      //   // span1={dataSuccess.length ?  : RegionName}
      //   // span2={`$ ${dataSuccess.length ? dataSuccess[0].gigs[0].BudgetMin : budget?budget:''} /wk`}
    }
  });
  const getMessage = (e) => {
    // console.log(message);
    console.log("167", e.target.value);
    setMessage(e.target.value);
    setMessageError(null);
  };

  const selectValue = (e) => {
    console.log(e);
    console.log("setting tax", e);
    setTax(e.target.value);
    setTaxTermError(null);
    if (taxTerm) {
      calculateBill();
    }
  };


  const handleInput = (e) => {
    if (!onlyNumb.test(e.target.value)) {
      setBidInputError("Not a valid number");
    }
    if (onlyNumb.test(e.target.value) || e.target.value === "") {
      setRate(e.target.value);
       
      // setShowTaxError
      setError("");
    } else if (taxTerm == "") {
      setTaxTermError("Please choose tax term");
    }
    if (e.target.value < 5) {
      setBidInputError("Minimum bid amount is $5");
    } else if (e.target.value > 4) {
      setBidInputError("");
    }
    
    // check ti
  };

  useEffect(() => {
    if (rate && onlyNumb.test(rate)) {
      calculateBill();
    }
    if (taxTerm == "W-2") {
      setSGFEE(29);
    }
    if (taxTerm == "1099/Corp-to-Corp") {
      setSGFEE(23.5);
    }
  });

  // Billed to client Formula
  // BilledToClient = ProposalBidRate / ((100 - SGFees)/100);
  // SGFees would be 23.5 if Talent selected 1099
  // SGFees would be 29 if Talent selceted W2
  const calculateBill = () => {
    if (taxTerm == "W-2") {
      var billedd = rate / ((100 - 29) / 100);
      let bill = parseFloat(billedd).toFixed(2);
      setBill(bill);
    } else if (taxTerm == "1099/Corp-to-Corp") {
      // setSGFEE(23.5);
      let billed = rate / ((100 - 23.5) / 100);
      let bill = parseFloat(billed).toFixed(2);
      setBill(bill);
    }
  };

  // useEffect(() => {
  //   if (dataSuccess.length) {
  //     try {
  //       const msgs = dataSuccess[0].Message.split('<br/>');
  //       setMessage(msgs[0]);
  //     }
  //     catch {
  //
  //     }
  //   }
  // }, [])

  const handleRateBy = (e) => {
    console.log("clicked", e.target.name);

    setRateBy(e.target.value);

    if (rate < 5 || rate == "" || rate == null) {
      setBidInputError("Minimum bid amount is $5");
      return;
    } else {
      setBidInputError("");
    }
  };

  const [form] = Form.useForm();

  const Message = (message) => {
    return message.split("<br/>")[0];
  };

  return (
    <div style={{ backgroundColor: "#f8f8f8" }}>
      <Row>
        <Col lg={14} md={24} xs={24} sm={24}>
          <div className="site-card-border-less-wrapper">
            <Card className="bid-card" lg={22} md={22} xs={22} sm={22}>
              {/* <Col sm={24}> */}
              <p className="bid-heading">{props.method === "put" ? "Edit Bid" : "Bid to Apply Job"}</p>
              <>
                <div className="bid-image-title">
                  {
                    <img
                      className="bid-image"
                      width={150}
                      height={100}
                      src={localStorage.getItem("image")}
                      alt="bid"
                    />
                  }
                  <span className="bid-job-title">
                    {" "}
                    {gigTitle ? gigTitle : null}{" "}
                  </span>

                </div>

                <p className="bid-text">
                  We're glad you found your dream job - now let's get your bid
                  submitted.
                </p>
              </>

              {loading || upLoading || dataLoading || rLoading ? (
                <div className={"bids-spin"}>
                  <Spin />
                </div>
              ) : (
                <>
                  <Form
                    form={form}
                    className="form-bid"
                    method={props.method}
                    onFinish={(e) => handleSubmit(e)}
                  >
                    {Error || upError || rError ? (
                      <>
                        <Alert
                          style={{ marginBottom: "10px" }}
                          message="Error"
                          description="Oh snap! something went wrong."
                          type="error"
                          showIcon
                        />
                        {Error || upError || rError}
                      </>
                    ) : (
                      <label></label>
                    )}
                    <Form.Item
                      validateStatus={messageError ? "error" : ""}
                      help={messageError ? messageError : null}
                      name="coverLetters"
                      className="coverLetterTextarea"
                      onChange={getMessage}
                    >
                      <TextArea
                        showCount
                        maxLength={150}
                        className="coverLetterTextarea"
                        rows={6}
                        name="coverLetters"
                        defaultValue={
                          props.method === "put" ? dataSuccess?.Message : ""
                        }
                        placeholder="Cover Message"
                        value={message}
                      />
                    </Form.Item>

                    <h4 className="billed-client tax-text">Choose Tax Term</h4>

                    <Form.Item
                      name="term"
                      style={{ width: "100%", marginBottom: "1.25rem" }}
                      validateStatus={taxTermError ? "error" : null}
                      help={taxTermError ? taxTermError : null}
                    // required={true}
                    >
                      <Radio.Group
                        onChange={selectValue}
                        value={taxTerm}
                        name="taxTerm"
                        // initialValue={taxTerm}
                        defaultValue={props.method === "put" ? dataSuccess?.IdealTerms
                          : ""
                        }
                        className="radio-group"
                      >
                        <Radio
                          className="radio-styles"
                          value="1099/Corp-to-Corp"
                          style={
                            taxTermError ? { border: " solid 1px #db2828" } : {}
                          }
                        >
                          1099/Corp-to-Corp
                        </Radio>

                        <Radio
                          value="W-2"
                          className="radio-styles"
                          style={
                            taxTermError ? { border: " solid 1px #db2828" } : {}
                          }
                        >
                          W-2
                        </Radio>
                      </Radio.Group>
                    </Form.Item>

                    <h4 className="billed-client">Your Bid</h4>
                    <div className="rate-group">
                      <Form.Item name="termrates">
                        <Radio.Group
                          onChange={handleRateBy}
                          className="radio-rate-group"
                          name="rateBy"
                          defaultValue={props.method === "put" ? dataSuccess?.BudgetType : rateBy}
                          value={rateBy}
                        >
                          <Radio className="radio-styles-rate" value="Hourly">
                            Hourly
                          </Radio>

                          <Radio className="radio-styles-rate" value="Weekly">
                            Weekly
                          </Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item
                        className="rate-field1"
                        required={true}
                      // validateStatus={bidInputError ? "error" : null}
                      >
                        <div className="select-currency" >
                          <Select defaultValue={props.method === "put" ?
                            (dataSuccess?.Currency === "USD" ? "$USD" :
                              dataSuccess?.Currency === "INR" ? "₹INR" :
                                dataSuccess?.Currency === "PESO" ? "₱PESO" : "$CAD") : "$USD"} onChange={handleChange}
                          >
                            <Option className="select-currency-options" value="$USD">USD - $</Option>
                            <Option className="select-currency-options" value="₹INR">INR - ₹</Option>
                            <Option className="select-currency-options" value="₱PESO">PESO - ₱</Option>
                            <Option className="select-currency-options" value="$CAD">CAD - $</Option>
                          </Select>

                          <Row>
                            <Input
                              // placeholder={currency.substring(0,1)}
                              prefix={currency.substring(0, 1)}
                              name="termrate"
                              onChange={handleInput}
                              className="rate-field"
                              // style={bidInputError ? { color: "#db2828" } : {}}
                              //defaultValue={dataSuccess?.Rate}
                             value={rate}
                            />
                            {/* {console.log("input data" ,dataSuccess?.Rate , currency )} */}
                          </Row>
                        </div>
                      </Form.Item>
                    </div>
                    <div className="rate-spans">
                      <Form.Item
                        validateStatus={bidInputError ? "error" : null}
                        help={bidInputError ? bidInputError : null}
                      >
                        <span
                          className="rate-span"
                          style={bidInputError ? { color: "#db2828" } : {}}
                        >
                          {span}
                        </span>
                      </Form.Item>
                    </div>
                    {
                      error ? (
                        <label
                          style={{
                            color: "red",
                            marginLeft: "2px",
                            marginTop: "2px",
                          }}
                        >
                          {error}
                        </label>
                      ) : null
                    }
                    <Form.Item>
                      <h4
                        className="billed-client"
                        style={
                          window.innerWidth > 756 ? { marginTop: "1rem" } : {}
                        }
                      >
                        Billed to Client
                      </h4>
                      {/* <div className="grid-amount"> */}
                      <Row>
                        <Col lg={12} md={12} sm={24} xs={24}>
                          {bill ? (
                            <Input
                              prefix={currency.substring(0, 1)}
                              className="bill-amount"
                              disabled
                              value={bill}
                            // defaultValue={dataSuccess?.ActualRate}
                            />
                          ) : (
                            (props?.method === "put" ? (<Input
                              className="bill-amount"
                              prefix={currency.substring(0, 1)}
                              disabled
                              defaultValue={dataSuccess?.ActualRate}
                            />) :
                              (<Input
                                className="bill-amount"
                                prefix={currency.substring(0, 1)}
                                disabled
                                value={"0.00"}
                              />)
                            )
                          )}
                        </Col>
                        {/* </div> */}
                      </Row>
                    </Form.Item>
                    <Divider className="bid-divider" />
                    <div className="bid-btn-div">
                      {/* <Row justify="end">
                  <Col span={6}> */}
                      <Form.Item>
                        <Button
                          type="default"
                          htmlType="submit"
                          className="bid-btn-form"
                        >
                          {props.method === "put" ? "Update Bid" : "Send Bid"} {bill ? `(${currency.substring(0, 1)} ${bill})` : (props.method === "put" ? `(${dataSuccess?.ActualRate})` : "")}
                        </Button >
                      </Form.Item >
                      {/* </Col>
                </Row> */}
                    </div >
                  </Form >
                </>
              )}
              {/* </Col> */}
            </Card >
          </div > {" "}
        </Col >
      </Row >
    </div >
  );
};
export default BidForm;
