import { types } from "../action-types";

const initialState = {
  myFavorites: [],
  Loading: false,
  Error: {},
  removeFavorites: [],
}

const myFavoritesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REMOVE_FAVORITE_GIG_CARD:
      return {
        ...state,
        loading: true,
      };
    case types.REMOVE_FAVORITE_GIG_CARD_SUCCESS:
      console.log("REMOVE_FAVORITE_GIG_CARD_SUCCESS", state.myFavorites,action.payload);
      return {
        ...state,
        myFavorites: state.myFavorites.filter((item, i) => action.payload._id !== item._id),
      };
    case types.FAVORITE_GIG_CARD:
      return {
        ...state,
        loading: true,
      };

    case types.GET_FAVORITES_INFO_REQUEST:
      return {
        ...state,
        Loading: true,
      }
    case types.GET_FAVORITES_INFO_SUCCESS:
      console.log("GET_FAVORITES_INFO_SUCCESS", action.payload);
      return {
        ...state,
        Loading: false,
        myFavorites: action.payload.body.data,
        Error: {},
      }
    case types.GET_FAVORITES_INFO_FAILURE:
      return {
        ...state,
        Loading: false,
        Error: action.error,
      }
    default:
      return state;
  }
};

export default myFavoritesReducer;
