import React, { useState, useEffect } from "react";
import { Menu, Select, Radio, Tag, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
//import "./ExperienceFilter.css";

const ExperienceLevel = (props) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  //const [check, setCheck] = useState();
  const filters = useSelector(
    (state) => state.searchSkillListingReducer.filters
  );
  const count = useSelector(state => state.searchSkillListingReducer.skillListings);

  const onExperienceRadioClicked = (e) => {
    //setCheck(e.target.value);
    props.setcheck(e.target.value);
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Radio.Group
      style={{ width: "100%" }}
      onChange={(e) => onExperienceRadioClicked(e)}
      value={props.check}
    >
      <Space direction="vertical" style={{ display: "flex" }}>
      {windowDimensions.width < 769 ?
            <>
                     <Radio className="radio-btns" value={"Less than 1 year"}>Less than 1 year</Radio>
                     <Radio className="radio-btns" value={"1-2 years"}>1-2 years</Radio>
                     <Radio className="radio-btns" value={"3-5 years"}>3-5 years</Radio>
                     <Radio className="radio-btns" value={"6-10 years"}>6-10 years</Radio>
                     <Radio className="radio-btns" value={"More than 10 years"}>More than 10 years</Radio>
                     </>
               : 
               
                        <>
                           <Radio
                              className="radio-btns-desktop"
                              // onClick={(e) => onExperienceRadioClicked(e)}
                              value={"Less than 1 year"}
                           >
                              Less than 1 year <div style={{display: "inline", color: "#7e7e7e" ,}} >{"("}{count.skillListings?.skilllistings?.experienceCount[0]?.count}{")"}</div>
                           </Radio>
                           <Radio
                              className="radio-btns-desktop"
                              // onClick={(e) => onExperienceRadioClicked(e)}
                              value={"1-2 years"}
                           >
                              1-2 years <div style={{display: "inline", color: "#7e7e7e" ,}} >{"("}{count.skillListings?.skilllistings?.experienceCount[1]?.count}{")"}</div>
                           </Radio>
                           <Radio
                              className="radio-btns-desktop"
                              // onClick={(e) => onExperienceRadioClicked(e)}
                              value={"3-5 years"}
                           >
                              3-5 years <div style={{display: "inline", color: "#7e7e7e" ,}} >{"("}{count.skillListings?.skilllistings?.experienceCount[2]?.count}{")"}</div>
                           </Radio>
                           <Radio
                              className="radio-btns-desktop"
                              // onClick={(e) => onExperienceRadioClicked(e)}
                              value={"6-10 years"}
                           >
                              6-10 years <div style={{display: "inline", color: "#7e7e7e" ,}} >{"("}{count.skillListings?.skilllistings?.experienceCount[3]?.count}{")"}</div>
                           </Radio>
                           <Radio
                              className="radio-btns-desktop"
                              // onClick={(e) => onExperienceRadioClicked(e)}
                              value={"More than 10 years"}
                           >
                              More than 10 years <div style={{display: "inline", color: "#7e7e7e" ,}} >{"("}{count.skillListings?.skilllistings?.experienceCount[4]?.count}{")"}</div>
                           </Radio>
                        </>
}
      </Space>
    </Radio.Group>
  );
};
export default ExperienceLevel;
