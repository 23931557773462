import { types } from "../action-types";

// import initialState from './appReducer';
const initialState = {
  loading: false,
  skillListings: [],
  error: {},
  searchvalue: "",
  filters: [],
  sugesstions: []
};

const searchSkillListingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEARCH_LISTING_INIT:
      return {
        ...state,
        loading: action.payload,
      };
    case types.SEARCH_LISTING_SUCCESS:
      console.log("Search SKILL listing payload ", action);
      return {
        ...state,
        loading: false,
        skillListings: { ...state.skillListings, skillListings: action.payload },
        error: {},
      };
    case types.SEARCH_LISTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.SEARCH_SKILL_VALUE:
      return {
        ...state,
        searchvalue: action.payload,
      };
    case types.GET_FILTERS_SUCCESS:
      return {
        ...state,
        filters: action.payload,
      };
    case types: SEARCH_SUGGESTIONS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case types.SEARCH_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        sugesstions: action.payload,
        error: false,
      };
    case types.SEARCH_SUGGESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        sugesstions: [],
        error: true,
      };
    default:
      return state;
  }
};

export default searchSkillListingReducer;