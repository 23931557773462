import { types } from "../action-types";

const initialState = {
    inboxLoading: false,
    sentItemsLoading: false,
    sendMessageLoading: false,
    sendMessageSuccess: false,
    inbox: [],
    sentItems: [],
    inboxError: {},
    sentItemsError: {},
    sendMessageError: {},
    inboxData:[],
    inboxDataParameters:[]
};

const messagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_INBOX_DATA_SUCCESS:
           // console.log("INBOX REDUCER PAYLOAD:", action.payload)
            return {
                ...state,
            inboxData: action.payload.data.body,
            inboxDataParameters:action.payload
            };

        case types.FETCH_MESSAGES_REQUESTED:
            return {
                ...state,
                inboxLoading: action.payload.type === 'inbox' ? true : state.inboxLoading,
                sentItemsLoading: action.payload.type === 'sentItems' ? true : state.sentItemsLoading,
            };

        case types.FETCH_MESSAGES_SUCCESS:
            return {
                ...state,
                inboxLoading: action.payload.type === 'inbox' ? false: state.inboxLoading,
                sentItemsLoading: action.payload.type === 'sentItems' ? false: state.sentItemsLoading,
                inbox: [...(action.payload.type === 'inbox' ? action.payload.data : state.inbox)],
                sentItems: [...(action.payload.type === 'sentItems' ? action.payload.data : state.sentItems)],
            };
        case types.FETCH_MESSAGES_FAILURE:
            return {
                ...state,
                loading: false,
                sentItems: action.payload.type === 'sentItems' ? [] : state.sentItems,
                inbox: action.payload.type === 'inbox' ? [] : state.inbox,
                sentItemsError: action.payload.type === 'sentItems' ? action.payload.error : state.sentItemsError,
                inboxError: action.payload.type === 'inbox' ? action.payload.error : state.inboxError,
            };
        case types.SEND_MESSAGE_REQUESTED:
            return {
                ...state,
                sendMessageLoading: true,
                sendMessageSuccess: false,
                sendMessageError: {},
            }
        case types.SEND_MESSAGE_ATTACHMENT_REQUESTED:
            return {
                ...state,
                sendMessageLoading: true,
            }
        case types.SEND_MESSAGE_SUCCESS:
            return {
                ...state,
                sendMessageLoading: false,
                sendMessageSuccess: true,
                sendMessageError: {},
            }
        case types.SEND_MESSAGE_FAILURE:
            return {
                ...state,
                sendMessageLoading: false,
                sendMessageSuccess: false,
                sendMessageError: action.error,
            }
        default:
            return state;
    }
};

export default messagesReducer;
