import React from "react";
import { Button, Col, Row } from "antd";
//import "./BidtoApply.css";
const FormBtn = () => {
  return (
    <Row className="buttons-row">
      {/* <Col lg={{ span: 12 }} xs={12} md={12}>
                <Button type="default" className="cancel-btn">Cancel</Button>
            </Col> */}
      <Col lg={1} md={1} xs={1}></Col>
      <Col lg={{ span: 11 }} xs={11} md={11}>
        <a href="/bidsuccess">
          <Button type="default" htmlType="submit" className="bid-btn">
            Bid to Apply
          </Button>
        </a>
      </Col>
    </Row>
  );
};
export default FormBtn;
