import React, { useEffect, useState } from "react";
// import { connect } from "react-redux";
import SocialOptions from "./SocialOptions";
import SignUp from "./SignUp";
import { useSelector, useDispatch } from "react-redux";

import Login from "./Login";
//import "./SocialOptions.css";

// Antd CSS
//import "antd/dist/antd.css";
import { checkEmailFlow, signUpLoginUpdate } from "../../../redux/actions";

// import Amplify, { Auth, Hub } from "aws-amplify";

function SignUpLogin() {
  const dispatch = useDispatch();
  const [signinClick, setSigninClick] = useState(false);

  const emailForward = useSelector((state) => state.authReducer.emailCheck);
  const signUpLogin = useSelector((state) => state.authReducer.signUpLogin);

  const switchToLoginSignUp = () => {
    dispatch(checkEmailFlow(false));
    dispatch(signUpLoginUpdate(!signUpLogin));
    setSigninClick(true);
  };

  return (
    <div className="authorizationSkill">
      <h1 className="topHeading">
        {signUpLogin ? "Join Skillgigs" : "Sign In to Skillgigs"}
      </h1>
      {!emailForward && (
        <>
          <div>
            {/* <h1 className="topHeading">Join Skillgigs</h1> */}
            <SocialOptions />
            <div className="dividerWidth">
              <h5 className="divider">OR</h5>
            </div>
          </div>
        </>
      )}
      {signUpLogin ? (
        <>
          <SignUp signinClick={signinClick} />
        </>
      ) : (
        <Login />
      )}
      <div className="authDivider"></div>
      <div className="accountAlready">
        {signUpLogin ? (
          <>
            Already have an account?
            <span className="accountSignIn" onClick={switchToLoginSignUp}>
              {` Sign In`}
            </span>
          </>
        ) : (
          <>
            Not a member yet?
            <span className="accountSignIn" onClick={switchToLoginSignUp}>
              {` Join Now`}
            </span>
          </>
        )}
      </div>
    </div>
  );
}

export default SignUpLogin;

// const mapStateToProps = (state) => ({});

// const mapDispatchToProps = {};

// export default connect(mapStateToProps, mapDispatchToProps)(SocialLogin);
