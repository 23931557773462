import React, { useEffect, useState, useRef } from "react";
import { IoLocationSharp, IoBriefcase } from "react-icons/io5";
import { BsCurrencyDollar, BsStarFill } from "react-icons/bs";
import { FaDollarSign } from "react-icons/fa";
import { HiClock } from "react-icons/hi";
import { HeartFilled, StarOutlined } from "@ant-design/icons";
import { Rate } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { alertNotification } from "../../components/notifications/alertNotification";

import "./GigsCard.css";
import {
  createBidReset,
  // favoritefunctiontrigger,
  // favoriteGigCard,
  isModalVisibleAuth,
  postFavoriteGigCard,
  removeFavoriteGigCard,
  removeFavoriteGigCardReducer,
  removeFavoriteGigCardSuccess,
  ResetBidData,
  retractBidReset,
  retractedBidUpdate,
  updateBidReset,
  UpdateSearchFilter,
} from "../../redux/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { getImageByStateCode } from "./GuestUser/components/cloudinary";
import moment from "moment";

import messageIcon from "../../assets/icons/messageIcon.svg";
import StageBar from "../../components/StageBar/StageBar";
import { state } from "@antv/g2plot/lib/adaptor/common";

const bidsData = [
  {
    bidReceived: "4100/wk",
    title: "RN - ICU Nurse",
    facility: "Kindred Hospital, Central Tampa, ...",
    gigTitle: "Del Sol Medical Center El Paso",
    location: "Pittsburgh, PA",
    gigRate: "4000/wk",
  },
  {
    bidReceived: "4200/wk",
    title: "RN - ICU Nurse",
    facility: "Kindred Hospital, Central Tampa, ...",
    gigTitle: "Del Sol Medical Center El Paso",
    location: "Pittsburgh, PA",
    gigRate: "4000/wk",
  },
  {
    bidReceived: "4300/wk",
    title: "RN - ICU Nurse",
    facility: "Kindred Hospital, Central Tampa, ...",
    gigTitle: "Del Sol Medical Center El Paso",
    location: "Pittsburgh, PA",
    gigRate: "4000/wk",
  },
];

// const screenWidth = window.screen.width;

const GigsCard = (props) => {
  const gig = props.data;
  console.log("gig", gig?.IsFavorite);
  const bid = props.bid;
  const budgetType = props.budgetType;
  const history = useHistory();
  const dispatch = useDispatch();
  const locationURL = useLocation().pathname;
  const [placeholder, showPlaceholder] = useState(false);
  const [budgetHourly, setBudgetHourly] = useState(false);
  const [dimensions, setDimensions] = useState(window.innerWidth);
  const { isModalVisibleAuth, setIsModalVisibleAuth } = props;
  const [IsFavoriteStatus, setIsFavoriteStatus] = useState("0");
  const authTokenValue = useSelector(
    (state) => state.authReducer.user.authToken
  );
  const IsFavoritesValuetrigger = useSelector(
    (state) => state.searchGigsReducer.IsFavoritesValue
  );
  // const ProfessionCode = useSelector(
  //   (state) => state.profileReducer.user.profile.ProfessionCode
  // );
  const ProfessionCode = gig?.ProfessionCode;
  const [stageBids, setStageBids] = useState(0);

  const PlacedBid = () => {
    dispatch(createBidReset());
    dispatch(updateBidReset());
    dispatch(ResetBidData());

    history.push({
      pathname: `/create-bid`,
      state: {
        title: gig.Title,
        FacilityName: gig.FacilityName,
        RegionName: gig.Location.Location,
        budget: gig.BudgetMin,
        id: gig._id,
      },
    });
  };
  const detailsPage = () => {
    dispatch(retractedBidUpdate(false));
    history.push({
      pathname: `/details/${gig._id}`,
      state: {
        IsFavoritesdata: gig?.IsFavorite,
        id: gig?._id,
      },
    });
  };
  // const [dimensions, setDimensions] = useState(window.innerWidth);

  // const checkDimensions = () => {
  //   setDimensions(window.innerWidth);
  // };

  const onHeartClick = (e) => {
    console.log("gig_id", e, gig._id);
    if (dimensions < 769) {
      e.stopPropagation();
      history.push("/login");
    } else {
      e.stopPropagation();
      setIsModalVisibleAuth(true);
    }
  };

  useEffect(() => {
    if (gig?.IsFavorite === false) {
      setIsFavoriteStatus("0");
      console.log(gig?.IsFavorite, IsFavoriteStatus, "gig.IsFavorite");
    } else {
      setIsFavoriteStatus("1");
      console.log(gig?.IsFavorite, IsFavoriteStatus, "gig.IsFavorite1");
    }
  }, [gig?.IsFavorite]);
  console.log(IsFavoriteStatus, "IsFavoriteStatus");
  // const dataToBeRendered = () => {
  //   if (dimensions <= 600) {
  //     return bidsData.filter((data, i) => i === 0);
  //   } else return bidsData;
  // };

  // Gig created on
  let msec = Number(gig?.Created);
  let time = new Date(msec);

  // console.log(gig);
  let year = time.getFullYear();
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let month = months[time.getMonth()];
  let date = time.getDate();
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let day = days[time.getDay()];
  const dates = { day, date, month, year }.toString();

  // Gig Expires on
  let expmsec = Number(gig?.ExpiryDate);
  let exptime = new Date(expmsec);
  let expyear = exptime.getFullYear();
  let expmonth = months[exptime.getMonth()];
  let expdate = exptime.getDate();
  let expday = days[exptime.getDay()];
  const expdates = { expday, expdate, expmonth, expyear }.toString();

  useEffect(() => {
    console.log("location: ", locationURL);
  }, [locationURL]);

  useEffect(() => {
    console.log(bid);
    if (budgetType === "Hourly") {
      setBudgetHourly(true);
    } else {
      setBudgetHourly(false);
    }
  }, [gig, budgetType, onHeartClick]);

  // const budget = () => {
  //   return(
  //     {budgetType ? (<><span>${gig?.BudgetMin ? Number(gig?.BudgetMin) * 40 : 0}/WK</span></>) : (<><span>${gig?.BudgetMin ? gig?.BudgetMin : 0}/HR</span></>)}

  //     )
  // };

  const AfterLoginonHeartClick = (e) => {
    if (gig?.IsFavorite === false) {
      console.log(gig?.IsFavorite, "gig?.IsFavorite");
      e.stopPropagation();
      alertNotification("success", "Job added to favorites");
      dispatch(postFavoriteGigCard(gig?._id, "Gig", props, gig));
    } else if (gig?.IsFavorite === true) {
      e.stopPropagation();
      dispatch(removeFavoriteGigCard(gig?._id, props));
      if (locationURL.substr(0, 7) === "/search") {
        dispatch(removeFavoriteGigCardReducer(gig?._id));
      }
      console.log(gig?.IsFavorite, "gig?.IsFavorite removed");
      alertNotification("success", "Job removed from favorites");
    }
  };
  console.log(props.Favorites, "props");
  return (
    <React.Fragment>
      <div
        className="sgigs-thecard"
        onClick={() => {
          detailsPage();
        }}
      >
        {placeholder ? (
          <img
            src={getImageByStateCode(
              gig?.Location?.RegionCode
              // gig?.Location?.CityName
            )}
            className="Guest_sgigs-image"
          />
        ) : (
          <img
            src={getImageByStateCode(
              // gig?.Location?.RegionCode,
              gig?.Location?.CityName
            )}
            className="Guest_sgigs-image"
            onError={(e) => showPlaceholder(true)}
          />
        )}
        <div className="sgigs-upAbove">
          <div className="sgigs-theTitle">
            {gig?.Title ? gig?.Title : "NA"}
          </div>
          <div className="sgigs-theFacility">
            {ProfessionCode === "HC"
              ? gig?.FacilityName
                ? gig?.FacilityName
                : "NA"
              : gig?.WorkplacePolicy
              ? gig?.WorkplacePolicy
              : "NA"}
          </div>
          <div className="sgigs-information">
            {/* <Rate count={1} disabled={true} value={1} /> */}
            {ProfessionCode === "HC" ? (
              <div className="information-left-outer">
                <div className="information-left">
                  <BsStarFill className="sgigs-star" />
                </div>
                <div className="information-right">
                  <div className="sgigs-rating">{gig?.Rating}</div>
                  <div className="review">({gig?.Review})</div>
                </div>
              </div>
            ) : (
              <div className="sgigs-IT-Experience">
                {gig?.ExperienceLevelName}
              </div>
            )}
            <div className="sgigs-review">
              <div className="posted-on">
                {moment({ time }).fromNow()}
              </div>
            </div>
          </div>

          <div className="dividerGigCard"></div>
          <div className="sgigs-gigcard-interestedBtn">
            <div className="sgigs-Budgets">
              {ProfessionCode === "HC" ? (
                budgetHourly ? (
                  <>
                    <span>${gig?.BudgetMin ? gig?.BudgetMin : 0}/HR</span>
                  </>
                ) : (
                  <>
                    <span>${gig?.BudgetMin ? gig?.BudgetMin : 0}/WK</span>
                  </>
                )
              ) : null}

              {/* ${gig?.BudgetMin ? Number(gig?.BudgetMin) * 40 : 0}/wk */}
            </div>
            {/* <div className="sgigs-div1">Pay Package</div> */}
            {/* <div className="sgigs-div2" onClick={PlacedBid}>
                $ Place a Bid
              </div> */}
            {/* {locationURL === "/receivedBids" ? (
              <div className="messageIcon">
                <img src={messageIcon} alt="messageIcon" />
              </div>
            ) : ( */}
            <div
              onClick={
                authTokenValue === null
                  ? (e) => onHeartClick(e)
                  : (e) => AfterLoginonHeartClick(e)
              }
              style={{ cursor: "pointer" }}
            >
              {locationURL === "/myfavorites" ||
              props.Favorites === "Favorites" ? (
                <Rate
                  style={{ color: "red" }}
                  character={<HeartFilled />}
                  count={1}
                  allowClear
                  defaultValue={gig?.IsFavorite === true ? 1 : 0}
                  disabled={true}
                />
              ) : (
                <Rate
                  style={{ color: "red" }}
                  character={<HeartFilled />}
                  count={1}
                  allowClear
                  value={gig?.IsFavorite === true ? 1 : 0}
                  disabled={authTokenValue === null ? true : false}
                />
              )}
            </div>
            {/* )} */}
          </div>
          {ProfessionCode === "HC" ? (
            <div className="sgigs-div1">Pay Package</div>
          ) : null}
        </div>
      </div>
      {/* ); */}
    </React.Fragment>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setIsModalVisibleAuth: (params) => {
      dispatch(isModalVisibleAuth(params));
    },
  };
};

export default connect(null, mapDispatchToProps)(GigsCard);
