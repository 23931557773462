import { types } from "../action-types";

const initialState = {
    loading: false,
    chats: [],
    // chat:{},
    error: false,
};

const getChatHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_CHAT_REQUESTED:
            return {
                ...state,
                loading: true,
            };

        case types.FETCH_CHAT_SUCCESS:
            return {
                ...state,
                loading: false,
                chats: action.payload,
                error: false,
            };
        case types.FETCH_CHAT_FAILURE:
            return {
                ...state,
                loading: false,
                chats: [],
                error: true,
            };
        // case types.MESSAGE_RECEIVED: {
        //     console.log("s:"+JSON.stringify(action.payload.message));
        //     return { 
        //         ...state,
        //         chats: [action.payload.message, ...state.chats]
        //     };
        // };
        case types.SEND_MESSAGE_SUCCESS:{
            console.log(JSON.stringify(action.payload));
            return{
                ...state,
                chats:[{...action.payload.data, SenderUser: [{name:action.payload.name,
                profile:{PictureUrl:action.payload.ProfilePic}
                }]}, ...state.chats]
            }
        }
        default:
            return state;
    }
};

export default getChatHistoryReducer;
