import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Radio, Space, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
//import "./location.filter.css";
import axios from "axios";
import {
  fetchKeySearchLocation,
  searchLocation,
} from "../../../../redux/actions";
import { debounce } from "../../../../shared/helpers";

const FilterLocation = (props) => {
  const dispatch = useDispatch();
  const [locationValue, setLocationValue] = useState([]);
  console.log(locationValue);
  const [searchtext, setSearch] = useState();
  const filters = useSelector(
    (state) => state.searchSkillListingReducer.filters
  );
  const locations = useSelector(
    (state) => state.searchSkillListingReducer.locations
  );
  console.log("locations", locations);

  useEffect(() => {
    var options = [];
    if (filters && filters.location) {
      for (var i = 0; i < filters.location.length; i++) {
        options = [
          ...options,
          {
            value: filters.location[i].LocationWithCountry,
          },
        ];
      }
      setLocationValue(options);
    }
  }, []);

  useEffect(() => {
    var options = [];
    if (locations?.length > 0) {
      for (var i = 0; i < locations?.length; i++) {
        options = [
          ...options,
          {
            value: locations[i].LocationWithCountry,
          },
        ];
      }
      setLocationValue(options);
    }
  }, [locations]);

  const onChange = (e) => {
    //setCheck(e.target.value);
    props.setcheck(e.target.value);
    //props.setFilter({location: e.target.value});
    if (props.mobileFilter) {
      props.setVisible(false);
    }
  };

  const onLocationSearch = (value) => {
    dispatch(fetchKeySearchLocation(value));
    // var options = []
    // for (var i = 0; i < location.length; i++) {
    //    console.log('location:', location[i].LocationWithCountry);
    //    options = [...options,
    //    {
    //       value: location[i].LocationWithCountry
    //    }]
    // }
    // setLocationValue(options);
    // console.log(e.target.value);
    // var search = locationValue.filter(item =>
    //    item.value.toLowerCase().includes(
    //       e.target.value.toLowerCase()
    //    )
    // )
    // console.log("Search: ", search);
    // setSearch(search);
  };

  return (
    <div>
      <Input
        type="text"
        onChange={debounce((e) => onLocationSearch(e.target.value))}
        className="filter-search-input"
        placeholder="Search City / State"
        prefix={<SearchOutlined />}
      />
      <Radio.Group
        onChange={onChange}
        className="location-radios-grp"
        value={props.check}
      >
        <Space direction="vertical">
          {!searchtext
            ? locationValue.map((items) => {
                return (
                  <Radio className="location-radio" value={items.value}>
                    {items.value}
                  </Radio>
                );
              })
            : searchtext.map((items) => {
                return (
                  <Radio value={items.value} className="location-radio">
                    {items.value}
                  </Radio>
                );
              })}
        </Space>
      </Radio.Group>
    </div>
  );
};
export default FilterLocation;
