import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  addGigCardFavoriteAPI,
  myFavoritesInfoAPI,
  removefavoriteGigCardAPI,
} from "../../API/API";
import { types } from "../action-types";
import {
  favoriteBidUpdate,
  getFavoritesInfoFailure,
  getFavoritesInfoSuccess,
  HomepageFavorite,
  HomepageFavoriteRemove,
  HomepageFavoriteUpdate,
  postFavoriteGigCardFailure,
  postFavoriteGigCardSuccess,
  removeFavoriteGigCardFailure,
  removeFavoriteGigCardReducer,
  removeFavoriteGigCardSuccess,
} from "../actions";

function* myFavoriteSaga(params) {
  console.log("my favorites:", params);

  try {
    const { page, perPage } = params.payload;
    const response = yield call(() => myFavoritesInfoAPI(page, perPage)); // API Backend
    console.log("FAVORITES RESPONSE:", response.data.data);
    if (response.status === 200) {
      yield put(getFavoritesInfoSuccess(response.data.data));
    } else {
      yield put(getFavoritesInfoFailure(response.data.data.body));
    }
  } catch (error) {
    console.log(error);
    yield put(getFavoritesInfoFailure(error));
  }
}

// Add to favorite

function* favoriteGigCardSaga(params) {
  console.log(params.payload[2].data._id, "favoriteGigCardSag");
  try {
    const response = yield call(() => addGigCardFavoriteAPI(params)); // API Backend
    console.log("favoriteGigCardSagaResponse", response);
    if (response.status === 200) {
      yield all([
        // put(removeFavoriteGigCardReducer(params?.payload[2]?.data?._id)),
        put(postFavoriteGigCardSuccess(params?.payload)),
        put(HomepageFavorite(params?.payload)),
        put(HomepageFavoriteUpdate(params))
      ]);
    } else {
      yield put(postFavoriteGigCardFailure(response));
    }
    // yield console.log("saga HIT hey2");
    // if (params.payload[1] === "Gig") return;
  } catch (error) {
    yield put(postFavoriteGigCardFailure(error));
  }
}

// Remove from favorite

function* RemovefavoriteGigCardSaga(params) {
  console.log(params, "favoriteGigCardSag remove");
  try {
    const response = yield call(() => removefavoriteGigCardAPI(params)); // API Backend
    console.log("favoriteGigCardSag:", params);
    if (response.status == 200) {
      yield all([
        put(removeFavoriteGigCardSuccess(response.data.favorite)),
        put(
          favoriteBidUpdate({
            id: params.payload[0],
            type: params.payload[1],
            stage: params?.payload[2],
          }),
        ),
        put(HomepageFavoriteRemove(params))
      ]);
    } else {
      yield put(removeFavoriteGigCardFailure(response));
    }
  } catch (error) {
    yield put(removeFavoriteGigCardFailure(error));
  }
}

export function* watchMyFavoriteSaga() {
  yield takeEvery(types.GET_FAVORITES_INFO_REQUEST, myFavoriteSaga);
}

// Add to favorite
export function* watchFavoriteGigCard() {
  yield takeEvery(types.FAVORITE_GIG_CARD, favoriteGigCardSaga);
}

// Remove from favorite
export function* watchRemoveFavoriteGigCard() {
  yield takeEvery(types.REMOVE_FAVORITE_GIG_CARD, RemovefavoriteGigCardSaga);
}
