import { call, put, takeEvery } from "redux-saga/effects";
import { types } from "../action-types";
import { fetchRetracteBidDataAPI, updateBidApi } from "../../API/API";
import { retractedBidFetchFailure, retractedBidFetchSuccess } from "../actions";

function* retractBidSaga(ProposalStatus,id,SenderLabels,RecipientLabels,Message) {
    // console.log("param:",JSON.stringify(status,date));
    try {
        const response = yield call(updateBidApi, ProposalStatus,id,SenderLabels,RecipientLabels,Message); 
        yield put({type: types.RETRACT_BID_SUCCESS, payload: response.data});
      }
      catch (error) {
        yield put({type: types.RETRACT_BID_FAILURE, error});
    }
}


  

export function* watchRetractBidSaga() {
    yield takeEvery(types.RETRACT_BID_REQUESTED, retractBidSaga);
}

