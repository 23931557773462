import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

//import "./TalentEmployer.css";

import Logo from "../../assets/icons/skillgigs_black_logo.svg";
import talent from "../../assets/img/becomeACandidate.png";
import employer from "../../assets/img/findACandidate.png";
import { fetchUserProfile, updateProfile } from "../../redux/actions";
import { getCookie } from "../../shared/helpers";

const TalentEmployer = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.profileReducer.user);
  // useEffect(() => {
  //   console.log("USER: ", user);
  // }, [user]);

  const talentEmpUpdate = (code, role) => {
    const profile = {
      AccountTypeCode: code,
      Role: role,
    };
    dispatch(
      updateProfile({
        userId: user.id,
        profile,
      })
    );
    if (code === "EMP") {
      window.location.replace("/auth");
      // window.location.replace('/employerprofession')
    } else {
      window.location.replace("/talent");
    }
  };

  return (
    <div className="TalentEmployerPage">
      <div className="TalentEmployer">
        <div className="sectionTL">
          <div className="headerTL">
            <div className="logoTL">
              <img src={Logo} alt="skillgigs_black_logo" />
            </div>
            <div className="subHeaderTL">
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s
            </div>
          </div>
          <div className="chooseTalentorNot">
            <div
              className="becomeACandidateBOX"
              onClick={() => talentEmpUpdate("TAL", ["CONS"])}
            >
              <img src={talent} alt="skillgigs_black_logo" />
              <div className="becomeACandidate">Become a Candidate</div>
            </div>
            <div
              className="findACandidateBOX"
              onClick={() => talentEmpUpdate("EMP", ["CLIE"])}
            >
              <img src={employer} alt="skillgigs_black_logo" />
              <div className="findACandidate">Find a Candidate</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TalentEmployer;
