import React, { useState } from "react";
//import "./Sidebar.css";
import { IoChevronBackSharp } from "react-icons/io5";
import { Auth } from "../../../config/aws-exports";
import { getCookie, setCookie } from "../../../shared/helpers";
import { updateUserInfo } from "../../../redux/actions";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

const Sidebar = (props) => {
  const history = useHistory();

  const onLogOut = async () => {
    try {
      // .then(data => console.log(data))
      // .catch(err => console.log(err));
      console.log("clicked");
      setCookie("token", "", false);
      setCookie("RememberMe", "false");
      // window.location.reload();
      await Auth.signOut();
      props.hide();
      props.sendAuthToken(null);
      props.changeStage("");
    } catch (error) {
      console.log("error signing out: ", error);
    }
    history.push("/login");
    window.location.reload(false);
  };

  // const onLogOut = async() =>{
  //   console.log("clicked")
  //   try {
  //     const done = await Auth.signOut();
  //     console.log(done);
  //     if (done) {
  //       let token = ""
  //       setCookie("token", token);
  //       history.push("/login");
  //       console.log("Logges out")
  //     }
  // } catch (error) {
  //     console.log('error signing out: ', error);
  // }
  // }
  console.log(props.profile);
  // useEffect(async () => {
  //   const session = await Auth.currentSession();
  //   if (session) {
  //     let token = session.getIdToken().getJwtToken();
  //     setCookie("token", token);
  //     props.sendAuthToken(token);
  //   }
  //
  // }, []);

  return (
    <div className="container-sidebar">
      {/* <button style={{color:"black", height:"2rem", width:"2rem", visibility: {mystate}}} onClick={clicked}>Hey</button> */}
      <div className="sidenav">
        {/* <IoChevronBackSharp className="icon"/> */}
        {/* <div className="back">Back</div> */}
        <div className="profilename-sidebar">{props.profile}</div>
        {/* <a href="#section" className="complete-profile">Complete profile</a> */}
      </div>
      <a className="logOut-sidebar" onClick={onLogOut}>
        Logout
      </a>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.user.name,
    error: state.profileReducer.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendAuthToken: (params) => {
      dispatch(updateUserInfo(params)); // action dispatched
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
