import React from "react";
import GuestNoSearchResultIcon from "../../../assets/icons/no-data_cuate.svg";
import "./GuestNoSearchResult.css";

const GuestNoSearchResult = () => {
  return (
    <>
      <div className="Guest_No_Search_Result_Container">
        <div className="Guest_No_Search_Result_Icon">
          <img src={GuestNoSearchResultIcon} alt="" />
        </div>
        <div className="Guest_No_Search_Result_Content">
          <h3>No Results Found For Your Search</h3>
          <p>Try searching something else</p>
        </div>
      </div>
    </>
  );
};

export default GuestNoSearchResult;
