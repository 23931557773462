import { types } from "../action-types";

// import initialState from './appReducer';
const initialState = {
  loading: false,
  gigs: [],
  error: {},
  searchvalue: "",
  searchSuggestions: [],
  jobQuery: null,
  skillQuery: null,
  locationQuery: null,
  budgetQuery: false,
  minbudgetQuery: null,
  maxbudgetQuery: null,
  budgetTypeQuery: null,
  sortValue: "Most Relevant",
  pageNo: null,
  filterDrawer: false,
  sortDrawer: false,
  isBudgetContract: false,
  isBudgetPermanent: false,
  activeFilters: null,
  WorkplacePolicy: null,
  IsFavoritesValue: false,
  myFavorites: [],
  // Loading: false,
  // Error: {},
  removeFavorites: [],
};

const searchGigsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEARCH_GIGS_INIT:
      return {
        ...state,
        loading: action.payload,
      };
    case types.SEARCH_GIGS_SUCCESS:
      console.log("Search Gigs payload ", action.payload);
      return {
        ...state,
        loading: false,
        gigs: { ...state.gigs, gigs: action.payload },
        error: {},
      };
    case types.SEARCH_GIGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.SEARCH_VALUE:
      return {
        ...state,
        searchvalue: action.payload,
      };
    case types.FETCH_SEARCH_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        searchSuggestions: {
          ...state.searchSuggestions,
          searchSuggestions: action.payload,
        },
        error: {},
      };
    case types.FETCH_SEARCH_SUGGESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.SEARCH_FILTER_UPDATE:
      const key = action.key;
      console.log("filter reducer", action, key, action.key, action.payload);
      return {
        ...state,
        [action.key]: action.payload,
        // jobQuery: action.payload,
      };
    case types.FAVORITE_GIG_CARD_SUCCESS:
      console.log("FAVORITE_GIG_CARD_SUCCESS", action.payload);
      const data = action.payload;
      console.log(data[0])
      let x;
      const index = state.gigs.gigs.gigs.data.map((obj,i) => {if(obj._id === data[0]) return x = i } )

      console.log(state.gigs.gigs.gigs.data[x].IsFavorite)
      let condition = state.gigs.gigs.gigs.data[x].IsFavorite
      const tmp = [...state.gigs.gigs.gigs.data]

       console.log(tmp,"tmp")
       console.log(tmp[x],"x")
       console.log(x)
      //  console.log(tmp[x].IsFavorite,"before update")
      tmp[x]={...state.gigs.gigs.gigs.data[x],"IsFavorite": !condition}
      // console.log(tmp[x].IsFavorite,"after update")
      return {
        ...state,
        gigs: { ...state.gigs, gigs: {...state.gigs.gigs,gigs:{...state.gigs.gigs.gigs,data:[...tmp]}} }
      };
    
      //  console.log(tmp[x].IsFavorite,"before update")
    case types.REMOVE_FAVORITE_GIG_CARD_REDUCER:
      console.log("FAVORITE_GIG_CARD_REDUCER", action.payload);
      // const dataR = action.payload;
      let y;
      const indexDel = state.gigs.gigs.gigs.data.map((obj,i)=>{if(obj._id === action.payload) return y = i})
      console.log(state.gigs,indexDel,"indexDel")
      let conditionDEL = state.gigs.gigs.gigs.data[y].IsFavorite
      const tmpDel = [...state.gigs.gigs.gigs.data]
      console.log(y)
      // console.log(tmpDel[y].IsFavorite,"before update DEL")
      // console.log(tmpDel[y].IsFavorite,"before update DEL")
      tmpDel[y]={...state.gigs.gigs.gigs.data[y],"IsFavorite": !conditionDEL}
      // console.log(tmpDel[y].IsFavorite,"after update DEL")

      return {
        ...state,
        gigs: { ...state.gigs, gigs: {...state.gigs.gigs,gigs:{...state.gigs.gigs.gigs,data:[...tmpDel]}} }
      };
    default:
       return state;
  }
};

export default searchGigsReducer;
