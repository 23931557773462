import { call, put, takeEvery } from "redux-saga/effects";
import { types } from "../action-types";
import { fetchChatHistoryApi, downloadChatFilesApi } from "../../API/API";

function* getChatHistorySaga(action) {
  try {
    const id = action.payload;
    const response = yield call(() => fetchChatHistoryApi(id));
    yield put({ type: types.FETCH_CHAT_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.FETCH_CHAT_FAILURE, error });
  }
}

// function* getChatFilesSaga(action){
//     console.log('downloading files...');
//     try{
//         const { FilePath } = action.payload;
//         const response = yield call(() => downloadChatFilesApi(FilePath));
//         yield put({ type: types.FETCH_CHAT_FILES_SUCCESS, payload: response.data });
//     }
//     catch(error){
//         yield put({type:types.FETCH_CHAT_FILES_FAILURE,error});
//     }
// }

export function* watchGetChatSaga() {
  yield takeEvery(types.FETCH_CHAT_REQUESTED, getChatHistorySaga);
}

// export function* watchGetChatFilesSaga(){
//     yield takeEvery(types.FETCH_CHAT_FILES_REQUESTED,getChatFilesSaga);
// }
