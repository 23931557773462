import React, { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const CandidateAppp = () => {

	const [flag, setFlag] = useState();
	const history = useHistory()
	const Profile = JSON.parse(localStorage.getItem('userProfile'));
	// console.log('user profile', userProfile);
	useEffect(() => {
		if (Profile?.profile?.AccountTypeCode == "EMP") {
			setFlag(false)
			window.location.replace('/employerdashboard')
		}
		else if (Profile?.profile?.AccountTypeCode == "TAL") {
			setFlag(false)
			window.location.replace('/talent')
		}
		else {
			window.location.replace('/login')
		}
	}, [])

	return (
		<>
			{
				flag &&
				<candidate-app />
			}
		</>
	)
}
export default CandidateAppp