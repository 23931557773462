import { combineReducers } from "redux";
import fetchDataReducer from "./fetchDataReducer";
import authReducer from "./authReducer";
import docReducer from "./docReducer";
import profileReducer from "./profileReducer";
import chekckUserReducer from "./chekckUserReducer";
import skillListingReducer from "./skillListingReducer";
import credentialingReducer from "./credentialingReducer";
import proposalsReducer from "./proposalsReducer";
import createBidReducers from "./createBidReducers";
import updateBidReducers from "./updateBidReducers";
import messagesReducer from "./messagesReducer";
import appReducer from "./appReducer";
import oneUserskillListingReducer from "./oneUserskillListingReducer";
import createInterviewReducer from "./createInterviewReducer";

import BidsReducer from "./BidsReducer";
import getBidDataReducer from "./fetchBidDataReducer";
import retractBidReducers from "./retractReducer";
import getChatHistoryReducer from "./chatHistoryReducer";

import searchGigsReducer from "./searchGigsReducer";
import timeSheetReducer from "./TimesheetReducer";
import timeSheetListReducer from "./timeSheetListReducer";
import createTimeSheetCorrectionReducer from "./createTimeSheetCorrection";
import UserAssignmentReducer from "./userAssignmentReducer";
import myFavoritesReducer from "./myFavoritesReducer";

import interviewReducer from "./interviewReducer";
import { NotificationReducer } from "./NotificationReducer";

// import {
//   resumeGetfollowersReducer,
//   resumeGetviewReducer,
//   resumeDetailReducer,
// } from "./resumeReducer";

import showPublicViewReducer from "./showPublicViewReducer";

import {
  resumeGetfollowersReducer,
  resumeGetviewReducer,
  resumeDetailReducer,
} from "./resumeReducer";
import homepageReducer from "./homepageReducer";
import searchSuggestionReducer from "./searchSuggestionReducer";
import onClearFilterReducer from "./onClearFilterReducer"
import IsLocationReducer from "./IsLocationReducer"
import getCompanyDetailsReducer from './companyDetailsReducer'
import searchSkillListingReducer from './searchSkillListingReducer';


import { types } from "../action-types";
import getProfileDetailsReducer from "./ProfileDetailsReducer";
const combineReducer = combineReducers({
  fetchDataReducer,
  authReducer,
  docReducer,
  profileReducer,
  chekckUserReducer,
  skillListingReducer,
  // appReducer
  credentialingReducer,
  proposalsReducer,
  createBidReducers,
  updateBidReducers,
  messagesReducer,
  getBidDataReducer,
  retractBidReducers,
  getChatHistoryReducer,

  searchGigsReducer,
  timeSheetReducer,
  timeSheetListReducer,
  createTimeSheetCorrectionReducer,
  UserAssignmentReducer,
  myFavoritesReducer,
  retractBidReducers,
  BidsReducer,

  interviewReducer,

  resumeGetfollowersReducer,
  resumeGetviewReducer,
  resumeDetailReducer,

  showPublicViewReducer,

  homepageReducer,
  NotificationReducer,
  onClearFilterReducer,
  searchSuggestionReducer,
  IsLocationReducer,
  getCompanyDetailsReducer,
  searchSkillListingReducer,
  getProfileDetailsReducer,
  oneUserskillListingReducer,
  createInterviewReducer
});

const rootReducer = (state, action) => {
  if (action.type === types.USER_LOGOUT) {
    return combineReducer({}, action);
  }
  return combineReducer(state, action);
};
export default rootReducer;
