import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AutoComplete, Input, Drawer } from "antd";
//import "./LocationFilter.css";
import FilterLocation from "./location.filter";

const Location = ({ setCheck, check }) => {
  // const [locationValue, setLocationValue] = useState([]);
  const [visible, setVisible] = useState(false);

  // const filters = useSelector(state => state.searchSkillListingReducer.filters);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  // useEffect(() => {
  //   var options = []
  //   if (filters && filters.location) {
  //     for (var i = 0; i < filters.location.length; i++) {
  //       options = [...options,
  //       {
  //         value: filters.location[i].LocationWithCountry
  //       }]
  //     }
  //     setLocationValue(options);
  //   }
  // }, [])
  // const onSelect = (value) => {
  //   console.log('onSelect', value);
  //   props.setCheckRadioLoc(value)
  // };
  return (
    <div className="search-location-filter-mobile">
      <Drawer
        className="location-drawer"
        onClose={onClose}
        visible={visible}
        closable={true}
        placement={"top"}
      >
        <FilterLocation
          setcheck={setCheck}
          check={check}
          mobileFilter={true}
          setVisible={setVisible}
        />
      </Drawer>
      {/* <AutoComplete
          options={locationValue}
          style={{
            width: '100%'
          }}
          // onChange={onChange}
          onSelect={onSelect}
          // onSearch={onLocationSearch}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
        > */}
      <Input
        className="input"
        value={check}
        onClick={showDrawer}
        type={"input"}
        placeholder="Search Location"
      />
      {/* </AutoComplete> */}
    </div>
  );
};
export default Location;
