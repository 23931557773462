import React, { useEffect, useState } from "react";
import { BsFillEyeFill } from "react-icons/bs";
import {
  Row,
  Col,
  Card,
  Avatar,
  Rate,
  Tag,
  Skeleton,
  Typography,
  Checkbox,
} from "antd";
import Frame from "../../assets/img/Top_rated@3x.svg";
// import ex_suitcase from '../../assets/icons/ex_suitcase.png';
import "./skillsCard.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getImage } from "./components/cloudinary";
import { useHistory } from "react-router-dom";

const GigsCard = React.memo((props) => {
  useEffect(() => {
    console.log("RATING:", props.Rating);
  }, [props.Rating]);

  const { Text } = Typography;
  const history = useHistory();

  //Hooks***************
  const [checked, setChecked] = React.useState(false);
  const [profilePic, setProfilePic] = React.useState();

  //Reducers**************
  const professionCode = useSelector(
    (state) => state.profileReducer.user.profile.ProfessionCode
  );

  //Functions************
  const detailsPage = () => {
    const encodeURl = encodeURIComponent(props._id);
    console.log("pushing", props);
    history.push({
      pathname: `/candidatedetail`,
      state: {
        id: props._id,
        GigId: props.job_id,
        JobTitle: props.job_title,
        fromMatches: props.findMatch ? true : false,
        token : false,
      },
    });
  };

  const [dimensions, setDimensions] = useState(window.innerWidth);

  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
  }, []);

  useEffect(() => {
    if (props.skillListingIds)
      props.skillListingIds?.find((id) => id._id == props._id)
        ? setChecked(true)
        : setChecked(false);
  }, [props.skillListingIds]);

  const onCheckChange = (data) => {
    if (checked == true) {
      setChecked(false);
      var a = props.skillListingIds.filter((si) => si._id != data._id);
      props.setSkillListingIds(a);
      props.setcheckAllClicked(false);
    } else {
      setChecked(true);
      props.setSkillListingIds([...props.skillListingIds, data]);
      props.setCandidateId(props._id);
    }
  };

  // 👇️ if you only need to capitalize first letter
  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(async () => {
    let profilePic = "";
    if (props.users) {
      if (props.users.profile.PictureUrl) {
        profilePic = await getImage(props.users.profile.PictureUrl);
      } else {
        profilePic = await getImage(props.PictureUrl);
      }
      setProfilePic(profilePic);
    } else {
      profilePic = await getImage(props.PictureUrl);
    }
  }, [props]);

  console.log("bid", props.data);

  return (
    <React.Fragment>
      <div className="sgigs-the-card">
        <Row className={"cards-top-row"}>
          <div style={{ position: "absolute" }}>
            {props.token != null && !props.bidstatus && !props.findMatch ? (
              <Checkbox
                className="checkboxes"
                onChange={() => onCheckChange(props)}
                checked={checked}
                key={props._id}
              />
            ) : null}
          </div>

          <div style={{ margin: "auto" }}>
            <div
              onClick={detailsPage}
              className={
                props.token == null
                  ? "image-circle-container-guest"
                  : "image-circle-container"
              }
            >
              <div
                className={
                  props.token != null
                    ? "guest_top-rated-vector"
                    : "guestUser-vector-icon"
                }
              >
                <img src={Frame} height="40" width={40} />
              </div>
              <div className="img-containerr">
                {props.users ? (
                  props.users.profile.PictureUrl ? (
                    <img
                      className="profile-imagee"
                      alt=""
                      src={profilePic && profilePic}
                    />
                  ) : (
                    <div className="img-textt">
                      {props.users
                        ? capitalizeFirst(props.users.name.split("")[0])
                        : ""}
                    </div>
                  )
                ) : props.candidateName ? (
                  <div className="img-textt">
                    {props.candidateName.split("")[0]}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div style={{ position: "absolute", right: "0", marginRight: "5px" }}>
            {props.token != null && props.prevviewed ? (
              <BsFillEyeFill style={{ color: "#7e7e7e", marginTop: "8px" }} />
            ) : null}
          </div>
        </Row>

        <div>
          {props.candidateName ? (
            <div className="container-meta">
              <p>
                {props.candidateName ? props.candidateName : "Candidate Name"}
              </p>
            </div>
          ) : (
            <div className="container-meta">
              <p>
                {props && props.users ? props.users.name : "Candidate Name"}
              </p>
            </div>
          )}
        </div>
        <div
          style={{
            textAlign: "center",
            lineHeight: "1.1",
            marginBottom: "7px",
          }}
        >
          <Rate
            allowHalf
            className="rate-stars"
            disabled
            value={props.Rating === 0 ? 3.5 : props.Rating}
          />
          <Text className="rating-text">
            <span style={{ color: "#ffba08", fontSize: "12px" }}>
              {" "}
              {props.Rating === 0 ? 3.5 : props.Rating}
            </span>{" "}
            ({props.Review})
          </Text>
        </div>
        {props.skillTitle ? (
          <Col className="skill-listing-title">
            {props.skillTitle ? props.skillTitle : "Skill Listing"}
          </Col>
        ) : (
          <Col className="skill-listing-title">
            {props ? props.Title : "Skill Listing"}
          </Col>
        )}
        <Row className="desired-rate-div">
          <Col xs={24} className="desired-rate">
            {professionCode !== "IT" ? (
              props.MinimumBid ? (
                <div className="rate-value">
                  <Text className="desired-rate-text">Desired Rate</Text>{" "}
                  {props.MinimumBid ? "$" + props.MinimumBid : "$Negotiable"}
                  {props.MinimumBidType ? "/" + props.MinimumBidType : ""}{" "}
                </div>
              ) : (
                <div className="rate-value">
                  <Text className="desired-rate-text">Desired Rate</Text>{" "}
                  {props && props.MinimumBid != null
                    ? "$" + props.MinimumBid
                    : "$Negotiable"}
                  {props && props.MinimumBidType
                    ? "/" + props.MinimumBidType
                    : ""}{" "}
                </div>
              )
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row className="Experience-row">
          <Col lg={20} xs={24} className="experience">
            {/* <img className="briefcase-img" src={ex_suitcase} alt="" /> */}
            {props.ExperienceLevelName ? (
              <Text style={{ marginLeft: "8px" }}>
                {props.ExperienceLevelName
                  ? props.ExperienceLevelName
                  : "1-2 years"}
              </Text>
            ) : (
              <Text style={{ marginLeft: "8px" }}>
                {props && props.users
                  ? props.users.profile.ExperienceLevelName
                  : "1-2 years"}
              </Text>
            )}
          </Col>
        </Row>
        {props.LastSuccessfulLoginDate ? (
          <div className={"last-active-text"}>
            {props?.LastSuccessfulLoginDate
              ? "Last active " + moment(props.LastSuccessfulLoginDate).fromNow()
              : "Last active : not available"}
          </div>
        ) : (
          <div className={"last-active-text"}>
            {props?.user_Schema &&
            props.user_Schema.profile.LastSuccessfulLoginDate
              ? "Last active " +
                moment(
                  props.user_Schema.profile.LastSuccessfulLoginDate
                ).fromNow()
              : "Last active : not available"}
          </div>
        )}
      </div>
    </React.Fragment>
  );
});

export default GigsCard;
