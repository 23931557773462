import React, { useState, useEffect } from "react";
import {
  Row, Col, Card, Modal, Tag,
  Avatar, Rate, Skeleton, Typography, Comment,
} from "antd";
import {
  HeartFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import './ProfileD.css'
import Frame from '../../assets/img/Top_rated@2x.svg';
import Frame2x from '../../assets/img/Top_rated@3x.svg';
import ex_suitcase from '../../assets/icons/ex_suitcase.png';
import ps_suitcase2x from '../../assets/icons/ps_suitcase@2x.svg';
import SkillListCard from './SkillListings/skillListings&Reviews';
import Reviews from './Reviews/Reviews';
import { fetchProfileDetails, getOneUserSkillListing, postFavorites, deleteFavorites, isModalVisibleAuth } from "../../redux/actions";
import moment from 'moment';
import threeDicon from '../../assets/img/3d_icon.svg';
import schedule_icon from '../../assets/img/schedule_icon.png'
import schedule_icon_fade from '../../assets/icons/healthicons_i-schedule-school-date-time-outline.png'
import schedule_icon_disable from '../../assets/icons/healthicons_i-schedule-school-date-time-outline.png'
import cil_chat_bubble from '../../assets/img/cil_chat-bubble.png'
import cil_chat_bubble_faded from '../../assets/icons/colorless_cil_chat-bubble.png'
import history from "../../routes/history";
import TopAlert from "../../components/TopNotification/TopAlert";
import { HiredStatusbtns, MatchesButton, PlacedBidBtns } from "./ButtonsSet/BottomBtns";
import { getImage } from "../../utilities/storageOperations/downloadURL";
import PlaceBidModal from "../../components/PlaceBidModal/PlaceBidModal";
import DesktopProfileCard from "./desktopProfileCard/DesktopProfileCard";
import InterviewDrawer from './InterviewDrawer/interviewDrawer';
import axios from "axios";

const BackendApiBaseUrl = process.env.REACT_APP_API_URL;

const ProfileDetail = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const authToken = location.state.token;

  console.log('state in profile', location.state.jobId);
  //Hooks
  const [stars, setStars] = useState(5);
  const { Text } = Typography;
  const [myModal, setModal] = useState("none");
  const [visible, setVisible] = useState(false);
  const [placebidModal, setplacebidModal] = useState(false);
  const [profilePic, setProfilePic] = React.useState();
  const [favorite, setFavorite] = React.useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [color, setColor] = useState("");
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [interviewDrawer, setinterviewDrawer] = useState(false);
  const [bidId, setbidId] = useState(false);
  const skillloading = useSelector(
    (state) => state.oneUserskillListingReducer.loading
  );

  //States from reducers
  const Pdetails = useSelector(
    (state) => state.getProfileDetailsReducer.details
  );
  const loading = useSelector(
    (state) => state.getProfileDetailsReducer.loading
  );
  const userSKillListing = useSelector(
    (state) => state.oneUserskillListingReducer.skillListings
  );
  const profileTypeRes = useSelector((state) => state.profileReducer.user);
  const get_favorite = useSelector(
    (state) => state.oneUserskillListingReducer.skillListings.isfavorite
  )
  const interviewScheduleSuccess = useSelector(state => state.createInterviewReducer.success);
  const RetractSuccess = useSelector(state => state.retractBidReducers.success)
  const BidSuccess = useSelector(state => state.createBidReducers.success)

  useEffect(() => {
    if (location.state.id) {
      dispatch(fetchProfileDetails(location.state.id));
    }
  }, [BidSuccess]);


  // console.log("details:", Pdetails);
  // console.log('userSKillListing', userSKillListing);

  const placeABidFunction = () => {
    if (windowDimensions.width < 768) {
      history.push({
        pathname: '/login'
      })
    }
    else {
      dispatch(isModalVisibleAuth(true))
    }


  }

  useEffect(() => {
    if (userSKillListing?.bid?.length !== undefined) {
      setbidId(userSKillListing?.bid[0]?._id)
    }
  }, [userSKillListing]);

  useEffect(() => {
    setFavorite(get_favorite);
  }, [get_favorite]);


  useEffect(() => {
    if (favorite) {
      setColor("red");
    } else {
      setColor("");
    }
  }, [favorite]);

  useEffect(() => {
    if (Pdetails.skill_listings && authToken !== null)
      dispatch(getOneUserSkillListing({ skillId: Pdetails.skill_listings?._id, jobId: location.state.jobId }));
  }, [Pdetails, location.state.jobId]);


  const myfavourite = () => {

    if (windowDimensions.width > 768) {
      dispatch(isModalVisibleAuth(true));
    }
    else {
      history.push("/login");
    }

  };


  useEffect(async () => {
    let profilePic = '';
    if (Pdetails.user_Schema) {
      if (Pdetails.user_Schema.profile.PictureUrl) {
        profilePic = await getImage(Pdetails.user_Schema.profile.PictureUrl)
      }
      setProfilePic(profilePic);
    }
  }, [Pdetails]);

  // const handleCancel = () => {
  //   console.log("Clicked cancel button");
  //   setVisible(false);
  // };

  const placeBidModalVisisblityHandler = (value) => {
    setplacebidModal(value);
  };
  var candidateList = [
    {
      id: Pdetails?.skill_listings?._id,
      //  img: profilePic && profilePic,

      img: Pdetails?.user_Schema?.profile.PictureUrl,
      name: Pdetails.user_Schema ? Pdetails.user_Schema.name : "",
      experience: Pdetails.experience ? Pdetails.experience : "0-0",
      title: Pdetails?.skill_listings?.Title,
      desired_rate: Pdetails?.skill_listings?.MinimumBid,
      bidType: Pdetails?.skill_listings?.MinimumBidType,
      profileType: profileTypeRes?.profile?.ProfessionCode
    },
  ];


  console.log("candidate detailss >", candidateList)

  const scheduleInterview = () => {
    setinterviewDrawer(true)
  }

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handle_3d_resume_btn = () => {
    if (windowDimensions.width > 768) {
      dispatch(isModalVisibleAuth(true));
    }
    else {
      history.push("/login");
    }
    // if (authToken == null) {
    //   dispatch(isModalVisibleAuth(true))
    // }
    // else {
    //   history.push({ pathname: '/resume-3d', state: { candidateid: Pdetails.user_Schema._id } })
    // }
  }

  useEffect(async () => {
    if (location.state.id && authToken !== null) {
      var headers = { "Content-Type": "application/json", Authorization: authToken };
      await axios.post(`${BackendApiBaseUrl}/empprofile/views/skilllisting`, { "SkillListingId": location.state.id }, { headers });
    }
  }, []);

  return (
    <>

      {displayModal ? (
        <>
          <TopAlert
            display={favorite}
            title={"Skill Listing added to favorites"}
            type={'success'}
          />
          <TopAlert
            display={!favorite}
            title={"Skill Listing removed from favorites"}
            type={'success'}
          />
        </>
      ) : (
        <></>
      )}
      <div className="profilereviewsrow">
        <div className='profile-skillListing-card'>
          <div className="profile-part">
            {loading ? <div><Skeleton avatar active /></div> :
              windowDimensions.width < 768 ?
                <Comment
                  avatar={
                    <Col>
                      <div className='top-rated-image'>
                        <img className="top-rated-img" src={Frame} />
                      </div>
                      <Col className="img-container">
                        {Pdetails.user_Schema ? (
                          Pdetails.user_Schema.profile.PictureUrl ? (
                            <img
                              className='profile-image'
                              alt="example"
                              src={profilePic && profilePic}
                            />
                          ) : (
                            <div className="img-text">
                              {Pdetails.user_Schema
                                ? Pdetails.user_Schema.name.split("")[0]
                                : ""}
                            </div>
                          )
                        ) : null}
                      </Col>
                    </Col>}
                  content={
                    <div>
                      <div className={'username'}>{Pdetails.user_Schema ? Pdetails.user_Schema.name : 'Candidate Name'}
                        <HeartFilled style={{ float: 'right', opacity: '65%', color: color }} onClick={myfavourite} />
                      </div>
                      <Row style={{ marginTop: '3px' }}>
                        <div >
                          <Rate value={stars} className="profile-ratings" />
                        </div>
                        <div lg={11} sm={11} xs={9}>
                          <div style={{ color: '#7e7e7e', fontSize: '12px', paddingLeft: '3px', paddingTop: '1px' }}>4.8 (493 reviews)</div>
                        </div>
                      </Row>
                      <div className='candidate-experience-mobile'>
                        <img className='experience-icon' src={ex_suitcase} alt={'experience-icon'} />
                        <span>{Pdetails.experience ? Pdetails.experience : 'Experience : none'}</span>
                      </div>
                      <div className={'last-active'}>
                        {Pdetails.user_Schema && Pdetails.user_Schema.profile.LastSuccessfulLoginDate != null ? 'Last active ' + moment(Pdetails.user_Schema.profile.LastSuccessfulLoginDate).fromNow() : 'Last active : not available'}
                      </div>
                      {userSKillListing.status == "hired" ? (
                        <div>
                          <Tag className="status-tag">Hired</Tag>
                        </div>
                      ) : null}
                      <Row style={{ width: '100%', paddingTop: '13px', textAlign: 'center' }}>
                        <Col>
                          <button className='resume-btn' onClick={handle_3d_resume_btn}>
                            <img src={threeDicon} />
                            View 3D Resume
                          </button>
                        </Col>
                      </Row>
                    </div>
                  }
                /> :

                <>
                  {/* for desktop */}
                  <div className="desktop-profile-card">
                    <HeartFilled style={{ opacity: '65%', color: color, fontSize: '16px', marginTop: '12px' }} onClick={myfavourite} />
                    <div style={{ marginLeft: "10.5vh" }}>
                      <div className='top-rated-image'>
                        <img className="top-rated-img" src={Frame2x} />
                      </div>
                      <Col className="img-container">
                        {Pdetails.user_Schema ? (
                          Pdetails.user_Schema.profile.PictureUrl ? (
                            <img
                              className='profile-image'
                              alt="example"
                              src={profilePic && profilePic}
                            />
                          ) : (
                            <div className="img-text">
                              {Pdetails.user_Schema
                                ? Pdetails.user_Schema.name.split("")[0]
                                : ""}
                            </div>
                          )
                        ) : null}
                      </Col>
                    </div>
                    {/* <div className="status-tag-div"><Tag className="status-tag">Hired</Tag></div> */}
                    {userSKillListing.status == "hired" ? (
                      <div className="status-tag-div">
                        <Tag className="status-tag">Hired</Tag>
                      </div>
                    ) : null}
                  </div>

                  <div className="profile-details-container">
                    <div className={'username'}>{Pdetails.user_Schema ? Pdetails.user_Schema.name : 'Candidate Name'}</div>
                    <div className="rating-div">
                      <div>
                        <Rate value={stars} className="profile-ratings" />
                      </div>
                      <div style={{ color: '#7e7e7e', fontSize: '12px', paddingLeft: '8px', paddingTop: '1px' }}>4.8 (493 reviews)</div>
                    </div>
                    <div className='candidate-experience'>
                      <img className='experience-icon2x' src={ps_suitcase2x} alt={'experience-icon'} />
                      <div>{Pdetails.experience ? Pdetails.experience : 'Experience : none'}</div>
                    </div>
                    <div className={'last-active'}>
                      {Pdetails.user_Schema && Pdetails.user_Schema.profile.LastSuccessfulLoginDate != null ? 'Last active ' + moment(Pdetails.user_Schema.profile.LastSuccessfulLoginDate).fromNow() : 'Last active : not available'}
                    </div>
                    <button className="resume-btn-3d" onClick={handle_3d_resume_btn}>
                      <img src={threeDicon} className='resume-3d-icon' />
                      View 3D Resume
                    </button>
                  </div>
                </>
            }
          </div>
          <div className="full-bottom-line"></div>
          <Row>
            {loading ? <div style={{ width: '90%', margin: 'auto' }}><Skeleton active /></div> :
              Pdetails.skill_listings ?
                <SkillListCard title={Pdetails.skill_listings.Title}
                  MinimumBid={Pdetails.skill_listings.MinimumBid}
                  MinimumBidType={Pdetails.skill_listings.MinimumBidType}
                  Location={Pdetails.skill_listings.Location.length ? Pdetails.skill_listings.Location[0].LocationWithCountry : ''}
                  PublishedDate={Pdetails.skill_listings.PublishedDate}
                  GigType={Pdetails.skill_listings.GigType}
                  StartDate={Pdetails.skill_listings.StartDate}
                  Skills={Pdetails.skill_listings.Skills}
                />
                : null}
          </Row>
          <div className="bottom-line"></div>

          <Row
            style={{ textAlign: "center", width: "100%", paddingTop: "10px" }}
          >
            {/* {skillloading ? (
              <div style={{ width: '90%', margin: 'auto' }}><Skeleton active /></div>
            ) : (userSKillListing.status == 'pending' && !RetractSuccess) ? (
              <>
                <PlacedBidBtns
                  userSkillData={userSKillListing} skil_id={location.state.id} />


                <Col lg={24} xs={24}>
                  <button
                    onClick={() => history.push('/conversations')}
                    className="msg-btn">
                    <img src={cil_chat_bubble} className="social-icon" />
                    Message Candidate
                  </button>
                </Col>
              </>
            ) : userSKillListing.status == "hired" ? (
              <HiredStatusbtns chaticon={cil_chat_bubble} />
            ) : location.state.fromMatches ?

              <>
                <MatchesButton
                  GigId={location.state.jobId}
                  JobTitle={location.state.JobTitle}
                  SkillListingId={userSKillListing?.skill_listings?._id}
                  SkillListingTitle={userSKillListing?.skill_listings?.Title}
                />
                <Col lg={24} xs={24}>
                  <button className="place-Bid-btn-disabled" disabled={true} >
                    Place a Bid
                  </button>
                </Col>
                <Col lg={24} xs={24}>
                  <button className={"Bid-btn-disabled"}
                  >
                    <img src={schedule_icon_fade} style={{ marginRight: '5px' }} />
                    Schedule an Interview
                  </button>
                </Col>
                <Col lg={24} xs={24}>
                  <button
                    className={"cand-prof-msg-btn-disabled"}>
                    <img src={cil_chat_bubble_faded} className="social-icon" />
                    Message Candidate
                  </button>
                </Col>

              </>
              : (userSKillListing.status == 'pending' && RetractSuccess?.status == 'Retracted' ||
                userSKillListing.status == 'idle') ?
                ( */}
            <>

              <Col lg={24} xs={24}>
                <button className="place-Bid-btn" onClick={placeABidFunction}>
                  Place a Bid
                </button>
              </Col>


              <Col lg={24} xs={24}>
                <button className={userSKillListing.status == 'idle' || RetractSuccess?.status == 'Retracted' ? "Bid-btn-disabled" : 'Bid-btn'}
                // onClick={scheduleInterview}
                >
                  <img src={userSKillListing.status == 'idle' || RetractSuccess?.status == 'Retracted' ? schedule_icon_fade : schedule_icon} style={{ marginRight: '5px' }} />
                  Schedule an Interview
                </button>
              </Col>
              <Col lg={24} xs={24}>
                <button
                  // onClick={()=>history.push('/conversations')}
                  className={userSKillListing.status == 'idle' || RetractSuccess?.status == 'Retracted' ? "cand-prof-msg-btn-disabled" : "msg-btn"}>
                  <img src={userSKillListing.status == 'idle' || RetractSuccess?.status == 'Retracted' ? cil_chat_bubble_faded : cil_chat_bubble} className="social-icon" />
                  Message Candidate
                </button>
              </Col>
            </>
            {/* )
                : */}
            {/* (
                  <>

                    <Col lg={24} xs={24}>
                      <button className="place-Bid-btn" onClick={placeABidFunction}>
                        Place a Bid
                      </button>
                    </Col>


                    <Col lg={24} xs={24}>
                      <button className={userSKillListing.status == 'idle' || RetractSuccess?.status == 'Retracted' ? "Bid-btn-disabled" : 'Bid-btn'}
                      // onClick={scheduleInterview}
                      >
                        <img src={userSKillListing.status == 'idle' || RetractSuccess?.status == 'Retracted' ? schedule_icon_fade : schedule_icon} style={{ marginRight: '5px' }} />
                        Schedule an Interview
                      </button>
                    </Col>
                    <Col lg={24} xs={24}>
                      <button
                        // onClick={()=>history.push('/conversations')}
                        className={userSKillListing.status == 'idle' || RetractSuccess?.status == 'Retracted' ? "cand-prof-msg-btn-disabled" : "msg-btn"}>
                        <img src={userSKillListing.status == 'idle' || RetractSuccess?.status == 'Retracted' ? cil_chat_bubble_faded : cil_chat_bubble} className="social-icon" />
                        Message Candidate
                      </button>
                    </Col>
                  </>
                ) */}
            {/* } */}
          </Row>
        </div>
        <Reviews Reviews={Pdetails?.reviews} />
      </div>

    </>
  )
};

export default ProfileDetail;
