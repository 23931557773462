import React, { useState, useEffect } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  ApolloLink,
  gql,
} from "@apollo/client";
import { RestLink } from "apollo-link-rest";
import { HttpLink } from "apollo-link-http";
import { setContext } from "@apollo/client/link/context";
// import { ApolloProvider } from "react-apollo";
import SearchBar from "./SearchBar";
import SearchResult from "./SearchResults";
import { SearchGigsApi } from "../../API/API";
import { connect } from "react-redux";
import { fetchGigs } from "../../redux/actions";
import { useSelector } from "react-redux";
//import "./SearchBar.css";
import SearchResults from "./SearchResults";
import GuestGigDetails from "./GuestUser/components/GuestGigDetails";
import { REACT_APP_API_URL } from "../../config";

const SearchGigs = (props) => {
  const BackendApiBaseUrl = REACT_APP_API_URL;
  const authTokenValue = useSelector(
    (state) => state.authReducer.user.authToken
  );
  // const GetGigs = props.user.Gigs;
  const [search, setSearch] = useState("");
  const [gigset, setGigSet] = useState();
  const restLink = new RestLink({
    endpoints: {
      openExchangeRate: "https://open.exchangerate-api.com/v6/",
    },
  });

  const httpLink = new HttpLink({
    uri: `${BackendApiBaseUrl}/query`,
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token if it exists
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: authTokenValue ? `${authTokenValue}` : "",
      },
    };
  });

  const client = new ApolloClient({
    // uri: `${BackendApiBaseUrl}/query`,
    cache: new InMemoryCache(),
    // link: ApolloLink.from([httpLink]),
    link: authLink.concat(httpLink),
  });

  // useEffect(() => {
  //   props.GetGigs();
  //   const GigsResponse = props.user.Gigs;
  //   console.log(GigsResponse);
  //   // setState(data);
  // }, []);

  // useEffect(() => {
  //   const GigsResponse = props.user.Gigs;
  //   // console.log(GigsResponse);
  //   setGigSet(GigsResponse);
  //   // setState(data);
  // }, [props.user.Gigs]);

  const query = gql`
    query {
      gigs {
        id
        JobOrderId
        ProfileId
        Title
        Description
        JobOrderStatusName
        JobOrderTypeName
        CityName
        RegionName
        RegionCode
        ZipCode
        CountryName
        CreatedBy
        Created
        Modified
        ModifiedBy
        IsActive
        BudgetType
        IdealContractLengthId
        IdealContractLengthName
        BudgetMin
        BudgetMax
        SuggestionsFrequency
        SuggestionsSent
        ExperienceLevelName
        InternalGigTypeCode
        ImportSource
        ExternalGigId
        ProfessionCode
        ImportProcessStatus
        FacilityName
        AutoLoaded
        SourceName
        SourceId
        Shift
        Skills
      }
    }
  `;

  // const { loading, error, data } = useQuery(query);
  const Query = () => {
    console.log("hitted");
    props.GetGigs(query);
    // const GetGigs = props.user.Gigs;
    // console.log(GetGigs);
    // if (loading) return "Loading...";
    // if (error) return `Error! ${error.message}`;
  };
  // console.log(GetGigs);
  return (
    <ApolloProvider client={client}>
      <SearchResults />
    </ApolloProvider>
  );
};

export default SearchGigs;

// Link: link allows you to customize the flow of data from your graphQL queries
// and mutations to your backend and in-app state management.
// This can include: adding custom headers and routing to REST endpoints and graphQL endpoints.
