import React, { useState, useEffect } from "react";
import { Menu, Dropdown, Button, message } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";

// CSS
//import "./GuestSearchResults.css";

// ICONS
import { ImPlus } from "react-icons/im";
import { FaBolt } from "react-icons/fa";
import { GiRoundStar } from "react-icons/gi";
import { ImDisplay } from "react-icons/im";
import { AiFillFile } from "react-icons/ai";
import { FaCoffee } from "react-icons/fa";
import { AiOutlineDollar } from "react-icons/ai";
import { LoadingOutlined } from "@ant-design/icons";

// ANTD
import { Progress } from "antd";

import GuestGigsCard from "./GuestGigsCard";
import SearchGigs from "../SearchGigs";

import { connect } from "react-redux";

import { fetchUserSkillListing } from "../../../redux/actions";

const GuestSearchResults = (props) => {
  const [gigs, setGigs] = useState([]);

  // console.log(gigs.gigs);
  useEffect(() => {
    setGigs(props.gigs?.gigs?.gigs.data);
    console.log(props.gigs);
  }, [props.gigs]);

  useEffect(() => {
    console.log(gigs);
  }, [gigs]);

  useEffect(() => {}, [props.value]);

  function handleMenuClick(e) {
    message.info("Click on menu item.");
    console.log("click", e);
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" icon={<UserOutlined />}>
        1st menu item
      </Menu.Item>
      <Menu.Item key="2" icon={<UserOutlined />}>
        2nd menu item
      </Menu.Item>
      <Menu.Item key="3" icon={<UserOutlined />}>
        3rd menu item
      </Menu.Item>
    </Menu>
  );

  return (
    <React.Fragment>
      <main className="g-gql-dashBoardFull">
        <div className="g-gql-dashboardHeaders">
          <div className="g-gql-countCheck">
            {/* <span className="g-gql-countCheck"> */}
            {gigs && gigs.length > 0 && props.value.length > 0
              ? `Showing ${gigs.length} results for "${props.value}"`
              : null}
            {/* </span> */}
          </div>
          {/* <div className="g-gql-viewAllBtn">View all</div> */}
          <div className="g-gql-viewAllBtn"></div>
        </div>
        {/* <body>
          <div class="filters-wrapper">
            <Dropdown overlay={menu} class="child">
              <Button>
                Button <DownOutlined />
              </Button>
            </Dropdown>
            <div class="parent">
              <Dropdown overlay={menu} class="child">
                <Button>
                  Button <DownOutlined />
                </Button>
              </Dropdown>
              <div class="child"></div>
              <div class="child"></div>
              <div class="child"></div>
            </div>
            <div className="location-button">Hey</div>
          </div>
        </body> */}
        {/* <hr className="g-gql-headerLine" /> */}
        <div className={`${props.value.length == 0 && "g-gql-receivedGigs"} `}>
          {props.value.length > 0 && !props.loading ? (
            <div className="g-gql-receivedGigs">
              {gigs && gigs.length > 0 ? (
                gigs.map((data) => {
                  // console.log('gig data: ',data);
                  return <GuestGigsCard data={data} />;
                })
              ) : (
                <div>No gigs found!</div>
              )}
            </div>
          ) : (
            <div>{props.value.length === 0 && `Search gigs`}</div>
          )}
          {/* <LoadingOutlined /> */}
          <div className="g-gql-loader">
            {props.value.length > 0 && props.loading && <LoadingOutlined />}
          </div>
          {/* <LoadingOutlined /> */}
          {/* {gigs && gigs.length > 0
            ? gigs.map((data) => {
                return <GigsCard data={data} />;
              })
            : "no gigs found "} */}
        </div>
      </main>
      {/* </div> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.searchGigsReducer.loading,
    gigs: state.searchGigsReducer.gigs,
    value: state.searchGigsReducer.searchvalue,
    error: state.searchGigsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getSkillListing: () => {
    //   dispatch(fetchUserSkillListing());
    // },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestSearchResults);
