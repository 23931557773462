import React, { useState, useEffect } from "react";
import {
  Menu,
  Select,
  Space,
  Dropdown,
  Button,
  Radio,
  Tag,
  Row,
  Switch,
  Pagination,
  Col,
  Typography,
  AutoComplete,
  Modal,
  Checkbox,
} from "antd";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  ApolloLink,
  gql,
  useLazyQuery,
} from "@apollo/client";
import { RestLink } from "apollo-link-rest";
import {
  // fetchGigs,
  SearchResponse,
  SearchResponseRequested,
  SearchValue,
  SearchResponseRequestedSkillListing,
  SearchResponseSkillListing,
  GetFilters,
} from "../../redux/actions";
import { HttpLink } from "apollo-link-http";
import {
  DownOutlined,
  SearchOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import MobilePagination from "../../components/Pagination/MobilePagination";
// import emptyresult from "../../assets/img/cuate@2x.png";

// CSS
import "./SearchResults.css";

// ICONS
import { LoadingOutlined } from "@ant-design/icons";
import { debounce, timeout } from "../../shared/helpers";
// import FilterIcon from "../../assets/img/filtericon.svg";
// import SortIcon from "../../assets/img/sorticon.svg";
// ANTD
import GigsCard from "./GigsCard";
import { connect } from "react-redux";
// redux Actions
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import Filters from "./MobileComponents/Filters";
// import PlaceBidModal from "../../components/PlaceBidModal/PlaceBidModal";
import BudgetFilter from "./components/BudgetFilter/BudgetFilter";
import ExperienceLevel from "./components/ExperienceLevelFilter/ExperienceLevelFilter";
import FilterLocation from "./components/LocationFilter/location.filter";
import { REACT_APP_API_URL } from "../../config";
// import { HidePreviouslyViewedFilter } from "./components/HidePreviouslyViewedFilter";

const SearchResults = React.memo((props) => {
  const dispatch = useDispatch();
  const url = useLocation();
  const history = useHistory();
  const { Text } = Typography;
  const { authToken } = props;
  // searchSkillListingReducer
  const filters = useSelector(
    (state) => state.searchSkillListingReducer.filters
  );
  const suggestions = useSelector(
    (state) => state.searchSkillListingReducer.sugesstions
  );
  console.log("suggestions-->", suggestions);
  const token = useSelector((state) => state.authReducer.user);
  const profileTypeRes = useSelector((state) => state.profileReducer.user);
  const professionCode = useSelector(
    (state) => state.profileReducer?.user?.profile?.ProfessionCode
  );
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [checkRadio, setCheckRadio] = useState(null);
  const [SearchFilters, SetSearchFilters] = useState({
    location: null,
    skillListing_type: null,
    budgetType: null,
    ExperienceLevel: null,
    budgetMin: null,
    budgetMax: null,
    NearBy: null,
  });

  const [searchFilters, setSearchFilters] = useState({
    location: null,
    skillListing_type: null,
    budgetType: null,
    ExperienceLevel: null,
    budgetMin: null,
    budgetMax: null,
    NearBy: null,
    hidePrevious: null,
  });
  //   const search = props.value;
  const [candidateId, setCandidateId] = useState();
  const [skillListings, setGigs] = useState([]);
  const [visible, setVisible] = useState(false);
  const [sortvisible, setSortVisible] = useState(false);
  const [sortType, setSortType] = useState("MOST_RECENT");
  const [sortTypeText, setsortTypeText] = useState("Most Recent");
  const [pageNo, setPageNo] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [placebidModal, setplacebidModal] = useState(false);
  const [candidateList, setcandidateList] = useState([]);
  const [locationValue, setLocationValue] = useState([]);
  const [jobTypeRadio, setJobTypeRadios] = useState(null);
  const [checkAllClicked, setcheckAllClicked] = useState(false);
  const [skillListingIds, setSkillListingIds] = useState([]);
  const [filterCount, setFilterCount] = useState();
  const [closeModalSkill, setCloseModalSkill] = useState(false);
  const [closeModalLoc, setCloseModalLoc] = useState(false);
  const [closeModalBudget, setCloseModalBudget] = useState(false);
  const [closeModalExp, setCloseModalExp] = useState(false);
  const [nearBy, setNearby] = useState({
    latitude: 33.5773696,
    longitude: 73.1480064,
    maxDistance: 50,
  });
  const [clearForm, setClearForm] = useState(false);
  const [budget, setBudget] = useState({
    budgetMin: null,
    budgetMax: null,
    budgetType: null,
  });
  console.log("budget -->", budget, budget.budgetMax, budget.budgetMin);
  const [perPage, setPerPage] = useState("12");
  const [checkRadioLoc, setCheckRadioLoc] = useState(null);
  const [hideCheck, setHideCheck] = useState(false);

  //// Search Skill Listing Start//////////
  const query = gql`
        query {
          skilllistings(
            _sort: ${sortType}
            _page: ${pageNo},
            _perPage: ${perPage},
            search: "${props.value}",
            GigType: "${searchFilters?.skillListing_type ?? null}",
            Location : "${searchFilters?.location ?? null}",
            BudgetMin : "${searchFilters?.budgetMin ?? null}",
            BudgetMax : "${searchFilters?.budgetMax ?? null}",
            BudgetType : "${searchFilters?.budgetType ?? null}",
            Experience: "${searchFilters?.ExperienceLevel ?? null}",
            NearBy : "${searchFilters?.NearBy ?? null}",
            Latitude : "${latitude}",
            Longitude : "${longitude}",
            maxDistance : "50",
            Authorization : "${token ? token.authToken : null}",
            hide: ${searchFilters.hidePrevious},
          ) {
            aggregate {
              count
              totalCount
            }
            gigCount{
              title
             count
         }
          experienceCount{
              title
             count
          }     
            data {
              _id
              ProfileId
              Title
              CreatedBy
              ModifiedBy
              IsActive
              Description
              Skills
              GigType
              MinimumBid
              MinimumBidType
              users {name,email,profile {PictureUrl,ExperienceLevelName,LastLoginDate, LastSuccessfulLoginDate}}
              bidstatus
              prevviewed
              Rating
              Review
            }
          }
        }
      `;

  const BackendApiBaseUrl = REACT_APP_API_URL;
  const [hitQuery, { loading, error, data }] = useLazyQuery(query);
  const httpLink = new HttpLink({
    // uri: `${BackendApiBaseUrl}/query`,
    uri: `${BackendApiBaseUrl}/guest/skilllisting/search`,
    // uri: `http://192.168.18.48:3000/development/guest/skilllisting/search`
  });

  const client = new ApolloClient({
    // uri: `${BackendApiBaseUrl}/query`,
    cache: new InMemoryCache(),
    link: ApolloLink.from([httpLink]),
  });

  useEffect(() => {
    props.searchResponse(data);
  }, [data, props.value, loading]);

  useEffect(() => {
    setPageNo(1);
    dispatch(GetFilters()); //get search filters
    debounce(hitQuery());
  }, []);

  useEffect(() => {
    // saving loading state to reducer from graphQL
    if (props.value.length > 0) {
      console.log("skilll data 22");
      props.SearchResponseRequested(loading);
    }
  }, [loading]);

  useEffect(() => {
    console.log("skilll data", data);
    setGigs(props.skillListings.length ? data : data);
  }, [props.skillListings]);

  console.log("my data", props.skillListings);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const clearAllFunction = () => {
    setVisible(false);
    setJobTypeRadios(null);
    setCheckRadio(null);
    setCheckRadioLoc("");
    setClearForm(true);
    setHideCheck(false);
    setBudget({ budgetMax: null, budgetMin: null, budgetType: null });
    setSearchFilters({
      ...searchFilters,
      location: null,
      skillListing_type: null,
      budgetType: null,
      ExperienceLevel: null,
      budgetMin: null,
      budgetMax: null,
      hidePrevious: null,
    });
    setFilterCount(0);
  };

  const clearFilter = (type) => {
    if (searchFilters.ExperienceLevel && type == "experience") {
      setSearchFilters({ ...searchFilters, ExperienceLevel: null });
      setCheckRadio(null);
      setFilter({ ExperienceLevel: null });
    }
    if (searchFilters.location && type == "location") {
      setSearchFilters({ ...searchFilters, location: null });
      setCheckRadioLoc(null);
      setFilter({ location: null });
    }
    if (
      (searchFilters.skillListing_type ||
        searchFilters.skillListing_type === null) &&
      type == "jobType"
    ) {
      setSearchFilters({ ...searchFilters, skillListing_type: null });
      setJobTypeRadios(null);
      setFilter({ skillListing_type: null });
    }
    if (
      searchFilters.budgetMax &&
      searchFilters.budgetMin &&
      type === "budget"
    ) {
      setSearchFilters({
        ...searchFilters,
        budgetMax: null,
        budgetMin: null,
        budgetType: null,
      });
      setBudget({ budgetMax: null, budgetMin: null, budgetType: null });
      setFilter({ budgetMax: null, budgetMin: null, budgetType: null });
      setClearForm(true);
    }
  };

  const ClearAll = ({ type }) => {
    return (
      <div className="clearAll-div">
        <div
          className="cancelfilter-btn"
          onClick={() => clearFilter(type)}
        >
          Clear All
        </div>
        <button
          className="applyfilter-btn"
          onClick={() => applyFilters(type)}
        >
          Apply
        </button>
      </div>
    );
  };

  const setFilter = (value) => {
    console.log("Filter called ", value);
    setSearchFilters(Object.assign(searchFilters, value));
    debounce(hitQuery());
    getFilterCount();
  };

  const getFilterCount = () => {
    let count = 0;
    if (searchFilters.ExperienceLevel != null) {
      count++;
    }
    if (searchFilters.NearBy != null) {
      count++;
    }
    if (searchFilters.budgetMax != null) {
      count++;
    }
    if (searchFilters.budgetMin != null) {
      count++;
    }
    if (searchFilters.budgetType != null) {
      count++;
    }
    // if (SearchFilters?.location != null) { count++ }
    if (searchFilters?.location != null) {
      count++;
    }
    if (searchFilters?.skillListing_type != null) {
      count++;
    }
    if (searchFilters?.hidePrevious != null) {
      count++;
    }
    setFilterCount(count);
  };

  useEffect(() => {
    var options = [];
    if (filters && filters.location) {
      for (var i = 0; i < filters.location.length; i++) {
        options = [
          ...options,
          {
            value: filters.location[i].LocationWithCountry,
          },
        ];
      }
      setLocationValue(options);
    }
  }, [props.value, filters]);

  const setJobType = (e) => {
    setJobTypeRadios(e.target.value);
  };

  const JobMenu = (
    <Menu className="skill-type-menu filter-menu">
      <div style={{ width: "90%", margin: "auto" }}>
        <Radio.Group value={jobTypeRadio} onChange={(e) => setJobType(e)}>
          <Space direction="vertical">
          <Radio value={'Contract'} >Contract <div style={{display: "inline", color: "#7e7e7e" , }} >{"("}{skillListings && skillListings?.skilllistings
              ? skillListings?.skilllistings?.gigCount[0]?.count
              : 0 }{")"}</div></Radio>
            <Radio value={'Permanent'} >Permanent <div style={{display: "inline", color: "#7e7e7e", }} >{"("}{skillListings && skillListings?.skilllistings
              ? skillListings?.skilllistings?.gigCount[1]?.count
              : 0}{")"}</div></Radio>
            <Radio value={'Per Diem'} >Per Diem <div style={{display: "inline", color: "#7e7e7e", }} >{"("}{skillListings && skillListings?.skilllistings
              ? skillListings?.skilllistings?.gigCount[2]?.count
              : 0}{")"}</div></Radio>
          </Space>
        </Radio.Group>
      </div>
      <div style={{ padding: "0px 5px 0px 4px" }}>
        <ClearAll type={"jobType"} />
      </div>
    </Menu>
  );

  const experienceLevel = (
    <Menu className="experiecne-level-menu filter-menu">
      <ExperienceLevel
        setFilter={setFilter}
        setcheck={setCheckRadio}
        check={checkRadio}
      />
      <ClearAll type={"experience"} />
    </Menu>
  );

  const BudgetMenu = (
    <Menu className="filter-menu">
      <div className="budget-filter-container">
        <BudgetFilter
          setFilter={setFilter}
          Filter={searchFilters}
          budget={budget}
          setBudget={setBudget}
          clear={clearForm}
          setClear={setClearForm}
        />
        <ClearAll type={"budget"} />
      </div>
    </Menu>
  );

  const locationMenu = (
    <Menu className="location-filter-menu">
      <div className="location-filter-menu-inner">
        <FilterLocation
          setFilter={setFilter}
          setcheck={setCheckRadioLoc}
          check={checkRadioLoc}
        />
        <ClearAll type={"location"} />
      </div>
    </Menu>
  );
  console.log("search results", searchFilters);
  const CountDetail = (props) => {
    return (
      <p>
        Showing {props.skillListings} results for{" "}
        <strong style={{ textTransform: "capitalize" }}>
          {" "}
          "{props.value}"
        </strong>
      </p>
    );
  };

  const onEnterKey = (e) => {
    setPageNo(1);
    if (e.key === "Enter" && e.target.value.length > 0) {
      if (url.pathname != "/search") {
        history.push("/search");
      }
      props.searchValue(e.target.value);
    }
    // dispatch(SearchSuggestions(e.target.value));
  };

  const applyFilters = (type) => {
    setVisible(false);
    if (type !== "mobile") {
      if (type === "budget") {
        if (
          budget.budgetMax &&
          budget.budgetMin &&
          budget.budgetMax > budget.budgetMin
        ) {
          setFilter({
            budgetMax: budget.budgetMax,
            budgetMin: budget.budgetMin,
            budgetType: budget.budgetType,
          });
        } else {
          setFilter({ budgetMax: null, budgetMin: null, budgetType: null });
        }
      }
      if (jobTypeRadio && type === "jobType") {
        setFilter({ skillListing_type: jobTypeRadio });
      }
      if (checkRadioLoc && type === "location") {
        setFilter({ location: checkRadioLoc });
      }
      if (checkRadio && type === "experience") {
        setFilter({ ExperienceLevel: checkRadio });
      }
      if (closeModalSkill) {
        setCloseModalSkill(false);
      }
      if (closeModalLoc) {
        setCloseModalLoc(false);
      }
      if (closeModalBudget) {
        setCloseModalBudget(false);
      }
      if (closeModalExp) {
        setCloseModalExp(false);
      }
    } else {
      if (checkRadio) {
        setFilter({ ExperienceLevel: checkRadio });
      }
      if (
        budget.budgetMax &&
        budget.budgetMin &&
        budget.budgetMax > budget.budgetMin
      ) {
        setFilter({
          budgetMax: budget.budgetMax,
          budgetMin: budget.budgetMin,
          budgetType: budget.budgetType,
        });
      }
      if (jobTypeRadio) {
        setFilter({ skillListing_type: jobTypeRadio });
      }
      if (checkRadioLoc) {
        setFilter({ location: checkRadioLoc });
      }

      if (hideCheck) {
        setFilter({ hidePrevious: true });
      }
      if (!hideCheck) {
        setFilter({ hidePrevious: null });
      }
    }
  };

  const SortResults = () => {
    setSortType(window.sort);
    setPageNo(1);
    debounce(hitQuery());
  };

  const onChangePage = (page, pageSize) => {
    setPageNo(page);
    setCurrentPage(page);
    setPerPage(pageSize);
  };

  const mylocation = () => {
    if (searchFilters.NearBy) {
      setFilter({ NearBy: null });
      setLatitude();
      setLongitude();
    } else {
      setFilter({ NearBy: true });
      if (navigator.geolocation) {
        navigator.geolocation.watchPosition(function (position) {
          console.log("Location Latitude is :", position.coords.latitude);
          console.log("Location Longitude is :", position.coords.longitude);
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        });
      }
    }
  };

  const onCheckAllChange = (e) => {
    var myarr = [];
    setcheckAllClicked(e.target.checked);
    if (e.target.checked) {
      myarr = skillListings.skilllistings.data.filter(
        (data) => data.bidstatus == false
      );
    }
    setSkillListingIds(myarr);
  };

  const placeBid = () => {
    var candidateslistArray = [];
    for (var i = 0; i < skillListingIds.length; ++i) {
      candidateslistArray.push({
        id: skillListingIds[i]._id,
        img: skillListingIds[i]?.users?.profile?.PictureUrl,
        name: skillListingIds[i]?.users?.name ?? "Candidate Name",
        experience:
          skillListingIds[i]?.users?.profile?.ExperienceLevelName ?? "0-0",
        title: skillListingIds[i]?.Title,
        desired_rate: skillListingIds[i]?.MinimumBid,
        bidType: skillListingIds[i]?.MinimumBidType,
        profileType: profileTypeRes?.profile?.ProfessionCode,
        lastActive: skillListingIds[i]?.users?.profile?.LastLoginDate,
        candidate_id: candidateId,
      });
    }
    console.log("All Selected Candidates are ", candidateslistArray);
    setcandidateList(candidateslistArray);
    setplacebidModal(true);
    console.log("candidate list length is", candidateList);
  };

  const cancelBid = () => {
    setSkillListingIds([]);
    setcheckAllClicked(false);
  };

  const placeBidModalVisisblityHandler = (value) => {
    setplacebidModal(value);
  };

  const onClick = ({ key }) => {
    if (key == "most_relevant") {
      setSortType("MOST_RELEVANT");
      setsortTypeText("Most Relevant");
    } else if (key == "most_recent") {
      setSortType("MOST_RECENT");
      setsortTypeText("Most Recent");
    } else if (key == "ratings_high_to_low") {
      setSortType("RATINGS_HIGH_TO_LOW");
      setsortTypeText("Ratings High to Low");
    } else if (key == "ratings_low_to_high") {
      setSortType("RATINGS_LOW_TO_HIGH");
      setsortTypeText("Ratings Low to High");
    } else if (key == "PAY_HI_TO_LOW") {
      setSortType("PAY_HI_TO_LOW");
      setsortTypeText("Desired Rate High to Low");
    } else if (key == "PAY_LOW_TO_HI") {
      setSortType("PAY_LOW_TO_HI");
      setsortTypeText("Desired Rate Low to High");
    }
  };

  const menu = (
    <Menu onClick={onClick}>
      <Menu.Item key="most_relevant">Most Relevant</Menu.Item>
      <Menu.Item key="most_recent">Most Recent</Menu.Item>
      <Menu.Item key="ratings_high_to_low">Ratings High to Low</Menu.Item>
      <Menu.Item key="ratings_low_to_high">Ratings Low to High</Menu.Item>
      {professionCode && professionCode == "IT" ? null : (
        <>
          <Menu.Item key="PAY_HI_TO_LOW">Desired Rate High to Low</Menu.Item>
          <Menu.Item key="PAY_LOW_TO_HI">Desired Rate Low to High</Menu.Item>
        </>
      )}
    </Menu>
  );

  const hidePreviousFunc = (e) => {
    if (hideCheck) {
      setHideCheck(false);
      setSearchFilters({ ...searchFilters, hidePrevious: null });
    } else {
      setHideCheck(true);
      setSearchFilters({ ...searchFilters, hidePrevious: true });
    }
  };

  return (
    <React.Fragment>
      <main className="gql-mainDiv">
        <div className="gql-dashboard-Headers">
          <div className="gql-countCheck">
            {skillListings &&
            skillListings.skilllistings > 0 &&
            props.value.length > 0 ? (
              <CountDetail gigs={skillListings.length} value={props.value} />
            ) : null}
          </div>
          <div className="gql-viewAllBtn"></div>
        </div>
        {/* //// Tags//// */}
        {!props.loading && props.value.length ? (
          <div className={"results_descriptiontext"}>
            Showing{" "}
            {skillListings && skillListings.skilllistings
              ? skillListings.skilllistings.aggregate.totalCount
              : 0}{" "}
            results for <strong>"{props.value}"</strong>
          </div>
        ) : null}
        <Col
          xs={24}
          className={
            windowDimensions.width < 769
              ? "suggestion-wrdapper-mobile"
              : "suggestion-wrdapper"
          }
        >
          {suggestions.skillsListing
            ? suggestions.skillsListing.map((suggesteditems) => {
                return (
                  <Tag  color="default" >
                    <Text style={{ color: "#9e9e9e" }}>{suggesteditems}</Text>
                  </Tag>
                );
              })
            : null}
        </Col>
        {/* ////// Dropdowns //// */}
        {/* https://ant.design/components/dropdown/ */}

        {windowDimensions.width > 768 ? (
          props.value.length ? (
            <>
              <div className="filters-container">
                <Space wrap className="space">
                  <Dropdown
                    overlay={JobMenu}
                    trigger="click"
                    className={
                      searchFilters?.skillListing_type && "dropdown-job"
                    }
                    visible={closeModalSkill}
                    onVisibleChange={() => {
                      setCloseModalSkill(!closeModalSkill);
                    }}
                  >
                    <Button className="skill-list-type-dropdown">
                      <span style={{fontWeight: '500'}}>Skill Listing Type </span><DownOutlined />
                    </Button>
                  </Dropdown>
                  <Dropdown
                    overlay={locationMenu}
                    className={searchFilters?.location && "dropdown-job"}
                    trigger="click"
                    visible={closeModalLoc}
                    onVisibleChange={() => {
                      setCloseModalLoc(!closeModalLoc);
                    }}
                  >
                    <Button className="visaStatus-dropdown">
                    <span style={{fontWeight: '500'}}>Location </span><DownOutlined />
                    </Button>
                  </Dropdown>
                  <Dropdown
                    overlay={BudgetMenu}
                    className={
                      searchFilters?.budgetMin && searchFilters.budgetMax
                        ? "dropdown-job"
                        : ""
                    }
                    trigger="click"
                    visible={closeModalBudget}
                    onVisibleChange={() => {
                      setCloseModalBudget(!closeModalBudget);
                    }}
                  >
                    <Button className="visaStatus-dropdown">
                    <span style={{fontWeight: '500'}}>Budget </span><DownOutlined />
                    </Button>
                  </Dropdown>
                  <Dropdown
                    overlay={experienceLevel}
                    className={
                      searchFilters?.ExperienceLevel && "dropdown-job"
                    }
                    trigger="click"
                    visible={closeModalExp}
                    onVisibleChange={() => {
                      setCloseModalExp(!closeModalExp);
                    }}
                  >
                    <Button className="skill-list-type-dropdown">
                    <span style={{fontWeight: '500' }}>Experience Level </span><DownOutlined />
                    </Button>
                  </Dropdown>
                </Space>

                <div className="previous-nearby-filter-div">
                  {/* <Radio className="hide-previously-filter">Hide previously viewed</Radio> */}
                  {/* <HidePreviouslyViewedFilter /> */}

                  {authToken != null && (
                    <div
                      style={{ width: "100%", marginTop: "0.6%" }}
                      onClick={(e) => hidePreviousFunc(e)}
                    >
                      <input
                        style={{ marginLeft: "2%", cursor: "pointer" }}
                        value=""
                        checked={hideCheck ? "true" : ""}
                        type="radio"
                        name="hidePreviouslyViewed"
                      />{" "}
                      &nbsp;Hide previously viewed
                    </div>
                  )}

                  <Row
                    style={{
                      width: "100%",
                      justifyContent: authToken == null && "right",
                    }}
                  >
                    <div className="location-button">
                      <Switch onChange={mylocation} />
                    </div>
                    <div className="Nearby-location-text">
                      Nearby Candidates
                    </div>
                  </Row>
                </div>
              </div>
              <div className="applied-filter">
                {searchFilters?.skillListing_type ? (
                  <Tag
                    closable={true}
                    className="tag-filter"
                    onClose={() => {
                      setSearchFilters({
                        ...searchFilters,
                        skillListing_type: null,
                      });
                      // SetSearchFilters({ ...searchFilters, skillListing_type: null })
                      setJobTypeRadios();
                    }}
                  >
                    {searchFilters.skillListing_type}
                  </Tag>
                ) : null}
                {searchFilters?.location ? (
                  <Tag
                    closable={true}
                    className="tag-filter"
                    onClose={() => {
                      setSearchFilters({ ...searchFilters, location: null });
                      setCheckRadioLoc(null);
                      setFilter({ location: null });
                    }}
                  >
                    {searchFilters.location}
                  </Tag>
                ) : null}
                {searchFilters?.budgetMin && searchFilters.budgetMax ? (
                  <Tag
                    closable={true}
                    className="tag-filter"
                    onClose={() => {
                      setSearchFilters({
                        ...searchFilters,
                        budgetMin: null,
                        budgetMax: null,
                        budgetType: null,
                      });
                      setBudget({
                        ...budget,
                        budgetMax: null,
                        budgetMin: null,
                        budgetType: null,
                      });
                      setCheckRadio("closeBud");
                      setClearForm(true);
                    }}
                  >
                    {"$" +
                      searchFilters.budgetMin +
                      " - " +
                      "$" +
                      searchFilters.budgetMax}
                  </Tag>
                ) : null}
                {searchFilters?.ExperienceLevel ? (
                  <Tag
                    closable={true}
                    className="tag-filter"
                    onClose={() => {
                      setSearchFilters({
                        ...searchFilters,
                        ExperienceLevel: null,
                      });
                      SetSearchFilters({
                        ...searchFilters,
                        ExperienceLevel: null,
                      });
                      // setCheckRadio('closeExp')
                    }}
                  >
                    {searchFilters.ExperienceLevel}
                  </Tag>
                ) : null}
                <div className="div2-searchResults">
                  <div className="sortText">Sort by</div>
                  <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    className="dropdown1"
                  >
                    {/* <Button style={{ border: "none" }}>
                      <div className="filterDropDownText">
                        {sortTypeText}
                      </div>
                      <DownOutlined className="dropDownIcon" />
                    </Button> */}
                    <Space style={{ border: "none" }}>
                      <a
                        style={{ border: "none" }}
                        onClick={(e) => e.preventDefault()}
                      >
                        <div className="filterDropDownText">
                          {sortTypeText}
                        </div>
                      </a>
                      <DownOutlined />
                    </Space>
                  </Dropdown>
                </div>
              </div>

              <div className="select-all-div">
                {authToken != null && (
                  <div className="div1-searchResults">
                    <Checkbox
                      className="checkAll-checkbox"
                      onChange={onCheckAllChange}
                      checked={checkAllClicked}
                    ></Checkbox>
                    <div style={{ paddingLeft: "10px", paddingTop: "1px" }}>
                      Select All Candidate for Bid
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : null
        ) : (
          props.value.length && (
            <>
              <Row
                style={{
                  justifyContent: "space-between",
                  width: "99%",
                  marginTop: "3px",
                }}
              >
                <div
                  className="filter-button1"
                  onClick={() => setVisible(true)}
                >
                  {/* <img className={"filter-button-icon"} alt="" src={FilterIcon} /> */}
                  <div className={"filter-button-text"}>
                    Filter <Text>{filterCount > 0 && `(${filterCount})`}</Text>{" "}
                  </div>
                </div>

                <div
                  className="filter-button2"
                  onClick={() => setSortVisible(true)}
                >
                  {/* <img className={"filter-button-icon"} alt="" src={SortIcon} /> */}
                  <div className={"filter-button-text"}>Sort</div>
                </div>
              </Row>
              <Row
                style={{
                  marginTop: "18px",
                  paddingLeft: "2px",
                  marginBottom: "8px",
                }}
              >
                <Col lg={18} xs={17} sm={17} className="location-text">
                  Nearby Candidates
                </Col>
                <Col
                  lg={5}
                  sm={6}
                  xs={6}
                  className="location-button-mobileView"
                >
                  <Switch onChange={mylocation} />
                </Col>
              </Row>
              <Modal
                title={
                  <div>
                    <Row>
                      <Col
                        xs={5}
                        className={"clearAll"}
                        onClick={clearAllFunction}
                      >
                        Clear All
                      </Col>
                      <Col xs={14} className={"filter-text"}>
                        Filters
                      </Col>
                    </Row>
                  </div>
                }
                centered
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={1000}
                height={1000}
                footer={[
                  <Button
                    key="back"
                    onClick={() => applyFilters("mobile")}
                    className="modal-button"
                  >
                    Apply Filter
                  </Button>,
                ]}
              >
                <Filters
                  prop="prop"
                  setFilter={setFilter}
                  setBudget={setBudget}
                  setClear={setClearForm}
                  budget={budget}
                  clear={clearForm} //for budget
                  setcheck={setCheckRadio}
                  check={checkRadio} //for experience level
                  setJobTypeRadios={setJobTypeRadios}
                  jobTypeRadio={jobTypeRadio} //for job type
                  setCheckRadioLoc={setCheckRadioLoc}
                  checkRadioLoc={checkRadioLoc} //for location
                  authToken={authToken}
                  hideCheck={hideCheck}
                  setHideCheck={setHideCheck}
                  searchFilters={searchFilters}
                  setSearchFilters={setSearchFilters}
                />
              </Modal>

              {/* Modal for sort */}
              <Modal
                title="Sort Candidates by"
                centered
                visible={sortvisible}
                onOk={() => setSortVisible(false)}
                onCancel={() => setSortVisible(false)}
                width={1000}
                height={1000}
                footer={[
                  <Button
                    key="back"
                    className="modal-button"
                    onClick={SortResults}
                  >
                    Show Results
                  </Button>,
                ]}
              >
                <Filters />
              </Modal>
              {authToken != null && props.value.length && !props.loading ? (
                <Col className="select-all">
                  <Checkbox onChange={onCheckAllChange}></Checkbox>
                  <Col style={{ paddingLeft: "10px", paddingTop: "1px" }}>
                    Select all Candidate for Bid
                  </Col>
                </Col>
              ) : null}
            </>
          )
        )}

        <div
          className={`${props.value.length == 0 && "skill-listings-container"} `}
        >
          {props.value.length > 0 && !props.loading && props.value != null ? (
            <div
              className={props.value.length > 0 && "skill-listings-container"}
              style={{
                maxWidth:
                  skillListings?.skilllistings?.data?.length <= 3 &&
                  windowDimensions.width > 768 &&
                  "55%",
              }}
              Pagination={true}
            >
              {skillListings && skillListings.skilllistings
                ? skillListings.skilllistings.data.map((data) => {
                    return (
                      <>
                        <GigsCard
                          totalRecords={
                            skillListings?.skilllistings?.data?.length
                          }
                          {...data}
                          token={authToken}
                          skillListingIds={skillListingIds}
                          setSkillListingIds={setSkillListingIds}
                          setcheckAllClicked={setcheckAllClicked}
                          setCandidateId={setCandidateId}
                        />
                      </>
                    );
                  })
                : null}
            </div>
          ) : (
            <div className="gql-message">
              {props.value.length === 0 &&
                `Search Skill Listings`}
            </div>
          )}
          {props.value.length > 0 && loading ? (
            <>
              <div className="gql-loader">
                <LoadingOutlined />
              </div>
            </>
          ) : null}
          {skillListings?.skilllistings?.data?.length === 0 ? (
            // <div className="skill-listings-container">
            //   <div className="gql-message">No Results Found For Your Search</div>
            // </div>

            <div className="noJobsContainer">
              <img
                // src={emptyresult}
                alt="no-jobsimg"
                className="joblisting-noimg"
                style={{ width: "15rem", height: "9.3rem" }}
              ></img>
              <>
                <div>
                  <p className="NoResultText">
                    {" "}
                    No Results Found For Your Search{" "}
                  </p>
                  <p className="NoResultText2">
                    Try searching something else
                  </p>
                </div>
              </>
            </div>
          ) : null}
        </div>
        {windowDimensions.width < 769 &&
        authToken != null &&
        skillListingIds?.length > 0 ? (
          <Col xs={24} className="multi-bid-btn-div">
            <Button className={"btn-multi-bids"} onClick={placeBid}>
              <Text className="multi-bids-btn-text">
                Place a Bid{" "}
                {skillListingIds?.length > 0
                  ? "( " + skillListingIds?.length + " Candidates )"
                  : null}
              </Text>
            </Button>

            <button
              className="mobile-cancel-bid-button"
              onClick={cancelBid}
            >
              Cancel
            </button>
          </Col>
        ) : authToken != null && skillListingIds?.length > 0 ? (
          <div className="btn-multi-div">
            <button className={"btn-multi-bids"} onClick={placeBid}>
              Place a Bid{" "}
              {skillListingIds?.length > 0
                ? "( " + skillListingIds?.length + " Candidates )"
                : null}
            </button>

            <button
              className="desktop-cancel-bid-button"
              onClick={cancelBid}
            >
              Cancel
            </button>
          </div>
        ) : null}
        {props.value.length > 0 ? (
          windowDimensions.width > 769 ? (
            <div style={{ margin: "auto", marginTop: "30px" }}>
              <Pagination
                total={
                  skillListings && skillListings.skilllistings
                    ? skillListings.skilllistings.aggregate.totalCount
                    : ""
                }
                pageSize={perPage}
                defaultCurrent={1}
                current={currentPage}
                pageSizeOptions={[12, 20]}
                onChange={(page, pageSize) => onChangePage(page, pageSize)}
                hideOnSinglePage={true}
              />
            </div>
          ) : (
            <div>
              <MobilePagination
                span={24}
                current={currentPage}
                defaultPageSize={20}
                total={
                  skillListings && skillListings.skilllistings
                    ? skillListings.skilllistings.aggregate.totalCount
                    : ""
                }
                onChange={(page) => onChangePage(page, 12)}
              />
            </div>
          )
        ) : null}
        {/* <PlaceBidModal
          visible={placebidModal}
          placeBidModalVisisblityHandler={placeBidModalVisisblityHandler}
          selectedCandidates={candidateList}
          multiBid={true}
          setSkillListingIds={setSkillListingIds}
          setcheckAllClicked={setcheckAllClicked}
        // desired_rate={}
        /> */}
      </main>
    </React.Fragment>
  );
});

const mapStateToProps = (state) => {
  return {
    loading: state.searchSkillListingReducer.loading,
    skillListings: state.searchSkillListingReducer.skillListings,
    value: state.searchGigsReducer.searchvalue,
    error: state.searchSkillListingReducer,
    token: state.authReducer.user?.authToken,
    authToken: state.authReducer.user.authToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SearchResponseRequested: (params) => {
      dispatch(SearchResponseRequestedSkillListing(params)); // action dispatched
    },
    searchResponse: (params) => {
      dispatch(SearchResponseSkillListing(params)); // action dispatched
    },
    searchValue: (params) => {
      dispatch(SearchValue(params)); // action dispatched
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
