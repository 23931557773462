import { call, put, takeEvery } from "redux-saga/effects";
import { types } from "../action-types";
import { getFiltersAPi, getSearchSuggestionsApi } from "../../API/API";

function* searchGetFiltersSaga() {
  try {
    const response = yield call(() => getFiltersAPi()); // API Backend
    console.log(response.data);
    yield put({ type: types.GET_FILTERS_SUCCESS, payload: response.data });
    // yield put(fetchDataSuccess(response.data));
  } catch (error) {
    yield put({ type: types.GET_FILTERS_FAILURE, error });
  }
}

function* getSearchSuggestions(action) {
  try {
    const response = yield call(() => getSearchSuggestionsApi(action.payload));
    yield put({ type: types.SEARCH_SUGGESTIONS_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.SEARCH_SUGGESTIONS_FAILURE, error });
  }
}


export function* watchGetFiltersSaga() {
  yield takeEvery(types.GET_FILTERS_REQUESTED, searchGetFiltersSaga);
}
export function* watchGetSearchSuggestionSaga() {
  yield takeEvery(types.SEARCH_SUGGESTIONS_REQUESTED, getSearchSuggestions);
}
