import React, { useState, useEffect } from "react";
import {
  Menu,
  Select,
  Space,
  Dropdown,
  Button,
  message,
  Radio,
  Tag,
  Row,
  Switch,
  Pagination,
  Input,
  Col,
  Typography,
  AutoComplete,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
//import "./Filters.css";
import { useDispatch, useSelector } from "react-redux";
import Location from "../components/LocationFilter/LocationFilter";
import ExperienceLevel from "../components/ExperienceLevelFilter/ExperienceLevelFilter";
import BudgetFilter from "../components/BudgetFilter/BudgetFilter";
import FilterLocation from "../components/LocationFilter/location.filter";
import { HidePreviouslyViewedFilter } from "../components/HidePreviouslyViewedFilter";

const Filters = (props) => {
  const { Text } = Typography;
  const [sortOption, setSortOption] = useState("");
  const [skillType, setSkillType] = useState(null);
  window.sort = sortOption;
  window.skillType = skillType;

  const sortOptionSelected = (e) => {
    console.log("sort", e.target.value);
    setSortOption(e.target.value);
  };

  const skillTypeSelected = (e) => {
    props.setJobTypeRadios(e.target.value);
  };

  return (
    <>
      {props.prop ? (
        <Col sm={24} xs={24} className="filters-container-mobile">
          <Text className="filter-heading">Skill Listing Type</Text>{" "}
          <br />
          <Radio.Group
            onChange={skillTypeSelected}
            value={props.jobTypeRadio}
            style={{ marginBottom: "5%", width: "100%" }}
          >
            <Space direction="vertical" style={{ width: "100%" }}>
              <Radio className="radios" value={"Contract"}>
                Conact
              </Radio>
              <Radio className="radios" value={"Permanent"}>
                Permanent
              </Radio>
              <Radio className="radios" value={"Per Diem"}>
                Per Diem
              </Radio>
            </Space>
          </Radio.Group>{" "}
          <br />
          <Text className="filter-heading">Location</Text> <br />
          <Location
            setCheck={props.setCheckRadioLoc}
            check={props.checkRadioLoc}
          />
          {/* <FilterLocation/> */}
          <Text className="filter-heading">Experience Level</Text> <br />
          <ExperienceLevel
            setFilter={props.setFilter}
            check={props.check}
            setcheck={props.setcheck}
          />
          <div style={{ marginTop: "4.2%", height: "1px" }}>
            <Text className="filter-heading">Budget</Text>
          </div>{" "}
          <br />
          <BudgetFilter
            setFilter={props.setFilter}
            setClear={props.setClear}
            setBudget={props.setBudget}
            budget={props.budget}
            clear={props.clear}
          />
          {props.authToken != null && (
            <HidePreviouslyViewedFilter
              hideCheck={props.hideCheck}
              setHideCheck={props.setHideCheck}
              searchFilters={props.searchFilters}
              setSearchFilters={props.setSearchFilters}
            />
          )}
        </Col>
      ) : (
        <Col sm={24} xs={24} className="filters-container">
          <Radio.Group
            style={{ marginBottom: "5%", width: "100%" }}
            onChange={sortOptionSelected}
          >
            <Space direction="vertical" style={{ width: "100%" }}>
              <Radio className="radios" value={"MOST_RELEVANT"}>
                Most Relevant
              </Radio>
              <Radio className="radios" value={"MOST_RECENT"}>
                Most Recent
              </Radio>
              <Radio className="radios" value={"RATINGS_HI_TO_LOW"}>
                Ratings High to Low
              </Radio>
              <Radio className="radios" value={"RATINGS_LOW_TO_HI"}>
                Ratings Low to High
              </Radio>
              <Radio className="radios" value={"PAY_HI_TO_LOW"}>
                Desired Rate High to Low
              </Radio>
              <Radio className="radios" value={"PAY_LOW_TO_HI"}>
                Desired Rate Low to High
              </Radio>
            </Space>
          </Radio.Group>
        </Col>
      )}
    </>
  );
};
export default Filters;
