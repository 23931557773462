import { put, call, takeEvery } from "@redux-saga/core/effects";
import {UpdateNotificationAPI } from "../../API/API";
import { types } from "../action-types";
import { UpdateNotificationFailure, UpdateNotificationSuccess } from "../actions";

function* UpdateNotificationSaga (action) {

    console.log("Notification Saga==========================================================================>",action.payload);
    try{
        console.log("Notification Saga Is working");
        const response = yield call (()=>UpdateNotificationAPI(action.payload))
        console.log("Notification Response ===========================================>" , response);
        if(response.status === 200){
            yield put (UpdateNotificationSuccess(response.profile))
        }else{
            yield put (UpdateNotificationFailure(response))
        }

    }catch(error){
        yield put (UpdateNotificationFailure(error))
    }
}

export function* watchNotificationSaga(){
    yield takeEvery(types.UPDATE_NOTIFICATION_INIT , UpdateNotificationSaga)
}