import React from "react";

//import "./ProfileAvatar.css";
import { useUpdateLogger } from "../shared/useUpdateLogger";

const ProfileAvatar = (props) => {
  // useUpdateLogger("props.profilePic", props.profilePic);
  return (
    <div className="avatar-container" onClick={props.onClick}>
      <span
        className="avatar-badge"
        style={{ display: `${!props.showBadge && "none"}` }}
      ></span>
      {props.profilePic ? (
        <img
          src={`${process.env.REACT_APP_S3_URL}/${props.profilePic}`}
          className="avatar-img"
        />
      ) : (
        <div
          className="avatar-initial"
          style={{ fontSize: props.fontSize }}
        >
          {props.name?.charAt(0).toUpperCase()}
        </div>
      )}
    </div>
  );
};

export default ProfileAvatar;
