import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

const Loader = ({ size, color, height }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        fontSize: size ? size : "2.5rem",
        margin: `${height ? height : "15vh"} auto`,
        color: color ? color : "#118936",
      }}
    >
      <LoadingOutlined />
    </div>
  );
};

export default Loader;
