import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import logo from "../../assets/icons/Logo.svg";
import avatar from "../../assets/img/avatar.png";

import { connect } from "react-redux";

import "./Header.css";
import Sidebar from "../../pages/Credentialing/components/Sidebar";

import {
  ConsoleSqlOutlined,
  LockOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import { MdEmail } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import { MdOutlineMessage } from "react-icons/md";
import { BsBell } from "react-icons/bs";
import { IoIosStar } from "react-icons/io";

import { GiHamburgerMenu } from "react-icons/gi";

import {
  Modal,
  Tooltip,
  Popover,
  Badge,
  Drawer,
  Layout,
  Menu,
  Dropdown,
} from "antd";

import ProfileHeader from "./components/ProfileHeader";
// import ProfileAvatar from "../ProfileAvatar";
import { Link, Switch, useHistory } from "react-router-dom";
// import SearchGigs from "../pages/SearchGigs/SearchGigs";
import GuestSearchGigs from "../../pages/SearchGigs/GuestUser/GuestSearchGigs";
import { useLocation, useRouteMatch } from "react-router-dom";
import { sectionHeader } from "@aws-amplify/ui";
import {
  checkEmailFlow,
  fetchUserProfile,
  isModalVisibleAuth,
  SearchValue,
  signUpLoginUpdate,
} from "../../redux/actions";
import { getCookie, setCookie } from "../../shared/helpers";
import SignUpLogin from "../../pages/Auth/components/SignUpLogin";
import SearchBar from "../../pages/SearchGigs/SearchBar";
import SearchBarMobile from "../../pages/SearchGigs/components/SearchBarMobile";
import ManageBidsMenu from "./components/ManageBidsMenu";
import ProfileAvatar from "../ProfileAvatar";
import Timer from "../Timer";
import { useUpdateLogger } from "../../shared/useUpdateLogger";

const Header = (props) => {
  const [loggedInSearchBar, setloggedInSearchBar] = useState(false);
  const dispatch = useDispatch();
  const { path, url } = useRouteMatch();
  const initialPath = useLocation().pathname.split("/")[1];
  const subrout = useLocation().pathname.split("/")[2];
  const [sbarVisible, setsbarVisible] = useState(false);
  const [proImg, setProImg] = useState(null);
  const [userName, setUserName] = useState("");
  const [profession, setProfession] = useState("");

  const signUpLogin = useSelector((state) => state.authReducer.signUpLogin);

  const urlCheck = useLocation();

  const history = useHistory();

  // const [isModalVisibleAuth, setIsModalVisibleAuth] = useState(false);
  const { isModalVisibleAuth, setIsModalVisibleAuth } = props;

  const { authToken } = props;

  const showModal = () => {
    setIsModalVisibleAuth(true);
  };

  const modalCancel = () => {
    setIsModalVisibleAuth(false);
  };

  const [check, setCheck] = useState("");
  const [header, setHeader] = useState("");
  let headerType = "";

  const [drawerState, setDrawerState] = useState({
    visible: false,
    placement: "left",
  });

  const [drawerStates, setDrawerStates] = useState({
    visibles: false,
    placements: "left",
  });

  const [sgLogo, setSgLogo] = useState(false);
  const [dimensions, setDimensions] = useState(window.innerWidth);
  // const { placements, visibles } = this.state;
  // const []

  const [dropVisible, setDropVisible] = useState(false);
  const [dropDisable, setDropDisable] = useState(false);

  const [dropVisibleBids, setDropVisibleBids] = useState(false);

  const handleVisibleChange = (visible) => {
    setDropVisible(visible);
  };

  const handleVisibleChangeBids = (visible) => {
    setDropVisibleBids(visible);
  };

  const hideDrop = () => {
    setDropVisible(false);
  };

  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
    if (getCookie("token") !== null && getCookie("token").length > 0) {
      const token = getCookie("token");
      props.profileDetails(token);
    }
  }, []);

  useEffect(() => {
    console.log("Token:", authToken);
  }, [authToken]);

  useEffect(() => {
    if (dimensions <= 1000) {
      setSgLogo(true);
    } else setSgLogo(false);
  }, [dimensions]);

  const clicked = () => {
    setsbarVisible(!sbarVisible);
    console.log(sbarVisible);
  };

  const signInUser = () => {
    if (window.innerWidth < 1000) {
      history.push("/login");
      setDrawerStates({ visibles: false });
    } else {
      showModal();
    }
    dispatch(checkEmailFlow(false));
    dispatch(signUpLoginUpdate(false));
  };

  const joinUser = () => {
    if (window.innerWidth < 1000) {
      dispatch(SearchValue(""));
      history.push("/login");
      setDrawerStates({ visibles: false });
    } else {
      showModal();
    }
    // dispatch(checkEmailFlow(false));
    dispatch(signUpLoginUpdate(true));
  };

  useEffect(() => {
    modalCancel();
  }, [urlCheck]);

  let headerContent = (
    <span>
      <LockOutlined /> Secured connection
    </span>
  );

  const showDrawer = () => {
    setDrawerState({ visible: true });
  };

  const onClose = () => {
    setDrawerState({ visible: false });
  };

  const showDrawers = () => {
    setDrawerStates({ visibles: true });
  };

  const onCloses = () => {
    setDrawerStates({ visibles: false });
  };

  useEffect(() => {
    if (props.profileImg) {
      setProImg(props.profileImg.PictureUrl);
    }

    // Displayed User Name
    if (props.profile.length > 10) {
      const name = props.profile.split(" ")[0];
      setUserName(name);
    } else {
      setUserName(props.profile);
    }

    const pro = props?.firstUser?.profile?.ProfessionCode;
    if (pro === "IT") {
      setProfession("IT");
    } else if (pro === "FL") {
      setProfession("IT Freelancer");
    } else {
      setProfession("Health Care");
    }

    console.log("Profile:", props.firstUser.profile.ProfessionCode);
  }, [props.profile]);

  const openMessages = () => {
    history.push({
      pathname: "/messages",
      state: {
        user: props.profile,
      },
    });
  };
  localStorage.setItem("userName", props.profile);

  const homepage = () => {
    history.push("/");
    setDrawerStates({ visibles: false });
  };
  // window.location.reload();

  // const ManageBidsMenu = (
  //   <Menu>
  //     <section className="manageBidsMenuHeader">
  //       <div className="receivedBidsHeader">
  //         <span>Received Bids</span>
  //         <span className="bidsCountHeader">3</span>
  //       </div>
  //       <div className="placedbidsHeader">Placed Bids</div>
  //       <div>Retracted Bids</div>
  //     </section>
  //   </Menu>
  // );

  const commonHeader = (
    <div className="wizard-head-dashboard" style={{ color: "white" }}>
      <div className="bars" onClick={showDrawer}>
        <GiHamburgerMenu />
      </div>
      {/* <div style={{ flex: 1 }}></div> */}
      {window.innerWidth > 1000 && <SearchBar />}
      {/* <div style={{ flex: 1 }}></div> */}
      {window.innerWidth < 1000 && (
        <img
          src={logo}
          alt="logo"
          className="logo"
          onClick={() => history.push("/")}
        />
      )}
      <div className="guest_default_loginDefaultButtonCSS theAlign">
        <Badge dot={true}>
          <Link to="/messages" className="inbox">
            Messages
          </Link>
        </Badge>
        <div className="skillListingButton">
          <Link className="skillListing" to="/manageskilllisting">
            Skill Listings
          </Link>
        </div>
        <div className="manageBidsButton">
          <Dropdown
            overlayClassName="header-dropdown-ManageBids"
            overlay={
              <ManageBidsMenu
                setDropVisibleBids={setDropVisibleBids}
                dropVisible={dropVisibleBids}
              />
              // <ProfileHeader hide={hideDrop} dropVisible={dropVisibleBids} />
            }
            onVisibleChange={() => handleVisibleChangeBids(!dropVisibleBids)}
            placement="bottomRight"
            trigger={["click"]}
            visible={dropVisibleBids}
            // disabled={dropDisable}
            arrow
          >
            <span className="manageBids">Manage Bids</span>
          </Dropdown>
        </div>
      </div>
      {window.innerWidth < 1000 && (
        <div className="mobileDefaultButtons">
          <div className="bellIcon">
            <Badge dot={true} onClick={() => history.push("/notifications")}>
              <BsBell color="white" fontSize={21} />
            </Badge>
          </div>
          <div className="messageIconHeader">
            <Badge dot={true} onClick={() => history.push("/messages")}>
              <MdOutlineMessage color="white" fontSize={23} />
            </Badge>
          </div>
        </div>
      )}
    </div>
  );

  useEffect(() => {
    if (initialPath == "credentialing") {
      setCheck(subrout);
    } else if (initialPath == "login") {
      setCheck(initialPath);
    } else if (initialPath == "search") {
      setCheck(initialPath);
    } else if (initialPath == "terms") {
      setCheck(initialPath);
    } else if (initialPath == "details") {
      setCheck(initialPath);
    } else if (initialPath == "forgot") {
      setCheck(initialPath);
    } else if (initialPath == "") {
      setCheck(initialPath);
    } else if (initialPath == "dashboard") {
      setCheck(initialPath);
    } else {
      setCheck(props.stage);
    }
    if (headerType == "default") {
      setHeader("black-header");
    }
    console.log("Initial path", initialPath);
    console.log("props stage", props.stage);

    console.log("check", check);

    // dropdown disable state on pages
    if (
      initialPath === "talentemployer" ||
      initialPath === "profession" ||
      initialPath === "role-experience" ||
      initialPath === "resume-upload" ||
      initialPath === "basic-profile-success" ||
      initialPath === "companydetails"
    ) {
      setDropDisable(true);
    } else setDropDisable(false);
  }, [initialPath, props.stage]);

  if (authToken != null) {
    headerContent = (
      <React.Fragment>
        <div className="wizard-head-dashboard" style={{ color: "white" }}>
          <img src={logo} alt="logo" className="logo" />
        </div>
        <div className="Avatar">
          <div className="Avat">{userName.charAt(0).toUpperCase()}</div>
        </div>
      </React.Fragment>
    );
  }

  switch (initialPath) {
    case "talentemployer":
    case "profession":
    case "role-experience":
    case "resume-upload":
    case "basic-profile-success":
      headerContent = (
        <>
          {window.innerWidth < 1000 && (
            <div
              className="wizard-head-dashboard-guest"
              style={{ color: "white" }}
            >
              <img src={logo} alt="logo" className="logo-guest" />
            </div>
          )}
        </>
      );
      break;

    default:
      headerType = "default";
      if (!authToken) {
        headerContent = (
          <div
            className="wizard-head-dashboard"
            style={{ color: "white" }}
          >
            <div className="bars" onClick={showDrawers}>
              <GiHamburgerMenu />
            </div>
            <img
              src={logo}
              alt="logo"
              className="logo"
              onClick={() => history.push("/")}
            />
            {window.innerWidth > 1000 && <SearchBar />}
            <section className="logoutButtonsHeader">
              <div className="btn_home" onClick={homepage}>
                Home
              </div>
              <div className="btn_sign_In" onClick={signInUser}>
                Sign In
              </div>
              <div className="theAlign">
                <div className="clickSkillListing" onClick={joinUser}>
                  Join
                </div>
              </div>
            </section>
          </div>
        );
      } else if (authToken && props.profileImg.ExperienceLevelName) {
        headerContent = commonHeader;
      } else {
        headerContent = (
          <>
            {window.innerWidth < 1000 && (
              <div
                className="wizard-head-dashboard-guest"
                style={{ color: "white" }}
              >
                <img src={logo} alt="logo" className="logo-guest" />
              </div>
            )}
          </>
        );
      }
      break;
  }

  const { placement, visible } = drawerState;
  const { placements, visibles } = drawerStates;

  const titleDrawer = (
    <div style={{ backgroupColor: "rgba(17, 137, 54, 0.2)" }}>
      <div className="title-drawer-mobileView">
        <Badge dot={true} status={"success" || "default"}>
          {/* <div className="Avat">{userName.charAt(0).toUpperCase()}</div> */}
          <div className="circle-wrap-p">
            <ProfileAvatar
              name={userName}
              profilePic={props.profileImg.PictureUrl}
              fontSize="1.25rem"
              onClick={() => {
                history.push("/profile");
                setDrawerState(false);
              }}
            />
          </div>
        </Badge>
        <div className="title-data">
          <div className="username-header">
            <span>{props.profile}</span>
            <span className="star-title-BM">
              <IoIosStar color="#ffba08" style={{ fontSize: "1.13rem" }} />
            </span>
            <span className="rating-title-BM">
              {props.firstUser.Rating || 3.5}
            </span>
          </div>
          <div className="hired">{props.profileImg.Discipline}</div>
          <div className="profession-change">
            {/* <span className="profession-title">{profession}</span> */}
            <span
              className="change-title"
              onClick={() => {
                history.push("/profile");
                setDrawerState(visible);
              }}
            >
              <u>Change</u>
            </span>
          </div>
        </div>
      </div>
      <Timer />
    </div>
  );

  const titleDrawerlogin = (
    <div>
      <div className="DrowerHeader">
        <img src={avatar} />
        <div>
          <div className="Guestuserfont">Guest User</div>
          <div className="welcomefont">Welcome to Skillgigs</div>
        </div>
      </div>
    </div>
  );

  useUpdateLogger("props hey", props);

  return (
    <React.Fragment>
      {sbarVisible && (
        <Sidebar changeStage={props.changeStage} hide={clicked} />
      )}
      <Modal
        bodyStyle={{ padding: 0 }}
        width="24.37rem"
        visible={isModalVisibleAuth}
        closable={false}
        footer={null}
        // style={{ top: 45, padding: 0 }}
        onCancel={modalCancel}
        maskClosable={true}
        centered={true}
        // destroyOnClose={true}
      >
        <SignUpLogin />
      </Modal>
      <div
        className={`header ${
          headerType === "default" && "black-header"
        }`}
      >
        {!sgLogo ? (
          <img
            src={logo}
            alt="logo"
            className="logo"
            onClick={() => history.push("/")}
          />
        ) : (
          <Drawer
            title={titleDrawer}
            placement="left"
            closable={false}
            onClose={onClose}
            visible={visible}
            className="burgerMenuDrawerMobileView"
            width="20rem"
            // key={placement}
          >
            <ProfileHeader
              changeStage={props.changeStage}
              hide={setDrawerState}
              visible={visible}
            />
          </Drawer>
        )}
        {headerContent}
        {authToken && window.innerWidth > 1000 && (
          <Dropdown
            overlayClassName="header-dropdown"
            overlay={<ProfileHeader hide={hideDrop} visible={dropVisible} />}
            onVisibleChange={() => handleVisibleChange(!dropVisible)}
            placement="bottomRight"
            trigger={["click"]}
            visible={dropVisible}
            disabled={dropDisable}
            arrow
          >
            <div className="profile theAlign Avat">
              <ProfileAvatar
                name={userName}
                profilePic={props.profileImg.PictureUrl}
              />
            </div>
          </Dropdown>
        )}

        <Drawer
          title={titleDrawerlogin}
          placement="left"
          closable={false}
          onClose={onCloses}
          visible={visibles}
          key={placements}
          className="burgerMenuDrawerMobileView"
        >
          <div className="clickSkillListinglogin" onClick={joinUser}>
            Join Skillgigs
          </div>
          <br></br>
          <div className="Mob_Btn">
            <span className="signIN" onClick={signInUser}>
              Sign In
            </span>
            <br></br>
            <span className="General_btn">General</span>
            <div className="Line-109"></div>
            <span className="homeBtn" onClick={homepage}>
              Home
            </span>
          </div>
        </Drawer>
      </div>
      {window.innerWidth < 1000 && (
        <nav
          className={
            authToken && loggedInSearchBar
              ? "searchHeaderMobileView searchHeaderMobileViewLoggedIn"
              : "searchHeaderMobileView"
          }
        >
          <SearchBar
            loggedInSearchBar={loggedInSearchBar}
            setloggedInSearchBar={setloggedInSearchBar}
          />
        </nav>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.user.name,
    profileImg: state.profileReducer.user.profile,
    firstUser: state.profileReducer.user,
    error: state.profileReducer.error,
    isModalVisibleAuth: state.authReducer.isModalVisibleAuth,
    authToken: state.authReducer.user.authToken,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    profileDetails: (token) => {
      dispatch(fetchUserProfile(token)); // action dispatched
    },
    setIsModalVisibleAuth: (params) => {
      dispatch(isModalVisibleAuth(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
