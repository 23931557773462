import { types } from "../action-types";

const initialState = {
  loading: false,
  bidStatus: [],
  bids: [],
  error: {},

  retractedBidData: [],
  placeBidData: [],
  isRetractedBids: false,
  detailPlacedBid: {},
  detailReceivedBid: {},
  declinedReasonsBid: [],
};

const BidsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_PLACE_BID_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_PLACE_BID_SUCCESS:
      return {
        ...state,
        loading: false,
        placeBidData: action.payload,
      };

    case types.FETCH_RECEIVEDBIDS_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_RECEIVEDBIDS_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        bidStatus: action.payload,
      };

    case types.FETCH_RECEIVEDBIDS_SUCCESS:
      return {
        ...state,
        loading: false,
        bids: action.payload,
        error: {},
      };
    case types.FETCH_RECEIVEDBIDS_FAILURE:
      return {
        ...state,
        loading: false,
        bids: [],
        error: action.error,
      };

    case types.FETCH_RETRACTED_BID_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_RETRACTED_BID_SUCCESS:
      return {
        ...state,
        loading: false,
        retractedBidData: action.payload,
      };

    case types.RETRACTED_BID_UPDATE:
      return {
        ...state,
        loading: false,
        isRetractedBids: action.payload,
      };

    case types.FETCH_DETAIL_PLACE_BID_INIT:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_DETAIL_PLACE_BID_SUCCESS:
      return {
        ...state,
        loading: false,
        detailPlacedBid: action.payload,
      };

    case types.FETCH_DETAIL_PLACE_BID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.FETCH_DETAIL_RECEIVED_BID_INIT:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_DETAIL_RECEIVED_BID_SUCCESS:
      return {
        ...state,
        loading: false,
        detailReceivedBid: action.payload,
      };

    case types.FETCH_DETAIL_RECEIVED_BID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.FAVORITE_BID_UPDATE:
      const obj = action.payload;

      if (obj?.type === "Gig" || obj.stage !== "Favorites") return;

      const filtereData = state.bids.body.data.filter(
        (item) => item._id !== obj?.id
      );

      return {
        ...state,
        bids: {
          ...state.bids,
          body: { ...state.bids.body, data: filtereData },
        },
      };

    case types.FETCH_DECLINED_REASONS_BIDS_INIT:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_DECLINED_REASONS_BIDS_SUCCESS:
      return {
        ...state,
        loading: false,
        declinedReasonsBid: action.payload,
      };

    case types.FETCH_DECLINED_REASONS_BIDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.UPDATE_DECLINED_REASONS_BIDS_INIT:
      return {
        ...state,
        loading: true,
      };

    case types.UPDATE_DECLINED_REASONS_BIDS_SUCCESS:
      return {
        ...state,
        loading: false,
        detailReceivedBid: {
          ...state.detailReceivedBid,
          BidStatusName: action.payload.BidStatusName,
          DeclineReason: action.payload.DeclineReason,
          ranking: {
            ...state.ranking,
            BidStatusName: action.payload.BidStatusName,
            BidStatusId: action.payload.BidStatusId,
          },
        },
      };

    case types.UPDATE_DECLINED_REASONS_BIDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default BidsReducer;
