import React, { useState } from 'react';
import { useEffect } from 'react';

const EmloyerAppp = () => {

	const [flag, setFlag] = useState();
	// const history = useHistory()
	const Profile = JSON.parse(localStorage.getItem('userProfile'));
	useEffect(() => {
		if (Profile?.profile
			.AccountTypeCode != 'EMP') {
			setFlag(false)
			window.location.replace('/')
		}
		else {
			setFlag(true)
		}
	}, [])

	return (
		<>
			{flag &&
				<employer-app />
			}
		</>
	)
}
export default EmloyerAppp