import React from "react";
//import "./TermsOfServices.css";
// import { Link } from "react-router-dom";
//import svg from src/assets/icons as link
// import HandSvg from "../../../../src/assets/icons/hand-right.svg";
import HandSvg from "../../../assets/icons/hand-right.svg";
import { Card } from "antd";
// const { Link } = Typography;

const TermsOfServices = () => {
  return (
    <Card className="tos-card">
      <div className="tos-page-layout">
        <div>
          <h2 className="tos-heading">Terms of Service</h2>
        </div>

        <p>Last Update: August 2021</p>
        <>
          <p>Welcome to Skillgigs</p>

          <p>
            Skillgigs is an AI Sourcing platform that places the best tech and
            healthcare talent within an innovative talent marketplace designed
            to help you make smarter, faster hiring decisions. Skillgigs builds
            relationships with top talent, and then helps this talent market
            themselves in our marketplace to top employers.
          </p>
          <p>
            The documents linked below are collectively referred to as the
            “Terms of Service.” Use of this website constitutes acknowledgment
            and acceptance of the Terms of Service.​​
          </p>
        </>

        <p className="subheading">Agreement for site users</p>

        <a href="https://skillgigs.com/site-agreement/">
          <p className="link-text">
            {" "}
            <img
              src={HandSvg}
              alt="Agreement for site users"
              className="handIcon"
              // style={{paddingLeft:"0.5rem"}}
            />
            Site Agreement and Service Policy{" "}
          </p>
        </a>
        <p className="subheading">All Site Visitors</p>
        <a href="https://skillgigs.com/privacy/">
          <p className="link-text privacy-policy">
            {" "}
            <img
              src={HandSvg}
              alt="Privacy policy"
              className="handIcon"
            />
            Privacy Policy{" "}
          </p>
        </a>

        <a href="https://skillgigs.com/cookie-policy/">
          <p className="link-text cookie-policy">
            {" "}
            <img src={HandSvg} alt="Cookie policy" className="handIcon" />
            Cookie Policy{" "}
          </p>
        </a>
      </div>
    </Card>
  );
};

export default TermsOfServices;
