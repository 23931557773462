import React, { useState } from 'react';
import { Row, Col, Card, Avatar, Rate, Tag, Typography } from "antd";
import { SettingOutlined, EllipsisOutlined, EnvironmentFilled } from '@ant-design/icons';
import './SkReview.css';
import moment from 'moment';
import { useEffect } from 'react';

const SkillListCard = (props) => {

    const [ bidType, setBidType ] = useState(null);

    useEffect(()=>{
        console.log('TYPE 1:',props.MinimumBidType);
        if (props.MinimumBidType === 'Hour'){
            setBidType('hr');
        }
        if (props.MinimumBidType === 'Week'){
            setBidType('wk');
        }
        if (props.MinimumBidType === 'Year'){
            setBidType('yr');
        }
        if (props.MinimumBidType === 'Monthly'){
            setBidType('mth');
        }
    },[props.MinimumBidType]);

    const { Text } = Typography
    
    return (
        <>
            <Col className='skill-card'
                // extra={<a href="#"><EllipsisOutlined key="ellipsis" /></a>} 
                style={{ width: '90%', margin: 'auto', paddingTop: '10px', paddingBottom: '12px' }}>
                <Row>
                    <Col lg={24} xs={24}>
                        <p className='card-title'>{props.title}</p>
                    </Col>
                    {/* <Col lg={3} xs={3}>
                        <EllipsisOutlined key="ellipsis" style={{ float: 'right' }} />
                    </Col> */}
                </Row>
                <Row >
                    <Col sm={12} xs={12} className='card-left-items'>Job Type</Col>

                    <Col sm={12} xs={12} className='card-right-items'><p>{props.GigType}</p></Col>
                </Row>
                <Row >
                    <Col sm={12} xs={12} className='card-left-items'>Willing to Relocate</Col>

                    <Col sm={12} xs={12} className='card-right-items'><p>{props.Location}</p></Col>
                </Row>
                <Row >
                    <Col sm={12} xs={12} className='card-left-items'>Skills</Col>

                    <Col sm={12} xs={12} className='card-right-items'>{props.Skills.map((skills) => {
                        return (<p>{skills}</p>)
                    })}</Col>
                </Row>

                <Row >
                    <Col sm={12} xs={12} className='card-left-items'>Available to Start</Col>

                    <Col sm={12} xs={12} className='card-right-items'><p>{moment(props.StartDate).format('DD/MM/YYYY')}</p></Col>
                </Row>

                <Row >
                    <Col sm={12} xs={12} className='card-left-items'>Desired Salary</Col>

                    <Col sm={12} xs={12} className='card-right-items'>
                        {props.MinimumBid ?
                            <Text style={{ fontWeight: 'bold' }}>${props.MinimumBid}/{props.MinimumBidType ? bidType : 'wk'}</Text> :
                            <Text style={{ fontWeight: 'bold' }}>$Negotiable</Text>
                        }
                    </Col>
                </Row>
                <Row>
                    {/* <Col lg={16}><Tag className='Tag'>Published</Tag>
                    </Col> */}
                    {/* <Col lg={8} className='time'><span>{moment(props.PublishedDate).fromNow()}</span></Col> */}
                </Row>
            </Col>
        </>
    );
}

export default SkillListCard;