import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { CheckCircleFilled, CloseOutlined, InfoCircleFilled } from "@ant-design/icons";
import "./TopAlert.css";

const TopAlert = ({ display, title, type }) => {
   const [alertDisplay, setAlertDisplay] = useState('none');
   const [boxDisplay, setDisplay] = useState(display);
   const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

   function getWindowDimensions() {
      const { innerWidth: width, innerHeight: height } = window;
      return {
         width,
         height,
      };
   }

   useEffect(() => {
      function handleResize() {
         setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
   }, []);


   useEffect(() => {
      if (display != 'none')
         setDisplay('flex')
      setTimeout(() => {
         setDisplay('none')
      }, 4000);
   }, [display]);

   const modalStyle = {
      display: display ? boxDisplay : alertDisplay,
      position: 'fixed',
      width: '100%',
      height: windowDimensions.width > 768 ? '4.3rem' : '7.5vh',
      left: '0',
      zIndex: '2000',
      top: '0',
      margin: 'auto',
      alignItems: 'center',
      background: type == 'success' ? '#118936' : type == 'warning' ? '#f3bd1a' : type == 'error' && 'red'
   };

   const closeAlert = () => {
      setAlertDisplay('none');
      setDisplay('none')
   };
   return (
      <>
         <div className="modal" style={modalStyle}>
            <div className="content-modal">
               <Row className='modal-body-row'>
                  <Col xs={22} className={'msg-alert'}>
                     {type == 'success' ?
                        <CheckCircleFilled style={{ paddingRight: '10px', paddingLeft: '15px' }} />
                        :
                        type == 'error' &&
                        <InfoCircleFilled style={{ paddingRight: '10px', paddingLeft: '15px' }} />
                     }
                     {title}
                  </Col>
                  <Col style={{ display: 'flex' }} xs={2} className="close-btn-div" onClick={closeAlert}><CloseOutlined className='close-btn' /></Col>
               </Row>
            </div>
         </div>
      </>
   );
}

export default TopAlert;
