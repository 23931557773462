import React, { useState, useEffect } from "react";
import { Cache } from "aws-amplify";
import { useHistory } from "react-router-dom";

// aws configuration
import { Auth, Hub } from "../../../config/aws-exports";

// Redux
import { connect } from "react-redux";
import { updateUserInfo } from "../../../redux/actions";

// react icons
import { FcGoogle } from "react-icons/fc";
import { BsLinkedin } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { GrApple } from "react-icons/gr";

//import "./SocialOptions.css";
import { REACT_APP_LinkedInClientID, REACT_APP_MY_URL } from "../../../config";

const SocialOptions = (props) => {
  // const [user, setUser] = useState(null);

  // useEffect(() => {
  //   Hub.listen("auth", ({ payload: { event, data } }) => {
  //     switch (event) {
  //       case "signIn":
  //       case "cognitoHostedUI":
  //         getUser().then((userData) => setUser(userData));
  //         break;
  //       case "signOut":
  //         setUser(null);
  //         break;
  //       case "signIn_failure":
  //       case "cognitoHostedUI_failure":
  //         console.log("Sign in failure", data);
  //         break;
  //     }
  //   });

  //   getUser().then((userData) => setUser(userData));
  // }, [user]);

  // function getUser() {
  //   return Auth.currentAuthenticatedUser()
  //     .then((userData) => userData)
  //     .catch(() => console.log("Not signed in"));
  // }

  return (
    <div>
      <section className="socialButtonsTop">
        <div
          className="socialButton facebookColor"
          onClick={() => {
            Auth.federatedSignIn({ provider: "Facebook" });
          }}
        >
          <BsFacebook
            size={16}
            style={{
              position: "relative",
              top: "4px",
              marginLeft: "1.875rem",
            }}
          />
          <span className="buttonFont">Continue with Facebook</span>
        </div>

        <div
          className="socialButton googleColor"
          onClick={() => {
            Auth.federatedSignIn({ provider: "Google" });
          }}
        >
          <FcGoogle
            size={16}
            style={{
              position: "relative",
              top: "4px",
              marginLeft: "1.875rem",
            }}
          />
          <span className="whiteBgButtonFont">Continue with Google</span>
        </div>
        <div
          className="socialButton appleColor"
          onClick={() => {
            Auth.federatedSignIn({ provider: "SignInWithApple" });
          }}
        >
          <GrApple
            size={16}
            style={{
              position: "relative",
              top: "3px",
              color: "black",
              marginLeft: "1.875rem",
            }}
          />
          <span
            className="whiteBgButtonFont"
            style={{ marginLeft: "2.25rem" }}
          >
            Continue with Apple
          </span>
        </div>
        <a
          href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${REACT_APP_LinkedInClientID}&redirect_uri=${REACT_APP_MY_URL}/linkcheck&state=foobar&scope=r_liteprofile%20r_emailaddress`}
        >
          {/*<a href="https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77vyvft64zvktg&redirect_uri=http://localhost:3000/auth/linkedin/callback&state=foobar&scope=r_liteprofile">*/}
          <div className="socialButton linkedINColor">
            <BsLinkedin
              size={16}
              style={{
                position: "relative",
                top: "4px",
                marginLeft: "1.875rem",
                marginRight: "0.15rem",
              }}
            />
            <span
              className="buttonFont"
              style={{ marginLeft: "1.625rem" }}
            >
              Continue with Linkedin
            </span>
          </div>
        </a>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    error: state.authReducer.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendAuthToken: (params) => {
      dispatch(updateUserInfo(params)); // action dispatched
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialOptions);
