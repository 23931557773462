// import { call, put, takeEvery } from "redux-saga/effects";
// import { types } from "../action-types";
// import { createInterviewApi } from "../../API/API";

// function* createInterviewSaga(SourceId, onModel, InterviewStatus, ClientProfileId, ConsultantProfileId,DeclineReasonId, GigId, SourceTypeName, Reason, ApprovedScheduledTiming,ScheduledTimings) {
// try {
//         // const {SourceId, onModel, InterviewStatus, ClientProfileId, ConsultantProfileId,DeclineReasonId, GigId, SourceTypeName, Reason, ApprovedScheduledTiming,ScheduledTimings} = action.payload;
//  const response = yield call(()=>createInterviewApi(SourceId,onModel,InterviewStatus,ClientProfileId,ConsultantProfileId, DeclineReasonId, GigId, SourceTypeName, Reason, ApprovedScheduledTiming, ScheduledTimings));
//   yield put({type: types.CREATE_INTERVIEW_SUCCESS, payload: response.data});
//     } catch (error) {
//         yield put({type: types.CREATE_INTERVIEW_FAILURE, error});
//     }
// }

// export function* watchCreateInterviewSaga() {
//     yield takeEvery(types.CREATE_INTERVIEW_REQUESTED, createInterviewSaga);
// }


import { call, put, takeEvery } from "redux-saga/effects";
import { types } from "../action-types";
import { createInterviewApi } from "../../API/API";

function* createInterviewSaga(params) {

    console.log('in saga ' , params)
try {const response = yield call(()=>createInterviewApi(params));
    yield put({type: types.CREATE_INTERVIEW_SUCCESS, payload: response.data});
      } catch (error) {
          yield put({type: types.CREATE_INTERVIEW_FAILURE, error});}}
 
  export function* watchCreateInterviewSaga() {
      yield takeEvery(types.CREATE_INTERVIEW_REQUESTED, createInterviewSaga);
  }
