import store from "../../../redux/store";
import axios from "axios";

export const getImage = async (s3key) => {
  if (s3key) {
    const token = store.getState().authReducer.user.authToken;
    const headers = { "Content-Type": "application/json", Authorization: token };
    const response = await axios.post(
      `https://mrcnet4vhh.execute-api.us-east-2.amazonaws.com/development/upload/get`,
      {
        s3key: s3key,
      },
      { headers }
    );
    return response.data;
  }
};

export const downloadAttachments = async (s3Key) => {
  var response = ''
  if (s3Key) {
    const token = store.getState().authReducer.user.authToken;
    const headers = { "Content-Type": "application/json", Authorization: token };
    const results = await axios.get(
      `https://mrcnet4vhh.execute-api.us-east-2.amazonaws.com/development/message/getattachment/${s3Key}`,
      { headers }
    ).then(result => {
      console.log('promise result', result);
      response = result.data.downloadURL;
    })
    return response
  }
}
